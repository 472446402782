import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TextElement from "../UFDiagramsUtils/TextElement";
import { MODULE_DETAIL_FIELDS } from "../UFDiagramsUtils/UFDiagramConstants";
import { convertUpto2Digits } from "../UFDiagramsUtils/UFDiagramHelper";

const UFDiagramModuleDetails = () => {
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const { ufModules, data, calcEngineData, activeUFModule } = useSelector(state => state.UFStore);
  const { uFModuleID } = data;
  const [selectedModule, setSelectedModule] = useState("");
  const [operatingFlux, setOperatingFlux] = useState("");
  const [totalModulesText, setTotalModulesText] = useState("");
  const [ufModulesText, setUFModulesText] = useState("");

  const getModuleName = useCallback(() => {
    const MODULE_NAME_MAX_LENGTH = 25;
    let moduleName = "";
    if (uFModuleID > 0 && ufModules?.length > 0) {
      const selectedModule = ufModules.find(m => m.ufmoduleId === +uFModuleID);
      moduleName = selectedModule?.newModuleLongName;
    }
    return moduleName?.length > MODULE_NAME_MAX_LENGTH
      ? moduleName.slice(0, MODULE_NAME_MAX_LENGTH - 3) + "..."
      : moduleName;
  }, [uFModuleID, ufModules]);

  useEffect(() => {
    const { totalTrains, modulesPerTrain, onlineTrains, redundantStandbyTrains, redundantTrains } = data;
    const totalModules = `${totalTrains} (${onlineTrains} + ${redundantStandbyTrains} + ${redundantTrains})`;
    let result = totalTrains * modulesPerTrain;
    let ufModulesTxt = `${totalTrains} X ${modulesPerTrain}  = ${result}`;
    if (activeUFModule?.integraPacInd || activeUFModule?.tRack) {
      const { modulesPerSkid, skidsPerTrain } = data;
      result = totalTrains * modulesPerSkid * skidsPerTrain;
      ufModulesTxt = `${totalTrains} X (${modulesPerSkid} X ${skidsPerTrain}) = ${result}`;
    }
    setTotalModulesText(totalModules);
    setUFModulesText(ufModulesTxt);
  }, [data]);

  useEffect(() => {
    const selectedUnit = unitConfig.selectedUnits[4];
    let value = calcEngineData.flux_Filter_actual || 0;
    value = convertUpto2Digits(value);
    setOperatingFlux(`${value} ${selectedUnit}`);
  }, [unitConfig.selectedUnits, calcEngineData]);

  useEffect(() => setSelectedModule(getModuleName), [getModuleName]);

  const getUFRecovery = () => {
    let value = 0;
    const recovery_UF = calcEngineData?.recovery_UF;
    if (recovery_UF) {
      value = convertUpto2Digits(recovery_UF * 100);
    }
    return `${value}%`;
  };

  return (
    <g id='module_details'>
      <rect
        id='Rectangle 963'
        x='140'
        y='205'
        width='2000'
        height='820'
        fill='#F9F9F9'
        stroke='#E1E1E1'
        strokeWidth='10'
      />

      <TextElement fontWeight='bold'>
        {MODULE_DETAIL_FIELDS.map((item, index) => (
          <tspan key={index} x='240' y={item.y}>
            {item.text}
          </tspan>
        ))}
      </TextElement>
      <TextElement>
        <tspan x='730' y='370'>
          {selectedModule}
        </tspan>
        <tspan x='1100' y='510'>
          {totalModulesText}
        </tspan>
        <tspan x='930' y='650'>
          {ufModulesText}
        </tspan>
        <tspan x='1130' y='790'>
          {operatingFlux}
        </tspan>
        <tspan x='1400' y='930'>
          {getUFRecovery()}
        </tspan>
      </TextElement>
    </g>
  );
};

export default React.memo(UFDiagramModuleDetails);
