import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import DupontLogger from "@utils/DupontLogger";

import { useCreateDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import {
  FINAL_PARAMETER_LIST,
  PROPERTIES_TO_CONVERT_FPA_DATA,
  PROPERTIES_TO_CONVERT_SAVING_DATA,
  RESIN_ID,
} from "@features/feedwater/ix/constants/IXDConstants";
import { convertAndFormatProperties } from "@features/feedwater/ix/IXConfiguration/IXhelper";
import {
  updateFlagIsSaveIXDReportJson,
  updatelistFinalParamAdj,
  updateShowFinalParameterTab,
  updateVesselCylindricalHeight,
  updatevesselwallThickness,
} from "@features/feedwater/ix/IXDSlice";

import useUnitConversion from "./useUnitConversion";

const { useSelector, useDispatch } = require("react-redux");

const useFPAValidation = () => {
  const dispatch = useDispatch();
  const Logger = DupontLogger("useFPAValidation");
  const { convertUnit, convertUnitNumber } = useUnitConversion();
  const ixStoreObj = useSelector(state => state.IXStore);
  const { listFinalParamAdj, saveVesselCylindricalHeight, data: ixStore } = ixStoreObj || {};
  const { cationResin, anionResin, selectedResinList } = ixStore;
  const [resinItem1, resinItem2] = selectedResinList || [];

  const { projectID, caseId } = useSelector(state => state.projectInfo.data);
  const { IXD } = useSelector(state => state.ReportIXD.tabAvailable);
  const { UserId } = useSelector(state => state.userInfo.data);
  const [getIXDetails] = useLazyGetAllDataQuery();
  const [IXData_PostData] = useCreateDataMutation();

  // The function fetchIXFPADetails is used to call the Final Parameter Adjustment API and fetch data
  const fetchIXFPADetails = async () => {
    try {
      const queryParams = {
        userID: UserId,
        projectID,
        caseID: caseId,
      };
      const params = new URLSearchParams(queryParams).toString();
      const response = await getIXDetails(`${"ix/api/v1/FinalParameter"}?${params}`);
      const { data } = response;
      if (response.isSuccess) {
        if (data?.length > 0) {
          const finalList = data.reduce((acc, item, index) => {
            if (Object.values(item).some(value => value !== null)) {
              const resinNameKey = `resinName${index + 1}`;
              const resinName = ixStoreObj[resinNameKey];
              acc.push({
                ...item,
                resinName: resinName,
                resinType: ixStoreObj.resinData[resinName],
              });
            }
            return acc;
          }, []);
          const vesselWallThickness = finalList.map(item => item.vesselWallThickness).filter(thickness => thickness);
          let counter = 0;
          const layout = [];
          const vesselArraySave = [];
          const addVesselLayout = (layoutType, count) => {
            for (let vesselIndex = 0; vesselIndex < count; vesselIndex++) {
              vesselArraySave.push(vesselWallThickness[counter]);
              counter++;
              layout.push(layoutType);
            }
          };
          const addDoubleVesselLayout = layoutType => {
            vesselArraySave.push(vesselWallThickness[counter]);
            layout.push(layoutType);
            vesselArraySave.push(vesselWallThickness[counter]);
            layout.push(layoutType);
            counter++;
          };
          if ([RESIN_ID.WAC_RESIN_ID, RESIN_ID.SAC_RESIN_ID].includes(cationResin)) addVesselLayout("C", 1);
          if (cationResin == RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID) addVesselLayout("C", 2);
          if ([RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID].includes(cationResin))
            addDoubleVesselLayout("C");
          if ([RESIN_ID.WBA_RESIN_ID, RESIN_ID.SBA_RESIN_ID].includes(anionResin)) addVesselLayout("A", 1);
          if (anionResin == RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID) addVesselLayout("A", 2);
          if ([RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID].includes(anionResin))
            addDoubleVesselLayout("A");
          if ([RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID].includes(anionResin))
            addDoubleVesselLayout("C");
          finalList[0] = { ...finalList[0], ixfpaRadioButtonID: ixStore.listFinalParamAdj[0]?.ixfpaRadioButtonID || 1 };
          const newList = finalList.map((item, index) => {
            const updatedItem = convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_FPA_DATA, convertUnit);
            const vesselWallThickness = convertUnit(vesselArraySave[index], SELECTED_UNIT.LENGTH, UNITS.METER, 0);
            dispatch(
              updateVesselCylindricalHeight({
                isInput: false,
                value: updatedItem.vesselCylindricalHeight,
                index: index,
                freeBoard: updatedItem.freeBoard,
              }),
            );
            return {
              ...updatedItem,
              minVesselCylindricalHeight: updatedItem.vesselCylindricalHeight,
              vesselWallThickness:vesselWallThickness,
              minFreeBoard: updatedItem.freeBoard,
              isCation: layout[index] == "C",
            };
          });
          dispatch(
            updatevesselwallThickness({
              vesselWallThickness0: newList[0].vesselWallThickness,
              vesselWallThickness2: newList[1].vesselWallThickness,
            }),
          );
          dispatch(updatelistFinalParamAdj(newList));
          // The below code is used for saving the FPA data
          if (IXD) {
            dispatch(updateFlagIsSaveIXDReportJson(true));
            const finalParameterList = newList.map(item =>
              convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_SAVING_DATA, convertUnitNumber),
            );
            const MethodName = { Method: "ix/api/v1/IXData" };
            const IXData_Method_Body = {
              ...MethodName,
              ...ixStore,
              listFinalParamAdj: finalParameterList,
              selectedResinList: [
                { ...resinItem1, inert: resinItem1?.inert || 0 },
                { ...resinItem2, inert: resinItem2?.inert || 0 },
              ],
            };
            await IXData_PostData(IXData_Method_Body);
          }
        }
      }
    } catch (error) {
      Logger.log("fetchIXFPADetails", error);
    }
  };

  const validateFPA = () => {
    const invalidVessels =
      listFinalParamAdj?.reduce((acc, item, index) => {
        const vesselHeight = saveVesselCylindricalHeight[`vesselCylindricalHeight${index}`];
        const isValid = vesselHeight.isUserInput ? item.vesselCylindricalHeight > vesselHeight.value : true;
        if (!isValid && !acc.includes(vesselHeight.vesselName)) {
          acc.push(vesselHeight.vesselName);
        }
        return acc;
      }, []) || [];

    let errorMsg = false;
    if (invalidVessels.length) {
      errorMsg = `Vessel height is inconsistent with Resin Volume and Vessel Diameter for ${invalidVessels.join(", ")}`;
    }
    return errorMsg;
  };
  const resetVesselCylindricalHeightData = () => {
    const newData = listFinalParamAdj.map((item, index) => {
      const tempVCH = saveVesselCylindricalHeight[`vesselCylindricalHeight${index}`];
      if (tempVCH.isUserInput) {
        dispatch(updateVesselCylindricalHeight({ isInput: false, index: index }));
        return { ...item, vesselCylindricalHeight: tempVCH.value, freeBoard: tempVCH.freeBoard };
      } else {
        return item;
      }
    });
    dispatch(updatelistFinalParamAdj(newData));
  };

  const updateParameterSettings = () => {
    dispatch(updateShowFinalParameterTab(false));
    dispatch(updatelistFinalParamAdj(Array(4).fill(FINAL_PARAMETER_LIST)));
  };

  const finalParameterDataCalcEngine = (controller, listFinalParamAdjListCalcEngine) => {
    const MAXM_VESSEL_NUMBER = 4;
    let counter = 0;
    const parameterMapping = {
      Custom_Inert_Height: "inertBedHeight",
      Custom_Height: "vesselCylindricalHeight",
      Custom_Diameter: "vesselDiameter",
      Custom_Wall: "vesselWallThickness",
    };
    const selectedParameter = parameterMapping[controller];
    const vesselMeasurmentsData = {};
    const compartMentData = {};
    for (let vesselNumber = 1; vesselNumber <= MAXM_VESSEL_NUMBER; vesselNumber++) {
      vesselMeasurmentsData[`Vessel_${vesselNumber}_${controller}`] = 0;
      compartMentData[`Vessel_${vesselNumber}_Comp_1_${controller}`] = 0;
      compartMentData[`Vessel_${vesselNumber}_Comp_2_${controller}`] = 0;
    }
    const resinMeasurmentsData = [];

    // Function to handle data processing for both cation and anion resins

    const processResinData = (resinTypes, isCation) => {
      const selectedResin = isCation ? cationResin : anionResin;

      if (listFinalParamAdjListCalcEngine.length >= counter && resinTypes.includes(selectedResin)) {
        let newData;
        if (
          [RESIN_ID.SAC_RESIN_ID, RESIN_ID.WAC_RESIN_ID, RESIN_ID.WBA_RESIN_ID, RESIN_ID.SBA_RESIN_ID].includes(
            selectedResin,
          )
        ) {
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter++;
        }

        if ([RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID, RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID].includes(selectedResin)) {
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter++;
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter++;
        }

        if (
          [
            RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID,
            RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID,
            RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID,
          ].includes(selectedResin)
        ) {
          newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          resinMeasurmentsData.push({ value: newData });
          counter += 2;
        }

        if (
          [
            RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID,
            RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID,
            RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID,
          ].includes(selectedResin)
        ) {
          if (controller === "Custom_Diameter" || controller === "Custom_Wall") {
            newData = listFinalParamAdjListCalcEngine[counter][selectedParameter];
          } else {
            newData =
              listFinalParamAdjListCalcEngine[counter][selectedParameter] +
              listFinalParamAdjListCalcEngine[counter + 1][selectedParameter];
          }

          const extraVessel = [
            listFinalParamAdjListCalcEngine[counter][selectedParameter],
            listFinalParamAdjListCalcEngine[counter + 1][selectedParameter],
          ];
          counter += 2;

          resinMeasurmentsData.push({ value: newData, extraVessel });
        }
      }
    };
    // Process cation resins
    if (listFinalParamAdjListCalcEngine && listFinalParamAdjListCalcEngine.length > 0 && IXD) {
      processResinData(
        [
          RESIN_ID.WAC_RESIN_ID,
          RESIN_ID.SAC_RESIN_ID,
          RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID,
          RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID,
          RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID,
          RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID,
          RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID,
        ],
        true,
      );
      // Process anion resins
      processResinData(
        [
          RESIN_ID.WBA_RESIN_ID,
          RESIN_ID.SBA_RESIN_ID,
          RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID,
          RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID,
          RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID,
        ],
        false,
      );
    }

    resinMeasurmentsData?.forEach((item, index) => {
      // Assign the main value to the vesselMeasurmentsData object
      vesselMeasurmentsData[`Vessel_${index + 1}_${controller}`] = item.value;
      // If extraVessel exists, assign each of its values to the compartMentData object
      if (item.extraVessel) {
        item.extraVessel.forEach((extra, i) => {
          compartMentData[`Vessel_${index + 1}_Comp_${i + 1}_${controller}`] = extra;
        });
      }
    });
    return { compartMentData, vesselMeasurmentsData };
  };

  return {
    validateFPA,
    resetVesselCylindricalHeightData,
    finalParameterDataCalcEngine,
    updateParameterSettings,
    fetchIXFPADetails,
  };
};

export default useFPAValidation;
