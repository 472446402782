import React from "react";
import { Modal } from "react-bootstrap";

import CloseCircleIcon from "@common/icons/CloseCircleIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal from "@common/styles/components/modals/CustomModal";

const ProjectErrorPopup = ({ show, close, message }) => (
  <>
    <StyledModal
      show={show}
      onHide={close}
      centered
      backdrop='static'
      keyboard={false}
      isWarningPopUp={true}
      maxWidth='416px'
    >
      <Modal.Body>
        <div className='warning-pop-up'>
          <div>
            <CloseCircleIcon />
          </div>
          <div>
            <CustomHeading fontFamily='DiodrumSemiBold' fontSize='16px' fontWeight='600' label={message} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <StandardPrimaryButton label='Ok' className='ok-btn' onClick={close} />
      </Modal.Footer>
    </StyledModal>
  </>
);

export default ProjectErrorPopup;
