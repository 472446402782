import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { METRIC_UNITS } from "@constants/units.constant";

import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";

const useUnitConversion = () => {
  const { GUnitConversionData, selectedUnits } = useSelector(state => ({
    GUnitConversionData: state.GUnitConversion.data,
    selectedUnits: state.projectInfo?.projectConfig?.unitConfig?.selectedUnits,
  }));

  const convertUnit = useCallback(
    (value, targetUnitIdx, sourceUnit, fixedDigit = 2) => {
      let convertedVal = value;
      const targetUnit = selectedUnits[targetUnitIdx];
      if (value !== null && value !== undefined && targetUnit && targetUnit !== sourceUnit) {
        convertedVal = GlobalUnitConversion(GUnitConversionData, value, targetUnit, sourceUnit);
        convertedVal = fixedDigit ? convertedVal.toFixed(fixedDigit) : convertedVal;
      }
      return convertedVal;
    },
    [GUnitConversionData, selectedUnits],
  );

  const convertUnitInt = useCallback(
    (value, targetUnitIdx, sourceUnit) => convertUnit(value, targetUnitIdx, sourceUnit, 0),
    [convertUnit],
  );

  const unitConversionByName = useCallback((value, targetUnit, sourceUnit, fixedDigit = 2) => {
    let convertedVal = value;
    if (value !== null && value !== undefined && targetUnit !== sourceUnit) {
      convertedVal = GlobalUnitConversion(GUnitConversionData, value, targetUnit, sourceUnit);
      convertedVal = fixedDigit ? convertedVal.toFixed(fixedDigit) : convertedVal;
    }
    return convertedVal;
  }, []);

  const convertUnitNumber = useCallback(
    (value, targetUnit, sourceUnitIdx, fixedDigit = 2) => {
      const sourceUnit = selectedUnits[sourceUnitIdx];
      if (value === null || value === undefined || targetUnit === sourceUnit) return value;
      const convertedVal = GlobalUnitConversion(GUnitConversionData, value, targetUnit, sourceUnit);
      return fixedDigit ? +convertedVal.toFixed(fixedDigit) : convertedVal;
    },
    [GUnitConversionData, selectedUnits],
  );

  // function converts value from metric to user selected unit
  const convertFromMetric = useCallback(
    (value, unitType, fixedDigit = 2) => {
      const targetUnit = selectedUnits[unitType];
      const sourceUnit = METRIC_UNITS[unitType];
      return unitConversionByName(value, targetUnit, sourceUnit, fixedDigit);
    },
    [selectedUnits],
  );

  // function converts value from user selected unit to metric
  const convertToMetric = useCallback(
    (value, unitType, fixedDigit = 2) => {
      const sourceUnit = selectedUnits[unitType];
      const targetUnit = METRIC_UNITS[unitType];
      return unitConversionByName(value, targetUnit, sourceUnit, fixedDigit);
    },
    [selectedUnits],
  );

  return useMemo(
    () => ({
      convertUnit,
      convertUnitInt,
      convertUnitNumber,
      unitConversionByName,
      convertFromMetric,
      convertToMetric,
    }),
    [convertUnit, convertUnitInt, convertUnitNumber, unitConversionByName, convertFromMetric, convertToMetric],
  );
};

export default useUnitConversion;
