import React from "react";

import IconButton from "@components/IconButton";
import CloseIcon from "@components/SVGs/CloseIcon";

import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";

import StyledCustomModal from "./StyledCustomModal";

export const CustomModalButtons = {
  CANCEL: "modal-cancel-btn",
  CONFIRM: "modal-confirm-btn",
  CLOSE: "modal-close-icon",
};

const CustomModal = ({
  header,
  headerHelper,
  confirmBtn,
  confirmBtnDisabled = false,
  cancelBtn,
  children,
  onModalClose,
  customclass,
  width,
}) => {
  const handleBtnClick = id => {
    if (onModalClose) {
      onModalClose({ target: { id } });
    }
  };
  return (
    <StyledCustomModal show centered className={`${customclass}`} width={width}>
      <StyledCustomModal.Header>
        <div className='pump-heading'>
          <CustomHeading
            label={header}
            color={colors.PrimaryDarkAquaMarine}
            fontFamily='DiodrumSemiBold'
            fontSize='16px'
          />
          <CustomHeading
            label={headerHelper}
            color={colors.blackTransparency045}
            fontSize='12px'
            className='sub-heading'
          />
        </div>
        <IconButton id='modal-close-icon' tooltip='Close' onClick={() => handleBtnClick(CustomModalButtons.CLOSE)}>
          <CloseIcon />
        </IconButton>
      </StyledCustomModal.Header>
      <StyledCustomModal.Body>{children}</StyledCustomModal.Body>
      <StyledCustomModal.Footer>
        {cancelBtn && (
          <StandardPrimaryButton
            label={cancelBtn}
            id='modal-cancel-btn'
            onClick={() => handleBtnClick(CustomModalButtons.CANCEL)}
          />
        )}
        {confirmBtn && (
          <StandardPrimaryButton
            label={confirmBtn}
            disabled={confirmBtnDisabled}
            id='modal-confirm-btn'
            onClick={() => handleBtnClick(CustomModalButtons.CONFIRM)}
          />
        )}
      </StyledCustomModal.Footer>
    </StyledCustomModal>
  );
};

export default CustomModal;
