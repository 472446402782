/* eslint-disable max-len */
import React from "react";

const CloseIconBig = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 25 24' fill='none'>
      <path
        d='M14.4922 12.2666L21.9541 3.62826C22.0791 3.4847 21.974 3.2666 21.7807 3.2666H19.5122C19.3786 3.2666 19.2507 3.32458 19.1626 3.42396L13.0083 10.5494L6.854 3.42396C6.76872 3.32458 6.64081 3.2666 6.50436 3.2666H4.23594C4.04264 3.2666 3.93746 3.4847 4.06254 3.62826L11.5244 12.2666L4.06254 20.9049C4.03452 20.9369 4.01655 20.9761 4.01075 21.0177C4.00495 21.0593 4.01157 21.1017 4.02983 21.1398C4.04809 21.1778 4.07721 21.21 4.11375 21.2325C4.15028 21.255 4.19269 21.2668 4.23594 21.2666H6.50436C6.63796 21.2666 6.76588 21.2086 6.854 21.1092L13.0083 13.9838L19.1626 21.1092C19.2479 21.2086 19.3758 21.2666 19.5122 21.2666H21.7807C21.974 21.2666 22.0791 21.0485 21.9541 20.9049L14.4922 12.2666Z'
        fill='black'
        fillOpacity='0.85'
      />
    </svg>
  </>
);

export default CloseIconBig;
