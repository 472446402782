import { useMemo } from "react";

import { convertUptoDigits } from "@utils/appUtils";

import InputReferenceText from "@common/styles/components/headings/InputReferenceText";

const InputRanges = ({ ranges, fixedDigits = 2 }) => {
  const message = useMemo(() => {
    if (!ranges) return null;
    const { minValue, maxValue } = ranges;
    return `Ranges ${convertUptoDigits(minValue, fixedDigits)} - ${convertUptoDigits(maxValue, fixedDigits)}`;
  }, [ranges, fixedDigits]);

  return message ? <InputReferenceText refText={message} /> : null;
};

export default InputRanges;
