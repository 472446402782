import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { FolderStrings } from "@utils/StringConstants";

import CustomModal, { CustomModalButtons } from "@components/CustomModal";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";

import { useCreateDataMutation, useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import { Folderbtnlist, FolderTempbtnlist, FolderupdateLoader } from "../ViewAllFolderSlice";

const FolderUpdatePopup = ({
  isOpen,
  setIsOpen,
  userID,
  folder,
  folders,
  setPopupOperator,
  setIsFolderCreated,
  mode,
}) => {
  const PROJECT_NAME_MAX_LENGTH = 20;

  const [inputValue, setInputValue] = useState({ folderName: folder?.folderName });
  const [Message, setMessage] = useState("");

  const [error, setError] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const [GetRenameData] = useUpdateDataMutation("");
  const [getFolderListData, folderListResponse] = useLazyGetAllDataQuery();
  const [openModal, setOpenModal] = useState(true);
  const [CreatePost] = useCreateDataMutation();
  const isRename = mode === "rename";
  const folderNameIntial = "Untitled Folder - ";

  const dispatch = useDispatch();

  useEffect(() => {
    if (folderListResponse.status === "fulfilled") {
      dispatch(Folderbtnlist(folderListResponse.data));
      dispatch(FolderTempbtnlist(folderListResponse.data));
      dispatch(FolderupdateLoader(false));
    }
  }, [folderListResponse]);

  useEffect(() => {
    if (folders && !folder) {
      const newFolderList = folders.filter(folder => folder.folderName.startsWith(folderNameIntial));
      newFolderList.sort(
        (x, y) => y.folderName.substring(folderNameIntial.length) - x.folderName.substring(folderNameIntial.length),
      );
      const value = newFolderList.length !== 0 ? newFolderList[0].folderName.substring(folderNameIntial.length) : 0;
      const folderValue = isNaN(value) ? 1 : parseInt(value) + 1;
      setInputValue({ ...inputValue, folderName: folderNameIntial + folderValue });
      setError(false);
    }
  }, [folders, folder]);

  useEffect(() => {
    if (isOpen) {
      setOpenModal(true);
    }
  }, [openModal]);

  const handleBlur = () => {
    // handle onBlur logic here
  };

  const handleFocus = index => {
    setIsFocused(index);
  };

  const handleInputChange = e => {
    const isRename = mode === "rename";
    const myvalus = e.target.value;
    const index = folders?.findIndex(item => item.folderName.toLowerCase().trim() === myvalus.toLowerCase().trim());

    if (myvalus.trim() === "") {
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage(
        isRename ? "This field cannot be empty, please enter a folder name." : "The folder name field is required!",
      );
      setError(true);
      return;
    } else if (myvalus.length < 3) {
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage("Folder name,three minimum length required!");
      setError(true);
      return;
    } else if (index > -1) {
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage(isRename ? "Data Exist !!" : "Folder name already exist!");
      setError(true);
      return;
    } else {
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage("");
      setError(false);
    }
  };

  const handleFolderOperation = async e => {
    if (inputValue?.folderName?.trim() === "" || inputValue?.folderName?.length < 3) {
      setError(true);
      return;
    } else {
      let newData = {};
      if (mode === "create") {
        newData = {
          Method: "masterdata/api/v1/Folder",
          userID: userID,
          folderName: inputValue.folderName?.trim(),
        };
      } else if (mode === "rename") {
        newData = {
          Method: "masterdata/api/v1/Folder",
          userID: userID,
          folderID: inputValue.folderID,
          folderName: inputValue.folderName,
        };
      }

      const ResponseValues = mode === "create" ? await CreatePost(newData) : await GetRenameData(newData);
      if (ResponseValues?.data?.responseMessage == "Success") {
        let successMessage = "";
        if (mode === "create") {
          successMessage = FolderStrings.folderCreateSuccess;
          setIsFolderCreated(true);
        } else if (mode === "rename") {
          const prevTitle =
            folder.folderName.length > PROJECT_NAME_MAX_LENGTH
              ? `${folder.folderName.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
              : folder.folderName;
          const newTitle =
            inputValue.folderName.length > PROJECT_NAME_MAX_LENGTH
              ? `${inputValue.folderName.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
              : inputValue.folderName;
          successMessage = `Folder name ${prevTitle} has been successfully renamed to ${newTitle}.`;
          getFolderListData(`masterdata/api/v1/FolderList?userID=${userID}`);
        }
        setPopupOperator({
          type: "success",
          show: true,
          message: successMessage,
          subtext: "",
        });
      } else if (ResponseValues?.error?.data?.responseCode === 400) {
        setPopupOperator({
          type: "error",
          show: true,
          message: FolderStrings.folderExists,
          subtext: "",
        });
      } else {
        setPopupOperator({
          type: "error",
          show: true,
          message: `${ResponseValues?.error?.data?.responseMessage}`,
          subtext: "",
        });
      }
      setIsOpen(false);
      document.body.classList.remove("disable");
    }
  };

  const handleClose = event => {
    const { id } = event.target;
    if (id === CustomModalButtons.CONFIRM) {
      handleFolderOperation();
    } else {
      setIsOpen(false);
    }
  };

  return (
    <CustomModal
      header={isRename ? "Rename Folder" : "Folder Name"}
      confirmBtn='Submit'
      onModalClose={handleClose}
      width='512px'
      confirmBtnDisabled={error}
    >
      <InputWithIcon
        isError={error}
        isWarning={false}
        type='text'
        onBlur={handleBlur}
        onFocus={() => handleFocus(1)}
        isFocused={isFocused === 1}
        maxLength='200'
        inputText={error ? <CloseCircleRedIcon /> : <CloseCircleGreenIcon />}
        unitBgColor='transparent'
        minLength='3'
        value={inputValue.folderName}
        onChange={handleInputChange}
      />
      <ErrorMessage errorIcon={true} style={{ visibility: error ? "visible" : "hidden" }} texMsg={Message} />
    </CustomModal>
  );
};

export default FolderUpdatePopup;
