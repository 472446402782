import { CHEMICALS_SYMBOL_MAPPING } from "@constants/global.constants";

const featureIdToField = {
  1: "mCIP",
  2: "CEB",
};

export const getDisabledUFTech = ufSpecialFeatureID => featureIdToField[ufSpecialFeatureID] || "";

export const convertUpto2Digits = value => (isNaN(parseFloat(value)) ? "0.00" : parseFloat(value).toFixed(2));

export const convertUptoDigits = (value, digits = 2) => {
  const tempValue = isNaN(value) ? 0 : parseFloat(value);
  return tempValue.toFixed(digits);
};

/**
 * Changes the format of a chemical symbol based on a predefined mapping.
 * Replace each occurrence of the 'from' symbol with the 'to' symbol
 *
 * @param {string} chemicalSymbol - The chemical symbol to be formatted.
 * @return {string} - The formatted chemical symbol.
 */
export const changeChemicalFormat = chemicalSymbol => {
  if (!chemicalSymbol) return "0";

  for (const [from, to] of Object.entries(CHEMICALS_SYMBOL_MAPPING)) {
    chemicalSymbol = `${chemicalSymbol}`.replaceAll(from, to);
  }

  return chemicalSymbol;
};

/**
 * Converts a source object into a new object with keys appended by a type.
 * @param {Object} source - The source object.
 * @param {string} type - The type to append to each key.
 * @return {Object} - The new object with keys appended by the type.
 */
export const getChemicalObject = (source, type, disabledField) =>
  Object.entries(source).reduce((result, [key, value]) => {
    const paramValue = disabledField && key.includes(disabledField) ? "0" : value;
    result[`${key}_${type}`] = paramValue;
    return result;
  }, {});

/**
 * Checks if a given technology is disabled.
 *
 * This function checks if the application is running in a production environment
 * and if the provided technology name is "ix" (case insensitive). If both conditions
 * are met, the technology is considered disabled and the function returns true.
 * Otherwise, it returns false.
 *
 * @param {string} techName - The name of the technology to check.
 * @returns {boolean} - Returns true if the technology is disabled, false otherwise.
 */
export const isTechDisabled = techName => {
  const { REACT_APP_IS_IX_DISABLED } = process.env;
  return REACT_APP_IS_IX_DISABLED?.toLowerCase() === "true" && techName?.toLowerCase() === "ix";
};

export const getFolderName = foldername =>
  foldername?.length > 20 ? `${foldername?.substring(0, 20)}...` : foldername;

export const debounce = (callback, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

export const formatDate = date => {
  const originalDate = new Date(date);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  return originalDate.toLocaleString("en-US", options) + " UTC";
};

const validData = (data, label, flag) => {
  const den = flag ? "0" : 0;
  return data ? data[label] : den;
};

export const formatChemicalSymbol = (chemicalSymbol, flag, field = "symbol") =>
  changeChemicalFormat(validData(chemicalSymbol, field, flag)).toString();

export const calculateSum = arr => {
  const sum = arr.reduce((acc, val) => {
    const value = parseFloat(val) || 0;
    return acc + value;
  }, 0);
  return sum;
};

// function returns true if all doses are disabled or total dose value is 0
export const isDoseErr = ({ indicators, doseValues }) => {
  const isAllDosesDisabled = indicators.every(indicator => !indicator);
  const totalDoseValue = calculateSum(doseValues);
  return isAllDosesDisabled || totalDoseValue === 0;
};

export const loadExternalScript = url => {
  if (url) {
    const script = document.createElement("script");
    script.src = url;
    document.body.appendChild(script);
  }
};

export const isValueInRange = (value, ranges) => {
  if (isNaN(value) || !ranges) return false;
  const { minValue, maxValue } = ranges;

  const val = parseFloat(value);
  return val >= minValue && val <= maxValue;
};
