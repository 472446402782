import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Droppable } from "react-drag-and-drop";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconButton from "@components/IconButton";
import PlusIcon from "@components/SVGs/PlusIcon";
import WaveProBrandLogo from "@components/WaveProBrandLogo";

import ArrowRightIcon from "@common/icons/ArrowRightIcon";
import DeleteIcon from "@common/icons/DeleteIcon";
import DeleteTealIcon from "@common/icons/DeleteTealIcon";
import FileIcon from "@common/icons/FileIcon";
import FileTealIcon from "@common/icons/FileTealIcon";
import RecentProjectIcon from "@common/icons/RecentProjectIcon";
import RecentProjectTealIcon from "@common/icons/RecentProjectTealIcon";
import StarIcon from "@common/icons/StarIcon";
import StarTealIcon from "@common/icons/StarTealIcon";
import TabletCloseMenuIcon from "@common/icons/TabletCloseMenuIcon";
import { updateTabAvailable } from "@common/ReportIXDSlice";
import { updateTabAvailableForUF } from "@common/ReportUFSlice";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import CustomeMessagePopup from "@common/utils/CustomeMessagePopup";
import { MyError } from "@common/utils/ErrorCreator";
import { updateGUnitConversion } from "@common/utils/GlobalUnitConversionSlice";

import { useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import FolderUpdatePopup from "@features/home/folder/FolderUpdatePopup";
import { setIsFolderRestored } from "@features/home/ViewAllFolderSlice";

import { btnlist, updateLoader } from "../home/CardListSlice";
import { FolderProjectID, updatetitle } from "../menu/FolderProjectSlice";

import { updateLeftpanel } from "./SideMenuSlice";
import SideMenuUL from "./SideMenuStyled";

const SideMenu = ({ showSideMenu, setShowMenuIcon }) => {
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 1;
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setAlertVisibility] = useState(false);
  const [alertData, setAlert] = useState({ type: "", message: "" });
  const [isFolderCreated, setIsFolderCreated] = useState(false);
  const isFolderRestored = useSelector(state => state.Folderview.isFolderRestored);

  const handleShowAlert = (type, message) => {
    setAlert({ type, message });
    setAlertVisibility(true);
  };

  const handleOpenCreateNewFolder = () => {
    setIsOpen(true);
    setIsFolderCreated(false);
    document.body.classList.add("disable");
  };

  const [activeMenuItem, setActiveMenuItem] = useState("recent");
  const dispatch = useDispatch();
  const [changeIcon, setChangeIcon] = useState(null);
  const [getData, responsedata] = useLazyGetAllDataQuery();
  const [getDataupdate, updateresponsedata] = useLazyGetAllDataQuery();

  const [FoldersName, setFoldersName] = useState("");
  const [getUnitConversion, unitConversionResponse] = useLazyGetAllDataQuery();

  const [popupOperator, setPopupOperator] = useState({
    message: "",
    show: false,
    type: "",
    subtext: "",
  });

  useEffect(() => {
    getUnitConversion(`${"masterdata/api/v1/UnitConversion"}?userID=${1}`);
  }, []);

  useEffect(() => {
    if (unitConversionResponse.isError) {
      throw new MyError("SystemDesig Api Error", unitConversionResponse.error.status, "ApiError");
    }

    if (unitConversionResponse.isSuccess) {
      dispatch(updateGUnitConversion(unitConversionResponse.data));
    }
  }, [unitConversionResponse]);

  useEffect(() => {
    if (responsedata.status !== "fulfilled") {
      getData(`masterdata/api/v1/FolderList?userID=${userID}`);
    } else {
      setFoldersName(responsedata);
    }
  }, [responsedata, FoldersName]);

  useEffect(() => {
    if (isFolderCreated) {
      LoadRecord();
      setIsFolderCreated(false);
      dispatch(setIsFolderRestored(false));
    }
  }, [isFolderCreated]);

  useEffect(() => {
    if (isFolderRestored) {
      LoadRecord();
      dispatch(setIsFolderRestored(false));
    }
  }, [isFolderRestored]);

  useEffect(() => {
    if (userID > 0) {
      getData(`masterdata/api/v1/FolderList?userID=${userID}`);
    }
  }, [userID]);

  useEffect(() => {
    if (updateresponsedata.status == "fulfilled") {
      dispatch(btnlist(updateresponsedata));
    }
  }, [updateresponsedata]);

  const btnRecentProject = listName => {
    dispatch(updateLeftpanel("masterdata/api/v1/ProjectRecent"));
    setActiveMenuItem(listName);
    dispatch(updateLoader(false));
  };
  const btnAllProject = listName => {
    dispatch(updateLeftpanel("masterdata/api/v1/ProjectAll"));
    setActiveMenuItem(listName);
    dispatch(updateLoader(false));
  };
  const btnfProject = listName => {
    dispatch(updateLeftpanel("masterdata/api/v1/ProjectFavorite"));
    setActiveMenuItem(listName);
    dispatch(updateLoader(false));
  };
  const btndeleteProject = listName => {
    dispatch(updateLeftpanel("masterdata/api/v1/ProjectDeleted"));
    setActiveMenuItem(listName);
    dispatch(updateLoader(false));
  };
  const btnFolderList = listName => {
    dispatch(updateLeftpanel("masterdata/api/v1/FolderList"));
    setActiveMenuItem(listName);
  };
  const btnFolderProject = (listName, e, Folder) => {
    dispatch(updateLeftpanel("masterdata/api/v1/FolderProject"));
    dispatch(FolderProjectID(e));
    dispatch(updatetitle(Folder));
    setselectedfolderID(e);
    setActiveMenuItem(listName);
    dispatch(updateLoader(false));
  };
  const [MovefoldeprojectData] = useUpdateDataMutation();
  const LoadRecord = () => {
    getData(`masterdata/api/v1/FolderList?userID=${userID}`);
  };
  const [folderID, setfolderID] = useState("");
  const [selectedfolderID, setselectedfolderID] = useState();
  const [dropdata, setdrodata] = useState([]);

  const handleMouseMove = e => {
    setfolderID("");
    if (dropdata.length > 0) {
      if (e !== folderID) {
        DragandDrop(e);
        setdrodata("");
      }
      setfolderID(e);
    }
  };
  const DragandDrop = async e => {
    const IndexData = FoldersName.data.filter(item => item.folderID === e);

    if (dropdata[3] == "false") {
      const MoveData = {
        Method: "masterdata/api/v1/ProjectFolderIDMapping",
        userID: userID,
        projectID: dropdata[0],
        folderID: e,
      };

      const MoveResponseValues = await MovefoldeprojectData(MoveData);
      if (MoveResponseValues.data.responseMessage == "Success") {
        const message = `Project '${dropdata[1]}' has been moved to '${IndexData[0].folderName}'.`;
        handleShowAlert("success", message);
        if (activeMenuItem == "recent") {
          getDataupdate(`masterdata/api/v1/ProjectRecent?userID=${userID}`);
        }
        if (activeMenuItem == "allProject") {
          getDataupdate(`masterdata/api/v1/ProjectAll?userID=${userID}`);
        }
        if (activeMenuItem == "favProject") {
          getDataupdate(`masterdata/api/v1/ProjectFavorite?userID=${userID}`);
        }
      } else if (MoveResponseValues.error.data.responseMessage == "Failure") {
        const errorMessage = `${MoveResponseValues.error.data.responseMessage}`;
        handleShowAlert("error", errorMessage);
      } else {
        const warningMessage = `${MoveResponseValues.data.responseMessage}`;
        handleShowAlert("warning", warningMessage);
      }
    }
  };
  const onItemDrop = async data => {
    const answer_array = data.item.split(",");
    setdrodata(answer_array);
  };

  const handleCloseMenu = () => {
    setShowMenuIcon(false);
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home");
    dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
    dispatch(updateTabAvailableForUF({ FeedSetup: false, UF: false }));
  };

  const handleCloseOperator = () => {
    setPopupOperator({
      message: "",
      show: false,
      type: "",
      subtext: "",
    });
  };

  const handleFolderPopupClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SideMenuUL showSideMenu={showSideMenu}>
        {!showSideMenu ? (
          ""
        ) : (
          <div className='tablet_menu_view'>
            <button className='close_icon_btn' onClick={handleCloseMenu}>
              <TabletCloseMenuIcon />
            </button>
            <WaveProBrandLogo handleNavigate={handleNavigate} />
          </div>
        )}
        <div className='import-div'>
          <StandardSecondaryButton href='#' className='import-project-btn' disabled={true} label='Import Project' />
        </div>
        <div className='project_navigation_menu'>
          <ul className='project_navigation_menu_list'>
            <li
              className={activeMenuItem === "recent" ? "li-active" : ""}
              onClick={() => btnRecentProject("recent")}
              onMouseEnter={() => setChangeIcon("recent")}
              onMouseLeave={() => setChangeIcon(null)}
            >
              <CustomLabel label='Recent Projects'>
                {activeMenuItem === "recent" || changeIcon === "recent" ? (
                  <RecentProjectTealIcon />
                ) : (
                  <RecentProjectIcon />
                )}
              </CustomLabel>
            </li>
            <li
              className={activeMenuItem === "allProject" ? "li-active" : ""}
              onClick={() => btnAllProject("allProject")}
              onMouseEnter={() => setChangeIcon("allProject")}
              onMouseLeave={() => setChangeIcon(null)}
            >
              <CustomLabel label='All Projects'>
                {activeMenuItem === "allProject" || changeIcon === "allProject" ? <FileTealIcon /> : <FileIcon />}
              </CustomLabel>
            </li>
            <li
              className={activeMenuItem === "favProject" ? "li-active" : ""}
              onClick={() => btnfProject("favProject")}
              onMouseEnter={() => setChangeIcon("favProject")}
              onMouseLeave={() => setChangeIcon(null)}
            >
              <CustomLabel label='Favourite Projects'>
                {activeMenuItem === "favProject" || changeIcon === "favProject" ? <StarTealIcon /> : <StarIcon />}
              </CustomLabel>
            </li>
            <li
              className={activeMenuItem === "delProject" ? "li-active" : ""}
              onClick={() => btndeleteProject("delProject")}
              onMouseEnter={() => setChangeIcon("delProject")}
              onMouseLeave={() => setChangeIcon(null)}
            >
              <CustomLabel label='Deleted'>
                {activeMenuItem === "delProject" || changeIcon === "delProject" ? <DeleteTealIcon /> : <DeleteIcon />}
              </CustomLabel>
            </li>
          </ul>
        </div>
        <span className='divider_line'></span>
        <div className='d-flex'>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <div onClick={LoadRecord}>Folders</div>
              </Accordion.Header>
              <div className='d-flex align-items-start information'>
                <div
                  className={activeMenuItem === "FolderList" ? "li-active" : ""}
                  onClick={() => btnFolderList("FolderList")}
                >
                  <a href='#' className='view-all-folder' onClick={btnFolderList}>
                    View all folders <ArrowRightIcon />
                  </a>
                </div>
              </div>
              <Accordion.Body>
                <ul className='list-unstyled folder-list'>
                  {FoldersName.data?.map((folder, ind) => (
                    <Droppable types={["item"]} onDrop={onItemDrop} key={`droppable-${ind}`}>
                      <li
                        key={`droppable-list-item-${ind}`}
                        onMouseOver={() => handleMouseMove(folder.folderID)}
                        className={
                          activeMenuItem === "FolderProject"
                            ? folder.folderID == selectedfolderID
                              ? "li-active wrapText"
                              : "wrapText"
                            : "wrapText"
                        }
                        onClick={() => btnFolderProject("FolderProject", folder.folderID, folder.folderName)}
                      >
                        {folder.folderName}
                      </li>
                    </Droppable>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <IconButton
            className='create-folder text-right'
            id='create-folder-icon'
            tooltip='Create new folder'
            onClick={handleOpenCreateNewFolder}
          >
            <PlusIcon />
          </IconButton>
        </div>
      </SideMenuUL>
      {isOpen && (
        <FolderUpdatePopup
          isOpen={isOpen}
          userID={userID}
          setIsOpen={setIsOpen}
          folders={FoldersName?.data}
          isFolderCreated={isFolderCreated}
          setIsFolderCreated={setIsFolderCreated}
          mode={"create"}
          handleCloseRenameProject={handleFolderPopupClose}
          popupOperator={popupOperator}
          setPopupOperator={setPopupOperator}
        />
      )}
      <CustomeMessagePopup operator={popupOperator} close={handleCloseOperator} />

      {/* )} */}
      {/* {isOpen && <Overlay>
        <div className="overlay" />
      </Overlay>} */}
    </>
  );
};

export default SideMenu;
