import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useUFConfig from "@hooks/useUFConfig";

import { UFDiagramDuPontCIPBackWash, UFDiagramDuPontCIPFiltrate, UFDiagramIngeWithoutCIPBackwash, UFDiagramIngeWithoutCIPFiltrate } from "@components/SVGs";
import UFDiagramIngeCIPBackwash from "@components/SVGs/UFDiagrams/UFDiagramIngeCIPBackwash";
import UFDiagramIngeCIPFiltrate from "@components/SVGs/UFDiagrams/UFDiagramIngeCIPFiltrate";

import { setIsSysDiagramVisible } from "../UFSlice";

import StyledUFSystemDesignDiagram from "./StyledUFSystemDesignDiagram";

const UFSystemDesignDiagram = () => {
  const dispatch = useDispatch();
  const { fetchUFConfig } = useUFConfig();
  const { data, ufCompany } = useSelector(state => state.UFStore);

  const [accordionOpen, setAccordionOpen] = useState(false);
  const [isBWOnlyDiagram, setIsBWOnlyDiagram] = useState(false);

  const handleAccordionOpen = () => setAccordionOpen(prev => !prev);

  useEffect(() => setIsBWOnlyDiagram(data.uFBWCEBStandbyOptionID === 2), [data.uFBWCEBStandbyOptionID]);

  useEffect(() => {
    if (accordionOpen) {
      fetchUFConfig();
    }
    dispatch(setIsSysDiagramVisible(accordionOpen));
  }, [accordionOpen]);

  const getSystemDiagram = () => {
    const isTechInge = ufCompany?.toLowerCase() === "inge";
    let DiagramComponent;

    if (isTechInge) {
      DiagramComponent = data.cIP > 0
        ? isBWOnlyDiagram ? UFDiagramIngeCIPBackwash : UFDiagramIngeCIPFiltrate
        : isBWOnlyDiagram ? UFDiagramIngeWithoutCIPBackwash : UFDiagramIngeWithoutCIPFiltrate;
    } else {
      DiagramComponent = isBWOnlyDiagram ? UFDiagramDuPontCIPBackWash : UFDiagramDuPontCIPFiltrate;
    }

    return <DiagramComponent />;
  };

  return (
    <>
      <StyledUFSystemDesignDiagram lg={12} md={12} sm={12} className='system-diagram-column'>
        <Accordion>
          <Accordion.Item eventKey='0'>
            <Accordion.Header onClick={handleAccordionOpen} as={Card.Header}>
              UF System Diagram
              {accordionOpen ? <span>Hide</span> : <span>Show</span>}
            </Accordion.Header>
            <Accordion.Body className='image-container'>
              {accordionOpen && getSystemDiagram()}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </StyledUFSystemDesignDiagram>
    </>
  );
};

export default UFSystemDesignDiagram;
