import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DupontLogger from "@utils/DupontLogger";

import { updateGlobalData } from "@common/GlobalDataSlice";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

const useGlobalDataHandler = () => {
  const Logger = DupontLogger("useGlobalDataHandler");
  const dispatch = useDispatch();
  const [getDataAPI] = useLazyGetAllDataQuery();

  const { countries, marketSegments } = useSelector(state => state.globalAppData);
  const { UserId } = useSelector(state => state.userInfo.data);

  const fetchCountries = async () => {
    if (countries.length > 0) return;
    try {
      const response = await getDataAPI("masterdata/api/v1/Country");
      dispatch(updateGlobalData({ type: "countries", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching countries: ", error);
    }
  };

  const fetchUnreadNotifications = useCallback(async userID => {
    try {
      const response = await getDataAPI(`masterdata/api/v1/UnreadNotification?userID=${userID}`);
      dispatch(updateGlobalData({ type: "unreadNotifications", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching unread notifications: ", error);
    }
  });

  const fetchMarketSegments = useCallback(async userID => {
    if (marketSegments.length > 0) return;
    try {
      const response = await getDataAPI(`masterdata/api/v1/MarketSegment?userID=${userID}`);
      dispatch(updateGlobalData({ type: "marketSegments", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching unread notifications: ", error);
    }
  });

  useEffect(() => {
    Logger.info("fetchUnreadNotifications UserId: ", UserId);
    if (UserId) {
      fetchUnreadNotifications(UserId);
      fetchMarketSegments(UserId);
    }
  }, [UserId]);

  return { fetchCountries };
};

export default useGlobalDataHandler;
