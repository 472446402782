import React, { useState } from "react";
import Select, { components } from "react-select";

import AccordionDownArrowIcon from "@common/icons/AccordionDownArrowIcon";
import AccordionUpArrowIcon from "@common/icons/AccordionUpArrowIcon";

import "./index.css";

const CustomDropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <AccordionUpArrowIcon /> : <AccordionDownArrowIcon />}
    </components.DropdownIndicator>
  );
const DupontDropdown = ({ options, defaultValue, onChange, value }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = selectedOption => {
    onChange(selectedOption);
  };

  return (
    <div className='dropdown-container'>
      <Select
        defaultValue={defaultValue}
        value={value}
        isMulti
        closeMenuOnSelect={false}
        name='dropwdown'
        onChange={handleChange}
        options={options}
        className='basic-multi-select'
        classNamePrefix='select'
        placeholder='Select type/category'
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
      />
    </div>
  );
};

export default DupontDropdown;
