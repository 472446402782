export const validateTemperature = (tempRanges, tempType, value, defaultValues) => {
  let errors = {};
  const { soft, hard } = tempRanges;
  const maxValue = parseFloat(defaultValues.Maximum);
  const minValue = parseFloat(defaultValues.Minimum);
  const designValue = parseFloat(defaultValues.Design);
  const inputValue = parseFloat(value);
  const hardMax = parseFloat(hard.max);
  const softMin = parseFloat(soft.min);
  const softMax = parseFloat(soft.max);

  switch (tempType) {
    case "Minimum": {
      if (inputValue <= designValue) {
        if (inputValue > hardMax) {
          errors = { Minimum: { isError: true } };
        } else if (inputValue < softMin || (inputValue > softMax && inputValue <= hardMax)) {
          errors = { Minimum: { isWarning: true } };
        } else {
          errors = {};
        }
      } else {
        errors = { Minimum: { isError: true } };
      }
      break;
    }
    case "Design": {
      if (minValue <= inputValue && inputValue <= maxValue) {
        if (inputValue > hardMax) {
          errors = { Design: { isError: true } };
        } else if (inputValue < softMin || (inputValue > softMax && inputValue <= hardMax)) {
          errors = { Design: { isWarning: true } };
        } else {
          errors = {};
        }
      } else {
        errors = { Design: { isError: true } };
      }
      break;
    }
    case "Maximum": {
      if (designValue <= inputValue) {
        if (inputValue > hardMax) {
          errors = { Maximum: { isError: true } };
        } else if (inputValue < softMin || (inputValue > softMax && inputValue <= hardMax)) {
          errors = { Maximum: { isWarning: true } };
        } else {
          errors = {};
        }
      } else {
        errors = { Maximum: { isError: true } };
      }
      break;
    }
  }
  return errors;
};

export const TEMPERATURE_OUT_OF_RANGE_MESSAGES = {
  Minimum:
    "Minimum Temperature Warning, The Minimum Temperature Value Should be Less Than or Equal to The Design Temperature Value. Please Revise Your Input.",
  Design:
    "Design Temperature Warning, The Design Temperature Value Should be Less Than or Equal to Maximum Temperature and Greater Than or Equal to Minimum Temperature. Please Revise Your Input !",
  Maximum:
    "Maximum Temperature Warning, The Maximum Temperature Value should be Greater Than or Equal to Design Temperature. Please Revise Your Input. !",
};
