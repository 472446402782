import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";
import { ValidationStrings } from "@utils/StringConstants";

import CustomCard from "@components/CustomCard";
import InputRanges from "@components/InputRanges";

import InfoIcon from "@common/icons/InfoIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import WarningMessage from "@common/styles/components/headings/WarningMessage";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";
import { colors } from "@common/styles/Theme";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import {
  CHEMICAL_RANGES,
  MAX_TEMP_CELCIUS,
  MAX_TEMP_CELCIUS_WARNING,
  UF_CHEMICAL_SCREENS,
  WATER_TYPE_IDS,
} from "./constants/UFConstants";
import CIPStyled from "./CIPStyled";
import { setUfDataUpdate, updateUFStoreData } from "./UFSlice";
import UFSystemDesignDiagram from "./UFSystemDesignDiagram";
import { isIngeSelected } from "./ufUtils";

const CIP_FIELDS = {
  recycleTemperature: "Recycle Temperature",
  bWStepInCIP: "Initial BW Cycles",
  cIPRinseSoakCycle: "Rinse/Soak Cycles",
  rinseBWCycle: "Rinse BW Cycles",
  uFCIPWaterTypeID: "CIP Water Source",
  mineral: "Mineral Acid",
  organic: "Organic Acid",
  alkali: "Alkali",
  oxidant: "Oxidant",
  cip_LSI: "CIP Scaling Potential",
  oxidant2: "Surfactant",
  heatingStepDuration: "Heating Step",
  recycleDuration: "Recycle",
  chemicalSoakingDuration_CIP: "Chemical Soaking",
};

const CIP = () => {
  const dispatch = useDispatch();
  const { unitConversionByName } = useUnitConversion();
  const { isValueInPh, getUFChemicalData, getChemicalRanges } = useUFChemicalsHandler();
  //get data from store
  const cipData = useSelector(state => state.UFStore.data);
  const { ufInputRangeConfig, ufInputRangeConfigByWaterType } = useSelector(state => state.UFStore);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const [inputError, setInputError] = useState();
  const { waterType, chemicalType } = useSelector(state => state.UFStore.cipDropdownData);
  const { waterSubTypeID, tempDesign, waterTypeID } =
    useSelector(state => state.Feedsetupdetailsdatapanel.streamData?.lstrequestsavefeedwater[0]?.streams[0]) || {};
  const [isFocused, setIsFocused] = useState(null);

  const provideRange = (label, hardCoded) => {
    waterSubTypeID;
    let foundRange = ufInputRangeConfigByWaterType.find(item => item.label == label && item.waterSubType);
    if (!foundRange) {
      foundRange = ufInputRangeConfig.find(item => item.label == label);
      if (!foundRange) {
        return hardCoded;
      } else {
        return foundRange;
      }
    } else {
      return foundRange;
    }
  };

  const selectedTempUnit = useMemo(() => unit.selectedUnits[SELECTED_UNIT.TEMPERATURE], [unit.selectedUnits]);

  const validations = useMemo(
    () => ({
      ...getChemicalRanges(isValueInPh ? "ph" : "mgL", UF_CHEMICAL_SCREENS.CIP),
      bWStepInCIP: { minValue: 1, maxValue: 10 },
      cIPRinseSoakCycle: { minValue: 1, maxValue: 10 },
      rinseBWCycle: { minValue: 1, maxValue: 10 },
      chemicalSoakingDuration_CIP: provideRange(ValidationStrings.cipSoak, {
        minValue: 5,
        maxValue: 720,
      }),
      recycleTemperature: {
        minValue: +unitConversionByName(tempDesign, selectedTempUnit, UNITS.celsius),
        maxValue: +unitConversionByName(MAX_TEMP_CELCIUS, selectedTempUnit, UNITS.celsius),
      },
      recycleDuration: provideRange(ValidationStrings.cipRecycle, { minValue: 7.5, maxValue: 240 }),
      heatingStepDuration: provideRange(ValidationStrings.cipHeatingStep, {
        minValue: 1,
        maxValue: 360,
        defaultValue: 60,
      }),
    }),
    [isValueInPh, selectedTempUnit],
  );

  const updateStoreData = newData => dispatch(updateUFStoreData(newData));
  const closeErrorMessag = () => setInputError();

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      closeErrorMessag();
    }
  };

  const checkError = id => !(validations[id]?.minValue <= cipData[id] && validations[id]?.maxValue >= cipData[id]);
  const handleFocus = id => setIsFocused(id);

  const handleBlur = event => {
    const { id, name, value } = event.target;
    const field = CIP_FIELDS[id];
    const fixedRange = name === "cIPRinseSoakCycle" || name === "rinseBWCycle" || name === "bWStepInCIP" ? 0 : 2;
    const { minValue, maxValue } = validations[name] || {};
    if (value < minValue || value > maxValue) {
      const error = `The ${field} value entered is outside the allowed range (${convertUptoDigits(minValue, fixedRange)}
                      to ${convertUptoDigits(maxValue, fixedRange)}). Please revise your input.`;
      setInputError(error);
      setTimeout(() => event.target.focus(), 0);
    } else {
      updateStoreData({ data: { [name]: Number(value).toFixed(fixedRange) }, calcEngineDataRefreshCount: 1 });
      setIsFocused(null);
      setInputError();
    }
  };

  const isHeatingStepDisabled = useMemo(() => {
    const recycleTemperature = convertUptoDigits(cipData.recycleTemperature, 1);
    let designTemp = unitConversionByName(tempDesign, selectedTempUnit, UNITS.celsius);
    designTemp = convertUptoDigits(designTemp, 1);
    return +recycleTemperature === +designTemp;
  }, [cipData.recycleTemperature, tempDesign, selectedTempUnit]);

  useEffect(() => {
    const heatingStepDuration = isHeatingStepDisabled
      ? 0
      : cipData.heatingStepDuration === 0
        ? validations["heatingStepDuration"].defaultValue
        : undefined;

    if (heatingStepDuration !== undefined) {
      updateStoreData({ data: { heatingStepDuration }, calcEngineDataRefreshCount: 1 });
    }
  }, [cipData.recycleTemperature, isHeatingStepDisabled]);

  const handleSelect = e => {
    const { value, name, id } = e.target;
    const chemicalValue = getUFChemicalData(id, value, UF_CHEMICAL_SCREENS.CIP);
    const eventData = { target: { id, value: chemicalValue, name: `${id}Value_CIP` } };
    handleInputChange(eventData);
    dispatch(
      updateUFStoreData({ data: { [name]: value.toString() }, calcEngineDataRefreshCount: 1, isUfDataUpdated: true }),
    );
  };

  const handleEnableandDisable = e => {
    const { name, checked, id } = e.target;
    const nameValue = `${id}ChemId_CIP`;

    const transferData = {
      target: {
        name: nameValue,
        value: checked ? chemicalType[id][0].id.toString() : 0,
        id: id,
      },
    };
    handleSelect(transferData);
    updateStoreData({ data: { [name]: checked }, calcEngineDataRefreshCount: 1 });
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    if (!isNaN(value)) {
      updateStoreData({ data: { [name]: value } });
      dispatch(setUfDataUpdate(true));
    }
  };

  const isRecycleTempError = useMemo(() => {
    const { recycleTemperature } = cipData;
    const { minValue, maxValue } = validations.recycleTemperature;
    const tempWarningUpperLimit = unitConversionByName(MAX_TEMP_CELCIUS_WARNING, selectedTempUnit, UNITS.celsius);
    const errors = {
      warning:
        recycleTemperature > tempWarningUpperLimit ? `Warning Ranges (${minValue} - ${tempWarningUpperLimit})` : null,
      error:
        recycleTemperature < minValue || recycleTemperature > maxValue
          ? `Values out of range(${minValue} - ${maxValue})`
          : null,
    };
    return errors;
  }, [cipData.recycleTemperature]);

  const handleKeyDownEvent = evt =>
    ["e", "E", "+", "-", "ArrowUp", "ArrowDown", "."].includes(evt.key) && evt.preventDefault();

  return (
    <>
      <CIPStyled>
        <UFSystemDesignDiagram />
        <div className='cip-temp-water-cycle'>
          <CustomCard
            className='cip-temp-card'
            header='CIP Temperature'
            tooltipLabel='CIPs can be done with heated water to improve cleaning efficiency'
          >
            <div className='temp-cip'>
              <CustomLabel label={CIP_FIELDS.recycleTemperature} />
              <InputWithText
                id='recycleTemperature'
                name='recycleTemperature'
                type='number'
                isError={checkError("recycleTemperature")}
                isWarning={isRecycleTempError.warning}
                inputText={selectedTempUnit}
                placeholder='0.00'
                onChange={handleInputChange}
                value={
                  isFocused === "recycleTemperature"
                    ? cipData.recycleTemperature
                    : Number.parseFloat(cipData.recycleTemperature).toFixed(1)
                }
                isFocused={isFocused === "recycleTemperature"}
                onBlur={handleBlur}
                onFocus={() => handleFocus("recycleTemperature")}
              />

              {isRecycleTempError.error ? (
                <ErrorMessage texMsg={isRecycleTempError.error} />
              ) : isRecycleTempError.warning ? (
                <WarningMessage txtMsg={isRecycleTempError.warning} />
              ) : (
                <InputRanges ranges={validations.recycleTemperature} fixedDigits={1} />
              )}
            </div>
          </CustomCard>
          <CustomCard
            className='cycles-card'
            header='Cycles'
            tooltipLabel='Provide number of initial BW cycles, chemical rinse/soak cycles, and final rinse BW cycles.'
          >
            <div className='cycle-input-wrapper'>
              <div className='initial-bw-cycle'>
                <CustomLabel label={CIP_FIELDS.bWStepInCIP} />
                <CustomInput
                  type='number'
                  id='bWStepInCIP'
                  name='bWStepInCIP'
                  isError={checkError("bWStepInCIP")}
                  placeholder='0'
                  value={
                    isFocused === "bWStepInCIP"
                      ? cipData.bWStepInCIP
                      : Number.parseFloat(cipData.bWStepInCIP).toFixed(0)
                  }
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isFocused={isFocused === "bWStepInCIP"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("bWStepInCIP")}
                />
                <InputRanges ranges={validations.bWStepInCIP} fixedDigits={0} />
              </div>
              <div className='rinse-soak-cycle'>
                <CustomLabel label={CIP_FIELDS.cIPRinseSoakCycle} />
                <CustomInput
                  type='number'
                  id='cIPRinseSoakCycle'
                  name='cIPRinseSoakCycle'
                  value={
                    isFocused === "cIPRinseSoakCycle"
                      ? cipData.cIPRinseSoakCycle
                      : Number.parseFloat(cipData.cIPRinseSoakCycle).toFixed(0)
                  }
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isError={checkError("cIPRinseSoakCycle")}
                  placeholder='0'
                  defaultValue='1'
                  isFocused={isFocused === "cIPRinseSoakCycle"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("cIPRinseSoakCycle")}
                />
                <InputRanges ranges={validations.cIPRinseSoakCycle} fixedDigits={0} />
              </div>
              <div className='rinse-bw-cycle'>
                <CustomLabel label={CIP_FIELDS.rinseBWCycle} />
                <CustomInput
                  type='number'
                  id='rinseBWCycle'
                  name='rinseBWCycle'
                  value={
                    isFocused === "rinseBWCycle"
                      ? cipData.rinseBWCycle
                      : Number.parseFloat(cipData.rinseBWCycle).toFixed(0)
                  }
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDownEvent}
                  onWheel={e => e.target.blur()}
                  isError={checkError("rinseBWCycle")}
                  placeholder='0'
                  isFocused={isFocused === "rinseBWCycle"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("rinseBWCycle")}
                />
                <InputRanges ranges={validations.rinseBWCycle} fixedDigits={0} />
              </div>
            </div>
          </CustomCard>
          <CustomCard
            className='cip-water-card'
            header='Water Source'
            tooltipLabel='Select source of water for cleaning protocols, RO permeate available if RO in design.'
          >
            <div>
              <CustomLabel label={CIP_FIELDS.uFCIPWaterTypeID} />
              <CustomSelect
                onChange={handleSelect}
                name='uFCIPWaterTypeID'
                value={cipData.uFCIPWaterTypeID}
                id='waterType'
              >
                {waterType.map(item => (
                  <option key={item.uFCIPWaterTypeID} value={item.uFCIPWaterTypeID}>
                    {item.cIPWaterTypeName}
                  </option>
                ))}
              </CustomSelect>
            </div>
          </CustomCard>
        </div>
        <div className='acid-alkaline-surfactant'>
          <CustomCard
            className='acid-cip-card'
            header='Acid CIP'
            tooltipLabel='Select mineral acid and organic acid chemical reagents and target doses or pH'
          >
            <div className='mineral-acid'>
              <div className='label-switch'>
                <CustomHeading label={CIP_FIELDS.mineral} />
                <ToggleSwitch
                  small
                  id='mineral'
                  name='mineralEnabled_Ind_CIP'
                  disabled={!chemicalType.mineral.length > 0}
                  checked={cipData.mineralEnabled_Ind_CIP}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='mineral'
                    disabled={!cipData.mineralEnabled_Ind_CIP}
                    value={cipData.mineralChemId_CIP}
                    name='mineralChemId_CIP'
                    onChange={handleSelect}
                  >
                    {!cipData.mineralEnabled_Ind_CIP && <option value={0}></option>}
                    {chemicalType.mineral.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    id='mineral'
                    name='mineralValue_CIP'
                    disabled={!cipData.mineralEnabled_Ind_CIP}
                    value={
                      isFocused === "mineralValue_CIP"
                        ? cipData.mineralValue_CIP
                        : Number.parseFloat(cipData.mineralValue_CIP).toFixed(2)
                    }
                    onChange={handleInputChange}
                    isError={checkError("mineralValue_CIP")}
                    inputText={cipData.mineralValueInPh_Ind_CIP ? "pH" : "mg/L"}
                    placeholder='0.00'
                    defaultValue={CHEMICAL_RANGES.mineral.ph.defaultValue}
                    isFocused={isFocused === "mineralValue_CIP"}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus("mineralValue_CIP")}
                  />
                  <InputRanges ranges={validations.mineralValue_CIP} />
                </div>
              </div>
            </div>

            <div className='organic-acid'>
              <div className='label-switch'>
                <CustomHeading label={CIP_FIELDS.organic} />
                <ToggleSwitch
                  small
                  id='organic'
                  name='organicEnabled_Ind_CIP'
                  disabled={!chemicalType.organic.length > 0}
                  checked={cipData.organicEnabled_Ind_CIP}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='organic'
                    disabled={!cipData.organicEnabled_Ind_CIP}
                    value={cipData.organicChemId_CIP}
                    name='organicChemId_CIP'
                    onChange={handleSelect}
                    className=''
                  >
                    {!cipData.organicEnabled_Ind_CIP && <option value={0}></option>}
                    {chemicalType.organic.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    id='organic'
                    disabled={!cipData.organicEnabled_Ind_CIP}
                    value={
                      isFocused === "organicValue_CIP"
                        ? cipData.organicValue_CIP
                        : Number.parseFloat(cipData.organicValue_CIP).toFixed(2)
                    }
                    name='organicValue_CIP'
                    onChange={handleInputChange}
                    isError={checkError("organicValue_CIP")}
                    inputText='mg/L'
                    placeholder='0.00'
                    isFocused={isFocused === "organicValue_CIP"}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus("organicValue_CIP")}
                  />
                  <InputRanges ranges={validations.organicValue_CIP} />
                </div>
              </div>
            </div>
          </CustomCard>
          <CustomCard
            className='alkaline-cip-card'
            header='Alkali/Oxidant CIP'
            tooltipLabel='Select alkali and oxidant chemical reagents and target doses or pH.'
          >
            <div className='alkali'>
              <div className='label-switch'>
                <CustomHeading label={CIP_FIELDS.alkali} />
                <ToggleSwitch
                  small
                  id='alkali'
                  name='alkaliEnabled_Ind_CIP'
                  disabled={!chemicalType.alkali.length > 0}
                  checked={cipData.alkaliEnabled_Ind_CIP}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='alkali'
                    disabled={!cipData.alkaliEnabled_Ind_CIP}
                    value={cipData.alkaliChemId_CIP}
                    name='alkaliChemId_CIP'
                    onChange={handleSelect}
                  >
                    {!cipData.alkaliEnabled_Ind_CIP && <option value={0}></option>}
                    {chemicalType.alkali.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    id='alkali'
                    name='alkaliValue_CIP'
                    disabled={!cipData.alkaliEnabled_Ind_CIP}
                    onChange={handleInputChange}
                    value={
                      isFocused === "alkaliValue_CIP"
                        ? cipData.alkaliValue_CIP
                        : Number.parseFloat(cipData.alkaliValue_CIP).toFixed(2)
                    }
                    isError={checkError("alkaliValue_CIP")}
                    inputText={cipData.alkaliValueInPh_Ind_CIP ? "pH" : "mg/L"}
                    placeholder='0.00'
                    defaultValue={CHEMICAL_RANGES.alkali.ph.defaultValue}
                    isFocused={isFocused === "alkaliValue_CIP"}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus("alkaliValue_CIP")}
                  />
                  <InputRanges ranges={validations.alkaliValue_CIP} />
                </div>
              </div>
            </div>
            <div className='oxidant'>
              <div className='label-switch'>
                <CustomHeading label={CIP_FIELDS.oxidant} />
                <ToggleSwitch
                  small
                  id='oxidant'
                  name='oxidantEnabled_Ind_CIP'
                  disabled={!chemicalType.oxidant.length > 0}
                  checked={cipData.oxidantEnabled_Ind_CIP}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='oxidant'
                    disabled={!cipData.oxidantEnabled_Ind_CIP}
                    value={cipData.oxidantChemId_CIP}
                    name='oxidantChemId_CIP'
                    onChange={handleSelect}
                  >
                    {!cipData.oxidantEnabled_Ind_CIP && <option value={0}></option>}
                    {chemicalType.oxidant.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    id='oxidant'
                    disabled={!cipData.oxidantEnabled_Ind_CIP}
                    value={
                      isFocused === "oxidantValue_CIP"
                        ? cipData.oxidantValue_CIP
                        : Number.parseFloat(cipData.oxidantValue_CIP).toFixed(2)
                    }
                    name='oxidantValue_CIP'
                    isError={checkError("oxidantValue_CIP")}
                    inputText='mg/L'
                    placeholder='0.00'
                    defaultValue='200'
                    isFocused={isFocused === "oxidantValue_CIP"}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus("oxidantValue_CIP")}
                    onChange={handleInputChange}
                  />
                  <InputRanges ranges={validations.oxidantValue_CIP} />
                </div>
              </div>
            </div>
            <div className='CIP-scaling'>
              <CustomLabel label={CIP_FIELDS.cip_LSI} />
              <InputWithText
                type='number'
                disabled={true}
                isError={false}
                name='cip_LSI'
                inputText='LSI'
                placeholder='0.00'
                defaultValue='2.00'
                isFocused={isFocused === 9}
                onBlur={handleBlur}
                onFocus={() => handleFocus(9)}
                onChange={handleInputChange}
                value={Number.parseFloat(cipData.cip_LSI).toFixed(2)}
              />
            </div>
          </CustomCard>
          <StyledCard className='surfactant-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label={CIP_FIELDS.oxidant2}
              />
              <div className='info-switch'>
                <ToggleSwitch
                  small
                  id='oxidant2'
                  name='oxidant2Enabled_Ind_CIP'
                  disabled={
                    !(
                      waterTypeID?.toString() === WATER_TYPE_IDS.WASTE_WATER.toString() &&
                      isIngeSelected(cipData.pUFTechnologyID)
                    )
                  }
                  checked={cipData.oxidant2Enabled_Ind_CIP}
                  onChange={handleEnableandDisable}
                />
                <IconWithTooltip
                  label='Select surfactant chemical reagent and target doses or pH.'
                  icon={<InfoIcon />}
                />
              </div>
            </Card.Header>
            <div className='surfactant-select-wrapper'>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='oxidant2'
                    value={cipData.oxidant2ChemId_CIP}
                    name='oxidant2ChemId_CIP'
                    onChange={handleSelect}
                    disabled={!cipData.oxidant2Enabled_Ind_CIP}
                  >
                    {!cipData.oxidant2Enabled_Ind_CIP && <option value={0}></option>}
                    {chemicalType.oxidant2.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    name='oxidant2Value_CIP'
                    disabled={!cipData.oxidant2Enabled_Ind_CIP}
                    id='oxidant2'
                    type='number'
                    isError={checkError("oxidant2Value_CIP")}
                    inputText='mg/L'
                    placeholder='0.00'
                    defaultValue='4'
                    isFocused={isFocused === "oxidant2Value_CIP"}
                    onBlur={e => handleBlur(e, "Surfactant")}
                    onFocus={() => handleFocus("oxidant2Value_CIP")}
                    onChange={handleInputChange}
                    value={
                      isFocused === "oxidant2Value_CIP"
                        ? cipData.oxidant2Value_CIP
                        : Number.parseFloat(cipData.oxidant2Value_CIP).toFixed(2)
                    }
                  />
                  <InputRanges ranges={validations.oxidant2Value_CIP} />
                </div>
              </div>
            </div>
          </StyledCard>
        </div>

        <div className='duration'>
          <CustomCard
            className='duration-card'
            header='Duration'
            tooltipLabel='Duration of chemical treatment steps. Initial and rinse BW steps follow normal BW cycle durations.'
          >
            <div className='duration-input-wrapper'>
              <div>
                <CustomLabel label={CIP_FIELDS.heatingStepDuration} />
                <InputWithText
                  type='number'
                  id='heatingStepDuration'
                  name='heatingStepDuration'
                  disabled={isHeatingStepDisabled}
                  isError={checkError("heatingStepDuration")}
                  inputText='min'
                  placeholder='0.00'
                  value={
                    isFocused === "heatingStepDuration"
                      ? cipData.heatingStepDuration
                      : Number.parseFloat(cipData.heatingStepDuration).toFixed(2)
                  }
                  onChange={handleInputChange}
                  isFocused={isFocused === "heatingStepDuration"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("heatingStepDuration")}
                />
                <InputRanges ranges={validations.heatingStepDuration} />
              </div>
              <div>
                <CustomLabel label={CIP_FIELDS.recycleDuration} />
                <InputWithText
                  type='number'
                  id='recycleDuration'
                  name='recycleDuration'
                  isError={checkError("recycleDuration")}
                  inputText='min'
                  isFocused={isFocused === "recycleDuration"}
                  value={
                    isFocused === "recycleDuration"
                      ? cipData.recycleDuration
                      : Number.parseFloat(cipData.recycleDuration).toFixed(2)
                  }
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("recycleDuration")}
                />
                <InputRanges ranges={validations.recycleDuration} />
              </div>
              <div>
                <CustomLabel label={CIP_FIELDS.chemicalSoakingDuration_CIP} />
                <InputWithText
                  type='number'
                  id='chemicalSoakingDuration_CIP'
                  name='chemicalSoakingDuration_CIP'
                  isError={checkError("chemicalSoakingDuration_CIP")}
                  inputText='min'
                  placeholder='0.00'
                  defaultValue='90'
                  value={
                    isFocused === "chemicalSoakingDuration_CIP"
                      ? cipData.chemicalSoakingDuration_CIP
                      : Number.parseFloat(cipData.chemicalSoakingDuration_CIP).toFixed(2)
                  }
                  onChange={handleInputChange}
                  isFocused={isFocused === "chemicalSoakingDuration_CIP"}
                  onBlur={handleBlur}
                  onFocus={() => handleFocus("chemicalSoakingDuration_CIP")}
                />
                <InputRanges ranges={validations.chemicalSoakingDuration_CIP} />
              </div>
            </div>
          </CustomCard>
        </div>
        {inputError && <ProjectErrorPopup show close={closeErrorMessag} message={inputError} />}
      </CIPStyled>
    </>
  );
};

export default CIP;
