import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Draggable } from "react-drag-and-drop";
import { useNavigate } from "react-router-dom"; // Ensure you have react-router-dom installed

import { PROJECT_NAME_MAX_LENGTH } from "@constants/global.constants";

import { getFolderName } from "@utils/appUtils";

import FavoriteIcon from "@common/icons/FavoriteIcon";
import MoreVertIcon from "@common/icons/MoreVertIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";

import AddToFolder from "./AddToFolder";
import DeleteFolderListItem from "./DeleteFolderListItem";

const ProjectItem = ({
  item,
  isDeletedView,
  onFavoriteToggle,
  onNavigate,
  onRestore,
  onPermanentDelete,
  handleOpenRenameProject,
  handleOpenSendProject,
  handleFolderPermanentDelete,
  handleRestoreFolder,
}) => {
  const [showAddToFolder, setShowAddToFolder] = useState(false);
  const [projectInfo, setProjectInfo] = useState(null);
  const handleNavigate = () => {
    onNavigate(item);
  };

  const onAddToFolder = projectInfo => {
    setProjectInfo(projectInfo);
    setShowAddToFolder(true);
  };

  const handleAddFolderClose = () => {
    setShowAddToFolder(false);
  };

  const isFolder = folderIdDeleted => folderIdDeleted !== 0;

  return (
    <Draggable
      type='item'
      data={[item.ProjectId, item.title, item.folderName, item.isDeleted]}
      key={item.ProjectId + item.title}
    >
      {!isFolder(item.folderIdDeleted) ? (
        <StyledCard className='list-view'>
          <div
            className={item.favorite ? "project_favorite favorite-icon" : "project_favorite"}
            onClick={!item.isDeleted ? () => onFavoriteToggle(item) : undefined}
          >
            {item.favorite && !item.isDeleted && (
              <div className='star-icon'>
                <FavoriteIcon />
              </div>
            )}
          </div>
          <div className='project_name' onClick={!isDeletedView ? handleNavigate : undefined}>
            <CustomHeading fontFamily='NotoSansSemiBold' fontWeight='700'>
              {item.title?.length > PROJECT_NAME_MAX_LENGTH
                ? `${item.title?.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
                : item.title}
            </CustomHeading>
          </div>
          <div className='project_created_date' onClick={!isDeletedView ? handleNavigate : undefined}>
            <CustomHeading fontFamily='NotoSansRegular'>{item.createDate}</CustomHeading>
          </div>
          <div className='project_modified_date' onClick={!isDeletedView ? handleNavigate : undefined}>
            <CustomHeading fontFamily='NotoSansRegular'>{item.description}</CustomHeading>
          </div>
          <div className='project-tags sample-project-tag' onClick={!isDeletedView ? handleNavigate : undefined}>
            {item.tagName && (
              <a className='tag-name' style={{ marginRight: "10px" }}>
                {item.tagName}
              </a>
            )}
            {item.folderName && (
              <a className='tag-name' href='#'>
                {getFolderName(item.folderName)}
              </a>
            )}
          </div>
          <div className='project-option'>
            <Dropdown className='project-option-dropdown'>
              <Dropdown.Toggle variant=''>
                <MoreVertIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {item.isDeleted ? (
                  <>
                    <Dropdown.Item onClick={() => onRestore(item.ProjectId)}>Restore Project</Dropdown.Item>
                    <Dropdown.Item onClick={() => onPermanentDelete(item.ProjectId)}>Permanently Delete</Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Item onClick={handleNavigate}>Open Project</Dropdown.Item>
                    <Dropdown.Item onClick={() => onFavoriteToggle(item)}>
                      {item.favorite ? "Remove from Favourite" : "Add to Favourite"}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onAddToFolder(item)}>Add To Folder</Dropdown.Item>
                    <Dropdown.Item onClick={() => onPermanentDelete(item.ProjectId)}>Delete Project</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleOpenRenameProject(item)}>Rename Project</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleOpenSendProject(item)}>Send Project</Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            {showAddToFolder && (
              <AddToFolder
                show={showAddToFolder}
                handleAddFolderClose={handleAddFolderClose}
                projectInfo={projectInfo}
              ></AddToFolder>
            )}
          </div>
        </StyledCard>
      ) : (
        <DeleteFolderListItem
          item={item}
          handleFolderPermanentDelete={handleFolderPermanentDelete}
          handleRestoreFolder={handleRestoreFolder}
        />
      )}
    </Draggable>
  );
};

const ProjectList = ({
  Cardstate,
  StoreSidLeftpanel,
  handleOnclick2,
  FavouriteRecord,
  handleOpenRestoreProject,
  handleOpenPermanentDelete,
  handleOpenRenameProject,
  handleOpenSendProject,
  handleUpdateProject,
  handleFolderPermanentDelete,
  handleRestoreFolder,
}) => {
  const navigate = useNavigate();

  const handleNavigate = item => {
    handleOnclick2(item);
    navigate("/FeedWaterHome", {
      state: { ...item, projectID: item.ProjectId, caseID: 0 },
    });
  };

  const isDeletedView = StoreSidLeftpanel === "masterdata/api/v1/ProjectDeleted";

  return (
    <div>
      {Cardstate?.map(item => (
        <ProjectItem
          key={item.ProjectId + item.title}
          item={item}
          isDeletedView={isDeletedView}
          onFavoriteToggle={FavouriteRecord}
          onNavigate={handleNavigate}
          onRestore={handleOpenRestoreProject}
          onPermanentDelete={handleOpenPermanentDelete}
          handleOpenRenameProject={handleOpenRenameProject}
          handleOpenSendProject={handleOpenSendProject}
          updateProjects={handleUpdateProject}
          handleFolderPermanentDelete={handleFolderPermanentDelete}
          handleRestoreFolder={handleRestoreFolder}
        />
      ))}
    </div>
  );
};

export default ProjectList;
