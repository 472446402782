import { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { UNIT_TYPES } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUFTechnologyStrings from "@hooks/useUFTechnologyStrings";
import useUnitConversion from "@hooks/useUnitConversion";

import { isValueInRange } from "@utils/appUtils";
import { DesignStrings } from "@utils/StringConstants";

import InputRanges from "@components/InputRanges";

import InfoIcon from "@common/icons/InfoIcon";
import PlusIcon from "@common/icons/PlusIcon";
import StandardLinkButtonWithIcon from "@common/styles/components/buttons/standard/StandardLinkButtonWithIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomRadioCheck from "@common/styles/components/checkboxs/CustomRadioCheck";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { colors } from "@common/styles/Theme";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import { updateFeedSetupStoreData } from "../feedsetup/FeedsetupSlice";
import { setCustomOfflineTimePerUnit, setUfDataUpdate, updateUFStoreData } from "../uf/UFSlice";

import { FIELD_OFFLINE_TIME_PER_TRAIN, UF_SPECIAL_FEATURE } from "./constants/UFConstants";
import ModuleSelection from "./UFConfiguration/ModuleSelection";
import ChemicalAdjustmentModal from "./ChemicalAdjustmentModal";
import DesignStyled from "./DesignStyled";
import UFSystemDesignDiagram from "./UFSystemDesignDiagram";
import { isIngeSelected } from "./ufUtils";

const Design = () => {
  const dispatch = useDispatch();
  const { updateMiniCIPChemicals } = useUFChemicalsHandler();
  const UFStore = useSelector(state => state.UFStore);
  const UFData = useSelector(state => state.UFStore.data);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);

  const { feedFlowRate, productFlowRate, selectedEndNode } = useSelector(state => state.processDiagramSlice);
  const { tempDesign } =
    useSelector(state => state.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]) || {};
  const defaultInputRangeConfig = useSelector(state => state.UFStore.defaultInputRangeConfig);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);

  const flowRateSelected = selectedEndNode == "startNode" ? "Feed" : "Product";
  const flowRateValueSelected = flowRateSelected == "Feed" ? feedFlowRate : productFlowRate;
  const { data } = UFStore;

  const [message, setMessage] = useState("");
  const [isFocused, setIsFocused] = useState(null);
  const [isFieldValid, setIsFieldValid] = useState(false);
  const [customValue, setCustomValue] = useState(UFData.offlinetimepertrain);
  const [openChemicalAdjustment, setOpenChemicalAdjustment] = useState(false);

  /* Design Cycle Intervals */
  const fieldFiltrationDuration = defaultInputRangeConfig["backwash_design"];
  const fieldDisInfectionCEB = defaultInputRangeConfig["disinfectionCEB"];
  const fieldAcidCEB = defaultInputRangeConfig["acidCEB"];
  const fieldMiniCIP = defaultInputRangeConfig["miniCIP"];
  const fieldAlkalineCEB = defaultInputRangeConfig["alkaliOxidantCEB"];
  const fieldCIP = defaultInputRangeConfig["cIP"];
  const ufTechnologyStrings = useUFTechnologyStrings();

  const technologyID = useSelector(state => state.UFStore.data.pUFTechnologyID);
  const [DISABLED_INPUTS, setDISABLED_INPUTS] = useState([]);

  const { convertFromMetric } = useUnitConversion();

  useEffect(() => {
    if (isIngeSelected(technologyID)) {
      setDISABLED_INPUTS([ufTechnologyStrings.airFlow, ufTechnologyStrings.aerationAirFlow]);
    } else {
      setDISABLED_INPUTS([
        ufTechnologyStrings.automatic,
        ufTechnologyStrings.aerationAirFlow,
        ufTechnologyStrings.disinfectionCEB,
      ]);
    }
  }, [technologyID]);

  const updateStoreData = newData => dispatch(updateUFStoreData(newData));
  useEffect(() => {
    if (UFStore.isOfflineTimeChanged && customValue != 12 && customValue != 0) {
      updateStoreData({ data: { offlinetimepertrain: customValue } });
    }
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsFieldValid(false);
    }
  };

  /* Handling Chemical Adjustment Popup */
  const handleOpenChemicalAdjustment = () => setOpenChemicalAdjustment(true);
  const handleFocus = id => setIsFocused(id);

  // TODO: Refactor the blur handler
  const handleBlur = e => {
    let valueIsSafe = false;
    const { name, value } = e.target;

    if (name === "filtrateFlux") {
      if (isRangeError("filtrateFlux", value)) {
        setIsFieldValid(true);
        setMessage("Please Enter Filtration Flux as per the range mentioned!");
        setIsFocused("filtrateFlux");
        setTimeout(() => e.target.focus(), 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "backwash_design") {
      if (value < fieldFiltrationDuration?.minValue || value > fieldFiltrationDuration?.maxValue || isNaN(value)) {
        setIsFieldValid(true);
        setIsFocused("backwash_design");
        setMessage("Please Enter Filtration Duration as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "backwashFlux") {
      if (isRangeError("backwashFlux", value)) {
        setIsFieldValid(true);
        setIsFocused("backwashFlux");
        setMessage("Please Enter Backwash Flux as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "cEBFlux") {
      if (isRangeError("cEBFlux", value)) {
        setIsFieldValid(true);
        setIsFocused("cEBFlux");
        setMessage("Please Enter CEB Flux as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        setIsFocused(null);
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "forwardFlushFlow") {
      if (isRangeError("forwardFlushFlow", value)) {
        setIsFieldValid(true);
        setIsFocused("forwardFlushFlow");
        setMessage("Please Enter Forward Flush Flow as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "recycleFlowRate") {
      if (isRangeError("recycleFlowRate", value)) {
        setIsFieldValid(true);
        setIsFocused("recycleFlowRate");
        setMessage("Please Enter CIP Recycle Flow Rate as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "alkaliOxidantCEB") {
      if (value < fieldAlkalineCEB?.minValue || value > fieldAlkalineCEB?.maxValue || isNaN(value)) {
        setIsFieldValid(true);
        setIsFocused("alkaliOxidantCEB");
        setMessage("Please Enter Alkali/Oxidant CEB as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        setIsFocused(null);
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "offlinetimepertrain") {
      //soft Limit - (0 to 30)
      if (
        value < FIELD_OFFLINE_TIME_PER_TRAIN?.minValue ||
        value > FIELD_OFFLINE_TIME_PER_TRAIN?.maxValue ||
        isNaN(value)
      ) {
        setIsFieldValid(true);
        setIsFocused("offlinetimepertrain");
        setMessage("Please Enter Offline Time per Unit as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        setIsFocused(null);
        valueIsSafe = true;
        setIsFieldValid(false);
        setCustomValue(value);
      }
    } else if (name === "acidCEB") {
      if (value < fieldAcidCEB?.minValue || value > fieldAcidCEB?.maxValue || isNaN(value)) {
        setIsFieldValid(true);
        setIsFocused("acidCEB");
        setMessage("Please Enter ACID CEB as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "cIP") {
      if (value < fieldCIP?.minValue || value > fieldCIP?.maxValue || isNaN(value)) {
        setIsFieldValid(true);
        setIsFocused("cIP");
        setMessage("Please Enter CIP as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "airFlow") {
      if (isRangeError("airFlow", value)) {
        setIsFieldValid(true);
        setIsFocused("airFlow");
        setMessage("Please Enter Air Flow as per the range mentioned!");
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    } else if (name === "recycleFlowRate_MiniCIP") {
      if (isRangeError("recycleFlowRate_MiniCIP", value)) {
        setIsFieldValid(true);
        setIsFocused("recycleFlowRate_MiniCIP");
        setMessage("Please Enter mini-CIP Recycle Flow rate as per the range mentioned!");
        setTimeout(() => e.target.focus(), 0);
      } else {
        valueIsSafe = true;
        setIsFieldValid(false);
      }
    }

    if (valueIsSafe) {
      setIsFocused(null);
      let data = {};
      if (name === "offlinetimepertrain") {
        const newValue = Number(value);
        setCustomValue(newValue);
        data = { [name]: newValue };
      } else if (name === "forwardFlushFlow") {
        data = { [name]: Number(value).toFixed(2) };
      } else {
        const floatValue = parseFloat(value);
        const isValidNumber = !isNaN(floatValue) && floatValue === Math.floor(floatValue);
        const val = isValidNumber ? Math.floor(floatValue) : floatValue.toFixed(2);
        data = { [name]: val.toString() };
      }

      dispatch(updateUFStoreData({ data, calcEngineDataRefreshCount: 1 }));
    }
  };

  /* Saving User Inputs in Component Internal State */
  const handleUFDesignTech = ({ target }) => {
    const { name, value } = target;
    const techId = Number(value);
    dispatch(updateFeedSetupStoreData({ isUpdateActiveModule: true }));
    updateStoreData({ data: { [name]: techId, ufSpecialFeatureID: 1 } });
  };

  const handleInputChange = ({ target }) => {
    let { name, value } = target;
    value = value || "";

    if (!isNaN(value)) {
      const newData = { [name]: value };

      if (name === "offlinetimepertrain") {
        const valueNumber = Number(value);
        newData[name] = valueNumber;
        setCustomValue(valueNumber);
        dispatch(setCustomOfflineTimePerUnit(true));
      }

      updateStoreData({ data: { ...newData } });
      dispatch(setUfDataUpdate(true));
      if (name === "ufSpecialFeatureID") {
        const { isCebOnly, isMiniCipOnly } = UF_SPECIAL_FEATURE;
        const isUpdate = +UFData.ufSpecialFeatureID === +isCebOnly || value < isMiniCipOnly;
        if (isUpdate) {
          updateMiniCIPChemicals(value !== isCebOnly);
        }
        updateMiniCipRecycleFlowRate(value);
      }
    }
  };

  const updateMiniCipRecycleFlowRate = specialFeatureId => {
    const { isCebOnly } = UF_SPECIAL_FEATURE;

    // recycleFlowRate_MiniCIP value should be zero when cebOnly spefial feature is selected.
    // when moving from ceb only to any other special feature should updated to recycleFlowRate value
    let miniCipRecycleFlowrateValue = UFData.recycleFlowRate_MiniCIP;
    if (+specialFeatureId === +isCebOnly) {
      miniCipRecycleFlowrateValue = 0;
    } else if (+UFData.ufSpecialFeatureID === +isCebOnly) {
      miniCipRecycleFlowrateValue = UFData.recycleFlowRate;
    }
    updateStoreData({ data: { recycleFlowRate_MiniCIP: miniCipRecycleFlowrateValue } });
  };

  const getRange = (field, unitType) => {
    const { minValue, maxValue } = defaultInputRangeConfig[field];
    return {
      minValue: +convertFromMetric(minValue, unitType),
      maxValue: +convertFromMetric(maxValue, unitType),
    };
  };

  const validations = {
    filtrateFlux: getRange("filtrateFlux", UNIT_TYPES.FLUX),
    backwashFlux: getRange("backwashFlux", UNIT_TYPES.FLUX),
    cEBFlux: getRange("cEBFlux", UNIT_TYPES.FLUX),
    airFlow: getRange("airFlow", UNIT_TYPES.GAS_FLOW),
    aerationAirFlow: getRange("aerationAirFlow", UNIT_TYPES.GAS_FLOW),
    forwardFlushFlow: getRange("forwardFlushFlow", UNIT_TYPES.FLOW),
    recycleFlowRate: getRange("recycleFlowRate", UNIT_TYPES.FLOW),
    recycleFlowRate_MiniCIP: getRange("recycleFlowRate_MiniCIP", UNIT_TYPES.FLOW),
  };

  const isRangeError = (field, currentVal) => {
    const value = currentVal || UFData[field];
    return !isValueInRange(value, validations[field]);
  };

  const handelOfflineTrain = e => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      const userInput = value == "" ? value : Number(value);
      setCustomValue(userInput);
      const newValue = value == "" ? value : Number(value);
      updateStoreData({ data: { [name]: newValue } });
      dispatch(setCustomOfflineTimePerUnit(true));
      dispatch(setUfDataUpdate(true));
    }
  };

  const checkError = id =>
    !(FIELD_OFFLINE_TIME_PER_TRAIN?.minValue <= data[id] && FIELD_OFFLINE_TIME_PER_TRAIN?.maxValue >= data[id]);

  const getError = name => {
    const value = data[name];
    const validator = FIELD_OFFLINE_TIME_PER_TRAIN;
    if (value < validator?.minValue || value > validator?.maxValue) {
      return <ErrorMessage texMsg={"Values out of range"} />;
    } else if (value < validator?.softLowerLimit || value > validator?.softUpperLimit) {
      if (value <= validator?.maxValue) {
        return (
          <InputReferenceText
            refText={`Ranges ${validator?.minValue?.toFixed(2)} - ${validator?.maxValue?.toFixed(2)}`}
          />
        );
      }
    } else {
      return (
        <InputReferenceText
          refText={`Ranges ${validator?.minValue?.toFixed(2)} - ${validator?.maxValue?.toFixed(2)}`}
        />
      );
    }
  };

  return (
    <>
      <DesignStyled className='g-0'>
        <UFSystemDesignDiagram />
        <Col lg={12} md={12} sm={12}>
          <div className='uf-design'>
            <div className='card-wrapper-one'>
              {/* UF Feed Flow Rate */}
              <StyledCard className='uf-feed-flow' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={`UF ${flowRateSelected} Flow Rate`}
                  />
                  <IconWithTooltip
                    label='Estimated UF Feed or Product flow, based on user input and estimated recoveries of other icons.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <CustomLabel disabled={true} label={ufTechnologyStrings.automatic} />
                <InputWithText
                  inputText={unit.selectedUnits[1]}
                  disabled={true}
                  isError={false}
                  value={flowRateValueSelected}
                  id='automatic'
                  placeholder='0.00'
                  onFocus={() => handleFocus(1)}
                  isFocused={isFocused === 1}
                  onBlur={handleBlur}
                />
              </StyledCard>
              {/* UF Technology Selection */}
              <StyledCard className='uf-technology-selection' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.ufTechnologySelection}
                  />
                  <IconWithTooltip
                    label='Select family of DuPont UF products, which determines the default cleaning protocols.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <CustomLabel label={ufTechnologyStrings.ufTechnology} />
                <CustomSelect
                  type=''
                  id='ufTechnologies'
                  name='pUFTechnologyID'
                  placeholder='UF Technologies'
                  onChange={handleUFDesignTech}
                  value={UFData.pUFTechnologyID}
                >
                  {UFStore.ufTechnologies.map(technology => (
                    <option value={technology.pUFTechnologyID} key={`ufTech-${technology.companyName}`}>
                      {technology.pUFTechName}
                    </option>
                  ))}
                </CustomSelect>
              </StyledCard>
              {/* Module Selection */}
              <ModuleSelection showProductGuidelines />
            </div>
            <div className='card-wrapper-two'>
              {/* Design Instantaneous (Gross) Flux and Flow Rates */}
              <StyledCard className='flux-and-flow-rate' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.designFlowFluxRates}
                  />
                  {/* <div className="spl-icon"><InfoIcon/></div> */}
                  <IconWithTooltip
                    label='Provide fluxes and flow rates for filtration and various steps in the cleaning protocols.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    label={`Filtrate Flux (for ${unit.selectedUnits[2] !== "°C" ? GlobalUnitConversion(GlobalUnitConversionStore, tempDesign, unit.selectedUnits[2], "°C") : tempDesign}${unit.selectedUnits[2]})`}
                  />

                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[4]}
                    id='filtrateInput'
                    name='filtrateFlux'
                    placeholder='0.00'
                    value={
                      isFocused === "filtrateFlux" ? UFData.filtrateFlux : parseFloat(UFData.filtrateFlux).toFixed(2)
                    }
                    disabled={DISABLED_INPUTS.includes("Filtrate Flux")}
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("filtrateFlux")}
                    isFocused={isFocused === "filtrateFlux"}
                    onBlur={handleBlur}
                    isError={isRangeError("filtrateFlux")}
                  />
                  <InputRanges ranges={validations.filtrateFlux} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel label={ufTechnologyStrings.backwashFlush} />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[4]}
                    id='backwashFluxInput'
                    name='backwashFlux'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.backwashFlush)}
                    isError={isRangeError("backwashFlux")}
                    placeholder='0.00'
                    value={
                      isFocused === "backwashFlux" ? UFData.backwashFlux : parseFloat(UFData.backwashFlux).toFixed(2)
                    }
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("backwashFlux")}
                    isFocused={isFocused === "backwashFlux"}
                    onBlur={handleBlur}
                  />
                  <InputRanges ranges={validations.backwashFlux} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel label={ufTechnologyStrings.cebFlux} />

                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[4]}
                    id='cEBFluxInput'
                    name='cEBFlux'
                    disabled={DISABLED_INPUTS.includes("CEB Flux")}
                    isError={isRangeError("cEBFlux")}
                    placeholder='0.00'
                    value={isFocused === "cEBFlux" ? UFData.cEBFlux : parseFloat(UFData.cEBFlux).toFixed(2)}
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("cEBFlux")}
                    isFocused={isFocused === "cEBFlux"}
                    onBlur={handleBlur}
                  />
                  <InputRanges ranges={validations.cEBFlux} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel label={ufTechnologyStrings.forwardFlushFlow} />
                  <InputWithText
                    type='number'
                    // inputText={fieldForwardFlush?.uom}
                    inputText={unit.selectedUnits[1] + "/module"}
                    id='forwardFlushFlowInput'
                    name='forwardFlushFlow'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.forwardFlushFlow)}
                    isError={isRangeError("forwardFlushFlow")}
                    placeholder='0.00'
                    value={
                      isFocused === "forwardFlushFlow"
                        ? UFData.forwardFlushFlow
                        : parseFloat(UFData.forwardFlushFlow).toFixed(2)
                    }
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("forwardFlushFlow")}
                    isFocused={isFocused === "forwardFlushFlow"}
                    onBlur={handleBlur}
                  />
                  <InputRanges ranges={validations.forwardFlushFlow} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    disabled={DISABLED_INPUTS.includes(DesignStrings.airFlow)}
                    label={ufTechnologyStrings.airFlow}
                  />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[18] + "/module"}
                    id='airFlowInput'
                    name='airFlow'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.airFlow)}
                    isError={isRangeError("airFlow")}
                    placeholder='0.00'
                    value={
                      isFocused === "airFlow"
                        ? UFData.airFlow
                        : isIngeSelected(UFData.pUFTechnologyID)
                          ? 0
                          : parseFloat(UFData.airFlow).toFixed(2)
                    }
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("airFlow")}
                    isFocused={isFocused === "airFlow"}
                    onBlur={handleBlur}
                  />
                  {!isIngeSelected(UFData.pUFTechnologyID) && <InputRanges ranges={validations.airFlow} />}
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    disabled={DISABLED_INPUTS.includes(DesignStrings.aerationAirFlow)}
                    label={ufTechnologyStrings.aerationAirFlow}
                  />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[18] + "/module"}
                    id='aerationAirFlowInput'
                    name='aerationAirFlow'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.aerationAirFlow)}
                    isError={isRangeError("aerationAirFlow")}
                    placeholder='0.00'
                    value={
                      isFocused === "aerationAirFlow" ? UFData.airFlow : parseFloat(UFData.aerationAirFlow).toFixed(2)
                    }
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("aerationAirFlow")}
                    isFocused={isFocused === "aerationAirFlow"}
                    onBlur={handleBlur}
                  />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    disabled={DISABLED_INPUTS.includes(DesignStrings.cIPRecycleFlowRate)}
                    label={ufTechnologyStrings.cIPRecycleFlowRate}
                  />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[1] + "/module"}
                    id='recycleFlowRateInput'
                    name='recycleFlowRate'
                    disabled={DISABLED_INPUTS.includes(DesignStrings.cIPRecycleFlowRate)}
                    isError={isRangeError("recycleFlowRate")}
                    placeholder='0.00'
                    value={
                      isFocused === "recycleFlowRate"
                        ? UFData.recycleFlowRate
                        : parseFloat(UFData.recycleFlowRate).toFixed(2)
                    }
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("recycleFlowRate")}
                    isFocused={isFocused === "recycleFlowRate"}
                    onBlur={handleBlur}
                  />
                  <InputRanges ranges={validations.recycleFlowRate} />
                </div>
                <div className='flux-and-flow-wrapper'>
                  <CustomLabel
                    disabled={DISABLED_INPUTS.includes(DesignStrings.miniCIPRecycleFlowRate)}
                    label={ufTechnologyStrings.miniCIPRecycleFlowRate}
                  />
                  <InputWithText
                    type='number'
                    inputText={unit.selectedUnits[1] + "/module"}
                    id='recycleFlowRate_MiniCIPInput'
                    name='recycleFlowRate_MiniCIP'
                    disabled={UFData.ufSpecialFeatureID == 1}
                    isError={isRangeError("recycleFlowRate_MiniCIP")}
                    placeholder='0.00'
                    value={
                      isFocused === "recycleFlowRate_MiniCIP"
                        ? UFData.recycleFlowRate_MiniCIP
                        : parseFloat(UFData.recycleFlowRate_MiniCIP).toFixed(2)
                    }
                    onChange={handleInputChange}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    onWheel={e => e.target.blur()}
                    onFocus={() => handleFocus("recycleFlowRate_MiniCIP")}
                    isFocused={isFocused === "recycleFlowRate_MiniCIP"}
                    onBlur={handleBlur}
                  />
                </div>
                <InputRanges ranges={validations.recycleFlowRate_MiniCIP} />
              </StyledCard>
            </div>
            <div className='card-wrapper-three'>
              {/* Pending Special Features-------------- */}
              <StyledCard className='uf-special-feature' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.uFSpecialFeatures}
                  />
                  <IconWithTooltip
                    label='Adjust feed water chemistry, decide if regular CEB or mini-CIP cleaning protocols will be used.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>
                <div>
                  <StandardLinkButtonWithIcon
                    label={ufTechnologyStrings.addChemicalDegas}
                    id='addChemicalBtn'
                    icon={<PlusIcon style={{ cursor: "notAllowed" }} />}
                    plusIcon={true}
                    padding='10px 0px'
                    onClick={handleOpenChemicalAdjustment}
                  />
                  {openChemicalAdjustment && <ChemicalAdjustmentModal close={() => setOpenChemicalAdjustment(false)} />}
                </div>
                <div className='uf_special_feature_radio'>
                  <CustomRadioCheck
                    type='radio'
                    name='ufSpecialFeatureID'
                    id='cebRadio'
                    label={ufTechnologyStrings.cEBOnly}
                    checked={UFData.ufSpecialFeatureID == 1}
                    value={1}
                    onChange={handleInputChange}
                  />
                  <CustomRadioCheck
                    type='radio'
                    name='ufSpecialFeatureID'
                    id='cipRadio'
                    label={ufTechnologyStrings.miniCIPOnly}
                    disabled={isIngeSelected(technologyID)}
                    checked={UFData.ufSpecialFeatureID == 2}
                    value={2}
                    onChange={handleInputChange}
                  />
                  <CustomRadioCheck
                    type='radio'
                    name='ufSpecialFeatureID'
                    id='cebCipRadio'
                    label={ufTechnologyStrings.CEBAndMiniCIP}
                    disabled={isIngeSelected(technologyID)}
                    onChange={handleInputChange}
                    value={3}
                    checked={UFData.ufSpecialFeatureID == 3}
                  />
                </div>
              </StyledCard>
              <StyledCard className='design-cycle-interval' borderRadius='4px'>
                <Card.Header>
                  <CustomHeading
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.designCycleInterval}
                  />
                  <IconWithTooltip
                    label='Provide the nominal interval between each cleaning protocol.'
                    icon={<InfoIcon />}
                  />
                </Card.Header>

                <div className='design_cycle_interval'>
                  <div className='design-filtration-left'>
                    <div className='filtration-duration'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.filtrationDuration)}
                        label={ufTechnologyStrings.filtrationDuration}
                      />
                      <InputWithText
                        type='number'
                        inputText={fieldFiltrationDuration?.uom || "Min"}
                        id='filtrationDurationInput'
                        name='backwash_design'
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.filtrationDuration)}
                        isError={
                          UFData.backwash_design < fieldFiltrationDuration?.minValue ||
                          UFData.backwash_design > fieldFiltrationDuration?.maxValue
                            ? true
                            : false
                        }
                        placeholder='0.00'
                        value={UFData.backwash_design}
                        onChange={handleInputChange}
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        onWheel={e => e.target.blur()}
                        onFocus={() => handleFocus("backwash_design")}
                        isFocused={isFocused === "backwash_design"}
                        onBlur={handleBlur}
                      />
                      <InputReferenceText
                        refText={`Ranges ${fieldFiltrationDuration?.minValue?.toFixed(2)} - ${fieldFiltrationDuration?.maxValue?.toFixed(2)}`}
                      />
                    </div>
                    <div className='cip'>
                      <CustomLabel disabled={DISABLED_INPUTS.includes("CIP")} label='CIP' />
                      <InputWithText
                        type='number'
                        inputText={fieldCIP?.uom}
                        id='cIPInput'
                        name='cIP'
                        disabled={DISABLED_INPUTS.includes("CIP")}
                        isError={UFData.cIP < fieldCIP?.minValue || UFData.cIP > fieldCIP?.maxValue ? true : false}
                        placeholder='0.00'
                        value={UFData.cIP}
                        onChange={handleInputChange}
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        onWheel={e => e.target.blur()}
                        onFocus={() => handleFocus("cIP")}
                        isFocused={isFocused === "cIP"}
                        onBlur={handleBlur}
                      />
                      <InputReferenceText
                        refText={`Ranges ${fieldCIP?.minValue?.toFixed(2)} - ${fieldCIP?.maxValue?.toFixed(2)}`}
                      />
                    </div>
                    <div className='mini-cip'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.miniCIP)}
                        label={ufTechnologyStrings.miniCip}
                      />
                      <InputWithText
                        type='number'
                        inputText={fieldMiniCIP?.uom}
                        id='miniCIPInput'
                        name='miniCIP'
                        disabled={UFData.ufSpecialFeatureID == 1 || isIngeSelected(technologyID)}
                        isError={
                          UFData.miniCIP < fieldMiniCIP?.minValue || UFData.miniCIP > fieldMiniCIP?.maxValue
                            ? true
                            : false
                        }
                        placeholder='0.00'
                        value={UFData.miniCIP}
                        onChange={handleInputChange}
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        onWheel={e => e.target.blur()}
                        onFocus={() => handleFocus("miniCIP")}
                        isFocused={isFocused === "miniCIP"}
                        onBlur={handleBlur}
                      />
                      {!(UFData.ufSpecialFeatureID == 1 || isIngeSelected(technologyID)) && (
                        <InputReferenceText
                          refText={`Ranges ${fieldMiniCIP?.minValue?.toFixed(2)} - ${fieldMiniCIP?.maxValue?.toFixed(2)}`}
                        />
                      )}
                    </div>
                  </div>
                  <div className='design-filtration-right'>
                    <div className='acid-ceb'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.acidCEB)}
                        label={ufTechnologyStrings.acidCEB}
                      />
                      <InputWithText
                        type='number'
                        inputText={fieldAcidCEB?.uom}
                        id='acidCEBInput'
                        name='acidCEB'
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.acidCEB)}
                        isError={
                          UFData.acidCEB < fieldAcidCEB?.minValue || UFData.acidCEB > fieldAcidCEB?.maxValue
                            ? true
                            : false
                        }
                        placeholder='0.00'
                        value={UFData.acidCEB}
                        onChange={handleInputChange}
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        onWheel={e => e.target.blur()}
                        onFocus={() => handleFocus("acidCEB")}
                        isFocused={isFocused === "acidCEB"}
                        onBlur={handleBlur}
                      />
                      <InputReferenceText
                        refText={`Ranges ${fieldAcidCEB?.minValue?.toFixed(2)} - ${fieldAcidCEB?.maxValue?.toFixed(2)}`}
                      />
                    </div>
                    <div className='alkaline-ceb'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes("Alkali/Oxidant CEB")}
                        label='Alkali/Oxidant CEB'
                      />
                      <InputWithText
                        type='number'
                        inputText={fieldAlkalineCEB?.uom}
                        id='alkaliOxidantCEBInput'
                        name='alkaliOxidantCEB'
                        disabled={DISABLED_INPUTS.includes("Alkali/Oxidant CEB")}
                        isError={
                          UFData.alkaliOxidantCEB < fieldAlkalineCEB?.minValue ||
                          UFData.alkaliOxidantCEB > fieldAlkalineCEB?.maxValue
                            ? true
                            : false
                        }
                        placeholder='0.00'
                        value={UFData.alkaliOxidantCEB}
                        onChange={handleInputChange}
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        onWheel={e => e.target.blur()}
                        onFocus={() => handleFocus("alkaliOxidantCEB")}
                        isFocused={isFocused === "alkaliOxidantCEB"}
                        onBlur={handleBlur}
                      />
                      <InputReferenceText
                        refText={`Ranges ${fieldAlkalineCEB?.minValue?.toFixed(2)} - ${fieldAlkalineCEB?.maxValue?.toFixed(2)}`}
                      />
                    </div>
                    <div className='disinfection-ceb'>
                      <CustomLabel
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.disinfectionCEB)}
                        label={ufTechnologyStrings.disinfectionCEB}
                      />
                      <InputWithText
                        type='number'
                        inputText={fieldDisInfectionCEB?.uom}
                        id='disinfectionCEBInput'
                        name='disinfectionCEB'
                        disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.disinfectionCEB)}
                        isError={
                          UFData.disinfectionCEB < fieldDisInfectionCEB?.minValue ||
                          UFData.disinfectionCEB > fieldDisInfectionCEB?.maxValue
                            ? true
                            : false
                        }
                        placeholder='0.00'
                        value={UFData.disinfectionCEB}
                        onChange={handleInputChange}
                        onKeyDown={evt =>
                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                        }
                        onWheel={e => e.target.blur()}
                        onFocus={() => handleFocus("disinfectionCEB")}
                        isFocused={isFocused === "disinfectionCEB"}
                        onBlur={handleBlur}
                      />
                      <InputReferenceText
                        refText={`Ranges ${fieldDisInfectionCEB?.minValue?.toFixed(2)} - ${fieldDisInfectionCEB?.maxValue?.toFixed(2)}`}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <CustomHeading
                    className='membrane-heading'
                    fontFamily='NotoSansRegular'
                    color={colors.PrimaryDarkAquaMarine}
                    label={ufTechnologyStrings.membraneIntegrityTesting}
                  />
                  <div className='cip'>
                    <CustomLabel
                      disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.membraneIntegrityTesting)}
                      label={ufTechnologyStrings.offlineTimeperUnit}
                    />

                    <InputWithText
                      type='text'
                      inputText={FIELD_OFFLINE_TIME_PER_TRAIN?.uom}
                      id='offlinetimepertrain'
                      name='offlinetimepertrain'
                      disabled={DISABLED_INPUTS.includes(ufTechnologyStrings.membraneIntegrityTesting)}
                      isError={checkError("offlinetimepertrain")}
                      placeholder='0.00'
                      value={UFData.offlinetimepertrain}
                      onChange={handelOfflineTrain}
                      onKeyDown={evt =>
                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                      }
                      onWheel={e => e.target.blur()}
                      onFocus={() => handleFocus("offlinetimepertrain")}
                      isFocused={isFocused === "offlinetimepertrain"}
                      onBlur={handleBlur}
                    />
                    {getError("offlinetimepertrain")}
                  </div>
                </div>
              </StyledCard>
            </div>
          </div>
        </Col>
      </DesignStyled>
      {isFieldValid && (
        <ProjectErrorPopup
          show={isFieldValid}
          close={() => {
            setIsFieldValid(false);
          }}
          message={message}
        />
      )}
    </>
  );
};

export default Design;
