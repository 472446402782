import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { debounce } from "@utils/appUtils";

import CloseIcon from "@common/icons/CloseBlackIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";

import { UF_MODULE_TABLE_CONFIG } from "../../constants/UFConstants";
import { setUFProductFilter } from "../../UFSlice";

import UFProductFilter from "./UFProductFilters";
import UFProductGuidelinesStyled from "./UFProductGuidelinesStyled";

const UFProductGuidelines = ({ show, close, onSelection }) => {
  const dispatch = useDispatch();
  const ufStoreData = useSelector(state => state.UFStore);
  const {
    filteredModules,
    selectedGuidelinesData,
    localFilterdModules,
    selectedModuleCategory,
    localSelectedModuleCategory,
  } = ufStoreData.ufProductFilter;
  const [selectedGuidelines, setSelectedGuidelines] = useState(ufStoreData.data.uFModuleID);

  /* When filter logics will be implemented we need to replace ufModules with filteredModules */
  const ufGuidelinesData = useMemo(
    () =>
      localFilterdModules?.map(m => ({
        id: m.ufmoduleId,
        moduleName: m.newModuleLongName,
        prvModuleName: m.moduleLongName,
        fberMat: m.fiberMaterial,
        flowPattern: m.flowPattern,
        moduleArea: m.moduleArea,
        type: m.moduleType,
        moduleLength: m.l,
        integrated: m?.integraFlowInd ? "Yes" : "No",
        integraPacInd: m.integraPacInd,
        orientation: m.orientation,
        bores: m.bores,
      })),
    [localFilterdModules],
  );

  const saveFilteredResult = () => {
    dispatch(
      setUFProductFilter({
        filteredModules: localFilterdModules,
        selectedModuleCategory: localSelectedModuleCategory,
        activeSearch: "",
      }),
    );
    close();
  };

  /* When filter logics will be implemented we need to replace ufModules with filteredModules */
  useEffect(() => {
    dispatch(setUFProductFilter({ selectedGuidelinesData: [...ufGuidelinesData] }));
  }, [localFilterdModules]);

  const handleRowSelection = item => {
    const isSelected = selectedGuidelines === item.id;
    setSelectedGuidelines(isSelected ? "" : item.id);
    onSelection(isSelected ? undefined : item);
    saveFilteredResult();
  };

  const handleClick = () => saveFilteredResult();

  const handleClose = () => {
    dispatch(
      setUFProductFilter({
        localFilterdModules: filteredModules,
        activeSearch: "",
        localSelectedModuleCategory: selectedModuleCategory,
      }),
    );
    close();
  };

  const renderRowData = (item, index) => {
    const isSelected = selectedGuidelines == item.id;
    return (
      <tr
        key={`row-${index}`}
        className={`internal-data ${isSelected ? "selected-row" : ""}`}
        onDoubleClickCapture={() => handleRowSelection(item)}
      >
        {UF_MODULE_TABLE_CONFIG.map((col, colIndex) => (
          <td className='data' key={`Col-${index}-${colIndex}`}>
            <CustomHeading label={item[col.field]} />
          </td>
        ))}
      </tr>
    );
  };

  return (
    <>
      <UFProductGuidelinesStyled show={show} onHide={close} centered backdrop='static' keyboard='false'>
        <UFProductGuidelinesStyled.Header className='uf-guideline-header'>
          <div>
            <CustomHeading
              fontFamily='DiodrumSemiBold'
              className='main-header'
              fontSize='16px'
              label='Ultrafiltration Product Guidelines'
              color={colors.PrimaryDarkAquaMarine}
            />
            <CustomHeading
              fontSize='12px'
              label='You may select the appropriate product by double-click on any row below.'
              color={colors.blackTransparency045}
            />
          </div>
          <button className='close-icon' onClick={handleClose}>
            <CloseIcon />
          </button>
        </UFProductGuidelinesStyled.Header>
        <UFProductGuidelinesStyled.Body>
          <Table responsive className='wrapper'>
            <thead className='header-title'>
              <tr className='title'>
                {UF_MODULE_TABLE_CONFIG.map((item, index) => (
                  <th key={`header-row-${index}`}>
                    <CustomHeading fontFamily='NotoSansSemiBold' label={item.title} />
                    {item.filter && <UFProductFilter />}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='internal-data-container' tabIndex='0'>
              {selectedGuidelinesData.map(renderRowData)}
            </tbody>
          </Table>
        </UFProductGuidelinesStyled.Body>
        <UFProductGuidelinesStyled.Footer>
          <StandardPrimaryButton label='OK' onClick={handleClick} disabled={localFilterdModules.length == 0} />
        </UFProductGuidelinesStyled.Footer>
      </UFProductGuidelinesStyled>
    </>
  );
};

export default UFProductGuidelines;
