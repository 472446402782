import styled from "styled-components";

import { colors, fontStyles } from "@common/styles/Theme";

import "@common/styles/diodrumFont.css";
import "@common/styles/notoSansFont.css";
const SideMenuUL = styled.div`
  border-width: 0px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: ${colors.GreyE1};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  margin-top: ${({ showSideMenu }) => (showSideMenu ? "0px" : "58px")};
  padding-top: ${({ showSideMenu }) => (showSideMenu ? "0px" : "15px")};
  padding-left: 0px;
  padding-right: 0;
  background-color: #fff;
  min-width: ${({ showSideMenu }) => (showSideMenu ? "350px" : "inherit")};
  width: ${({ showSideMenu }) => (showSideMenu ? "35.5%" : "19%")};
  .tablet_menu_view {
    display: flex;
    padding: 8.5px 10px 15px 15px;
    gap: 15px;
    .close_icon_btn {
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
  hr {
    margin: 0;
    border: 1px solid ${colors.Grey96};
  }
  .import-div {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    .import-project-btn {
      width: 80%;
    }
  }
  .project_navigation_menu {
    border-bottom: 1px solid ${colors.GreyE1};
    .project_navigation_menu_list {
      padding-left: 0px;
      list-style: none;
      li {
        padding: 11px 0;
        cursor: pointer;
        > label {
          padding-left: 32px;
          display: flex;
          gap: 10px;
          margin-bottom: 0;
          cursor: pointer;
        }
        :hover {
          > label {
            color: ${colors.PrimaryDarkAquaMarine};
          }
        }
      }
      .li-active {
        background: #e2f7f5;
        border-right: 3px solid ${colors.PrimaryDarkAquaMarine};
        > label {
          color: ${colors.PrimaryDarkAquaMarine};
        }
      }
    }
  }
  .information {
    flex-direction: column;
    p {
      ${fontStyles.diodrum10}
      color:${colors.Black};
      padding: 0;
      margin-bottom: 10px;
    }
    .view-all-folder {
      ${fontStyles.notoSans12}
      letter-spacing: 0.005em;
      color: ${colors.Black};
      text-decoration: none;
      color: var(--Black, rgba(0, 0, 0));
      font-size: 14px;
      line-height: 18px;
      padding: 10px 10px 10px 54px;

      display: inline-block;
      :hover {
        color: ${colors.PrimaryDarkAquaMarine};
        svg {
          path {
            fill: ${colors.PrimaryDarkAquaMarine};
          }
        }
      }
      svg {
        path {
          fill: ${colors.Black};
        }
      }
    }
  }
  .import-div {
    display: flex;
    justify-content: center;
    .import-project-btn {
      width: 80%;
    }
  }

  .accordion {
    /* border: 1px solid black; */
    /* border: 0; */
    border-radius: 0;
    padding-left: 0px;
    width: 80%;
    --bs-accordion-body-padding-x: 0;
    --bs-accordion-body-padding-y: 0;
    --bs-accordion-btn-padding-x: 00.9rem;
    --bs-accordion-btn-padding-y: 0.9rem;
    --bs-accordion-btn-icon-width: 0.8rem;
    .accordion-header {
      margin-top: 17px;
      button {
        width: max-content;
        padding: 5px 16px;
        background-color: transparent;
        box-shadow: none;
        ${fontStyles.notoSans16};
        color: ${colors.Black};
        margin-left: 38px;
      }
      .accordion-button {
        ::after {
          background-position: center;
          margin-left: -75px;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Union' fill-rule='evenodd' clip-rule='evenodd' d='M6.58558 1.05546C6.97611 0.66493 7.53013 0.58578 7.82302 0.87867C8.11591 1.17157 8.03677 1.72558 7.64624 2.11611L4.81782 4.94454C4.57436 5.18786 4.26737 5.3103 3.99971 5.29719C3.73216 5.31045 3.42536 5.18801 3.18203 4.94456L0.353605 2.11613C-0.0369189 1.7256 -0.116065 1.17159 0.176829 0.87869C0.469722 0.5858 1.02374 0.66494 1.41427 1.05547L3.99993 3.64112L6.58558 1.05546Z' fill='black' fill-opacity='0.85'/%3E%3C/svg%3E");
        }
      }
    }
    .accordion-item {
      border: none;
      background-color: transparent;
    }

    .accordion-body {
      /* border: 1px solid black; */
      width: 125%;
      /* padding-left: 16px; */
      padding: unset;
      overflow-y: auto;
      scrollbar-width: none;
      scrollbar-color: ${colors.DupontBrandRed};
      background-color: transparent;
      border: none;
      ::-webkit-scrollbar {
        width: 6px;
        height: 80px;
        color: ${colors.DupontBrandRed};
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${colors.PrimaryDarkAquaMarine};
        border-radius: 5px;
        border: none;
      }
      .folder-list {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        .folder-list-active {
          background: #e2f7f5;
          border-right: 3px solid ${colors.PrimaryDarkAquaMarine};
        }
        li {
          cursor: pointer;
          padding: 10px 0;
          text-decoration: none;
          ${fontStyles.diodrum14}
          align-items: center;
          color: ${colors.Black};
          padding-left: 54px;
          :hover {
            color: ${colors.PrimaryDarkAquaMarine};
          }
        }
        .li-active {
          background: #e2f7f5;
          border-right: 3px solid ${colors.PrimaryDarkAquaMarine};
        }
      }
    }
  }
  .wrapText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .create-folder {
    position: absolute;
    right: 10px;
    margin-top: 17px;
    .create-folder-icon {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      margin-top: 2px;
      cursor: pointer;
      :hover {
        path,
        line {
          stroke: ${colors.PrimaryDarkAquaMarine};
        }
      }
    }
  }
  @media (max-width: 575px) {
    position: unset;
    display: block;
    height: 100%;
    .side-menu {
      height: 100%;
    }
  }
  @media (max-width: 767px) {
    display: block;
    height: 100%;
    .side-menu {
      height: 100%;
    }
    .import-project {
      margin-left: 20%;
    }
  }
  @media (max-width: 992px) {
    .project-list {
      padding-left: 0;
    }
    .import-project,
    .create-folder {
      font-size: 12px;
      padding: 4px 10px;
      margin-left: 10%;
    }
    .project-list {
      li {
        a {
          font-weight: 400;
          font-size: 11px;
        }
      }
    }
    .folder-list {
      li {
        a {
          font-size: 11px;
        }
      }
    }
    .information {
      p {
        font-size: 9px;
        font-weight: 400;
      }
    }
  }
  @media (min-width: 1920px) {
    .import-project,
    .create-folder {
      /* margin-left: 60px; */
    }
  }
`;

export default SideMenuUL;
