import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import CustomSelect from "@common/styles/components/selects/CustomSelect";

import CardWrapper from "./CardWrapper";
import { getTempRanges } from "./ChemicalAjustmentLimit";
import InputBoxWithValidation from "./InputBoxWithValidation";

const ChemicalAdjustmentTemperatureCard = ({ temperatureData, designTempInput, setDesignTempInput, handleBlur }) => {
  const { unitConversionByName } = useUnitConversion();
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);

  const [tempertureRanges, setTemperatureRanges] = useState({});
  const [designTemperatures, setDesignTemperatures] = useState([]);
  const [designTemp, setDesignTemp] = useState({});

  useEffect(() => {
    const ranges = getTempRanges(unitConfig.selectedUnits[2]);
    setTemperatureRanges(ranges);
  }, [unitConfig.selectedUnits]);

  useEffect(() => {
    const designTemperatures = temperatureData.map(item => ({
      ...item,
      value: unitConversionByName(item.value, unitConfig.selectedUnits[2], UNITS.celsius),
    }));
    setDesignTemperatures(designTemperatures);
    setDesignTemp(designTemperatures[1]);
    setDesignTempInput(designTemperatures[1].value);
  }, [unitConfig.selectedUnits, temperatureData]);

  const handleInputChange = event => {
    const { id, value } = event.target;
    const actionMap = {
      temp_input: () => setDesignTempInput(value),
      temp_dropdown: () => {
        const jsonValue = JSON.parse(value);
        setDesignTemp(jsonValue);
        setDesignTempInput(jsonValue.value);
      },
    };

    if (actionMap[id]) {
      actionMap[id]();
    }
  };

  return (
    <CardWrapper label='Temperature' toggleHidden>
      <div className='input-select'>
        <div>
          <CustomSelect id='temp_dropdown' value={JSON.stringify(designTemp)} onChange={handleInputChange}>
            {designTemperatures.map(item => (
              <option key={item.id} value={JSON.stringify(item)}>
                {item.label}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div>
          <InputBoxWithValidation
            id='temp_input'
            disabled={designTemp.id !== "specify"}
            value={designTempInput}
            inputText={unitConfig.selectedUnits[2]}
            onBlur={handleBlur}
            onChange={handleInputChange}
            ranges={tempertureRanges}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default ChemicalAdjustmentTemperatureCard;
