const UnitsMapping = {
  Flow: "unitTypeFlow",
  Temperature: "unitTypeTemp",
  Pressure: "unitTypePressure",
  Flux: "unitTypeFlux",
  Area: "unitTypeArea",
  "Concentration (gases)": "unitTypeContentration",
  Density: "unitTypeDensity",
  Length: "unitTypeLength",
  Power: "unitTypePower",
  "Specific Velocity": "unitTypeSVelocity",
  "Volume (Solution)": "unitTypeSVolume",
  "Volume (Resin)": "unitTypeRVolume",
  "Volume (Common)": "unitTypeCVolume",
  "Regeneration Dose": "unitTypeRegeneration",
  "Linear Velocity": "unitTypeLVelocity",
  Weight: "unitTypeWeight",
  Conductivity: "unitTypeConductivity",
  "Gas Flow": "unitTypeGasFlow",
  Organics: "unitTypeOrganic",
};

export const parseSelectedUnits = sourceData =>
  sourceData.reduce((parsedUnits, unitData) => {
    const key = UnitsMapping[unitData.uomTypeName];
    const value = unitData.unitKey?.find(unit => unit.isSelected == true).uomName;
    return { ...parsedUnits, [key]: value };
  }, {});

export const convertChemicalListToObj = chemicalList => {
  const chemicalListById = {};
  const chemicalListByName = {};

  chemicalList.forEach(chemical => {
    const { iD, displayName } = chemical;
    chemicalListById[iD] = chemical;
    chemicalListByName[displayName] = chemical;
  });

  return { chemicalListById, chemicalListByName };
};
