import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import WarningIcon from "@common/icons/WarningIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal, { StyledModalFooter } from "@common/styles/components/modals/CustomModal";
import { colors } from "@common/styles/Theme";

const ProjectSaveWarning = ({ show, close, yes }) => {
  const [openModal, setOpenModal] = useState(true);

  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    if (show === true) {
      setOpenModal(true);
      close(false);
    }
  }, [openModal]);

  return (
    <>
      <StyledModal
        show={show && openModal}
        onHide={handleClose}
        keyboard='false'
        centered
        maxWidth='416px'
        isWarningPopUp={true}
      >
        <Modal.Body isWarningPopUp={true}>
          <div className='warning-pop-up'>
            <div>
              <WarningIcon />
            </div>
            <div>
              <CustomHeading
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                fontWeight='600'
                color={colors.Black}
                label='You have unsaved data, do you want to save the project?'
              />
            </div>
          </div>
        </Modal.Body>
        <StyledModalFooter isWarningPopUp={true}>
          <StandardSecondaryButton className='' id='' onClick={handleClose} label='No' />
          <StandardPrimaryButton className='' id='' onClick={yes} label='Yes' />
        </StyledModalFooter>
      </StyledModal>
    </>
  );
};

export default ProjectSaveWarning;
