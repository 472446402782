import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import { SYSTEM_DESIGN_WATER } from "@features/feedwater/systemdesign/constants/SystemDesignConstant";
import { getFiltrateValue } from "@features/feedwater/uf/UFConfiguration/UFHelper";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";

const UFDiagramFiltrateDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);

  const [filtrateData, setFilterateData] = useState({});

  useEffect(() => {
    const data = {
      net: getFiltrateValue(SYSTEM_DESIGN_WATER.PRODUCT_WATER, calcEngineData, unitConfig, unitConversionByName),
      gross: getFiltrateValue(SYSTEM_DESIGN_WATER.GROSS_WATER, calcEngineData, unitConfig, unitConversionByName),
    };
    setFilterateData(data);
  }, [unitConfig.selectedUnits, calcEngineData]);

  //Calculating XY position for text positioning in SVG
  const netFiltrateXYPos = useMemo(
    () => (isInge ? (isCIP ? { x: 9700, y: 850 } : { x: 9700, y: 425 }) : { x: 9790, y: 900 }),
    [isInge, isCIP],
  );
  const grossFiltrateXYPos = useMemo(
    () => (isInge ? (isCIP ? { x: 6490, y: 1000 } : { x: 6490, y: 575 }) : { x: 6490, y: 900 }),
    [isInge, isCIP],
  );

  return (
    <>
      <BoldTextElement>
        <tspan {...grossFiltrateXYPos}>Gross Filtrate</tspan>
        <tspan x={netFiltrateXYPos.x} y={netFiltrateXYPos.y}>
          Net Filtrate
        </tspan>
      </BoldTextElement>
      {/* Gross Filterate */}
      <TextElement fill='#454545' fontSize='120'>
        <tspan x={grossFiltrateXYPos.x} y={grossFiltrateXYPos.y + 140}>
          {filtrateData.gross}
        </tspan>
      </TextElement>
      {/* Net Filtrate */}
      <TextElement fill='#454545'>
        <tspan x={netFiltrateXYPos.x} y={netFiltrateXYPos.y + 140}>
          {filtrateData.net}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramFiltrateDetails;
