import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { colors, modalStyles } from "@common/styles/Theme";

const StyledCustomModal = styled(Modal)`
  background-color: ${colors.blackTransparency045};

  .modal-dialog {
    max-width: 1188px;
  }
  .modal-content {
    ${modalStyles.normalModalStyle};
    width: ${props => props.width || "1188px"} !important;
    margin: 0 auto;

    .modal-header {
      border: none;
      background: ${colors.GreyF8};
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;

      .main-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .modal-footer {
    border-top: 0.5px solid ${colors.GreyE1};
    border-bottom: none;
    border-left: none;
    border-right: none;
    background: ${colors.White};
  }
`;
export default StyledCustomModal;
