import React from "react";
import styled from "styled-components";

import CustomTooltip from "@common/styles/components/tooltip/CustomTooltip";

const StyledButton = styled.button`
  /* height: 32px;
  width: 32px; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
`;

const IconButton = ({ tooltip, placement, children, onClick, id, className }) => {
  const handleClick = () => {
    if (onClick) onClick({ target: { id } });
  };
  return (
    <CustomTooltip text={tooltip} placement={placement}>
      <StyledButton data-tip={tooltip} onClick={handleClick} id={id} className={className}>
        {children}
      </StyledButton>
    </CustomTooltip>
  );
};

export default IconButton;
