import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT } from "@constants/units.constant";

import DupontLogger from "@utils/DupontLogger";

import {
  CHEMICAL_RANGES,
  CHEMICAL_TYPES,
  CHEMICAL_VARIABLES,
  COMPANY_CODE,
  UF_CHEMICAL_SCREENS,
  WATER_TYPE_IDS,
} from "@features/feedwater/uf/constants/UFConstants";
import { updateUFStoreData } from "@features/feedwater/uf/UFSlice";
import { isIngeSelected } from "@features/feedwater/uf/ufUtils";

const useUFChemicalsHandler = () => {
  const Logger = DupontLogger("useUFChemicalsHandler");
  const dispatch = useDispatch();

  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig) || {};
  const { waterTypeID, waterSubTypeID, totalDissolvedSolutes } =
    useSelector(state => state.Feedsetupdetailsdatapanel.streamData?.lstrequestsavefeedwater[0]?.streams[0]) || {};
  const { data: UFData, cipDropdownData, ufDoseGuidline, ufPHGuidline } = useSelector(state => state.UFStore);
  const { chemicalType } = cipDropdownData || {};

  const isValueInPh = useMemo(() => totalDissolvedSolutes > 0, [totalDissolvedSolutes]);
  const selectedTempUnit = useMemo(
    () => unitConfig?.selectedUnits[SELECTED_UNIT.TEMPERATURE],
    [unitConfig?.selectedUnits],
  );

  const getChemicalById = (type, id) => chemicalType[type]?.find(chem => `${chem.id}` === `${id}`);

  const getTargetPh = (isMineral, screen) => {
    const guidelineName = `${screen} ${isMineral ? "Acid" : CHEMICAL_TYPES.alkali}`.toLowerCase();
    const phData = ufPHGuidline.find(
      item => item.waterSubTypeId === waterSubTypeID && item.guidelineName.toLowerCase() === guidelineName,
    );
    return phData?.targetValuePH ?? 0;
  };

  const getConcentration = (type, id, config, screen) => {
    let value = 0;
    const isMineral = type === CHEMICAL_TYPES.mineral;
    screen = screen === UF_CHEMICAL_SCREENS.MINI_CIP ? UF_CHEMICAL_SCREENS.CIP : screen;
    if (isValueInPh) {
      value = getTargetPh(isMineral, screen);
    } else {
      value = getChemicalDoseValue(type, id, screen);
    }
    return value;
  };

  const getChemicalDoseValue = (type, id, screen) => {
    const chemData = getChemicalById(type, id);
    const chemicalDoseGuideline =
      chemData &&
      ufDoseGuidline.find(
        item =>
          item.waterSubTypeId == waterSubTypeID &&
          item.symbol == chemData.symbol &&
          item.guidelineName.includes(screen),
      );
    return chemicalDoseGuideline?.targetDose ?? 0;
  };

  const getUFChemicalData = (type, id, screen) => {
    let value = 0;
    if (id !== 0) {
      if (type === CHEMICAL_TYPES.mineral || type === CHEMICAL_TYPES.alkali) {
        const config = CHEMICAL_VARIABLES[screen][type];
        value = getConcentration(type, id, config, screen);
      } else {
        value = getChemicalDoseValue(type, id, screen);
      }
    }
    return value;
  };

  const defaultChemicals = useMemo(() => {
    let chemicalIDs = {};
    if (chemicalType) {
      const { alkali, mineral, organic, oxidant, oxidant2 } = chemicalType;
      const findChemicalId = chemicals => (chemicals && chemicals[0]?.id) || 0;
      chemicalIDs = {
        [CHEMICAL_TYPES.mineral]: findChemicalId(mineral),
        [CHEMICAL_TYPES.organic]: findChemicalId(organic),
        [CHEMICAL_TYPES.alkali]: findChemicalId(alkali),
        [CHEMICAL_TYPES.oxidant]: findChemicalId(oxidant),
        [CHEMICAL_TYPES.oxidant2]: findChemicalId(oxidant2),
      };
    }
    return chemicalIDs;
  }, [chemicalType]);

  const getCEBChemicalPhValues = () => {
    const { alkaliChemId, mineralChemId, alkaliEnabled_Ind_CEB, mineralEnabled_Ind_CEB } = UFData;
    const data = {
      alkaliValueInPh_Ind: true,
      mineralValueInPh_Ind: true,
      mineralValue: mineralEnabled_Ind_CEB
        ? getUFChemicalData(CHEMICAL_TYPES.mineral, mineralChemId, UF_CHEMICAL_SCREENS.CEB)
        : 0,
      alkaliValue: alkaliEnabled_Ind_CEB
        ? getUFChemicalData(CHEMICAL_TYPES.alkali, alkaliChemId, UF_CHEMICAL_SCREENS.CEB)
        : 0,
    };
    return data;
  };

  const getCIPChemicalPhValues = () => {
    const { alkaliChemId_CIP, mineralChemId_CIP, alkaliEnabled_Ind_CIP, mineralEnabled_Ind_CIP } = UFData;
    const data = {
      alkaliValueInPh_Ind_CIP: true,
      mineralValueInPh_Ind_CIP: true,
      mineralValue_CIP: mineralEnabled_Ind_CIP
        ? getUFChemicalData(CHEMICAL_TYPES.mineral, mineralChemId_CIP, UF_CHEMICAL_SCREENS.CIP)
        : 0,
      alkaliValue_CIP: alkaliEnabled_Ind_CIP
        ? getUFChemicalData(CHEMICAL_TYPES.alkali, alkaliChemId_CIP, UF_CHEMICAL_SCREENS.CIP)
        : 0,
    };
    return data;
  };

  const getMiniCIPChemicalPhValues = () => {
    const { alkaliChemId_MiniCIP, mineralChemId_MiniCIP, alkaliEnabled_Ind_MiniCIP, mineralEnabled_Ind_MiniCIP } =
      UFData;
    const data = {
      alkaliValueInPh_Ind_MiniCIP: true,
      mineralValueInPh_Ind_MiniCIP: true,
      mineralValue_MiniCIP: mineralEnabled_Ind_MiniCIP
        ? getUFChemicalData(CHEMICAL_TYPES.mineral, mineralChemId_MiniCIP, UF_CHEMICAL_SCREENS.MINI_CIP)
        : 0,
      alkaliValue_MiniCIP: alkaliEnabled_Ind_MiniCIP
        ? getUFChemicalData(CHEMICAL_TYPES.alkali, alkaliChemId_MiniCIP, UF_CHEMICAL_SCREENS.MINI_CIP)
        : 0,
    };
    return data;
  };

  const setUFChemicalValuesInPH = () => {
    if (UFData.mineralValueInPh_Ind) return;

    dispatch(
      updateUFStoreData({
        data: {
          ...getCEBChemicalPhValues(),
          ...getCIPChemicalPhValues(),
          ...getMiniCIPChemicalPhValues(),
        },
      }),
    );
  };

  const getChemicalDetails = (chemicalType, screen, isEnabled) => {
    const chemID = defaultChemicals[chemicalType];
    const key = screen === UF_CHEMICAL_SCREENS.CEB ? "" : `_${screen}`;
    let value = 0;

    if (isEnabled) {
      const screenType = UF_CHEMICAL_SCREENS.MINI_CIP === screen ? UF_CHEMICAL_SCREENS.CIP : screen; // For mini-cip also, need to get values same as CIP
      value = getUFChemicalData(chemicalType, chemID, screenType);
      isEnabled = isEnabled && value > 0;
    }
    return {
      [`${chemicalType}Enabled_Ind_${screen}`]: isEnabled,
      [`${chemicalType}ChemId${key}`]: isEnabled ? chemID : "0",
      [`${chemicalType}Value${key}`]: value,
    };
  };

  const getDefaultValue = (screen, isEnabled = true) => ({
    ...getChemicalDetails(CHEMICAL_TYPES.organic, screen, false),
    ...getChemicalDetails(CHEMICAL_TYPES.mineral, screen, isEnabled),
    ...getChemicalDetails(CHEMICAL_TYPES.alkali, screen, isEnabled),
    ...getChemicalDetails(CHEMICAL_TYPES.oxidant, screen, isEnabled),
  });

  const getSurfactantDefaultValue = isEnabled => {
    const chemID = defaultChemicals[CHEMICAL_TYPES.oxidant2];
    return {
      oxidant2Enabled_Ind_CIP: isEnabled,
      oxidant2ChemId_CIP: isEnabled ? chemID : "0",
      oxidant2Value_CIP: isEnabled ? getUFChemicalData(CHEMICAL_TYPES.oxidant2, chemID, UF_CHEMICAL_SCREENS.CIP) : 0,
    };
  };

  const disableUFChemicals = () => {
    const chemicalsData = Object.values(UF_CHEMICAL_SCREENS).reduce((result, screen) => {
      const newData = getDefaultValue(screen, false);
      return { ...result, ...newData };
    }, {});
    const cipData = getSurfactantDefaultValue(false);
    const data = { ...chemicalsData, ...cipData };
    dispatch(updateUFStoreData({ data }));
  };

  const setUFChemicalsDefaultValues = () => {
    Logger.log("setUFChemicalsDefaultValues: ", waterTypeID, waterSubTypeID, defaultChemicals);
    if (waterSubTypeID) {
      const isWasterWater = +waterTypeID === WATER_TYPE_IDS.WASTE_WATER;
      const isInge = isIngeSelected(UFData.pUFTechnologyID);
      const chemicalsData = Object.values(UF_CHEMICAL_SCREENS).reduce((result, screen) => {
        const newData =
          isInge && screen === UF_CHEMICAL_SCREENS.MINI_CIP ? getDefaultValue(screen, false) : getDefaultValue(screen);
        return { ...result, ...newData };
      }, {});
      const disinfectCEB = getChemicalDetails("disOxidant", UF_CHEMICAL_SCREENS.CEB, false);
      const cipData = getSurfactantDefaultValue(isInge && isWasterWater);
      const data = { ...chemicalsData, ...cipData, ...disinfectCEB };
      dispatch(updateUFStoreData({ data }));
      return data;
    }
  };

  const updateMiniCIPChemicals = isEnabled => {
    const data = getDefaultValue(UF_CHEMICAL_SCREENS.MINI_CIP, isEnabled);
    Logger.log("updateMiniCIPChemicals: ", isEnabled, data);
    dispatch(updateUFStoreData({ data }));
  };

  const getChemicalRanges = (valueType, screen) => {
    const company = COMPANY_CODE[UFData.pUFTechnologyID];
    const key = screen ? `_${screen}` : "";
    const ranges = {
      [`mineralValue${key}`]: CHEMICAL_RANGES.mineral[valueType][company],
      [`alkaliValue${key}`]: CHEMICAL_RANGES.alkali[valueType][company],
      [`organicValue${key}`]: CHEMICAL_RANGES.organic.mgL[company],
      [`oxidantValue${key}`]: CHEMICAL_RANGES.oxidant.mgL[company],
      [`oxidant2Value${key}`]: CHEMICAL_RANGES.oxidant2.mgL[company],
    };
    return ranges;
  };

  return {
    isValueInPh,
    selectedTempUnit,
    getChemicalRanges,
    getUFChemicalData,
    disableUFChemicals,
    updateMiniCIPChemicals,
    setUFChemicalValuesInPH,
    setUFChemicalsDefaultValues,
  };
};

export default useUFChemicalsHandler;
