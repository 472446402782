import React from "react";

import "./index.css";

const CustomTable = ({ headers, data, maxHeight = 300 }) => {
  const defaultColWidth = 100 / headers.length;

  const getColWidth = header => {
    let width = `${defaultColWidth}%`;
    if (header.colWidth) {
      width = `${header.colWidth}px`;
    }
    return { width };
  };
  return (
    <div className='custom-table-container'>
      <table className='table'>
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header.field} style={getColWidth(header)}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ maxHeight }}>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td key={colIndex} style={getColWidth(header)}>
                  {row[header.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
