import { CHEMICAL_SYMBOLS } from "@constants/units.constant";

const chemicalIncluded = [
  "Dechlorinator",
  "Organic Acid",
  "Oxidant",
  "Coagulant",
  "Antiscalant",
  "Salt",
  "Base",
  "Acid",
  "Surfactant",
];

const calculateSpecificGravity = (bulkConc_per, molecularWeight, coeffs) => {
  const temprature = 25;
  const avPitzer = Math.exp(0.411276653 + 0.008251869 * temprature + 0.0000162678 * Math.pow(temprature, 2));
  const Conc_molal = ((bulkConc_per / 100 / (1 - bulkConc_per / 100)) * 1000) / molecularWeight;
  const Volume = calculateVolume(Conc_molal, coeffs, avPitzer);
  const density_water = calculateDensityWater(temprature);

  return (1000 + Conc_molal * molecularWeight) / (1000 + ((Conc_molal * density_water) / 1000) * Volume);
};

const calculateVolume = (Conc_molal, coeffs, avPitzer) =>
  coeffs.CSA +
  (coeffs.CSB / 1.2) * avPitzer * Math.log(1 + 1.2 * Math.sqrt(coeffs.CSB * Conc_molal)) +
  coeffs.CSC * Conc_molal +
  coeffs.CSD * Math.pow(Conc_molal, 2) +
  coeffs.CSE * Math.pow(Conc_molal, 3);

const calculateDensityWater = temprature =>
  (999.83952 +
    16.945176 * temprature +
    -0.0079870401 * Math.pow(temprature, 2) +
    -0.000046170461 * Math.pow(temprature, 3) +
    0.00000010556302 * Math.pow(temprature, 4) +
    -2.8054253e-10 * Math.pow(temprature, 5)) /
  (1 + 0.01687985 * temprature);

const calculatePolynomialGravity = (bulkConc_per, coeffs) =>
  coeffs.CSBP +
  coeffs.CSCP * bulkConc_per +
  coeffs.CSDP * Math.pow(bulkConc_per, 2) +
  coeffs.CSEP * Math.pow(bulkConc_per, 3);

const calculateBulkDensity = (bulkConc, newData, chemicalData, chemProperty) => {
  const isChemicalIncluded = chemicalIncluded.includes(newData.chemicalName);

  const { iD: chemID, symbol } = chemicalData.find(chemical => chemical.chemicalName === newData.chemicalName) || {};
  const objChemProperties = chemProperty.find(chem => chem.chemicalId === chemID);

  if (!isChemicalIncluded && objChemProperties && chemID) {
    const bulkConc_per = bulkConc;
    const molecularWeight = objChemProperties.molecularWeight;

    const coeffSpecGravityA = objChemProperties.coeff_SpecGravity_A != null;

    const coeffs = {
      CSA: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_A : 0,
      CSB: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_B : 0,
      CSC: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_C : 0,
      CSD: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_D : 0,
      CSE: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_E : 0,
      CSBP: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_BPrime : 0,
      CSCP: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_CPrime : 0,
      CSDP: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_DPrime : 0,
      CSEP: coeffSpecGravityA ? objChemProperties.coeff_SpecGravity_EPrime : 0,
    };

    let specificGravity = 0;
    if (bulkConc_per <= 50) {
      specificGravity = calculateSpecificGravity(bulkConc_per, molecularWeight, coeffs);
    } else {
      if (symbol === CHEMICAL_SYMBOLS.H2SO4) {
        specificGravity = calculatePolynomialGravity(bulkConc_per, coeffs);
      } else {
        specificGravity = newData.bulkDensity;
      }
    }
    return specificGravity;
  }
  return newData.bulkDensity;
};

export default calculateBulkDensity;
