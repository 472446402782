import { useDispatch } from "react-redux";

import { resetTheIXDReport, updateTabAvailable } from "@common/ReportIXDSlice";
import { resetReportUfSlice } from "@common/ReportUFSlice";

import { resetReport } from "@features/feedwater/activitymonitor/activityMonitorSlice";
import { resetFeedSetupStoreData } from "@features/feedwater/feedsetup/FeedsetupSlice";
import { FINAL_PARAMETER_LIST, SAVE_VESEEL_CYLINDRICAL_HEIGHT } from "@features/feedwater/ix/constants/IXDConstants";
import {
  resetIXData,
  resetVesselCylindricalHeights,
  setIxdActiveTab,
  updatelistFinalParamAdj,
  updateShowFinalParameterTab,
  updateViewReport,
} from "@features/feedwater/ix/IXDSlice";
import { resetRecoveryValues } from "@features/feedwater/systemdesign/processDiagramSlice";
import { resetUfData } from "@features/feedwater/uf/UFSlice";

export const useResetProjectData = () => {
  const dispatch = useDispatch();

  // moving logic to a common place
  const resetProjectData = () => {
    dispatch(resetRecoveryValues());
    dispatch(resetFeedSetupStoreData());
    dispatch(resetUfData());
    dispatch(resetIXData());
    dispatch(resetReport());
    dispatch(updateViewReport("false"));
    dispatch(updateShowFinalParameterTab(false));
    dispatch(setIxdActiveTab(1));
    dispatch(resetReportUfSlice());
    dispatch(resetTheIXDReport());
    dispatch(resetVesselCylindricalHeights(SAVE_VESEEL_CYLINDRICAL_HEIGHT));
    dispatch(updatelistFinalParamAdj([Array(4).fill(FINAL_PARAMETER_LIST)]));
    dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
  };
  return { resetProjectData };
};
