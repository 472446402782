import React, { useEffect, useState } from "react";

import InputRangeWithErrorAndWaring from "@components/InputRefWithErrorAndWaring";

import InputWithText from "@common/styles/components/inputs/InputWithText";

const InputBoxWithValidation = ({ id, value, disabled, inputText, onBlur, onChange, ranges, handleFocus, errors }) => {
  const [validationState, setValidationState] = useState({ isError: false, isWarning: false, message: "" });

  useEffect(() => {
    if (errors) {
      setValidationState(errors);
    }
  }, [errors]);

  const handleValueChange = event => {
    const inputValue = event.target.value;
    const { softLimit, hardLimit } = ranges;
    let newState = { isError: false, isWarning: false, message: "" };

    if (inputValue > hardLimit.maxValue || inputValue < hardLimit.minValue) {
      newState = { isError: true, message: `Ranges must be between ${hardLimit.minValue} to ${hardLimit.maxValue}` };
    } else if (
      inputValue < softLimit.minValue ||
      (inputValue > softLimit.maxValue && inputValue <= hardLimit.maxValue)
    ) {
      newState = { isWarning: true, message: `Ranges must be between ${softLimit.minValue} to ${softLimit.maxValue}` };
    }
    setValidationState(newState);
    onChange(event);
  };

  const handleKeyDown = evt => ["e", "E", "+", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault();

  return (
    <>
      <InputWithText
        id={id}
        value={value}
        inputText={inputText}
        onBlur={onBlur}
        onChange={handleValueChange}
        disabled={disabled}
        type='number'
        isWarning={validationState.isWarning}
        isError={validationState.isError}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
      />
      <InputRangeWithErrorAndWaring ranges={ranges?.hardLimit} {...validationState} />
    </>
  );
};

export default InputBoxWithValidation;
