import ViewReport from "./ViewReport";
// import SystemDesignStyled from "../systemdesign/SystemDesignStyled";
// import { Container } from "react-bootstrap";

const Report = () => (
  <>
    {/* <SystemDesignStyled>
        <Container fluid className="g-0"> */}
    <ViewReport />
    {/* <Footer/> */}
    {/* </Container>
      </SystemDesignStyled> */}
  </>
);

export default Report;
