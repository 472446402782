export const UNITS = {
  celsius: "°C",
  farhenite: "°F",
  meter: "m",
  millimeter: "mm",
  centimeter: "cm",
  inch: "in",
  kilowatt: "kW",
  ppmv: "ppmv", //parts per million by volume
  gcm3: "g/cm³", //grams per cubic centimeter
  uscm: "µS/cm", //microsiemens per centimeter
  bar: "bar",
  psi: "psi",
  CUBIC_METER: "m³",
  METER: "m",
  BV_PER_HR: "BV/h",
  BV: "BV",
  GRAMS_PER_LITRE: "g/L",
  MICROGRAM_LITRE: "µg/L",
  MiliEquivalentPerLiter: "meq/L",
  MiligramPerLiter: "mg/L",
  PartsPerMillion: "ppm CaC03",
  MicroAtmosphere: "µatm",
  meterCubePerHour: "m³/h",
  lmh: "LMH",
  squareMeter: "m²",
  kiloGram: "kg",
  meterPerHour: "m/h",
  normalMeterCubePerHour: "Nm³/h",
  toc: "mg/L TOC",
};

export const SELECTED_UNIT = {
  TEMPERATURE: 2,
  PRESSURE: 3,
  LENGTH: 8,
  VOLUME: 12,
  SPECIFIC_VELOCITY: 10,
  VOLUME_COMMON: 13,
  REGENERATION_DOSE: 14,
  CONDUCTIVITY: 17,
};

export const CHEMICAL_SYMBOLS = {
  H2SO4: "H₂SO₄",
};

// this const is similar to above selected unit, prefer this for better readability
export const UNIT_TYPES = {
  FLOW: 1,
  PRESSURE: 3,
  TEMPERATURE: 2,
  FLUX: 4,
  AREA: 5,
  GASES_CONCENTRATION: 6,
  CONDUCTIVITY: 17,
  DENSITY: 7,
  LENGTH: 8,
  POWER: 9,
  SPECIFIC_VELOCITY: 10,
  VOLUME_SOLUTION: 11,
  VOLUME_RESIN: 12,
  VOLUME_COMMON: 13,
  REGENERANT_DOSE: 14,
  LINEAR_VELOCITY: 15,
  WEIGHT: 16,
  GAS_FLOW: 18,
  ORGANICS: 19,
};

export const METRIC_UNITS = {
  [UNIT_TYPES.FLOW]: UNITS.meterCubePerHour,
  [UNIT_TYPES.PRESSURE]: UNITS.bar,
  [UNIT_TYPES.TEMPERATURE]: UNITS.celsius,
  [UNIT_TYPES.FLUX]: UNITS.lmh,
  [UNIT_TYPES.AREA]: UNITS.squareMeter,
  [UNIT_TYPES.GASES_CONCENTRATION]: UNITS.MicroAtmosphere,
  [UNIT_TYPES.CONDUCTIVITY]: UNITS.uscm,
  [UNIT_TYPES.DENSITY]: UNITS.gcm3,
  [UNIT_TYPES.LENGTH]: UNITS.millimeter,
  [UNIT_TYPES.POWER]: UNITS.kilowatt,
  [UNIT_TYPES.SPECIFIC_VELOCITY]: UNITS.BV_PER_HR,
  [UNIT_TYPES.VOLUME_SOLUTION]: UNITS.CUBIC_METER,
  [UNIT_TYPES.VOLUME_RESIN]: UNITS.CUBIC_METER,
  [UNIT_TYPES.VOLUME_COMMON]: UNITS.BV,
  [UNIT_TYPES.REGENERANT_DOSE]: UNITS.GRAMS_PER_LITRE,
  [UNIT_TYPES.LINEAR_VELOCITY]: UNITS.meterPerHour,
  [UNIT_TYPES.WEIGHT]: UNITS.kiloGram,
  [UNIT_TYPES.GAS_FLOW]: UNITS.normalMeterCubePerHour,
  [UNIT_TYPES.ORGANICS]: UNITS.toc,
};
