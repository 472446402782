import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import TextElement from "../UFDiagramsUtils/TextElement";

const UFChemAdjustmentData = {
  phDetails: {
    x: "200",
    y: "3770",
    d: "M1630 3730L1530 3672.26L1530 3787.74L1630 3730ZM1320 3740L1540 3740L1540 3720L1320 3720L1320 3740Z",
  },
  oxidantDetails: {
    x: "200",
    y: "3970",
    d: "M1630 3930L1530 3872.26L1530 3987.74L1630 3930ZM1320 3940L1540 3940L1540 3920L1320 3920L1320 3940Z",
  },
  coagulantDetails: {
    x: "200",
    y: "4170",
    d: "M1630 4130L1530 4072.26L1530 4187.74L1630 4130ZM1320 4140L1540 4140L1540 4120L1320 4120L1320 4140Z",
  },
};

const IngeChemAdjustmentData = {
  phDetails: {
    x: "100",
    y: "3650",
    d: "M1520 3604L1420 3546.26L1420 3661.74L1520 3604ZM1210 3614L1430 3614L1430 3594L1210 3594L1210 3614Z",
  },
  oxidantDetails: {
    x: "100",
    y: "3850",
    d: "M1520 3804L1420 3746.26L1420 3861.74L1520 3804ZM1210 3814L1430 3814L1430 3794L1210 3794L1210 3814Z",
  },
  coagulantDetails: {
    x: "100",
    y: "4050",
    d: "M1520 4004L1420 3946.26L1420 4061.74L1520 4004ZM1210 4014L1430 4014L1430 3994L1210 3994L1210 4014Z",
  },
};

const IngeNoCIPChemAdjustmentData = {
  phDetails: {
    x: "100",
    y: "3650",
    d: "M1520 3210L1420 3152.26L1420 3267.74L1520 3210ZM1210 3220L1430 3220L1430 3200L1210 3200L1210 3220Z",
  },
  oxidantDetails: {
    x: "100",
    y: "3850",
    d: "M1520 3410L1420 3352.26L1420 3467.74L1520 3410ZM1210 3420L1430 3420L1430 3400L1210 3400L1210 3420Z",
  },
  coagulantDetails: {
    x: "100",
    y: "4050",
    d: "M1520 3610L1420 3552.26L1420 3667.74L1520 3610ZM1210 3620L1430 3620L1430 3600L1210 3600L1210 3620Z",
  },
};

const UFDiagramDegasDetails = ({ isInge, isCIP }) => {
  const { ufChemicalAdjustment } = useSelector(state => state.UFStore);
  const { chemicalListById } = useSelector(state => state.projectInfo.projectConfig.chemicalConfig);

  const [phDetails, setPHDetails] = useState(null);
  const [oxidantDetails, setOxidantDetails] = useState(null);
  const [coagulantDetails, setCoagulantDetails] = useState(null);

  const parsePhDetails = (chemicalId, value) => {
    const chemical = chemicalListById[chemicalId];
    return `${value} mg/L ${chemical.symbol} ${chemical.bulkConcentration}%`;
  };

  const ChemDetailsConfig = isInge
    ? isCIP
      ? IngeChemAdjustmentData
      : IngeNoCIPChemAdjustmentData
    : UFChemAdjustmentData;

  useEffect(() => {
    const { phDown, oxidant, coagulant } = ufChemicalAdjustment?.data || {};
    if (phDown) {
      const phConcentration = ufChemicalAdjustment?.phConcentration || 0;
      setPHDetails(parsePhDetails(phDown.chemicalId, phConcentration));
    } else {
      setPHDetails(null);
    }
    const setDetails = (chemical, setDetailFunction) => {
      if (chemical) {
        setDetailFunction(parsePhDetails(chemical.chemicalId, chemical.value));
      } else {
        setDetailFunction(null);
      }
    };

    setDetails(oxidant, setOxidantDetails);
    setDetails(coagulant, setCoagulantDetails);
  }, [ufChemicalAdjustment]);

  const getXYPosition = type => {
    let x = ChemDetailsConfig[type].x;
    let y = ChemDetailsConfig[type].y;

    if (isInge && !isCIP) {
      y = y - 400;
      x = x - 20;
    }

    return { x, y };
  };

  return (
    <>
      {phDetails && (
        <>
          <TextElement fill='#454545'>
            <tspan {...getXYPosition("phDetails")}>{phDetails}</tspan>
          </TextElement>
          <path id='Arrow 30' d={ChemDetailsConfig.phDetails.d} fill='#E61611' />
        </>
      )}

      {oxidantDetails && (
        <>
          <TextElement fill='#454545'>
            <tspan {...getXYPosition("oxidantDetails")}>{oxidantDetails}</tspan>
          </TextElement>
          <path id='Arrow 36' d={ChemDetailsConfig.oxidantDetails.d} fill='#E61611' />
        </>
      )}

      {coagulantDetails && (
        <>
          <TextElement fill='#454545'>
            <tspan {...getXYPosition("coagulantDetails")}>{coagulantDetails}</tspan>
          </TextElement>
          <path id='Arrow 37' d={ChemDetailsConfig.coagulantDetails.d} fill='#E61611' />
        </>
      )}
    </>
  );
};

export default UFDiagramDegasDetails;
