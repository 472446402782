import DupontLogger from "@utils/DupontLogger";

import { CEB_DETAILS_LIST } from "./UFDiagramConstants";

export const convertUpto2Digits = value => (isNaN(parseFloat(value)) ? "0.00" : parseFloat(value).toFixed(2));

const Logger = DupontLogger("UFDiagramHelper");
const FLOW_METRIC_UNIT = "m³/h";

const getCEBValue = (data, calcEngineData, chemicalListById, cebItem, convertUnitCallback) => {
  if (!data[cebItem.isEnabled]) return;

  const chemId = data[cebItem.chemicalId];
  const chemical = chemicalListById[chemId];
  if (!chemical) {
    Logger.error(`getCEBValue - Chemical with ID ${chemId} not found in chemicalListById`);
    return;
  }

  const { displayName, bulkConcentration } = chemical;
  const unit = data[cebItem.valueInPh] ? "pH" : "mg/L";
  const prefix = cebItem.prefix || "CEB";
  const chemValue = convertUnitCallback(calcEngineData[cebItem.calcField]);
  const chemDetails = `${chemValue} ${bulkConcentration}%`;

  return `${prefix} ${data[cebItem.value]} ${unit} ${displayName} ${chemDetails}`;
};

export const getCEBChemicalDetails = (data, calcEngineData, chemicalListById, convertUnitCallback) => {
  // If UF Special Feature ID is 2 (mini-CIP only), filter out CEB details upfront
  const relevantDetailsList =
    String(data.ufSpecialFeatureID) === "2"
      ? CEB_DETAILS_LIST.filter(param => !param.key.includes("ceb"))
      : CEB_DETAILS_LIST;

  return relevantDetailsList.reduce((result, param) => {
    result[param.key] = getCEBValue(data, calcEngineData, chemicalListById, param, convertUnitCallback);
    return result;
  }, {});
};

export const getFeedPumpMaxFlow = (calcEngineData, unitConfig) => {
  let value = 0;
  const rawActualDay = calcEngineData?.flow_Feed_Pump_max;
  if (rawActualDay) {
    value = Math.round(+rawActualDay, 0);
  }
  return `Max ${value} ${unitConfig.selectedUnits[1]}`;
};

export const getPumpValues = (unitConfig, calcEngineData, pumpType, pressureValue, unitConversionByName) => {
  const selectedUnit = unitConfig.selectedUnits[1];
  const pressureUnit = unitConfig.selectedUnits[3];
  let value = calcEngineData[pumpType] || 0;

  if (value && selectedUnit !== FLOW_METRIC_UNIT) {
    value = unitConversionByName(value, selectedUnit, FLOW_METRIC_UNIT);
  }
  value = Math.round(value);

  return {
    value: `${value} ${selectedUnit}`,
    pressure: `@ ${pressureValue} ${pressureUnit}`,
  };
};
