import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import DupontLogger from "@utils/DupontLogger";

import WarningIcon from "@common/icons/WarningIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import StyledModal from "@common/styles/components/modals/CustomModal";
import { colors } from "@common/styles/Theme";

import { useDeleteDataMutation, useUpdateDataMutation } from "@services/apiConfig";

import { btnlist, updateLoader } from "./CardListSlice";
import DeleteProjectSuccessfulPopup from "./DeleteProjectSuccessfulPopup";
import { setIsFolderRestored } from "./ViewAllFolderSlice";

const DeleteProjectPopup = props => {
  const Logger = DupontLogger("DeleteProjectPopup");
  const dispatch = useDispatch();
  const [deletePost] = useDeleteDataMutation();
  const [RestorPost] = useUpdateDataMutation();

  const StoreData = useSelector(state => state.cardlist.data);
  const [deleteSuccess, setdeleteSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(true);

  useEffect(() => {
    if (props.show === true) {
      setOpenModal(true);
      close(false);
    }
  }, [openModal]);

  const DeleteRecord = (id, methodName, IsDeleted) => {
    dispatch(updateLoader(false));
    const data = methodName;
    if (IsDeleted === "R") {
      const newData = StoreData.filter(item => item.projectID !== id);
      RestorPost(data)
        .then(() => {
          dispatch(btnlist(newData));
        })
        .catch(error => {
          Logger.log("Error deleting data: ", error);
        });
    } else if (IsDeleted === "F") {
      const newData = StoreData.filter(item => item.folderID !== id);
      deletePost(data)
        .then(() => {
          dispatch(btnlist(newData));
        })
        .catch(error => {
          Logger.log("Error deleting data: ", error);
        });
    } else if (IsDeleted === "RF") {
      //For Restore Folder
      RestorPost(data)
        .then(response => {
          if (response?.data?.responseCode === 200) {
            const newData = StoreData.filter(item => item.folderID !== id);
            dispatch(btnlist(newData));
            dispatch(setIsFolderRestored(true));
          }
        })
        .catch(error => {
          Logger.log("Error deleting data: ", error);
        });
    } else {
      const newData = StoreData.filter(item => item.projectID !== id);
      deletePost(data)
        .then(() => {
          dispatch(btnlist(newData));
        })
        .catch(error => {
          Logger.log("Error deleting data: ", error);
        });
    }
    setdeleteSuccess(true);
    props.close(false);
  };

  return (
    <>
      <StyledModal
        show={props.show && openModal}
        onHide={() => close(false)}
        keyboard='false'
        centered
        backdrop='static'
        isWarningPopUp
        maxWidth='416px'
      >
        <Modal.Body>
          <div className='warning-pop-up'>
            <div>
              <WarningIcon />
            </div>
            <div>
              <CustomHeading
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                fontWeight='600'
                color={colors.Black}
                label={props.lblMessage}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <StandardSecondaryButton className='' id='canBtn' onClick={() => props.close(false)} label='Cancel' />
          <StandardPrimaryButton
            label='OK'
            className=''
            onClick={() => DeleteRecord(props.id, props.methodName, props.IsDeleted)}
          />
          <DeleteProjectSuccessfulPopup
            show={deleteSuccess}
            close={() => setdeleteSuccess(false)}
            childParentModal={() => close(false)}
          />
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default DeleteProjectPopup;
