/* eslint-disable max-len */
import React from "react";

const DeleteCaseIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <line y1='2.96191' x2='18' y2='2.96191' stroke='black' />
      <mask id='path-2-inside-1_185507_33196' fill='white'>
        <path d='M4.42578 2C4.42578 0.895429 5.32121 0 6.42578 0H11.2782C12.3828 0 13.2782 0.89543 13.2782 2V3.46154H4.42578V2Z' />
      </mask>
      <path
        d='M3.42578 2C3.42578 0.343146 4.76893 -1 6.42578 -1H11.2782C12.9351 -1 14.2782 0.343146 14.2782 2H12.2782C12.2782 1.44772 11.8305 1 11.2782 1H6.42578C5.8735 1 5.42578 1.44772 5.42578 2H3.42578ZM13.2782 3.46154H4.42578H13.2782ZM3.42578 3.46154V2C3.42578 0.343146 4.76893 -1 6.42578 -1V1C5.8735 1 5.42578 1.44772 5.42578 2V3.46154H3.42578ZM11.2782 -1C12.9351 -1 14.2782 0.343146 14.2782 2V3.46154H12.2782V2C12.2782 1.44772 11.8305 1 11.2782 1V-1Z'
        fill='black'
        mask='url(#path-2-inside-1_185507_33196)'
      />
      <mask id='path-4-inside-2_185507_33196' fill='white'>
        <path d='M2.65625 16C2.65625 17.1046 3.55168 18 4.65625 18H13.3448C14.4493 18 15.3448 17.1046 15.3448 16V3.46154H2.65625V16Z' />
      </mask>
      <path
        d='M1.65625 16C1.65625 17.6569 2.9994 19 4.65625 19H13.3448C15.0016 19 16.3448 17.6569 16.3448 16H14.3448C14.3448 16.5523 13.8971 17 13.3448 17H4.65625C4.10397 17 3.65625 16.5523 3.65625 16H1.65625ZM15.3448 3.46154H2.65625H15.3448ZM1.65625 3.46154V16C1.65625 17.6569 2.9994 19 4.65625 19V17C4.10397 17 3.65625 16.5523 3.65625 16V3.46154H1.65625ZM13.3448 19C15.0016 19 16.3448 17.6569 16.3448 16V3.46154H14.3448V16C14.3448 16.5523 13.8971 17 13.3448 17V19Z'
        fill='black'
        mask='url(#path-4-inside-2_185507_33196)'
      />
    </svg>
  </>
);

export default DeleteCaseIcon;
