/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  CHEMICAL_FIELDS,
  CHEMICAL_INCLUDED,
  CHEMICAL_VALUE_MAX_FRACTIONS,
  CHEMICALS_LIST,
  COST_CATEGORIES,
  DEFAULT_CHEMICAL_VALIDATION,
  DEFAULT_NEW_CHEMICAL_DATA,
  REQUIRED_VALIDATION,
  TITLE_DATA,
} from "@constants/projectCostAndChemicalLibrary.constant";
import { UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import calculateBulkDensity from "@utils/chemicalLibUtils";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import CloseIcon from "@common/icons/CloseIcon";
import CloseIconBig from "@common/icons/CloseIconBig";
import DeleteIconBig from "@common/icons/DeleteIconBig";
import PencilIconBlack from "@common/icons/PencilIconBlack";
import RightTickMarkBigIcon from "@common/icons/RightTickMarkBigIcon";
import { updateChemicalConfig } from "@common/ProjectInfoSlice";
import StandardDashedButton from "@common/styles/components/buttons/standard/StandardDashedButton";
import StandardLinkButtonWithIcon from "@common/styles/components/buttons/standard/StandardLinkButtonWithIcon";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomRadioCheck from "@common/styles/components/checkboxs/CustomRadioCheck";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import LabelWithTooltip from "@common/styles/components/headings/LabelWithTooltip";
import ChemInputWithIcon from "@common/styles/components/inputs/ChemInputWithIcon";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import { colors } from "@common/styles/Theme";
import CustomeMessagePopup from "@common/utils/CustomeMessagePopup";
import DynamicLoadder from "@common/utils/DynamicLoadder";
import { MyError } from "@common/utils/ErrorCreator";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import { updateUnitFlag, updateUnitTypeDensity, updateUnitTypeSVolume } from "@common/utils/GlobalUnitConversionSlice";

import { useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import DefaultChemicalPrices from "./DefaultChemicalPrices";
import DefaultOperatingCost from "./DefaultOperatingCost";
import DefaultValueSaved from "./DefaultValueSaved";
import EditSavedMessage from "./EditSavedMessage";
import ProjectCostAndChemicalLibraryStyled from "./ProjectCostAndChemicalLibraryStyled";

const ProjectCostAndChemicalLibrary = ({ show, close, forUser }) => {
  const dispatch = useDispatch();
  const { unitConversionByName } = useUnitConversion();
  const { selectedUnits } = useSelector(state => state.projectInfo.projectConfig.unitConfig);
  const projectid = useSelector(state => state.projectInfo?.data?.projectID);
  const userID = useSelector(state => state.userInfo.data.UserId);
  const [defaultValueSaved, setDefaultValueSaved] = useState(false);
  const { projectTitle } = useSelector(state => state.projectInfo);
  const [loadder, setLoadder] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [openChemicalPrices, setOpenChemicalPrices] = useState(false);
  const [openDefaultOperatingCost, setOpenDefaultOperatingCost] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [openAddChemical, setOpenAddChemical] = useState(false);
  const [isError, setIsError] = useState(DEFAULT_CHEMICAL_VALIDATION);
  const [isDisabled, setIsDisabeld] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const [isInEditMode, setIsInEditMod] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [disableBD, setDisableBD] = useState(false);

  //api variables
  const [getChemicalData, chemicalResponse] = useLazyGetAllDataQuery();
  const [getCurrencylist, currencyResponse] = useLazyGetAllDataQuery();
  const [getUnitlist, unitResponse] = useLazyGetAllDataQuery();
  const [getData, response] = useLazyGetAllDataQuery();
  const [updateDataDefaultCost] = useUpdateDataMutation();
  const [updateChemicalDefu] = useUpdateDataMutation();
  const [updateData, updateResponse] = useUpdateDataMutation();

  //data store
  const [newData, setNewData] = useState(DEFAULT_NEW_CHEMICAL_DATA); // New chemical Data
  const [currencyInfo, setCurrencyInfo] = useState(COST_CATEGORIES);
  const [chemicalList, setChemicalList] = useState([]);
  const [chemProperty, setChemProperty] = useState([]);
  const [chemicalData, setChemicalData] = useState([]);
  const [popupOperator, setPopupOperator] = useState({
    type: "",
    message: "",
    show: false,
  });
  const [currencyUnitFactor, setCurrencyUnitFactor] = useState(1);
  const [selectedCurrencyUnit, setSelectedCurrencyUnit] = useState("m³");
  const [showDropDownController, setShowDropDownController] = useState([]);
  const [mouseEnter, setMouseEnter] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyUnit, setCurrencyUnit] = useState("$");
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);

  const titleData = TITLE_DATA(selectedUnits, currencyUnit);
  const requiredValidation = REQUIRED_VALIDATION;
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  useEffect(() => {
    if (show === true) {
      setOpenModal(true);
      close(false);
    }
  }, [openModal]);

  useEffect(() => {
    if (show) {
      try {
        getData(
          `masterdata/api/v${1}/OperatingCostChemical?userid=${userID}${forUser ? "" : `&projectid=${projectid}`}`,
        );
        getCurrencylist(
          `${"masterdata/api/v1/DefaultCurrency"}?userID=${userID}${forUser ? "" : `&projectid=${projectid}`}`,
        );
        getUnitlist(
          `${"masterdata/api/v1/UnitOfMeassure"}?userID=${userID}${forUser ? "" : `&projectid=${projectid}`}`,
        );
        getChemicalData(`masterdata/api/v${1}/ChemicalCategory`);
      } catch (error) {
        // console.log(error);
      }
    }
  }, [show]);

  const bulkDensityInMetricUnit = useCallback(
    value => unitConversionByName(value, unit.selectedUnits[7], UNITS.gcm3, CHEMICAL_VALUE_MAX_FRACTIONS),
    [unit.selectedUnits[7]],
  );

  useEffect(() => {
    if (response.isLoading) {
      setLoadder(true);
    }
    if (response.isError) {
      throw new MyError("OperatingCostChemical Api Error", response.error.status, "ApiError");
    }
    if (response.isSuccess) {
      const data = [];
      response.data.lstChemicalVMs.map(item => {
        data.push(item.showDropDown);
      });
      setShowDropDownController(data);
      const { rawWater, wasteWaterDisposal, electricity } = response.data;
      setCurrencyInfo({
        rawWater,
        wasteWaterDisposal,
        electricity,
      });
      const lstChemicalVMs = response.data.lstChemicalVMs.map(item => {
        const bulkDensity = bulkDensityInMetricUnit(item.bulkDensity);
        return { ...item, bulkDensity };
      });
      setChemicalList(lstChemicalVMs);
      setLoadder(false);
    }
  }, [response]);

  useEffect(() => {
    if (response.isSuccess && currencyResponse.isSuccess && unitResponse.isSuccess) {
      const { rawWater, wasteWaterDisposal, electricity } = response.data;
      const selectedData = currencyResponse.data.find(a => a.isDefault);
      const exchangeRate = selectedData ? selectedData.currencyValue : 1;
      setCurrencyInfo({
        rawWater: Number((rawWater * exchangeRate) / currencyUnitFactor).toFixed(4),
        wasteWaterDisposal: Number((wasteWaterDisposal * exchangeRate) / currencyUnitFactor).toFixed(4),
        electricity: Number(electricity * exchangeRate).toFixed(4),
      });
    }
  }, [response, currencyResponse, unitResponse, currencyUnitFactor]);

  useEffect(() => {
    if (chemicalResponse.isSuccess) {
      const { data } = chemicalResponse;
      const { lstChemCategory } = data;

      setChemicalData([...lstChemCategory].sort((a, b) => a.chemicalName.localeCompare(b.chemicalName)));
      setChemProperty(data.lstChemProperty);
    }
    if (chemicalResponse.isError) {
      throw new MyError("OperatingCostChemical Api Error", chemicalResponse.error.status, "ApiError");
    }
  }, [chemicalResponse]);

  useEffect(() => {
    if (unitResponse.isSuccess) {
      const data = unitResponse.data.find(item => item.uomTypeID == 11).unitKey.find(item => item.isSelected);

      const unitName = data.uomName === "gal" ? "Kgal" : data.uomName;
      switch (data.uomId) {
        case 21:
          setCurrencyUnitFactor(0.2642);
          break;
        case 22:
          setCurrencyUnitFactor(1000);
          break;
        case 23:
          setCurrencyUnitFactor(1000000);
          break;
        case 24:
          setCurrencyUnitFactor(1);
          break;
        default:
          break;
      }
      setSelectedCurrencyUnit(unitName);
    }
  }, [unitResponse]);
  useEffect(() => {
    if (currencyResponse.isSuccess) {
      const selectedData = currencyResponse.data.find(a => a.isDefault);

      let currencyName = selectedData ? selectedData.currencyName.replace("(", " ").replace(")", "").split(" ") : ["$"];
      currencyName = currencyName[currencyName.length - 1];
      setCurrencyUnit(currencyName);
      setExchangeRate(selectedData ? selectedData.currencyValue : 1);
    }
    if (currencyResponse.isError) {
      throw new MyError("OperatingCostChemical Api Error", currencyResponse.error.status, "ApiError");
    }
  }, [currencyResponse]);

  useEffect(() => {
    const data = [];
    if (chemicalList) {
      chemicalList.map(item => {
        data.push(item.showDropDown);
      });
      setShowDropDownController(data);
    }
  }, [chemicalList]);

  useEffect(() => {
    if (updateResponse.isSuccess) {
      setOpenSuccessMessage(true);
      dispatch(
        updateChemicalConfig({
          rawWater: Number((currencyInfo.rawWater * currencyUnitFactor) / exchangeRate),
          wasteWaterDisposal: Number((currencyInfo.wasteWaterDisposal * currencyUnitFactor) / exchangeRate),
          electricity: Number(currencyInfo.electricity / exchangeRate),
          lstChemicalVMs: chemicalList,
        }),
      );
    }
    if (updateResponse.isError) {
      setPopupOperator({
        type: "error",
        message: "Something went wrong please try again.",
        show: true,
      });
    }
  }, [updateResponse]);
  useEffect(() => {
    setCurrencyInfo({
      rawWater: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, currencyInfo.rawWater, unit.selectedUnits[11], "m³").toFixed(2),
      ),
      wasteWaterDisposal: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          currencyInfo.wasteWaterDisposal,
          unit.selectedUnits[11],
          "m³",
        ).toFixed(2),
      ),
      electricity: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, currencyInfo.electricity, unit.selectedUnits[11], "m³").toFixed(
          2,
        ),
      ),
    });
    dispatch(updateUnitTypeSVolume(unit.selectedUnits[11]));
    dispatch(updateUnitTypeDensity(unit.selectedUnits[7]));
    dispatch(updateUnitFlag(false));
    // }
  }, [unit.selectedUnits]);

  const handleFocus = id => {
    setIsFocused(id);
  };
  const handleBlur = () => {
    setIsFocused(null);
  };
  const handleClose = () => {
    setOpenModal(false);
    setOpenAddChemical(false);
  };
  const handleOpenChemicalPrice = () => {
    setOpenChemicalPrices(true);
  };
  const handleOpenDefaultOperatingCost = () => {
    setOpenDefaultOperatingCost(true);
  };

  //open new chemical tab
  const handleOpenAddChemical = () => {
    setOpenAddChemical(true);
    setNewData(DEFAULT_NEW_CHEMICAL_DATA);
    setIsError(DEFAULT_CHEMICAL_VALIDATION);
  };

  //close new chemical tab
  const handleCloseAddChemical = () => {
    setOpenAddChemical(false);
    setNewData(DEFAULT_NEW_CHEMICAL_DATA);
  };

  //handle change value function
  const handleChangeCurrency = e => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      setCurrencyInfo({ ...currencyInfo, [name]: value });
    }
  };

  const handleShowDropDown = e => {
    const { checked, name } = e.target;
    const [cat, id, key] = name.split("|");
    if (requiredValidation.includes(cat) && !checked) {
      const canRemove = chemicalList.filter(item => item.chemicalCat == cat && item.showDropDown).length > 1;
      if (canRemove) {
        setChemicalList(prev =>
          prev.map((chem, index) => {
            if (id == 0) {
              if (key == index) {
                chem = { ...chem, showDropDown: checked };
              }
            } else {
              if (chem.iD == id) {
                chem = { ...chem, showDropDown: checked };
              }
            }

            return chem;
          }),
        );
      } else {
        setPopupOperator({
          type: "warning",
          message: `Please, select at least one ${cat} chemical under first column.`,
          show: true,
        });
      }
    } else {
      setChemicalList(prev =>
        prev.map((chem, index) => {
          if (id == 0) {
            if (key == index) {
              chem = { ...chem, showDropDown: checked };
            }
          } else {
            if (chem.iD == id) {
              chem = { ...chem, showDropDown: checked };
            }
          }

          return chem;
        }),
      );
    }
  };

  const handleCLoseOperator = () => {
    setPopupOperator({
      show: false,
      message: "",
      type: "",
    });
  };

  const handleSaveNewChem = () => {
    const getDulkCon = (name, value) => {
      const data = [...CHEMICALS_LIST];
      if (!name || !value) {
        return true;
      } else {
        if (data.includes(name)) {
          setPopupOperator({
            type: "error",
            message: `For ${name} the bulk concentration value between 0 - 50%`,
            show: value < 1 || value > 50,
          });
          return value < 1 || value > 50;
        } else {
          setPopupOperator({
            type: "error",
            message: `For ${name} the bulk concentration value between 0 - 100%`,
            show: value < 1 || value > 100,
          });
          return value < 1 || value > 100;
        }
      }
    };
    const whichHasError = {
      chemicalName: !newData.chemicalName,
      symbol: !newData.symbol,
      displayName: !newData.displayName,
      bulkConcentration:
        !newData.bulkConcentration ||
        newData.bulkConcentration < 1 ||
        getDulkCon(newData.chemicalName, newData.bulkConcentration),
      bulkDensity: !newData.bulkDensity || newData.bulkDensity < 0,
      bulkPrice: !newData.bulkPrice || newData.bulkPrice < 0,
    };
    const hasError = Object.values(whichHasError).find(item => item);
    if (hasError) {
      setIsError(whichHasError);
    } else {
      setIsError(DEFAULT_CHEMICAL_VALIDATION);
      const isExist = chemicalList.find(
        item => item.chemicalName == newData.chemicalName && item.bulkConcentration == newData.bulkConcentration,
      );
      if (isInEditMode) {
        setChemicalList(prev =>
          prev.map((item, index) => {
            if (index === selectedIndex) {
              return {
                ...newData,
                bulkPrice: newData.bulkPrice / exchangeRate,
              };
            } else {
              return item;
            }
          }),
        );
        setOpenAddChemical(false);
        setIsInEditMod(false);
        setSelectedIndex(-1);
      } else {
        if (isExist) {
          setPopupOperator({
            type: "error",
            message: `Bulk Concentration ${newData.bulkConcentration} is already present for ${newData.chemicalName}.`,
            show: true,
          });
        } else {
          setChemicalList([...chemicalList, { ...newData, bulkPrice: newData.bulkPrice / exchangeRate }]);
          setOpenAddChemical(false);
        }
      }
    }
  };

  const handleNumberValue = ({ target }) => {
    const { value, name } = target;
    if (!isNaN(value)) {
      setIsError(DEFAULT_CHEMICAL_VALIDATION);
      const { BULK_CONCENTRATION } = CHEMICAL_FIELDS;
      if (name === BULK_CONCENTRATION && newData.symbol) {
        let bulkDensity = newData.bulkDensity;
        if (name === BULK_CONCENTRATION) {
          const densityValue = calculateBulkDensity(value, newData, chemicalData, chemProperty);
          bulkDensity = bulkDensityInMetricUnit(densityValue);
        }
        setNewData({
          ...newData,
          [name]: value,
          displayName: `${newData.symbol} (${value}%)`,
          bulkDensity: bulkDensity,
        });
      } else {
        setNewData({ ...newData, [name]: value });
      }
    }
  };
  const handleTextValue = e => {
    const { value, name } = e.target;
    setIsError(DEFAULT_CHEMICAL_VALIDATION);
    if (name == "symbol") {
      setNewData({
        ...newData,
        [name]: value,
        displayName: `${value} (${newData.bulkConcentration}%)`,
      });
    } else {
      setNewData({ ...newData, [name]: value });
    }
  };
  const handleInputChemical = e => {
    const selectedChemicalName = e.target.value;
    const chemicalIncluded = [...CHEMICAL_INCLUDED];
    const isChemicalIncluded = chemicalIncluded.includes(e.target.value);
    const selectedChemicalObj = chemicalData.find(chemical => chemical.chemicalName === selectedChemicalName);
    const bulkDensity = bulkDensityInMetricUnit(selectedChemicalObj?.bulkDensity);
    if (selectedChemicalName === "Chemical") {
      setNewData(DEFAULT_NEW_CHEMICAL_DATA);
      setDisableBD(false);
      setIsDisabeld(false);
    } else if (isChemicalIncluded) {
      setNewData({
        ...DEFAULT_NEW_CHEMICAL_DATA,
        symbol: "",
        displayName: "",
        costType: "L",
        chemicalCat: selectedChemicalObj?.chemicalCat,
        chemicalName: selectedChemicalObj?.chemicalName,
        bulkConcentration: selectedChemicalObj?.bulkConcentration,
        bulkDensity,
        bulkPrice: selectedChemicalObj?.bulkPrice,
      });
      setIsDisabeld(false);
      setDisableBD(false);
    } else {
      // setSelectedChemicalObject(selectedChemicalObj);
      setDisableBD([7, 8, 9, 10, 4].includes(selectedChemicalObj.iD));
      setNewData({
        ...DEFAULT_NEW_CHEMICAL_DATA,
        chemicalCat: selectedChemicalObj?.chemicalCat,
        chemicalName: selectedChemicalObj?.chemicalName,
        bulkConcentration: selectedChemicalObj?.bulkConcentration,
        bulkDensity,
        bulkPrice: selectedChemicalObj?.bulkPrice,
        costType: "L",
        symbol: selectedChemicalObj?.symbol,
        displayName: selectedChemicalObj?.displayName,
      });
      setIsDisabeld(true);
    }
  };

  const handleNewChemChek = e => {
    const { name, checked } = e.target;
    setNewData({ ...newData, [name]: checked });
  };

  const checkIfListIsValid = () => {
    const errorsList = [];
    requiredValidation.map(item => {
      const hasChem = chemicalList.find(chem => chem.chemicalCat == item && chem.showDropDown);
      if (!hasChem) {
        errorsList.push(`Please, select at least one ${item} chemical under first column.`);
      }
    });
    return errorsList;
  };

  const handleSaveData = () => {
    const errorList = checkIfListIsValid();
    if (errorList.length > 0) {
      setPopupOperator({ type: "warning", message: errorList[0], show: true });
    } else {
      const lstChemicalVMs = chemicalList.map(item => {
        item = {
          ...item,
          bulkDensity: Number(
            unitConversionByName(item.bulkDensity, UNITS.gcm3, unit.selectedUnits[7], CHEMICAL_VALUE_MAX_FRACTIONS),
          ),
        };
        return item;
      });
      const sendData = {
        Method: "masterdata/api/v1/OperatingCostData",
        userID: userID,
        projectID: projectid,
        rawWater: Number((currencyInfo.rawWater * currencyUnitFactor) / exchangeRate),
        wasteWaterDisposal: Number((currencyInfo.wasteWaterDisposal * currencyUnitFactor) / exchangeRate),
        electricity: Number(currencyInfo.electricity / exchangeRate),
        lstChemicalVMs: lstChemicalVMs,
      };
      if (forUser) {
        updateDefaultCost();
        updateCPD();
        setOpenSuccessMessage(true);
      } else {
        updateData(sendData);
      }
    }
  };

  const updateDefaultCost = () => {
    updateDataDefaultCost({
      Method: "masterdata/api/v1/MakeDefaultOperationRawCosts",
      rawWater: Number((currencyInfo.rawWater * currencyUnitFactor) / exchangeRate),
      wasteWaterDisposal: Number((currencyInfo.wasteWaterDisposal * currencyUnitFactor) / exchangeRate),
      electricity: Number(currencyInfo.electricity / exchangeRate),
      lstChemicalVMs: chemicalList,
      userID: userID,
    });
    setDefaultValueSaved(true);
  };

  const updateCPD = () => {
    updateChemicalDefu({
      userID: userID,
      Method: "masterdata/api/v1/MakeDefaultChemicals",
      rawWater: Number((currencyInfo.rawWater * currencyUnitFactor) / exchangeRate),
      wasteWaterDisposal: Number((currencyInfo.wasteWaterDisposal * currencyUnitFactor) / exchangeRate),
      electricity: Number(currencyInfo.electricity / exchangeRate),
      lstChemicalVMs: chemicalList,
    });
    setOpenChemicalPrices(false);
    setDefaultValueSaved(true);
  };

  const handleEditChemical = (data, index) => {
    setNewData(data);
    setOpenAddChemical(true);
    setIsInEditMod(true);
    setSelectedIndex(index);
  };

  const deleteTheChemical = index => {
    const existData = chemicalList[index];
    if (existData.showDropDown && requiredValidation.includes(existData.isSystem)) {
      const canBeDelete =
        chemicalList.filter(item => item.showDropDown && item.chemicalCat == existData.chemicalCat).length > 1;
      if (canBeDelete) {
        const newChemicalList = chemicalList.map(item => {
          if (item.showDropDown && item.chemicalCat === existData.chemicalCat) {
            return { ...item, isDeleted: true };
          }
          return item;
        });
        setChemicalList(newChemicalList);
      } else {
        setPopupOperator({
          type: "warning",
          message: `Please, select at least one ${existData.chemicalCat} chemical under first column.`,
          show: true,
        });
      }
    } else {
      const newChemicalList = chemicalList.map((item, ind) => {
        if (ind == index) {
          return { ...item, isDeleted: true };
        }
        return item;
      });
      setChemicalList(newChemicalList);
    }
  };

  return (
    <div
      onKeyDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      onFocus={e => e.stopPropagation()}
      onMouseOver={e => e.stopPropagation()}
    >
      <ProjectCostAndChemicalLibraryStyled
        show={show && openModal}
        onHide={handleClose}
        centered
        backdrop='static'
        keyboard='false'
      >
        <Modal.Header>
          <Modal.Title>
            <CustomHeading
              label='Chemical Library and Operating Costs'
              color={colors.SecondaryElfGreen}
              fontFamily='DiodrumSemiBold'
              fontSize='16px'
              fontWeight='600'
            />
            {/* ${forUser ? "user account preference":`project ${projectTitle}`}. Only select chemicals are shown below`} */}
            <CustomHeading
              label={`Add chemicals as per your need and update operating cost for
                   ${forUser ? "user account preference" : `project ${projectTitle}`}.`}
              color={colors.blackTransparency045}
              fontFamily='DiodrumRegular'
              fontSize='12px'
              fontWeight='400'
              className='subtitle'
            ></CustomHeading>{" "}
          </Modal.Title>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <DynamicLoadder isLoading={loadder}>
            <StyledCard className='operating-cost-card'>
              <div className='operating-cost-title'>
                <CustomHeading
                  label='Operation Cost'
                  fontFamily='DiodrumRegular'
                  fontSize='14px'
                  color={colors.PrimaryDarkAquaMarine}
                  fontWeight='400'
                />

                <StandardLinkButtonWithIcon
                  plusIcon={false}
                  id='defaultBtn'
                  label='Make costs as New Default'
                  onClick={handleOpenDefaultOperatingCost}
                ></StandardLinkButtonWithIcon>

                <DefaultOperatingCost
                  show={openDefaultOperatingCost}
                  close={setOpenDefaultOperatingCost}
                  yes={updateDefaultCost}
                />
              </div>
              <div className='water-type-wrapper'>
                <div className='raw-water'>
                  <CustomHeading
                    label='Raw Water'
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    color={colors.Black}
                    fontWeight='400'
                  />

                  <InputWithIcon
                    disabled={false}
                    placeholder='0'
                    type='text'
                    isError={!currencyInfo.rawWater || currencyInfo.rawWater < 0}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus("rawWater")}
                    name='rawWater'
                    isFocused={isFocused === "rawWater"}
                    inputText={
                      !currencyInfo.rawWater || currencyInfo.rawWater < 0 ? (
                        <CloseCircleRedIcon />
                      ) : (
                        <CloseCircleGreenIcon />
                      )
                    }
                    value={
                      isFocused === "rawWater"
                        ? currencyInfo.rawWater
                        : `${Number(currencyInfo.rawWater).toFixed(
                            4,
                          )} ${currencyUnit.toString()}/${selectedCurrencyUnit}`
                    }
                    unitBgColor='transparent'
                    onChange={handleChangeCurrency}
                  />
                  <ErrorMessage
                    errorIcon={true}
                    style={{
                      visibility: !currencyInfo.rawWater || currencyInfo.rawWater < 0 ? "visible" : "hidden",
                    }}
                    texMsg='Input Required'
                  />
                </div>
                <div className='waste-water'>
                  <CustomHeading
                    label='Waste Water Disposal'
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    color={colors.Black}
                    fontWeight='400'
                  />
                  <InputWithIcon
                    disabled={false}
                    isError={!currencyInfo.wasteWaterDisposal || currencyInfo.wasteWaterDisposal < 0}
                    inputText={
                      !currencyInfo.wasteWaterDisposal || currencyInfo.wasteWaterDisposal < 0 ? (
                        <CloseCircleRedIcon />
                      ) : (
                        <CloseCircleGreenIcon />
                      )
                    }
                    type='text'
                    onBlur={handleBlur}
                    onFocus={() => handleFocus("wasteWaterDisposal")}
                    isFocused={isFocused === "wasteWaterDisposal"}
                    name='wasteWaterDisposal'
                    unitBgColor='transparent'
                    value={
                      isFocused === "wasteWaterDisposal"
                        ? currencyInfo.wasteWaterDisposal
                        : `${Number(currencyInfo.wasteWaterDisposal).toFixed(
                            4,
                          )} ${currencyUnit.toString()}/${selectedCurrencyUnit}`
                    }
                    onChange={handleChangeCurrency}
                  />
                  <ErrorMessage
                    errorIcon={true}
                    style={{
                      visibility:
                        !currencyInfo.wasteWaterDisposal || currencyInfo.wasteWaterDisposal == 0 ? "visible" : "hidden",
                    }}
                    texMsg='Input Required'
                  />
                </div>
                <div className='electricity'>
                  <CustomHeading
                    label='Electricity'
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    color={colors.Black}
                    fontWeight='400'
                  />

                  <InputWithIcon
                    disabled={false}
                    isError={!currencyInfo.electricity || currencyInfo.electricity == 0}
                    type='text'
                    onBlur={handleBlur}
                    onFocus={() => handleFocus("electricity")}
                    isFocused={isFocused === "electricity"}
                    name='electricity'
                    inputText={
                      !currencyInfo.electricity || currencyInfo.electricity == 0 ? (
                        <CloseCircleRedIcon />
                      ) : (
                        <CloseCircleGreenIcon />
                      )
                    }
                    unitBgColor='transparent'
                    value={
                      isFocused === "electricity"
                        ? currencyInfo.electricity
                        : `${Number(currencyInfo.electricity).toFixed(4)} ${currencyUnit.toString()}/kWh`
                    }
                    onChange={handleChangeCurrency}
                  />
                  <ErrorMessage
                    errorIcon={true}
                    style={{
                      visibility: !currencyInfo.electricity || currencyInfo.electricity == 0 ? "visible" : "hidden",
                    }}
                    texMsg='Input Required'
                  />
                </div>
              </div>
            </StyledCard>
            <div className='g-0 header-row'>
              {titleData.map((item, index) => (
                <div className='header-title' key={index}>
                  <CustomHeading
                    label={item.title}
                    fontFamily='NotoSansSemiBold'
                    fontSize='14px'
                    color={colors.Black}
                    fontWeight='700'
                  />
                </div>
              ))}
            </div>
            <div className='chem-cost-data-wrapper' tabIndex='0'>
              {chemicalList.map(
                (data, index) =>
                  !data.isDeleted && (
                    <div className='chemical-details-row' key={index}>
                      <div className='chemical-label'>
                        <CustomRadioCheck
                          type='checkbox'
                          name={`${data.chemicalCat}|${data.iD}|${index}`}
                          id={data.iD}
                          key={index}
                          checked={showDropDownController[index]}
                          onChange={handleShowDropDown}
                        />
                      </div>
                      <div className='chemical-label'>
                        <LabelWithTooltip
                          toolTipText={true}
                          label={data.chemicalName}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <LabelWithTooltip
                          toolTipText={true}
                          label={data.symbol}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <LabelWithTooltip
                          toolTipText={true}
                          label={data.chemicalName}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <CustomHeading
                          label={data.chemicalCat}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <CustomHeading
                          label={Number(data.bulkConcentration).toFixed(2)}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <CustomHeading
                          label={Number(data.bulkDensity).toFixed(4)}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <CustomHeading
                          label={Number(data.bulkPrice * exchangeRate).toFixed(2)}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <CustomHeading
                          label={data.costType}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        <LabelWithTooltip
                          toolTipText={true}
                          label={data.displayName}
                          fontFamily='DiodrumRegular'
                          fontSize='14px'
                          color={colors.Black}
                          fontWeight='400'
                        />
                      </div>
                      <div className='chemical-label'>
                        {data.isSystem ? (
                          <CustomHeading
                            label='-'
                            fontFamily='DiodrumRegular'
                            fontSize='14px'
                            color={colors.Black}
                            fontWeight='400'
                          />
                        ) : (
                          <>
                            <Button id='closeBtn' onClick={() => deleteTheChemical(index)}>
                              <DeleteIconBig />
                            </Button>
                            <Button id='closeBtn' onClick={() => handleEditChemical(data, index)}>
                              <PencilIconBlack />
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  ),
              )}
            </div>
            <div className='add-btn-div'>
              <div className='new-data-wrapper' tabIndex='0'>
                {openAddChemical ? (
                  <Row className='input-field-row'>
                    <div className='chemical-input-field'>
                      <CustomRadioCheck
                        disabled={false}
                        isError={false}
                        type='checkbox'
                        id='checkbox'
                        name='showDropDown'
                        checked={newData.showDropDown}
                        onChange={handleNewChemChek}
                      />
                    </div>
                    <div className='chemical-input-field'>
                      <CustomSelect
                        id='inputField'
                        placeholder='Chemical' // Assuming CustomSelect supports a placeholder prop. If not, this won't have an effect.
                        onChange={handleInputChemical}
                        value={newData.chemicalName}
                      >
                        <option value='' disabled selected={!newData.chemicalName}>
                          Chemical
                        </option>
                        {chemicalData
                          .sort((a, b) => a.chemicalName.localeCompare(b.chemicalName))
                          .map(item => (
                            <option key={item.chemicalName} value={item.chemicalName}>
                              {item.chemicalName}
                            </option>
                          ))}
                      </CustomSelect>
                    </div>
                    <div className='chemical-input-field'>
                      <ChemInputWithIcon
                        disabled={isDisabled}
                        isError={isError.symbol}
                        type='text'
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(5)}
                        isFocused={isFocused === 5}
                        id='inputField'
                        inputText={<CloseCircleGreenIcon />}
                        unitBgColor='transparent'
                        placeholder='Symbol'
                        name='symbol'
                        value={newData.symbol}
                        onChange={e => handleTextValue(e)}
                      />
                    </div>
                    <div className='chemical-input-field'>
                      <ChemInputWithIcon
                        disabled={isDisabled}
                        isError={isError.chemicalName}
                        type='text'
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(6)}
                        isFocused={isFocused === 6}
                        id='inputField'
                        inputText={<CloseCircleGreenIcon />}
                        unitBgColor='transparent'
                        placeholder='Chemical Name'
                        name='chemicalName'
                        value={newData.chemicalName}
                        onChange={handleTextValue}
                      />
                    </div>
                    <div className='chemical-input-field'>
                      <ChemInputWithIcon
                        disabled={true}
                        isError={false}
                        type='text'
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(4)}
                        isFocused={isFocused === 4}
                        id='inputField'
                        inputText={<CloseCircleGreenIcon />}
                        unitBgColor='transparent'
                        placeholder='Chemical Category'
                        name='chemicalCat'
                        value={newData.chemicalCat}
                        onChange={handleTextValue}
                      />
                    </div>
                    <div className='chemical-input-field'>
                      <ChemInputWithIcon
                        disabled={false}
                        isError={isError.bulkConcentration}
                        type='text'
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(8)}
                        isFocused={isFocused === 8}
                        id='inputField'
                        inputText={<CloseCircleGreenIcon />}
                        unitBgColor='transparent'
                        placeholder='Concentration'
                        name={CHEMICAL_FIELDS.BULK_CONCENTRATION}
                        value={
                          isFocused === 8 ? newData.bulkConcentration : Number(newData.bulkConcentration).toFixed(2)
                        }
                        onChange={handleNumberValue}
                      />
                    </div>
                    <div className='chemical-input-field'>
                      <ChemInputWithIcon
                        disabled={disableBD}
                        isError={isError.bulkDensity}
                        type='text'
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(9)}
                        isFocused={isFocused === 9}
                        id='inputField'
                        inputText={<CloseCircleGreenIcon />}
                        unitBgColor='transparent'
                        placeholder='Density'
                        name='bulkDensity'
                        onChange={handleNumberValue}
                        value={isFocused === 9 ? newData.bulkDensity : Number(newData.bulkDensity).toFixed(4)}
                      />
                    </div>
                    <div className='chemical-input-field'>
                      <ChemInputWithIcon
                        disabled={false}
                        isError={isError.bulkPrice}
                        type='text'
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(10)}
                        isFocused={isFocused === 10}
                        id='inputField'
                        inputText={<CloseCircleGreenIcon />}
                        unitBgColor='transparent'
                        placeholder='Price'
                        name='bulkPrice'
                        onChange={handleNumberValue}
                        value={isFocused === 10 ? newData.bulkPrice : Number(newData.bulkPrice).toFixed(2)}
                      />
                    </div>
                    <div className='chemical-input-field'>
                      <CustomSelect
                        type=''
                        id='inputField'
                        name='costType'
                        onChange={handleTextValue}
                        value={newData.costType}
                      >
                        <option>gal</option>
                        <option>lb</option>
                        <option>Kg</option>
                        <option>L</option>
                      </CustomSelect>
                    </div>
                    <div className='chemical-input-field'>
                      <ChemInputWithIcon
                        disabled={isDisabled}
                        isError={isError.displayName}
                        type='text'
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(11)}
                        isFocused={isFocused === 11}
                        id='inputField'
                        inputText={<CloseCircleGreenIcon />}
                        unitBgColor='transparent'
                        placeholder='Displayed as'
                        name='displayName'
                        value={newData.displayName}
                        onChange={handleTextValue}
                      />
                    </div>
                    <div className='chemical-input-field cross_tick_icon'>
                      <Button
                        onClick={handleCloseAddChemical}
                        onMouseEnter={() => setMouseEnter(true)}
                        onMouseLeave={() => setMouseEnter(false)}
                        id='closeBtn'
                      >
                        {mouseEnter ? <DeleteIconBig /> : <CloseIconBig />}
                      </Button>
                      <Button id='tickBtn' onClick={handleSaveNewChem}>
                        <RightTickMarkBigIcon />
                      </Button>
                    </div>
                  </Row>
                ) : (
                  ""
                )}
              </div>
              <StandardDashedButton
                label='Add Chemical'
                className='add-chemical-button'
                id='addBtn'
                onClick={handleOpenAddChemical}
                disabled={openAddChemical}
              />
            </div>
          </DynamicLoadder>
        </Modal.Body>
        <Modal.Footer>
          <StandardLinkButtonWithIcon
            label='Make Chemical Prices as New Default'
            plusIcon={false}
            id='btnDefault'
            onClick={handleOpenChemicalPrice}
            disabled={false}
          ></StandardLinkButtonWithIcon>

          <DefaultChemicalPrices show={openChemicalPrices} close={setOpenChemicalPrices} yes={updateCPD} />
          <StandardPrimaryButton
            label='Save'
            disabled={false}
            id='btnSave'
            onClick={() => handleSaveData()}
          ></StandardPrimaryButton>
          <EditSavedMessage show={openSuccessMessage} close={setOpenSuccessMessage} parentModal={setOpenModal} />
        </Modal.Footer>
        <CustomeMessagePopup operator={popupOperator} close={handleCLoseOperator} />
        <DefaultValueSaved show={defaultValueSaved} close={setDefaultValueSaved} parentModal={setDefaultValueSaved} />
      </ProjectCostAndChemicalLibraryStyled>
    </div>
  );
};

export default ProjectCostAndChemicalLibrary;
