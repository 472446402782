import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import DupontDropdown from "@components/Dropdown";
import SortOrder from "@components/SortOrder";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseIcon from "@common/icons/CloseIcon";
import FilterIcon from "@common/icons/FilterIconOpenProject";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import { colors } from "@common/styles/Theme";

import { MODULE_TYPES, VALIDATION_DELAY_MS } from "../../constants/UFConstants";
import { setUFProductFilter } from "../../UFSlice";
import { getModules, handleSortingOperations, searchFilterLogic } from "../UFHelper";

import StyledUFProductFilters, { StyledDiv } from "./StyledUFProductFIlters";
import { DEFAULT_PRODUCT_TYPE_FILTERS, PRODUCT_CATEGORIES } from "./UFProductGuidelines.constant";

const UFProductFilter = () => {
  const dispatch = useDispatch();
  const { ufModules, ufProductFilter } = useSelector(state => state.UFStore);
  const { activeSearch, localSelectedModuleCategory, activeSortOrder, isValidForFiltering, localFilterdModules } =
    ufProductFilter || {};

  const handleChangeFilterInput = ({ target: { value } }) => {
    const filterdSearchedData = searchFilterLogic(value, ufModules);
    const newFilteredData = getModules(localSelectedModuleCategory, filterdSearchedData);
    const sortedFilteredValue = handleSortingOperations(activeSortOrder, newFilteredData);
    dispatch(setUFProductFilter({ localFilterdModules: sortedFilteredValue, activeSearch: value }));
  };
  const handleClearFilters = () => {
    dispatch(
      setUFProductFilter({
        activeSearch: "",
        flagForModuleFilter: false,
      }),
    );
    handleDropDownFilterChange(DEFAULT_PRODUCT_TYPE_FILTERS, "");
  };

  const handleSortingChange = option => {
    const fileterdModules = handleSortingOperations(option, localFilterdModules);
    dispatch(setUFProductFilter({ localFilterdModules: fileterdModules, activeSortOrder: option }));
  };

  const handleDropDownFilterChange = (selectedOption, localActive) => {
    const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
    const hasDW =
      selectedValues.includes(MODULE_TYPES.DRINKING_WATER) || selectedValues.includes(MODULE_TYPES.NON_DRINKING_WATER);
    const hasRackOpen =
      selectedValues.includes(MODULE_TYPES.OPEN_PLATFORM) || selectedValues.includes(MODULE_TYPES.RACK);

    if (!hasDW || !hasRackOpen) {
      dispatch(setUFProductFilter({ isValidForFiltering: true }));
      setTimeout(() => dispatch(setUFProductFilter({ isValidForFiltering: false })), VALIDATION_DELAY_MS);
      return;
    }

    const filteredModules = getModules(selectedOption, ufModules);
    const filterdSearchedData = searchFilterLogic(localActive, filteredModules);
    const sortedFilteredValue = handleSortingOperations(activeSortOrder, filterdSearchedData);
    dispatch(
      setUFProductFilter({ localFilterdModules: sortedFilteredValue, localSelectedModuleCategory: selectedOption }),
    );
  };

  return (
    <StyledUFProductFilters className='project-filter-icon'>
      <Dropdown>
        <Dropdown.Toggle>
          <FilterIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <SortOrder onChange={handleSortingChange} value={activeSortOrder} />
          <div className='search-inputbox'>
            <CustomHeading
              className='label'
              fontFamily='DiodrumSemiBold'
              label='Type a name'
              color={colors.PrimaryDarkAquaMarine}
            />
            <InputWithIcon
              type='text'
              value={activeSearch}
              id='module-name-filter-input'
              name='module-name-filter-input'
              placeholder='Type text here'
              onChange={handleChangeFilterInput}
              inputText={activeSearch && <CloseCircleGreenIcon />}
            />
          </div>
          <StyledDiv>
            <CustomHeading
              className='label'
              fontFamily='DiodrumSemiBold'
              label='Product Type/Category'
              color={colors.PrimaryDarkAquaMarine}
            />
            <CustomHeading
              fontSize='12px'
              label='Select atleast two product category: Drinking or Non-drinking Water and Rack or Open Platform'
              color={isValidForFiltering ? colors.DupontBrandRed : colors.blackTransparency045}
            />
            <DupontDropdown
              options={PRODUCT_CATEGORIES}
              defaultValue={DEFAULT_PRODUCT_TYPE_FILTERS}
              onChange={option => handleDropDownFilterChange(option, activeSearch)}
              value={localSelectedModuleCategory}
            />
          </StyledDiv>
          <div className='clear-filter'>
            <Button id='btnClear' onClick={handleClearFilters}>
              Clear Filters
              <CloseIcon />
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </StyledUFProductFilters>
  );
};

export default UFProductFilter;
