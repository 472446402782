import { getChemicalObject, getDisabledUFTech } from "@utils/appUtils";

import { UF_PAYLOAD_CHEMICAL_IDS, UF_PAYLOAD_CHEMICAL_NAMES } from "./useUFConfigConstants";

export const getUFConfigChemicalParams = (chemicalConfig, UFData) => {
  const disabledField = getDisabledUFTech(UFData.ufSpecialFeatureID);
  const { chemicalListById, chemicalListByName } = chemicalConfig;
  const validData = (data, label) => (data ? data[label] : 0);

  const getChemicalByName = (name, type, divisor = 1) => {
    if (disabledField && name.includes(disabledField)) {
      return "0";
    }

    const value = validData(chemicalListByName[name], type);
    return (value / divisor).toString();
  };

  const getChemicalsData = (chemicals, getChemical) => {
    const result = {};
    for (const key in chemicals) {
      result[key] = getChemical(chemicals[key]);
    }
    return result;
  };

  const getChemicalsDataById = (property, transformFn) => {
    const chemicalIds = getChemicalObject(UF_PAYLOAD_CHEMICAL_IDS, property, disabledField);
    return getChemicalsData(chemicalIds, id => transformFn(chemicalListById[UFData[id]]).toString());
  };

  const getChemicalsDensityById = () => getChemicalsDataById("density", data => validData(data, "bulkDensity"));
  const getChemicalsBukPriceById = () => getChemicalsDataById("price", data => validData(data, "bulkPrice"));
  const getChemicalsBulkConcentrationById = () =>
    getChemicalsDataById("bulk_conc", data => validData(data, "bulkConcentration") / 100);

  const getChemicalsDataByName = (property, transformFn) => {
    const chemicalNames = getChemicalObject(UF_PAYLOAD_CHEMICAL_NAMES, property, disabledField);
    return getChemicalsData(chemicalNames, transformFn);
  };

  const getChemicalsDensityByName = () =>
    getChemicalsDataByName("density", name => getChemicalByName(name, "bulkDensity"));

  const getChemicalsBukPriceyByName = () =>
    getChemicalsDataByName("price", name => getChemicalByName(name, "bulkPrice"));

  const getChemicalsBulkConcentrationByName = () =>
    getChemicalsDataByName("bulk_conc", name => {
      const divisor = 100;
      return getChemicalByName(name, "bulkConcentration", divisor);
    });

  return {
    ...getChemicalsDensityById(),
    ...getChemicalsDensityByName(),
    ...getChemicalsBukPriceById(),
    ...getChemicalsBukPriceyByName(),
    ...getChemicalsBulkConcentrationById(),
    ...getChemicalsBulkConcentrationByName(),
  };
};

export const getFeedwaterParams = StreamStoreData => {
  const params = {};
  if (StreamStoreData) {
    const { tempDesign, pH, turbidity, toc, tss, totalDissolvedSolutes, cations, anions, neutral } = StreamStoreData;
    params["feed_water"] = {
      methodname: "normal",
      designTemp: tempDesign,
      ph: parseFloat(pH),
      Degas: 0.0,
      percentage_of_initial_total_CO2_remaining: parseFloat(StreamStoreData?.percentContribution),
      Equilibrate_with: 0.0,
      Adjustment_Type: 0.0,
      Add_Reagent: 0.0,
      Total_CO2: 0.0,
      turbidity: turbidity,
      organicToc: toc,
      tss: tss,
      tds: totalDissolvedSolutes,
      cations: cations,
      anions: anions,
      neutrals: neutral,
      LSI_targ: 0,
      SDI_targ: 0,
      ChemicalAdjustment: [
        {
          CaSO4_per: 0,
          BaSO4_per: 0,
          SrSO4_per: 0,
          CaF2_per: 0,
          SiO2_per: 0,
          MgOH2_per: 0,
          LSI: 0,
          SDI: 0,
        },
      ],
    };
  }
  return params;
};
