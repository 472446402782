import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomCard from "@components/CustomCard";

import CustomLabel from "@common/styles/components/headings/CustomLabel";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import CustomRadio from "@common/styles/components/radios/CustomRadio";

import { updateUFStoreData } from "../UFSlice";

import { calculateUFFields } from "./UFHelper";

const SystemConf = () => {
  const dispatch = useDispatch();

  const { ufStandByOptions, ufStorageTankOptions, activeUFModule, data: UFData } = useSelector(state => state.UFStore);
  const { uFBWCEBStandbyOptionID, uFStorageTankOptionID } = UFData;
  const [storgaeTank, setStorageTank] = useState();

  const handleSelection = (option, isManualSelection) => {
    const data = {
      uFBWCEBStandbyOptionID: option,
      redundantStandbyTrains: option == 1 ? 0 : 1,
    };

    const calculatedUFFields = calculateUFFields({ ...UFData, ...data }, activeUFModule);
    const updateStoreData = {
      data: { ...data, ...calculatedUFFields },
      isUfDataUpdated: true,
    };
    if (isManualSelection) {
      updateStoreData.calcEngineDataRefreshCount = 1;
    }
    dispatch(updateUFStoreData(updateStoreData));
  };

  useEffect(() => {
    const item = ufStorageTankOptions.find(({ storageTankOptionID }) => storageTankOptionID === uFStorageTankOptionID);
    const tankOptionName = item?.storageTankOptionName || "";
    setStorageTank(tankOptionName);
  }, [uFStorageTankOptionID]);

  return (
    <div className='system-module-wrapper'>
      <CustomCard
        header='System Configuration'
        className='system-configuration-card'
        tooltipLabel='Select normal operating protocol, either constant operating flux or constant output flow.'
      >
        <CustomLabel label='Standby Option' />
        <div className='standby_radios'>
          {ufStandByOptions?.map(({ standByOptionID, standByOptionName }, idx) => {
            const isSelected = standByOptionID === uFBWCEBStandbyOptionID;
            return (
              <CustomRadio
                key={`standby-option-${idx}`}
                type='radio'
                name='uFBWCEBStandbyOptionID'
                value={standByOptionID}
                checked={isSelected}
                onChange={() => handleSelection(standByOptionID, true)}
                label={standByOptionName}
              />
            );
          })}
        </div>
        <div className='storage-tank'>
          <CustomLabel label='Storage Tank Option' />
          <CustomInput disabled value={storgaeTank} id='storageTankOption' placeholder='Storage Tank Options' />
        </div>
      </CustomCard>
    </div>
  );
};

export default SystemConf;
