import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useUFConfig from "@hooks/useUFConfig";

import CustomCard from "@components/CustomCard";

import ArrowRightBlackIcon from "@common/icons/ArrowRightBlackIcon";
import StandardLinkButtonWithIcon from "@common/styles/components/buttons/standard/StandardLinkButtonWithIcon";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import CustomSelect from "@common/styles/components/selects/CustomSelect";

import { updateUFStoreData } from "../UFSlice";

import UFProductGuidelines from "./UFProductGuidelines";

const ModuleSelection = ({ showProductGuidelines }) => {
  const dispatch = useDispatch();
  const { updateActiveModule } = useUFConfig();
  const [selectedModule, setSelectedModule] = useState("0");

  const { data, ufModules, bwProtocol } = useSelector(state => state.UFStore);
  const { filteredModules } = useSelector(state => state.UFStore.ufProductFilter);

  const [openUFGuideline, setOpenUFGuideline] = useState(false);

  useEffect(() => setSelectedModule(data?.uFModuleID), [data?.uFModuleID]);

  const handleUFModule = event => {
    updateActiveModule(event.target.value);
    const selectedModule = ufModules.find(item => item.ufmoduleId === parseInt(event.target.value));

    //When UXA protocol is slected drain_backwash and drain 0
    if (selectedModule?.newModuleLongName?.includes("UXA") && bwProtocol.length > 1) {
      dispatch(updateUFStoreData({ data: { drain_backWash: 0, drain: 0 } }));
    }
  };
  const handleOpenUFGuideLine = () => setOpenUFGuideline(true);

  const setPopUPBasedModuleSelection = item => {
    if (item?.id) {
      updateActiveModule(item.id);
    }
  };

  return (
    <CustomCard className='module-selection-card' header='Module Selection' tooltipLabel='Select a module.'>
      <div style={{ marginTop: "2px" }}>
        {/* <CustomHeading mandatoryIcon label='DuPont UF Module' /> */}
        <CustomLabel label='DuPont UF Module' mandatoryIcon />
        <CustomSelect
          type=''
          id='pesSelect'
          name='uFModuleID'
          value={selectedModule}
          placeholder='UF Modules'
          onChange={handleUFModule}
        >
          {/* When filter logics will be implemented we need to replace ufModules with filteredModules */}
          {filteredModules?.map((module, index) => (
            <option value={module.ufmoduleId} key={`ufModule-${index}`}>
              {module.newModuleLongName}
            </option>
          ))}
        </CustomSelect>
      </div>

      {showProductGuidelines && (
        <>
          <div className='btn-div' style={{ marginTop: 15 }}>
            <StandardLinkButtonWithIcon
              id='ufProductDefinitions'
              label='UF Product Guidelines'
              padding='10px 0px'
              icon={<ArrowRightBlackIcon />}
              onClick={handleOpenUFGuideLine}
            />
          </div>
          {openUFGuideline && (
            <UFProductGuidelines
              show
              onSelection={item => setPopUPBasedModuleSelection(item)}
              close={() => setOpenUFGuideline(false)}
            />
          )}
        </>
      )}
    </CustomCard>
  );
};

export default ModuleSelection;
