import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT, UNIT_TYPES } from "@constants/units.constant";

import { updateGlobalUnits, updateUnitFlag } from "@common/utils/GlobalUnitConversionSlice";

import {
  updateExisting,
  updateIXStore,
  updatelistFinalParamAdj,
  updateProductQualityRegenerantDose,
  updateVesselCylindricalHeight,
} from "@features/feedwater/ix/IXDSlice";
import { getDefaultOnVessel1Selected } from "@features/feedwater/ix/IXDUtilityFunction";

import useUnitConversion from "./useUnitConversion";

export const useUnitConversionIXD = () => {
  const ixStoreObj = useSelector(state => state.IXStore);
  const ixStore = useSelector(state => state.IXStore.data);
  const { unitConversionByName, convertUnit } = useUnitConversion();
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const selectedUnits = unit.selectedUnits;

  const {
    unitTypeSVelocity,
    unitTypeConductivity,
    unitTypeRVolume,
    unitTypeLength,
    unitTypeTemp,
    unitTypeCVolume,
    unitTypeRegeneration,
    unitTypePressure,
  } = useSelector(state => state.GUnitConversion);

  const dispatch = useDispatch();

  const getEquipmentsConversion = selectedUnits => ({
    pdExtPiping: unitConversionByName(ixStore.pdExtPiping, selectedUnits[UNIT_TYPES.PRESSURE], unitTypePressure),
    pdIntDistributor: unitConversionByName(
      ixStore.pdIntDistributor,
      selectedUnits[UNIT_TYPES.PRESSURE],
      unitTypePressure,
    ),
    effluentPressure: unitConversionByName(
      ixStore.effluentPressure,
      selectedUnits[UNIT_TYPES.PRESSURE],
      unitTypePressure,
    ),
    tankTemperature: unitConversionByName(ixStore.tankTemperature, selectedUnits[UNIT_TYPES.TEMPERATURE], unitTypeTemp),
    backwashTowerDiameter: unitConversionByName(
      ixStore.backwashTowerDiameter || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      selectedUnits[UNIT_TYPES.LENGTH],
      unitTypeLength,
    ),
    sacRegenVesselDia: unitConversionByName(
      ixStore.sacRegenVesselDia || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      selectedUnits[UNIT_TYPES.LENGTH],
      unitTypeLength,
    ),
    sbaRegenVesselDia: unitConversionByName(
      ixStore.sbaRegenVesselDia || getDefaultOnVessel1Selected(ixStore.ixTreatment, ixStore?.vessel1),
      selectedUnits[UNIT_TYPES.LENGTH],
      unitTypeLength,
    ),
  });

  const updateIXConversion = () => {
    const ixStore = ixStoreObj?.data;
    const resinVal = ixStore?.listRegenConds;
    const ixStoreAdvance = ixStore?.listAdvRegen;
    const ixRegenreteDose = ixStore?.listProductQualityandregeneration;
    const ixStoreCation = ixStore.listProductQualityandregeneration[0];
    const ixStoreAnion = ixStore.listProductQualityandregeneration[1];
    /*----Unit conversion for regenenConditionPage start-----*/
    const [a, b] = resinVal;
    let cationTemp = resinVal[0]?.temperature;
    let anionTemp = resinVal[1]?.temperature;
    if (a) {
      cationTemp = unitConversionByName(resinVal[0]?.temperature, selectedUnits[UNIT_TYPES.TEMPERATURE], unitTypeTemp);
    }
    if (b) {
      anionTemp = unitConversionByName(resinVal[1]?.temperature, selectedUnits[UNIT_TYPES.TEMPERATURE], unitTypeTemp);
    }
    const [Ra, Rd] = ixRegenreteDose;
    let cationRegenreteDoseVel = ixRegenreteDose[0]?.regenerantDoseVal4;
    let anionRegenreteDoseVel = ixRegenreteDose[1]?.regenerantDoseVal4;
    let cationAverageConduc = ixRegenreteDose[0]?.averageConductivityVal;
    let anionAverageConduc = ixRegenreteDose[1]?.averageConductivityVal;
    let cationendpointConduc = ixRegenreteDose[0]?.endpointConductivityVal;
    let anionendpointConduc = ixRegenreteDose[1]?.endpointConductivityVal;
    if (Ra) {
      cationRegenreteDoseVel = unitConversionByName(
        ixRegenreteDose[0]?.regenerantDoseVal4,
        selectedUnits[UNIT_TYPES.REGENERANT_DOSE],
        unitTypeRegeneration,
      );
      cationAverageConduc = unitConversionByName(
        ixRegenreteDose[0]?.averageConductivityVal,
        selectedUnits[UNIT_TYPES.CONDUCTIVITY],
        unitTypeConductivity,
      );
      cationendpointConduc = unitConversionByName(
        ixRegenreteDose[0]?.endpointConductivityVal,
        selectedUnits[UNIT_TYPES.CONDUCTIVITY],
        unitTypeConductivity,
      );
    }
    if (Rd) {
      anionRegenreteDoseVel = unitConversionByName(
        ixRegenreteDose[1]?.regenerantDoseVal4,
        selectedUnits[UNIT_TYPES.REGENERANT_DOSE],
        unitTypeRegeneration,
      );
      anionAverageConduc = unitConversionByName(
        ixRegenreteDose[0]?.averageConductivityVal,
        selectedUnits[UNIT_TYPES.CONDUCTIVITY],
        unitTypeConductivity,
      );
      anionendpointConduc = unitConversionByName(
        ixRegenreteDose[0]?.endpointConductivityVal,
        selectedUnits[UNIT_TYPES.CONDUCTIVITY],
        unitTypeConductivity,
      );
    }

    const [c, d] = ixStoreAdvance;
    let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
    let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
    let cationDisVol = ixStoreAdvance[0]?.displacementVolume;
    let anionDisVol = ixStoreAdvance[1]?.displacementVolume;
    let cationFasVol = ixStoreAdvance[0]?.fatRinseVolume;
    let anionFasVol = ixStoreAdvance[1]?.fatRinseVolume;
    if (c) {
      cationregenVel = unitConversionByName(
        ixStoreAdvance[0]?.regenerationVelocity,
        selectedUnits[UNIT_TYPES.SPECIFIC_VELOCITY],
        unitTypeSVelocity,
      );
      cationDisVol = unitConversionByName(
        ixStoreAdvance[0]?.displacementVolume,
        selectedUnits[UNIT_TYPES.VOLUME_COMMON],
        unitTypeCVolume,
      );
      cationFasVol = unitConversionByName(
        ixStoreAdvance[0]?.fatRinseVolume,
        selectedUnits[UNIT_TYPES.VOLUME_COMMON],
        unitTypeCVolume,
      );
    }
    if (d) {
      anionregeneVel = unitConversionByName(
        ixStoreAdvance[1]?.regenerationVelocity,
        selectedUnits[UNIT_TYPES.SPECIFIC_VELOCITY],
        unitTypeSVelocity,
      );
      anionDisVol = unitConversionByName(
        ixStoreAdvance[1]?.displacementVolume,
        selectedUnits[UNIT_TYPES.VOLUME_COMMON],
        unitTypeCVolume,
      );
      anionFasVol = unitConversionByName(
        ixStoreAdvance[1]?.fatRinseVolume,
        selectedUnits[UNIT_TYPES.VOLUME_COMMON],
        unitTypeCVolume,
      );
    }

    dispatch(
      updateIXStore({
        ...ixStore,
        space_velocity_txt: unitConversionByName(
          ixStore.space_velocity_txt,
          selectedUnits[UNIT_TYPES.SPECIFIC_VELOCITY],
          unitTypeSVelocity,
        ),
        ...getEquipmentsConversion(selectedUnits),
        listRegenConds: [
          { ...ixStore.listRegenConds[0], temperature: cationTemp },
          { ...ixStore.listRegenConds[1], temperature: anionTemp },
        ],
        listAdvRegen: [
          {
            ...ixStore.listAdvRegen[0],
            regenerationVelocity: Number(cationregenVel),
            displacementVolume: Number(cationDisVol),
            fatRinseVolume: Number(cationFasVol),
          },
          {
            ...ixStore.listAdvRegen[1],
            regenerationVelocity: Number(anionregeneVel),
            displacementVolume: Number(anionDisVol),
            fatRinseVolume: Number(anionFasVol),
          },
        ],
        listProductQualityandregeneration: [
          {
            ...ixStore.listProductQualityandregeneration[0],
            regenerantDoseVal4: Number(cationRegenreteDoseVel),
            averageConductivityVal: Number(cationAverageConduc),
            endpointConductivityVal: Number(cationendpointConduc),
          },
          {
            ...ixStore.listProductQualityandregeneration[1],

            regenerantDoseVal4: Number(anionRegenreteDoseVel),
            averageConductivityVal: Number(anionAverageConduc),
            endpointConductivityVal: Number(anionendpointConduc),
          },
        ],
      }),
    );

    dispatch(
      updateProductQualityRegenerantDose([
        {
          ...ixStoreCation,
          averageConductivityVal: unitConversionByName(
            ixStoreCation.averageConductivityVal,
            selectedUnits[UNIT_TYPES.CONDUCTIVITY],
            unitTypeConductivity,
          ),
          endpointConductivityVal: unitConversionByName(
            ixStoreCation.endpointConductivityVal,
            selectedUnits[UNIT_TYPES.CONDUCTIVITY],
            unitTypeConductivity,
          ),

          regenerantDoseVal4: unitConversionByName(
            ixStoreCation.regenerantDoseVal4,
            selectedUnits[UNIT_TYPES.REGENERANT_DOSE],
            unitTypeRegeneration,
          ),
        },
        {
          ...ixStoreAnion,
          averageConductivityVal: unitConversionByName(
            ixStoreAnion.averageConductivityVal,
            selectedUnits[UNIT_TYPES.CONDUCTIVITY],
            unitTypeConductivity,
          ),
          endpointConductivityVal: unitConversionByName(
            ixStoreAnion.endpointConductivityVal,
            selectedUnits[UNIT_TYPES.CONDUCTIVITY],
            unitTypeConductivity,
          ),
          regenerantDoseVal4: unitConversionByName(
            ixStoreAnion.regenerantDoseVal4,
            selectedUnits[UNIT_TYPES.REGENERANT_DOSE],
            unitTypeRegeneration,
          ),
        },
      ]),
    );

    const list = [...ixStoreObj.existingPlantDescription];
    const newList = list.map((item, index) => {
      const resinVolumeAsDelivered = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].resinVolumeAsDelivered,
        selectedUnits[UNIT_TYPES.VOLUME_RESIN],
        unitTypeRVolume,
      );
      const inertResinVolume = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].inertResinVolume,
        selectedUnits[UNIT_TYPES.VOLUME_RESIN],
        unitTypeRVolume,
      );
      const vesselDiameter = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].vesselDiameter,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const resinBedHeightAsDelivered = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].resinBedHeightAsDelivered,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const resinBedStandardHeight = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].resinBedStandardHeight,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const resinBedHeightAsRegenerated = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].resinBedHeightAsRegenerated,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const resinBedHeightAsExhausted = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].resinBedHeightAsExhausted,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const inertBedHeight = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].inertBedHeight,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const vesselCylindricalHeight = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].vesselCylindricalHeight,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const vesselWallThickness = unitConversionByName(
        ixStoreObj.existingPlantDescription[index].vesselWallThickness,
        selectedUnits[UNIT_TYPES.LENGTH],
        unitTypeLength,
      );
      const pressureDropwithRecomQty = unitConversionByName(
        ixStoreObj.listFinalParamAdj[index]?.pressureDropwithRecomQty,
        selectedUnits[UNIT_TYPES.PRESSURE],
        unitTypePressure,
      );
      return {
        ...item,
        resinVolumeAsDelivered: Number.parseFloat(resinVolumeAsDelivered).toFixed(2),
        inertResinVolume: Number.parseFloat(inertResinVolume).toFixed(2),
        vesselDiameter: Number.parseFloat(vesselDiameter).toFixed(2),
        resinBedHeightAsDelivered: Number.parseFloat(resinBedHeightAsDelivered).toFixed(2),
        resinBedStandardHeight: Number.parseFloat(resinBedStandardHeight).toFixed(2),
        resinBedHeightAsRegenerated: Number.parseFloat(resinBedHeightAsRegenerated).toFixed(2),
        resinBedHeightAsExhausted: Number.parseFloat(resinBedHeightAsExhausted).toFixed(2),
        inertBedHeight: Number.parseFloat(inertBedHeight).toFixed(2),
        vesselCylindricalHeight: Number.parseFloat(vesselCylindricalHeight).toFixed(2),
        vesselWallThickness: Number.parseFloat(vesselWallThickness).toFixed(2),
        pressureDropwithRecomQty: Number.parseFloat(pressureDropwithRecomQty).toFixed(2),
      };
    });
    dispatch(updateExisting(newList));
    const listFinal = [...ixStoreObj.listFinalParamAdj];
    const newListFinal = listFinal.map((item, index) => {
      const finalParam = ixStoreObj.listFinalParamAdj[index];
      const resinVolumeAsDelivered = convertUnit(
        finalParam.resinVolumeAsDelivered,
        SELECTED_UNIT.VOLUME,
        unitTypeRVolume,
        0,
      );
      const inertResinVolume = convertUnit(finalParam.inertResinVolume, SELECTED_UNIT.VOLUME, unitTypeRVolume, 0);
      const vesselDiameter = convertUnit(finalParam.vesselDiameter, SELECTED_UNIT.LENGTH, unitTypeLength, 0);
      const resinBedHeightAsDelivered = convertUnit(
        finalParam.resinBedHeightAsDelivered,
        SELECTED_UNIT.LENGTH,
        unitTypeLength,
        0,
      );
      const resinBedStandardHeight = convertUnit(
        finalParam.resinBedStandardHeight,
        SELECTED_UNIT.LENGTH,
        unitTypeLength,
        0,
      );
      const resinBedHeightAsRegenerated = convertUnit(
        finalParam.resinBedHeightAsRegenerated,
        SELECTED_UNIT.LENGTH,
        unitTypeLength,
        0,
      );
      const resinBedHeightAsExhausted = convertUnit(
        finalParam.resinBedHeightAsExhausted,
        SELECTED_UNIT.LENGTH,
        unitTypeLength,
        0,
      );
      const inertBedHeight = convertUnit(finalParam.inertBedHeight, SELECTED_UNIT.LENGTH, unitTypeLength, 0);
      const vesselCylindricalHeight = convertUnit(
        finalParam.vesselCylindricalHeight,
        SELECTED_UNIT.LENGTH,
        unitTypeLength,
        0,
      );
      const vesselWallThickness = convertUnit(finalParam.vesselWallThickness, SELECTED_UNIT.LENGTH, unitTypeLength, 0);
      const pressureDropwithRecomQty = convertUnit(finalParam.pressureDropwithRecomQty, 3, unitTypePressure, 0);
      const freeBoard = convertUnit(finalParam.freeBoard, SELECTED_UNIT.LENGTH, unitTypeLength, 0);
      dispatch(
        updateVesselCylindricalHeight({
          isInput: false,
          value: vesselCylindricalHeight,
          freeBoard: freeBoard,
          index: index,
        }),
      );

      return {
        ...item,
        resinVolumeAsDelivered: parseFloat(resinVolumeAsDelivered),
        inertResinVolume: parseFloat(inertResinVolume),
        vesselDiameter: parseFloat(vesselDiameter),
        resinBedHeightAsDelivered: parseFloat(resinBedHeightAsDelivered),
        resinBedStandardHeight: parseFloat(resinBedStandardHeight),
        resinBedHeightAsRegenerated: parseFloat(resinBedHeightAsRegenerated),
        resinBedHeightAsExhausted: parseFloat(resinBedHeightAsExhausted),
        inertBedHeight: parseFloat(inertBedHeight),
        vesselCylindricalHeight: parseFloat(vesselCylindricalHeight),
        vesselWallThickness: parseFloat(vesselWallThickness),
        pressureDropwithRecomQty: parseFloat(pressureDropwithRecomQty),
        freeBoard: parseFloat(freeBoard),
      };
    });
    dispatch(updatelistFinalParamAdj(newListFinal));

    const newUnits = {
      unitTypeSVelocity: selectedUnits[10],
      unitTypeRVolume: selectedUnits[12],
      unitTypeLength: selectedUnits[8],
      unitTypeConductivity: selectedUnits[17],
      unitTypeTemp: selectedUnits[2],
      unitTypeCVolume: selectedUnits[13],
      unitTypeRegeneration: selectedUnits[14],
      unitTypeContentration: selectedUnits[6],
      unitTypeFlow: selectedUnits[1],
      unitTypeOrganic: selectedUnits[19],
      unitTypePressure: selectedUnits[3],
    };
    dispatch(updateGlobalUnits(newUnits));
    dispatch(updateUnitFlag(true));
  };

  return { updateIXConversion };
};
