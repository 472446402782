import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import TextElementWithArrow from "../UFDiagramsUtils/TextElementWithArrow";
import { CEB_DETAILS_LIST } from "../UFDiagramsUtils/UFDiagramConstants";
import { convertUpto2Digits, getCEBChemicalDetails } from "../UFDiagramsUtils/UFDiagramHelper";

const VOL_SOLUTION_METRIC_UNIT = "L";

const UFDiagramBWCEBChemDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();

  const { unitConfig, chemicalConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const { data, calcEngineData } = useSelector(state => state.UFStore);
  const [cebChemicals, setCEBChemicals] = useState({});

  const convertUnitCallback = useCallback(
    value => {
      const targetUnit =
        unitConfig.selectedUnits[11] !== "gal" ? VOL_SOLUTION_METRIC_UNIT : unitConfig.selectedUnits[11];
      if (value && targetUnit !== VOL_SOLUTION_METRIC_UNIT) {
        value = unitConversionByName(value, targetUnit, VOL_SOLUTION_METRIC_UNIT);
      }
      value = convertUpto2Digits(value);
      return `${value} ${targetUnit}/h`;
    },
    [unitConfig.selectedUnits],
  );

  useEffect(() => {
    const cebChemicalDetails = getCEBChemicalDetails(
      data,
      calcEngineData,
      chemicalConfig.chemicalListById,
      convertUnitCallback,
    );
    setCEBChemicals(cebChemicalDetails);
  }, [data, calcEngineData, unitConfig.selectedUnits]);

  const getXY = (x, y) => (isInge ? isCIP ? { x: x - 100, y: y - 120 } : { x: x - 100, y: y - 520 } : { x, y });
  const getArrowPath = ({ arrowDuPontCIP, arrowIngeWithCIP, arrowIngeWithoutCIP }) =>
    isInge ? (isCIP ? arrowIngeWithCIP : arrowIngeWithoutCIP) : arrowDuPontCIP;

  return (
    <>
      {CEB_DETAILS_LIST.map(item => {
        const { key, x, y } = item;
        const text = cebChemicals[key];
        return text && <TextElementWithArrow key={key} {...getXY(x, y)} d={getArrowPath(item)} text={text} />;
      })}
    </>
  );
};

export default UFDiagramBWCEBChemDetails;
