import React, { useEffect, useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { formatDate } from "@utils/appUtils";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import { updateGlobalData } from "@common/GlobalDataSlice";

import { useCreateDataMutation, useUpdateDataMutation } from "@services/apiConfig";

import { PROPERTIES_TO_CONVERT_SAVING_DATA, STRING } from "@features/feedwater/ix/constants/IXDConstants";
import { convertAndFormatProperties } from "@features/feedwater/ix/IXConfiguration/IXhelper";
import { setIXDUpdate, updateFlagIsSaveIXDReportJson, updateIXMenuIconHeader } from "@features/feedwater/ix/IXDSlice";
import { getTechnologyId, isIXTechnology } from "@features/feedwater/ix/IXDUtilityFunction";
import CurrencyAndUnitPopUp from "@features/feedwater/modals/CurrencyAndUnitPopUp";
import ProjectCostAndChemicalLibrary from "@features/feedwater/modals/ProjectCostAndChemicalLibrary";
import Pumps from "@features/feedwater/modals/Pumps";
import { setLoading } from "@features/feedwater/systemdesign/processDiagramSlice";
import ProjectSaveWarning from "@features/feedwater/systemdesign/ProjectSaveWarning";
import { updateMenuIconHeader, updateUFStoreData } from "@features/feedwater/uf/UFSlice";
import { clearAuthData } from "@features/login/AuthSlice";
import { updateLeftpanel } from "@features/menu/SideMenuSlice";

import NotificationIcon from "../icons/NotificationIcon";
import NotificationReadUnreadIcon from "../icons/NotificationReadUnreadIcon";
import NotificationTealIcon from "../icons/NotificationTealIcon";
import TabletViewMenuIcon from "../icons/TabletViewMenuIcon";
import { updateTabAvailable } from "../ReportIXDSlice";
import { updateTabAvailableForUF } from "../ReportUFSlice";
import GlobalUnitConversion from "../utils/GlobalUnitConversion";

import HeaderStyled from "./HeaderStyled";

const Header = ({ showSideMenu, setShowMenuIcon }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { convertUnitNumber, unitConversionByName } = useUnitConversion();

  const dataisChanged = useSelector(state => state.Feedsetupdetailsdatapanel.dataisChanged);
  const [updateData] = useUpdateDataMutation();
  const [IXData_PostData] = useCreateDataMutation();
  const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
  const userAttributes = useSelector(state => state.Auth.customAttributes);
  const [openCandU, setOpenCandU] = useState(false);
  const [openPumps, setOpenPumps] = useState(false);
  const [openCostandChemicle, setOpenCandC] = useState(false);
  const { addedTechnology, isDataUpdated, feedWaterData, nodes, edges } = useSelector(
    state => state.processDiagramSlice,
  );

  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const { projectTitle } = useSelector(state => state.projectInfo);

  useEffect(() => {
    if (!isLoggedIn) {
      return clearUserData();
    }
  }, [isLoggedIn]);

  const UserInfoStore = useSelector(state => state.userInfo.data);
  const scrollDirection = useSelector(state => state.scrollData.direction);
  const feedCheck = useSelector(state => state.tabData.tab);
  const UFData = useSelector(state => state.UFStore.data);
  const { isForDrinkingWater } = useSelector(state => state.UFStore);
  const { isUfDataUpdated } = useSelector(state => state.UFStore);
  const user_id = UserInfoStore ? UserInfoStore.UserId : 1;
  const projectID = ProjectInfoStore ? ProjectInfoStore.projectID : 1;
  const caseID = ProjectInfoStore ? ProjectInfoStore.caseId : 1;
  const { First_Name, Last_Name } = userAttributes || {};
  const { lastLoggedIn, applicationVersion } = UserInfoStore || {};
  const initials = First_Name?.charAt(0).toUpperCase() + Last_Name?.charAt(0).toUpperCase();
  const ixStoreObj = useSelector(state => state.IXStore);
  const ixStore = useSelector(state => state.IXStore.data);
  const { listAdvRegen, listProductQualityandregeneration, listRegenConds } = ixStore;
  const ixResinID1 = ixStore?.selectedResinList[0]?.ixResinID1;
  const ixResinID2 = ixStore?.selectedResinList[0]?.ixResinID2;
  const ixResinID3 = ixStore?.selectedResinList[1]?.ixResinID1;
  const ixResinID4 = ixStore?.selectedResinList[1]?.ixResinID2;
  const treatmentName = ixStore?.treatmentName;
  let formattedDate = "";
  /* Formatting LastLoggedIn */
  if (lastLoggedIn && !isNaN(Date.parse(lastLoggedIn))) {
    const originalDate = new Date(lastLoggedIn);
    const isDate = new Date(originalDate.getTime());
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      // timeZone: "Europe/Paris",
    };

    formattedDate = isDate.toLocaleString("en-US", options);
    sessionStorage.setItem("lastLoginDate", formattedDate);
  } else {
    formattedDate = sessionStorage.getItem("lastLoginDate");
  }
  const [activeMenu, setActiveMenu] = useState(null);
  const [changeNotificationIcon, setChangeNotificationIcon] = useState(null);
  const [currency, setCurrency] = useState(false);
  const [PostRead] = useCreateDataMutation();
  const [notificationList, setNotificationList] = useState([]);
  const [saveWarning, setSaveWarning] = useState(false);
  const [updateFeedsetupData] = useCreateDataMutation();
  const [updateUFData] = useCreateDataMutation();

  const { unreadNotifications } = useSelector(state => state.globalAppData);

  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);

  const tab = useSelector(state => state.tabData.tab);
  const FeedStreamData = useSelector(state => state.Feedsetupdetailsdatapanel.streamData);
  const caseTreatmentIDIXD = useSelector(
    state =>
      state.processDiagramSlice.lstTechnologyLists.find(
        item => item.technologyID === getTechnologyId(ixStore.treatmentName) && !item.isDeleted,
      )?.caseTreatmentID,
  );
  const [resinItem1, resinItem2] = ixStore.selectedResinList;

  useEffect(() => {
    setNotificationList(unreadNotifications);
  }, [unreadNotifications]);

  const handleNavigate = () => {
    dispatch(updateLeftpanel("masterdata/api/v1/ProjectRecent"));
    if (location.pathname === "/FeedWaterHome") {
      if (tab == "System Design" && isDataUpdated) {
        setSaveWarning(true);
      } else if (tab == "Feed Setup" && dataisChanged) {
        setSaveWarning(true);
      } else if (tab == "UF" && isUfDataUpdated) {
        setSaveWarning(true);
      } else if (isIXTechnology(tab) && ixStoreObj.isIXDDataUpdated) {
        setSaveWarning(true);
      } else {
        navigate("/home");
        dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
        dispatch(updateTabAvailableForUF({ FeedSetup: false, UF: false }));
      }
    } else {
      window.location.reload();
    }
  };

  const dataUf = {
    userID: UFData.userID,
    projectID: UFData.projectID,
    caseID: UFData.caseID,
    treatmentName: "UF",
    treatmentObjID: UFData.treatmentObjID,
    fromTreatmentObjID: UFData.fromTreatmentObjID,
    treatmentSeqNo: UFData.treatmentSeqNo,
    recoveryTypeID: parseInt(UFData.recoveryTypeID),
    recovery: Number(UFData.recovery),
    feed: Number(UFData.feed),
    automatic: UFData.automatic,
    recoveryRo: Number(UFData.recoveryRo),
    compactionTemperature: Number(UFData.compactionTemperature),
    isCompactionFlux: UFData.isCompactionFlux,
    uFDesignFluxID: parseInt(UFData.uFDesignFluxID),
    caseTreatmentID: parseInt(UFData.caseTreatmentID),
    filtrateFlux: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.filtrateFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
    ),
    backwashFlux: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.backwashFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
    ),
    cEBFlux: Number(GlobalUnitConversion(GlobalUnitConversionStore, UFData.cEBFlux, "LMH", unit.selectedUnits[4])),
    forwardFlushFlow: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.forwardFlushFlow, "m³/h", unit.selectedUnits[1]).toFixed(
        2,
      ),
    ),
    airFlow: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.airFlow, "Nm³/h", unit.selectedUnits[18]).toFixed(2),
    ),
    aerationAirFlow: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.aerationAirFlow, "Nm³/h", unit.selectedUnits[18]).toFixed(
        2,
      ),
    ),
    recycleFlowRate: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.recycleFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(2),
    ),
    recycleFlowRate_MiniCIP: Number(
      GlobalUnitConversion(
        GlobalUnitConversionStore,
        UFData.recycleFlowRate_MiniCIP,
        "m³/h",
        unit.selectedUnits[1],
      ).toFixed(2),
    ),
    uFModuleID: parseInt(UFData.uFModuleID),
    flow_FF1: Number(UFData.flow_FF1),
    flow_FF2: Number(UFData.flow_FF2),
    flow_FF3: Number(UFData.flow_FF3),
    flow_FF4: Number(UFData.flow_FF4),
    aDBWDisplacement: Number(UFData.aDBWDisplacement),
    fTLDisplacement: Number(UFData.fTLDisplacement),
    typicalWaitDuration_Dupont: Number(UFData.typicalWaitDuration_Dupont),
    typicalPumpRamp_Dupont: Number(UFData.typicalPumpRamp_Dupont),
    typicalWaitDuration_Inge: Number(UFData.typicalWaitDuration_Inge),
    typicalPumpRamp_Inge: Number(UFData.typicalPumpRamp_Inge),
    typicalWaitDuration_Memcor: Number(UFData.typicalWaitDuration_Memcor),
    typicalPumpRamp_Memcor: Number(UFData.typicalPumpRamp_Memcor),
    uFDesignCycleIntervalsID: parseInt(UFData.uFDesignCycleIntervalsID),
    backwash_design: Number(UFData.backwash_design),
    airScour_design: Number(UFData.airScour_design),
    acidCEB: Number(UFData.acidCEB),
    alkaliOxidantCEB: Number(UFData.alkaliOxidantCEB),
    cIP: Number(UFData.cIP),
    miniCIP: Number(UFData.miniCIP),
    disinfectionCEB: Number(UFData.disinfectionCEB),
    t_CEB_Rinse12: Number(UFData.t_CEB_Rinse12),
    t_CEB_Rinse2: Number(UFData.t_CEB_Rinse2),
    bwRinseDrainBottom: Number(UFData.bwRinseDrainBottom),
    bwRinseDrainTop: Number(UFData.bwRinseDrainTop),
    uFBWCEBStandbyOptionID: parseInt(UFData.uFBWCEBStandbyOptionID),
    bWPerCEBstandbyTrains: parseInt(UFData.bWPerCEBstandbyTrains),
    uFConfigurationID: parseInt(UFData.uFConfigurationID),
    uFCIPStandbyTrainOptionID: parseInt(UFData.uFCIPStandbyTrainOptionID),
    cIPstandbyTrains: parseInt(UFData.cIPstandbyTrains),
    integraPackDesign_Ind: UFData.integraPackDesign_Ind,
    drinkingWaterInd: UFData.drinkingWaterInd,
    membraneintergrityoption_Ind: UFData.membraneintergrityoption_Ind,
    modulesPerSkid: parseInt(UFData.modulesPerSkid),
    modulesPerTrain: parseInt(UFData.modulesPerTrain),
    offlinetimepertrain: parseInt(UFData.offlinetimepertrain),
    onlineTrains: parseInt(UFData.onlineTrains),
    redundantStandbyTrains: parseInt(UFData.redundantStandbyTrains),
    skids: parseInt(UFData.skids),
    skidsPerTrain: parseInt(UFData.skidsPerTrain),
    uFStorageTankOptionID: parseInt(UFData.uFStorageTankOptionID),
    totalModules: parseInt(UFData.totalModules),
    totalTrains: parseInt(UFData.totalTrains),
    redundantTrains: parseInt(UFData.redundantTrains),
    isBWCEBStandbyTrainsEnabled: UFData.isBWCEBStandbyTrainsEnabled,
    radTR1: UFData.radTR1,
    radTR2: UFData.radTR2,
    radTR3: UFData.radTR3,
    radMR1: UFData.radMR1,
    radMR2: UFData.radMR2,
    radMR3: UFData.radMR3,
    uFFiltrationID: Number(UFData.uFFiltrationID),
    backwash_Filtration: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.backwash_Filtration, "bar", unit.selectedUnits[3]).toFixed(
        2,
      ),
    ),
    acidCEB_Filtration: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.acidCEB_Filtration, "bar", unit.selectedUnits[3]).toFixed(
        2,
      ),
    ),
    alkaliCEB_Filtration: Number(
      GlobalUnitConversion(
        GlobalUnitConversionStore,
        UFData.alkaliCEB_Filtration,
        "bar",
        unit.selectedUnits[3],
      ).toFixed(2),
    ),
    cIP_Filtration: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.cIP_Filtration, "bar", unit.selectedUnits[3]).toFixed(2),
    ),
    miniCIP_Filtration: Number(UFData.miniCIP_Filtration),
    strainerRecovery: Number(UFData.strainerRecovery),
    strainerSize: Number(UFData.strainerSize),
    uFTanksID: Number(UFData.uFTanksID),
    chemicalStorageTime: Number(UFData.chemicalStorageTime),
    bWTankRefillRate: Number(UFData.bWTankRefillRate),
    filterateTank: Number(UFData.filterateTank),
    bWTank: Number(UFData.bWTank),
    cIPTank: Number(UFData.cIPTank),
    uFEquipmentPressureID: Number(UFData.uFEquipmentPressureID),
    maxAirScourPressure: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.maxAirScourPressure, "bar", unit.selectedUnits[3]).toFixed(
        2,
      ),
    ),
    maxAirProcPressure: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.maxAirProcPressure, "bar", unit.selectedUnits[3]).toFixed(
        2,
      ),
    ),
    filteratePressure: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.filteratePressure, "bar", unit.selectedUnits[3]).toFixed(
        2,
      ),
    ),
    nonIntegraPacTrainPresDrop: Number(
      GlobalUnitConversion(
        GlobalUnitConversionStore,
        UFData.nonIntegraPacTrainPresDrop,
        "bar",
        unit.selectedUnits[3],
      ).toFixed(2),
    ),
    integraPacFiltrationPreDrop: Number(
      GlobalUnitConversion(
        GlobalUnitConversionStore,
        UFData.integraPacFiltrationPreDrop,
        "bar",
        unit.selectedUnits[3],
      ).toFixed(2),
    ),
    backwashPipingPreDrop: Number(
      GlobalUnitConversion(
        GlobalUnitConversionStore,
        UFData.backwashPipingPreDrop,
        "bar",
        unit.selectedUnits[3],
      ).toFixed(2),
    ),
    cIPPipingPreDrop: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.cIPPipingPreDrop, "bar", unit.selectedUnits[3]).toFixed(2),
    ),
    uFPowerID: Number(UFData.uFPowerID),
    pLCPowerReqPertrain: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.pLCPowerReqPertrain, "kW", unit.selectedUnits[9]).toFixed(
        2,
      ),
    ),
    volvePowerReqPerTrain: Number(
      GlobalUnitConversion(
        GlobalUnitConversionStore,
        UFData.volvePowerReqPerTrain,
        "kW",
        unit.selectedUnits[9],
      ).toFixed(2),
    ),
    uFValvesID: Number(UFData.uFValvesID),
    valvesPerTrain: Number(UFData.valvesPerTrain),
    valveOpenCloseDuration: Number(UFData.valveOpenCloseDuration),
    uFCEBID: Number(UFData.uFCEBID),
    uFCEBWaterTypeID: parseInt(UFData.uFCEBWaterTypeID),
    ceb_AirScour: Number(UFData.ceb_AirScour),
    backWash1_CEB: Number(UFData.backWash1_CEB),
    backWash2_CEB: Number(UFData.backWash2_CEB),
    cEBTemperature: parseInt(UFData.cEBTemperature),
    chemicalSoakingDuration_CEB: parseInt(UFData.chemicalSoakingDuration_CEB),
    drain: Number(UFData.drain),
    forwardFlush: Number(UFData.forwardFlush),
    designTemperature_Ind: UFData.designTemperature_Ind,
    ceb_LSI: Number(UFData.ceb_LSI),
    sameAsBackwash_Ind: UFData.sameAsBackwash_Ind,
    alkaliEnabled_Ind_CEB: UFData.alkaliEnabled_Ind_CEB,
    organicEnabled_Ind_CEB: UFData.organicEnabled_Ind_CEB,
    oxidantEnabled_Ind_CEB: UFData.oxidantEnabled_Ind_CEB,
    mineralEnabled_Ind_CEB: UFData.mineralEnabled_Ind_CEB,
    disOxidantEnabled_Ind_CEB: UFData.disOxidantEnabled_Ind_CEB,
    mineralValue: Number(UFData.mineralValue),
    organicValue: Number(UFData.organicValue),
    oxidantValue: Number(UFData.oxidantValue),
    alkaliValue: Number(UFData.alkaliValue),
    disOxidantValue: Number(UFData.disOxidantValue),
    alkaliChemId: UFData.alkaliChemId?.toString(),
    mineralChemId: UFData.mineralChemId?.toString(),
    organicChemId: UFData.organicChemId?.toString(),
    oxidantChemId: UFData.oxidantChemId?.toString(),
    disOxidantChemId: UFData.disOxidantChemId?.toString(),
    alkaliValueInPh_Ind: UFData.alkaliValueInPh_Ind,
    mineralValueInPh_Ind: UFData.mineralValueInPh_Ind,
    uFCIPID: Number(UFData.uFCIPID),
    bWStepInCIP: parseInt(UFData.bWStepInCIP),
    chemicalSoakingDuration_CIP: Number(UFData.chemicalSoakingDuration_CIP),
    uFCIPWaterTypeID: parseInt(UFData.uFCIPWaterTypeID),
    heatingStepDuration: Number(UFData.heatingStepDuration),
    cip_LSI: Number(UFData.cip_LSI),
    recycleDuration: Number(UFData.recycleDuration),
    recycleTemperature: Number(
      GlobalUnitConversion(GlobalUnitConversionStore, UFData.recycleTemperature, "°C", unit.selectedUnits[2]).toFixed(
        2,
      ),
    ),
    rinseBWCycle: parseInt(UFData.rinseBWCycle),
    cIPRinseSoakCycle: Number(UFData.cIPRinseSoakCycle),
    alkaliEnabled_Ind_CIP: UFData.alkaliEnabled_Ind_CIP,
    organicEnabled_Ind_CIP: UFData.organicEnabled_Ind_CIP,
    oxidantEnabled_Ind_CIP: UFData.oxidantEnabled_Ind_CIP,
    mineralEnabled_Ind_CIP: UFData.mineralEnabled_Ind_CIP,
    oxidant2Enabled_Ind_CIP: UFData.oxidant2Enabled_Ind_CIP,
    mineralValue_CIP: Number(UFData.mineralValue_CIP),
    organicValue_CIP: Number(UFData.organicValue_CIP),
    oxidantValue_CIP: Number(UFData.oxidantValue_CIP),
    alkaliValue_CIP: Number(UFData.alkaliValue_CIP),
    oxidant2Value_CIP: Number(UFData.oxidant2Value_CIP),
    alkaliChemId_CIP: UFData.alkaliChemId_CIP?.toString(),
    mineralChemId_CIP: UFData.mineralChemId_CIP?.toString(),
    organicChemId_CIP: UFData.organicChemId_CIP?.toString(),
    oxidantChemId_CIP: UFData.oxidantChemId_CIP?.toString(),
    oxidant2ChemId_CIP: UFData.oxidant2ChemId_CIP?.toString(),
    alkaliValueInPh_Ind_CIP: UFData.alkaliValueInPh_Ind_CIP,
    mineralValueInPh_Ind_CIP: UFData.mineralValueInPh_Ind_CIP,
    uFBackWashID: parseInt(UFData.uFBackWashID),
    bWTemperature: Number(UFData.bWTemperature),
    bWDesignTemperature_Ind: UFData.bWDesignTemperature_Ind,
    uFBWWaterTypeID: parseInt(UFData.uFBWWaterTypeID),
    uFBWFlushWaterTypeID: parseInt(UFData.uFBWFlushWaterTypeID),
    uFBWProtocolID: parseInt(UFData.uFBWProtocolID),
    oxidantID: UFData.oxidantID.toString(),
    oxidantDosage: Number(UFData.oxidantDosage),
    backwash_AirScour: Number(UFData.backwash_AirScour),
    backWash1_backWash: Number(UFData.backWash1_backWash),
    backWash2_backWash: Number(UFData.backWash2_backWash),
    drain_backWash: Number(UFData.drain_backWash),
    forwardFlush_backWash: Number(UFData.forwardFlush_backWash),
    lF: Number(UFData.lF),
    t_FTL: Number(UFData.t_FTL),
    t_BWBtnAirScour: parseInt(UFData.t_BWBtnAirScour),
    uFMiniCIPID: Number(UFData.uFMiniCIPID),
    bWStepInMiniCIP: Number(UFData.bWStepInMiniCIP),
    rinseBWCycle_MiniCIP: parseInt(UFData.rinseBWCycle_MiniCIP),
    chemicalSoakingDuration_MiniCIP: Number(UFData.chemicalSoakingDuration_MiniCIP),
    uFMiniCIPWaterTypeID: parseInt(UFData.uFMiniCIPWaterTypeID),
    heatingStepDuration_MiniCIP: Number(UFData.heatingStepDuration_MiniCIP),
    lSI_MiniCIP: Number(UFData.lSI_MiniCIP),
    recycleDuration_MiniCIP: Number(UFData.recycleDuration_MiniCIP),
    recycleTemperature_MiniCIP: Number(UFData.recycleTemperature_MiniCIP),
    cIPRinseSoakCycle_MiniCIP: Number(UFData.cIPRinseSoakCycle_MiniCIP),
    alkaliEnabled_Ind_MiniCIP: UFData.alkaliEnabled_Ind_MiniCIP,
    organicEnabled_Ind_MiniCIP: UFData.organicEnabled_Ind_MiniCIP,
    oxidantEnabled_Ind_MiniCIP: UFData.oxidantEnabled_Ind_MiniCIP,
    mineralEnabled_Ind_MiniCIP: UFData.mineralEnabled_Ind_MiniCIP,
    oxidant2Enabled_Ind_MiniCIP: UFData.oxidant2Enabled_Ind_MiniCIP,
    mineralValue_MiniCIP: Number(UFData.mineralValue_MiniCIP),
    organicValue_MiniCIP: Number(UFData.organicValue_MiniCIP),
    oxidantValue_MiniCIP: Number(UFData.oxidantValue_MiniCIP),
    alkaliValue_MiniCIP: Number(UFData.alkaliValue_MiniCIP),
    oxidant2Value_MiniCIP: Number(UFData.oxidant2Value_MiniCIP),
    alkaliChemId_MiniCIP: UFData.alkaliChemId_MiniCIP.toString(),
    mineralChemId_MiniCIP: UFData.mineralChemId_MiniCIP.toString(),
    organicChemId_MiniCIP: UFData.organicChemId_MiniCIP.toString(),
    oxidantChemId_MiniCIP: UFData.oxidantChemId_MiniCIP.toString(),
    oxidant2ChemId_MiniCIP: UFData.oxidant2ChemId_MiniCIP.toString(),
    alkaliValueInPh_Ind_MiniCIP: UFData.alkaliValueInPh_Ind_MiniCIP,
    mineralValueInPh_Ind_MiniCIP: UFData.mineralValueInPh_Ind_MiniCIP,
    ufSpecialFeatureID: parseInt(UFData.ufSpecialFeatureID),
    pUFTechnologyID: parseInt(UFData.pUFTechnologyID),
  };
  const handleYes = () => {
    if (tab == "System Design") {
      const lstTechnologyLists = [];
      addedTechnology.map(item => {
        item.id > 0 ? lstTechnologyLists.push({ technologyID: item.id }) : null;
      });
      updateData({
        Method: "masterdata/api/v1/SystemDesign",
        ...feedWaterData,
        flowValue: Number(
          GlobalUnitConversion(
            GlobalUnitConversionStore,
            feedWaterData.flowValue,
            "m³/h",
            unit.selectedUnits[1],
          ).toFixed(2),
        ),
        flow: Number(
          GlobalUnitConversion(GlobalUnitConversionStore, feedWaterData.flow, "m³/h", unit.selectedUnits[1]).toFixed(2),
        ),
        userID: user_id,
        processMap: { nodes: nodes, edges: edges },
        lstTechnologyLists,
      });
      dispatch(setLoading());
      navigate("/home");
    } else if (tab == "Feed Setup") {
      updateFeedsetupData(FeedStreamData);
      navigate("/home");
    } else if (tab == "UF") {
      dispatch(updateUFStoreData({ isSaveReportJson: true }));
      const MethodName = { Method: "uf/api/v1/SaveUFData" };
      const UFRequestDetails = {
        ...MethodName,
        ...dataUf,
        ["drinkingWater_Ind"]: isForDrinkingWater,
        ["userID"]: user_id,
        ["projectID"]: projectID,
        ["caseID"]: caseID,
        ["treatmentName"]: "UF",
      };
      updateUFData(UFRequestDetails);
      //Timeout added to trigger Save UF reportJson data before navigating to Home
      setTimeout(() => navigate("/home"), 100);
    } else if (isIXTechnology(tab)) {
      dispatch(updateFlagIsSaveIXDReportJson(true));
      SaveUpdatedData();
      //Timeout added to trigger Save IXD reportJson data before navigating to Home
      setTimeout(() => navigate("/home"), 100);
    } else {
      navigate("/home");
    }
    setSaveWarning(false);
  };

  //IXD save
  const SaveUpdatedData = async () => {
    let finalParameterList = [];
    if (!ixStoreObj) return;
    if (ixStoreObj?.viewReport === "true" || ixStoreObj?.showFinalParameterTab) {
      finalParameterList = ixStoreObj?.listFinalParamAdj || [];
    } else {
      finalParameterList = ixStoreObj?.existingPlantDescription || [];
    }
    if (finalParameterList.length <= 1) {
      let vesselCount = 0;
      if (ixStoreObj.resinName4 !== null) {
        vesselCount = 4;
      } else if (ixStoreObj.resinName3 !== null) {
        vesselCount = 3;
      } else if (ixStoreObj.resinName2 !== null) {
        vesselCount = 2;
      } else {
        vesselCount = 1;
      }
      const finalPrameterEmptyList = Array.from({ length: vesselCount }, (_, index) => ({
        resinType: ixStoreObj.resinData[ixStoreObj[`resinName${index + 1}`]],
        resinName: ixStoreObj[`resinName${index + 1}`],
        resinId:
          ixStoreObj[`resinName${index + 1}`] == "WAC"
            ? ixResinID1
            : ixStoreObj[`resinName${index + 1}`] == "SAC"
              ? ixResinID2
              : ixStoreObj[`resinName${index + 1}`] == "WBA"
                ? ixResinID3
                : ixResinID4,
        vesselNo: index + 1,
        resinVolumeAsDelivered: 0,
        vesselDiameter: 0,
        resinBedHeightAsDelivered: 0,
        resinBedStandardHeight: 0,
        resinBedHeightAsExhausted: 0,
        resinBedHeightAsRegenerated: 0,
        inertResinVolume: 0,
        inertBedHeight: 0,
        freeBoard: 0,
        vesselCylindricalHeight: 0,
        vesselWallThickness: 0,
        pressureDropwithRecomQty: 0,
        resinPackagingSize: 0,
        ixfpaRadioButtonID: 0,
      }));
      if (vesselCount < 1) {
        finalParameterList = finalPrameterEmptyList;
      }
    }
    finalParameterList = finalParameterList.map(item =>
      convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_SAVING_DATA, convertUnitNumber),
    );
    /*----Unit conversion for regenenConditionPage start-----*/
    const convertAndAssign = (source, property, unit, selectedUnit) =>
      source?.[property] ? convertUnitNumber(source[property], unit, selectedUnit) : 0;

    const [listRegenConds1, listRegenConds2] = listRegenConds || [];
    const cationTemp = convertAndAssign(listRegenConds1, "temperature", UNITS.celsius, SELECTED_UNIT.TEMPERATURE);
    const anionTemp = convertAndAssign(listRegenConds2, "temperature", UNITS.celsius, SELECTED_UNIT.TEMPERATURE);

    const [listProductQualityandregeneration1, listProductQualityandregeneration2] =
      listProductQualityandregeneration || [];
    const cationRegenreteDoseVel = convertAndAssign(
      listProductQualityandregeneration1,
      STRING.REGENERAT_DOSE,
      UNITS.GRAMS_PER_LITRE,
      SELECTED_UNIT.REGENERATION_DOSE,
    );
    const anionRegenreteDoseVel = convertAndAssign(
      listProductQualityandregeneration2,
      STRING.REGENERAT_DOSE,
      UNITS.GRAMS_PER_LITRE,
      SELECTED_UNIT.REGENERATION_DOSE,
    );

    const cationAverageConduc = convertAndAssign(
      listProductQualityandregeneration1,
      STRING.AVG_CONDUCTIVITY,
      UNITS.uscm,
      SELECTED_UNIT.CONDUCTIVITY,
    );
    const anionAverageConduc = convertAndAssign(
      listProductQualityandregeneration2,
      STRING.AVG_CONDUCTIVITY,
      UNITS.uscm,
      SELECTED_UNIT.CONDUCTIVITY,
    );

    const cationendpointConduc = convertAndAssign(
      listProductQualityandregeneration1,
      STRING.END_CONDUCTIVITY,
      UNITS.uscm,
      SELECTED_UNIT.CONDUCTIVITY,
    );
    const anionendpointConduc = convertAndAssign(
      listProductQualityandregeneration2,
      STRING.END_CONDUCTIVITY,
      UNITS.uscm,
      SELECTED_UNIT.CONDUCTIVITY,
    );
    /*----Unit conversion for regenenConditionPage end-----*/
    /*----Unit conversion for Advance Regeneration start-----*/
    const [listAdvRegen1, listAdvRegen2] = listAdvRegen || [];
    const cationregenVel = convertAndAssign(
      listAdvRegen1,
      STRING.REGENVELOCITY,
      UNITS.BV_PER_HR,
      SELECTED_UNIT.SPECIFIC_VELOCITY,
    );
    const anionregeneVel = convertAndAssign(
      listAdvRegen2,
      STRING.REGENVELOCITY,
      UNITS.BV_PER_HR,
      SELECTED_UNIT.SPECIFIC_VELOCITY,
    );
    const cationDisVol = convertAndAssign(
      listAdvRegen1,
      STRING.DISPLACEMENT_VOL,
      UNITS.BV,
      SELECTED_UNIT.VOLUME_COMMON,
    );
    const anionDisVol = convertAndAssign(listAdvRegen2, STRING.DISPLACEMENT_VOL, UNITS.BV, SELECTED_UNIT.VOLUME_COMMON);
    const cationFasVol = convertAndAssign(listAdvRegen1, STRING.FAST_RINSE_VOL, UNITS.BV, SELECTED_UNIT.VOLUME_COMMON);
    const anionFasVol = convertAndAssign(listAdvRegen2, STRING.FAST_RINSE_VOL, UNITS.BV, SELECTED_UNIT.VOLUME_COMMON);
    const MethodName = { Method: "ix/api/v1/IXData" };
    const IXData_Method_Body = {
      ...MethodName,
      ...ixStore,
      treatmentObjID: caseTreatmentIDIXD,
      caseTreatmentID: caseTreatmentIDIXD,
      treatmentName: treatmentName,
      listFinalParamAdj: ixStoreObj.showFinalParameterTab ? finalParameterList : [],
      selectedResinList: [
        { ...resinItem1, inert: resinItem1?.inert || 0 },
        { ...resinItem2, inert: resinItem2?.inert || 0 },
      ],
      space_velocity_txt: convertUnitNumber(
        ixStore.space_velocity_txt,
        UNITS.BV_PER_HR,
        SELECTED_UNIT.SPECIFIC_VELOCITY,
        2,
      ),
      listRegenConds: [
        { ...ixStore.listRegenConds[0], temperature: cationTemp },
        { ...ixStore.listRegenConds[1], temperature: anionTemp },
      ],
      listAdvRegen: [
        {
          ...ixStore.listAdvRegen[0],
          regenerationVelocity: cationregenVel,
          displacementVolume: cationDisVol,
          fatRinseVolume: cationFasVol,
        },
        {
          ...ixStore.listAdvRegen[1],
          regenerationVelocity: anionregeneVel,
          displacementVolume: anionDisVol,
          fatRinseVolume: anionFasVol,
        },
      ],
      listProductQualityandregeneration: [
        {
          ...ixStore.listProductQualityandregeneration[0],
          regenerantDoseVal4: cationRegenreteDoseVel,
          averageConductivityVal: cationAverageConduc,
          endpointConductivityVal: cationendpointConduc,
        },
        {
          ...ixStore.listProductQualityandregeneration[1],
          regenerantDoseVal4: anionRegenreteDoseVel,
          averageConductivityVal: anionAverageConduc,
          endpointConductivityVal: anionendpointConduc,
        },
      ],
      selectedProcessID: ixStore.selectedProcessID,
      //Equipment details in IX Technology
      pdExtPiping: unitConversionByName(ixStore.pdExtPiping, UNITS.bar, unit.selectedUnits[3]),
      pdIntDistributor: unitConversionByName(ixStore.pdIntDistributor, UNITS.bar, unit.selectedUnits[3]),
      effluentPressure: unitConversionByName(ixStore.effluentPressure, UNITS.bar, unit.selectedUnits[3]),
      tankTemperature: Number(unitConversionByName(ixStore.tankTemperature, "°C", unit.selectedUnits[2])),
      backwashTowerDiameter: Number(
        unitConversionByName(ixStore.backwashTowerDiameter, UNITS.millimeter, unit.selectedUnits[8]),
      ),
      sacRegenVesselDia: Number(
        unitConversionByName(ixStore.sacRegenVesselDia, UNITS.millimeter, unit.selectedUnits[8]),
      ),
      sbaRegenVesselDia: Number(
        unitConversionByName(ixStore.sbaRegenVesselDia, UNITS.millimeter, unit.selectedUnits[8]),
      ),
    };
    await IXData_PostData(IXData_Method_Body);
    dispatch(setIXDUpdate(false));
  };

  const notificationread = async list => {
    const newData = {
      Method: "masterdata/api/v1/MarkAsReadNotification",
      userID: user_id,
      lstNotificationIDs: list,
    };
    await PostRead(newData);
    dispatch(updateGlobalData({ type: "unreadNotifications", value: [] }));
  };

  const handleReadNotification = id => {
    const tempData = notificationList.map(item => {
      if (id === item.id) {
        item = {
          ...item,
          isRead: true,
        };
      }
      return item;
    });
    setNotificationList(tempData);
    notificationread([
      {
        notificationID: id,
      },
    ]);
  };

  const markAllAsRead = () => {
    const newList = [];
    const tempData = notificationList.map(item => {
      item = {
        ...item,
        isRead: true,
      };
      newList.push({
        notificationID: item.id,
      });
      return item;
    });
    setNotificationList(tempData);
    notificationread(newList);
  };
  const handleMenuItemClick = menuItem => {
    setActiveMenu(menuItem);
  };

  const clearUserData = () => {
    dispatch(clearAuthData());
    window.localStorage.clear();
  };

  /* Clear user loggedin informations from store, and redirect to salesforce logout */
  const handleLogout = () => {
    sessionStorage.clear();
  };

  // const scrollCheck  = localStorage.getItem("scroll check");
  const unreadNotification = notificationList.filter(item => !item.isRead);
  const unreadCount = unreadNotification.length;
  const handleOpenSideMenu = () => {
    setShowMenuIcon(true);
  };
  const headerMenuIconStatus = useSelector(state => state.UFStore.headerMenuIcon);
  const headerIXMenuIconStatus = useSelector(state => state.IXStore.tabletMenuIcon);
  const handleOpenUfSideMenu = () => {
    dispatch(updateMenuIconHeader(!headerMenuIconStatus));
  };
  const handleOpenIXSideMenu = () => {
    dispatch(updateIXMenuIconHeader(!headerIXMenuIconStatus));
  };
  const tabletView = useSelector(state => state.UFStore.tabletMenuIcon);
  const ixTabletView = useSelector(state => state.IXStore.ixTabletView);
  return (
    <>
      <HeaderStyled scrollDirection={scrollDirection}>
        <ProjectSaveWarning
          show={saveWarning}
          close={() => {
            setSaveWarning(false);
            navigate("/home");
          }}
          yes={handleYes}
        />
        <div className='global_header'>
          <div className='global_header_brand_logo'>
            {showSideMenu ? (
              <div onClick={handleOpenSideMenu} className='hamburger_menu'>
                <TabletViewMenuIcon />
              </div>
            ) : tabletView && feedCheck === "UF" ? (
              <div onClick={handleOpenUfSideMenu} className='hamburger_menu'>
                <TabletViewMenuIcon />
              </div>
            ) : ixTabletView && isIXTechnology(feedCheck) ? (
              <div onClick={handleOpenIXSideMenu} className='hamburger_menu'>
                <TabletViewMenuIcon />
              </div>
            ) : (
              ""
            )}
            <WaveProBrandLogo handleNavigate={handleNavigate} />
          </div>
          <div className='global_header_selected_project_name'>
            <h4 className='selected-project-title'>{location.pathname === "/FeedWaterHome" && projectTitle}</h4>
          </div>
          <div className='global_header_header_menu'>
            <Dropdown
              className='notification'
              onClick={() => handleMenuItemClick("notification")}
              onMouseEnter={() => setChangeNotificationIcon("notification")}
              onMouseLeave={() => setChangeNotificationIcon(null)}
            >
              <Dropdown.Toggle>
                {changeNotificationIcon === "notification" ? <NotificationTealIcon /> : <NotificationIcon />}
                {notificationList.length > 0 && <span className='notification-count'>{unreadCount}</span>}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className='notification-header d-flex justify-content-between'>
                  {unreadCount > 0 ? (
                    <>
                      <span>Latest</span>
                      <a onClick={markAllAsRead}>Mark all as read</a>
                    </>
                  ) : (
                    <span>No new Notification to show</span>
                  )}
                </div>
                {notificationList.map((item, index) => (
                  <div key={index}>
                    {!item.isRead && (
                      <Card key={item.index}>
                        <Card.Body>
                          <Row>
                            <Card.Title as={Col} className='col-sm-7'>
                              {item.header}
                            </Card.Title>
                            <Card.Title as={Col} className='col-sm-4'>
                              {/* <a className="notification-tag">lorem ipsum</a> */}
                            </Card.Title>
                            <Card.Title as={Col} className='col-sm-1'>
                              <span onClick={() => handleReadNotification(item.id)}>
                                <NotificationReadUnreadIcon />
                              </span>
                            </Card.Title>
                          </Row>
                          <Card.Text>{item.description}</Card.Text>
                          <p className='text-muted'>{formatDate(item.notificationDate)}</p>
                          <div className='markasread'></div>
                        </Card.Body>
                      </Card>
                    )}
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className='user-profile'>
              <Dropdown.Toggle
                variant=''
                id='dropdown-basic'
                className={activeMenu === "userprofile" ? "active-notification-button" : ""}
              >
                <span className='profile-icon'>{initials}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Col sm={12}>
                  <Row>
                    <Col lg={7} className='user-setting'>
                      <Card.Body>
                        <p className='logged-username'>
                          {`Hi ${First_Name ? First_Name : ""} ${Last_Name ? Last_Name : ""}`}
                        </p>
                        <p className='login-text'>
                          {formattedDate ? `Last Login: ${formattedDate} UTC` : "Last Login: NA"}
                        </p>
                        <h1 className='wavepro-version'>{`  WAVE PRO | Version : ${applicationVersion}`}</h1>
                        <Card.Title className=''>Profile Settings</Card.Title>
                        <ul className='list-unstyled temp-disable'>
                          <li>
                            <a href='#'>My Profile</a>
                          </li>
                          <li>
                            <a>My Projects & Cases</a>
                          </li>
                        </ul>
                        <Card.Title className=''>Account Settings</Card.Title>
                        <ul className='list-unstyled'>
                          <li>
                            <a onClick={() => setOpenCandU(true)}>Currency & Units</a>
                          </li>
                          <li>
                            <a onClick={() => setOpenCandC(true)}>Operating Costs</a>
                          </li>
                          <li>
                            <a onClick={() => setOpenPumps(true)}>Pumps</a>
                          </li>
                        </ul>
                        <CurrencyAndUnitPopUp show={currency} close={setCurrency} />
                      </Card.Body>
                    </Col>
                    <Col sm={5}>
                      <Card.Body>
                        <Card.Title className=''>WAVE Help</Card.Title>
                        <ul className='list-unstyled temp-disable'>
                          <li>
                            <a href='#'>About WAVE</a>
                          </li>
                          <li>
                            <a href='#'>WAVE Academy</a>
                          </li>
                          <li>
                            <a href='#'>Resource Center</a>
                          </li>
                          <li>
                            <a href='#'>Quick Help(FAQ)</a>
                          </li>
                          <li>
                            <a href='#'>Start a Tour</a>
                          </li>
                        </ul>
                        <Card.Title className=''>Other Settings</Card.Title>
                        <ul className='list-unstyled'>
                          <li>
                            <a href='https://www.dupont.com/legal-notices-and-terms-of-use.html' target='__blank'>
                              Terms & Conditions
                            </a>
                          </li>
                          <li>
                            <a href='https://www.dupont.com/privacy.html' target='__blank'>
                              Privacy Policy
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${process.env.REACT_APP_TOKEN_SFDCURL}auth/idp/oidc/logout`}
                              className={isLoggedIn ? "" : "not-loggedin"}
                              onClick={handleLogout}
                            >
                              Sign Out
                            </a>
                          </li>
                        </ul>
                      </Card.Body>
                    </Col>
                  </Row>
                </Col>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <CurrencyAndUnitPopUp show={openCandU} close={setOpenCandU} forUser={true} />
        <Pumps show={openPumps} close={setOpenPumps} forUser={true} />
        <ProjectCostAndChemicalLibrary show={openCostandChemicle} close={setOpenCandC} forUser={true} />
      </HeaderStyled>
    </>
  );
};

export default Header;
