import { memo, useState } from "react";
import { useReactFlow } from "reactflow";
import styled from "styled-components";

import "@common/styles/diodrumFont.css";
import "@common/styles/notoSansFont.css";
const handleStyle = { left: 10 };

import DeleteIcon from "@common/icons/DeleteIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";

function DeleteNode({ id, data }) {
  const { deleteElements } = useReactFlow();
  const [isFocus, setIsFocus] = useState(null);
  const [openPhAdjustment, setOpenPhAdjustment] = useState(false);
  const handleOpenPhAdjustment = () => {
    setOpenPhAdjustment(true);
  };

  const handleClosePhAdjustment = () => {
    setOpenPhAdjustment(false);
  };

  const handleFocus = id => {
    setIsFocus(id);
  };
  const handleBlur = () => {
    setIsFocus(null);
  };

  return (
    <DeleteNodeStyled>
      <div className='delete-box'>
        <StyledCard className='delete-card'>
          <CustomHeading
            fontFamily='NotoSansRegular'
            fontSize='16px'
            fontWeight='600'
            color={colors.PrimaryDarkAquaMarine}
            label='Drag & Drop here to Delete'
          />
          <DeleteIcon />
        </StyledCard>
      </div>
    </DeleteNodeStyled>
  );
}

export default memo(DeleteNode);

const DeleteNodeStyled = styled("div")`
  /* .delete-box {
    width:70%;
  } */
  .delete-card {
    /* display: flex; */
    width: 201.94px;
    height: 118px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px dashed ${colors.Grey96};
    background: ${colors.White};
    h3 {
      text-align: center;
      margin-bottom: 10px;
    }
  }
`;
