export const TITLE_DATA = (selectedUnits, currencyUnit) => [
  { title: "Show in drop-down" },
  { title: "Chemical" },
  { title: "Symbol" },
  { title: "Chemical Name" },
  { title: "Chemical Category" },
  { title: "Bulk Concentration (%)" },
  {
    title: `Bulk Density (${selectedUnits[7] ? selectedUnits[7] : "lb/gal"})`,
  },
  { title: `Bulk Price (${currencyUnit})` },
  { title: "Cost Type" },
  { title: "Displayed as" },
  { title: "Actions" },
];

export const REQUIRED_VALIDATION = ["Acid", "Base", "Oxidant", "Organic Acid", "Salt", "Coagulant"];
export const CHEMICAL_VALUE_MAX_FRACTIONS = 4;

export const VALIDATION_FLAG = [
  { chemicalCat: "Acid", target: "acid" },
  { chemicalCat: "Base", target: "base" },
  { chemicalCat: "Oxidant", target: "oxidant" },
  { chemicalCat: "Organic Acid", target: "organicAcid" },
  { chemicalCat: "Salt", target: "salt" },
];

export const DEFAULT_NEW_CHEMICAL_DATA = {
  iD: 0,
  isSystem: false,
  chemicalCat: "",
  chemicalName: "",
  symbol: "",
  displayName: "",
  bulkConcentration: 0,
  bulkDensity: 0,
  showDropDown: false,
  bulkPrice: 0,
  costType: "L",
  isDeleted: false,
};

export const DEFAULT_CHEMICAL_VALIDATION = {
  chemicalName: false,
  symbol: false,
  displayName: false,
  bulkConcentration: false,
  bulkDensity: false,
  bulkPrice: false,
};

export const COST_CATEGORIES = {
  rawWater: 1,
  wasteWaterDisposal: 2,
  electricity: 3,
};

export const CHEMICALS_LIST = [
  "Hydrochloric Acid",
  "Sodium Chloride",
  "Sodium Hydroxide",
  "Sodium Carbonate",
  "Sodium Hypochlorite",
];

export const CHEMICAL_INCLUDED = [
  "Dechlorinator",
  "Organic Acid",
  "Oxidant",
  "Coagulant",
  "Antiscalant",
  "Salt",
  "Base",
  "Surfactant",
  "Acid",
];

export const CHEMICAL_FIELDS = {
  BULK_CONCENTRATION: "bulkConcentration",
};
