import React, { useMemo } from "react";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";

const UFDiagramStaticText = ({ isInge, isCIP }) => {
  const feedValvePosition = useMemo(
    () => (isInge ? (isCIP ? { x: 2100, y: 4250 } : { x: 2300, y: 3850 }) : { x: 2210, y: 4385 }),
    [isInge, isCIP],
  );
  const bottomDrainPosition = useMemo(
    () => (isInge ? (isCIP ? { x: 5070, y: 4250 } : { x: 5070, y: 3850 }) : { x: 5070, y: 4385 }),
    [isInge, isCIP],
  );
  const filtrateValvePosition = useMemo(
    () => (isInge ? (isCIP ? { x: 6500, y: 1600 } : { x: 6500, y: 1200 }) : { x: 5590, y: 1465 }),
    [isInge, isCIP],
  );
  const backwashValvePosition = useMemo(
    () => (isInge ? (isCIP ? { x: 5250, y: 1700 } : { x: 5250, y: 1300 }) : { x: 5375, y: 1765 }),
    [isInge, isCIP],
  );
  const topDrainValvePosition = useMemo(
    () => (isInge ? (isCIP ? { x: 9780, y: 1675 } : { x: 9780, y: 1275 }) : { x: 9920, y: 1775 }),
    [isInge, isCIP],
  );

  return (
    <>
      <BoldTextElement fill='#454545' {...feedValvePosition}>
        Feed Valve
      </BoldTextElement>
      <BoldTextElement fill='#454545' {...bottomDrainPosition}>
        Bottom Drain Valve
      </BoldTextElement>
      {!isInge && (
        <BoldTextElement fill='#454545' x='3110' y='3800.64'>
          Air Valve
        </BoldTextElement>
      )}
      <BoldTextElement fill='#454545' {...backwashValvePosition}>
        Backwash Valve
      </BoldTextElement>
      <BoldTextElement fill='#454545' {...filtrateValvePosition}>
        Filtrate Valve
      </BoldTextElement>
      <BoldTextElement fill='#454545'>
        <tspan {...topDrainValvePosition}>Top</tspan>
        <tspan x={topDrainValvePosition.x} y={topDrainValvePosition.y + 120}>
          Drain Valve
        </tspan>
      </BoldTextElement>
    </>
  );
};

export default UFDiagramStaticText;
