import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, Tabs } from "react-tabs";

import { FLOW_METRIC_UNIT } from "@constants/global.constants";
import { UNITS } from "@constants/units.constant";

import useFPAValidation from "@hooks/useFPAValidation";
import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUFConfig from "@hooks/useUFConfig";
import { CHEMICAL_DISPLAY_NAMES } from "@hooks/useUFConfig/useUFConfigConstants";
import { useUFModuleFilters } from "@hooks/useUFModuleFilters";
import useUnitConversion from "@hooks/useUnitConversion";
import { useUnitConversionIXD } from "@hooks/useUnitConversionIXD";

import { getChemicalErrorMsg } from "@utils/activityMonitorUtils";
import DupontLogger from "@utils/DupontLogger";

import ActivityMonitorTriangle from "@common/icons/ActivityMonitorTriangle";
import ProgressBrokenIcon from "@common/icons/ProgressBrokenIcon";
import { updateTabAvailable } from "@common/ReportIXDSlice";
import { updateTabAvailableForUF } from "@common/ReportUFSlice";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";
import DynamicLoadder from "@common/utils/DynamicLoadder";
import { MyError } from "@common/utils/ErrorCreator";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import { updateGlobalUnits } from "@common/utils/GlobalUnitConversionSlice";
import UnitConversion from "@common/utils/UnitConversion";

import { useCreateDataMutation, useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import FeedSetup from "../feedsetup/FeedSetup";
import {
  BW_SOURCE_IXMB,
  IXD_DESIGN_FPA,
  PROPERTIES_TO_CONVERT_CALC_ENGINE_DATA,
  PROPERTIES_TO_CONVERT_SAVING_DATA,
  SIO2_MASS_MICRO,
  SIO2_MASS_MILLI,
  TECH_IXMB,
  TECH_UF,
  WATER_BLOCK_ID,
} from "../ix/constants/IXDConstants";
import { convertAndFormatProperties, isInertVessel, updateResinFlags } from "../ix/IXConfiguration/IXhelper";
import IXD from "../ix/IXD";
import {
  setIXDUpdate,
  updateAfterReportChemDoseData,
  updateAfterReportOverRunDoseData,
  updateEvaluateExistFlag,
  updateFlagIsSaveIXDReportJson,
  updateShowFinalParameterTab,
  updateViewReport,
} from "../ix/IXDSlice";
import { getTechnologyId } from "../ix/IXDUtilityFunction";
import Report from "../report/Report";
import { SYSTEM_DESIGN_WATER } from "../systemdesign/constants/SystemDesignConstant";
import { updateRecovery } from "../systemdesign/processDiagramSlice";
import SystemDesign from "../systemdesign/SystemDesign";
import { TYPICAL_PUMP_RAMP, TYPICAL_WAIT_DURATION } from "../uf/constants/UFConstants";
import UF from "../uf/UF";
import { getUFStoreParams } from "../uf/UFConfiguration/UFHelper";
import {
  setUfDataUpdate,
  updateUFStandByOptions,
  updateUFStorageTankOptions,
  updateUFStore,
  updateUFStoreData,
} from "../uf/UFSlice";

import { setErrorReport, setReportData, setReportLoader } from "./activityMonitorSlice";
import ActivityMonitorStyled from "./ActivityMonitorStyled";
import { setTabData } from "./tabChangeSlice";

import "react-tabs/style/react-tabs.css";

const ActivityMonitor = ({ setCurrentPanel }) => {
  const Logger = DupontLogger("ActivityMonitor");
  const dispatch = useDispatch();
  const { convertUnit, convertUnitNumber } = useUnitConversion();
  const { setUFModules } = useUFModuleFilters();
  const { setUFChemicalsDefaultValues, setUFChemicalValuesInPH } = useUFChemicalsHandler();
  const { validateFPA, resetVesselCylindricalHeightData, finalParameterDataCalcEngine, fetchIXFPADetails } =
    useFPAValidation();

  const [IXData_PostData] = useCreateDataMutation();

  const [getSwellingValues, responseSwellingValues] = useLazyGetAllDataQuery();
  const [getSwellingValues2, responseSwellingValues2] = useLazyGetAllDataQuery();
  const [getSwellingValues3, responseSwellingValues3] = useLazyGetAllDataQuery();
  const [getSwellingValues4, responseSwellingValues4] = useLazyGetAllDataQuery();

  const UFData = useSelector(state => state.UFStore.data);
  const { countries } = useSelector(state => state.globalAppData);
  const UserInfoStore = useSelector(state => state.userInfo.data);

  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const { chemicalConfig, pumpCofig, unitConfig, currencyConfig } = useSelector(
    state => state.projectInfo.projectConfig,
  );
  const { projectInfoVM, appInfoVM } = useSelector(state => state.projectInfo.projectData);

  const { data: FeedStoreData, isDataFetched: isFeedDataFetched } = useSelector(
    state => state.Feedsetupdetailsdatapanel,
  );
  const FeedStreamData = useSelector(state => state.Feedsetupdetailsdatapanel.streamData);
  const feedDataJson = useSelector(
    state => state.Feedsetupdetailsdatapanel.streamData?.lstrequestsavefeedwater[0].streams[0],
  );

  const StreamStoreData = FeedStreamData.lstrequestsavefeedwater[0]?.streams[0];
  const { technologyAdded, isLoading, systemDesignCaseTreatmentVM } = useSelector(state => state.processDiagramSlice);
  const { waterTypeID, waterSubTypeID } = StreamStoreData || {};

  //UF store data -------------------------------------------------------------------------------------------
  const UFStore = useSelector(state => state.UFStore);
  //const ufData = useSelector(state => state.UFStore.data);
  const {
    isUFDetailsFetched,
    isUFDesignDataFetched,
    isUFBWDataFetched,
    ufModules,
    ufModuleFlowVM,
    ufModulePressureRatingVM,
    ufChemicalAdjustment,
    isSaveReportJson,
    ufCompany,
  } = UFStore;

  const ufData = useMemo(() => UFStore.data, [UFStore.data]);
  const { getCalculateData } = useUFConfig();

  //UF ReportUFslice
  const { existingActiveModueID, DefaultUFstore } = useSelector(state => {
    const { activeModueID, ...DefaultUFstore } = state.ReportUF.data;
    return { existingActiveModueID: activeModueID, DefaultUFstore };
  });
  const tabAvailableForUF = useSelector(state => state.ReportUF.tabAvailableForUF);
  const ufReportLoading = useSelector(state => state.ReportUF.ufReportLoading);

  //Process diagram Data--------------------------------------------------------------------------------------
  const {
    addedTechnology,
    feedWaterData,
    needToRetriveData,
    nodes,
    edges,
    techNolist,
    feedFlowRate,
    productFlowRate,
    selectedEndNode,
    lstTechnologyLists,
    defaultRecovery,
  } = useSelector(state => state.processDiagramSlice);

  const caseTreatmentID = useMemo(
    () => lstTechnologyLists.find(item => item?.technologyID === 1 && !item?.isDeleted)?.caseTreatmentID,
    [lstTechnologyLists],
  );

  useEffect(() => {
    if (isSaveReportJson) {
      SaveUFJSONData(false);
      dispatch(updateUFStoreData({ isSaveReportJson: false }));
    }
  }, [isSaveReportJson]);

  //IX store Data ---------------------------------------------------------------------------------------------
  const {
    userID,
    projectID,
    caseID,
    treatmentObjID,
    listRegenConds,
    listAdvRegen,
    plant_to_Upcore_ind,
    evaluteExisting_ind,
    degasifation_ind,
    selectedEffluent,
    effluentValue,
    location,
    anionResin,
    cationResin,
    vessel1,
    vessel2,
    vessel3,
    vessel4,
    trains_Online,
    trains_StandBy,
    no_Buffer_Tank_ind,
    space_velocity_txt,
    evaluateExisiting_ind_RuntimeOptimized,
    operatingCycle_Lenght_txt,
    bypassed,
    listProductQualityandregeneration,
    hrs_Day_ind,
    pdExtPiping,
    pdIntDistributor,
    effluentPressure,
    tankTemperature,
    backwashTowerDiameter,
    sacRegenVesselDia,
    sbaRegenVesselDia,
    selectedProcessID,
  } = useSelector(state => state.IXStore.data);
  const resinVal = useSelector(state => state.IXStore.data?.listRegenConds);
  const uomData = useSelector(state => state.GUnitConversion.data);
  const IXDloadder = useSelector(state => state.ReportIXD.loadder);
  const ixStoreAdvance = useSelector(state => state.IXStore?.data?.listAdvRegen);
  const ixRegenreteDose = useSelector(state => state.IXStore?.data?.listProductQualityandregeneration);
  const ixStoreResin1 = useSelector(state => state.IXStore.data.selectedResinList.filter(item => item.columnNo === 1));
  const ixStoreResin2 = useSelector(state => state.IXStore.data.selectedResinList.filter(item => item.columnNo === 2));

  const ixStoreCation = useSelector(state => state.IXStore.data.listProductQualityandregeneration[0]);
  const ixStoreAnion = useSelector(state => state.IXStore.data.listProductQualityandregeneration[1]);
  const ixStore = useSelector(state => state.IXStore.data);
  const ixResinID1 = ixStore?.selectedResinList[0]?.ixResinID1;
  const ixResinID2 = ixStore?.selectedResinList[0]?.ixResinID2;
  const ixResinID3 = ixStore?.selectedResinList[1]?.ixResinID1;
  const ixResinID4 = ixStore?.selectedResinList[1]?.ixResinID2;
  const { projectTitle } = useSelector(state => state.projectInfo);
  const ixStoreObj = useSelector(state => state.IXStore);
  const { evaluateExistFlag, listFinalParamAdj, isSaveIXDReportJson, showFinalParameterTab } = ixStoreObj;
  const validdesignID = useSelector(state => state.IXStore.data.validDesignID);
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData } = useSelector(state => state.UFStore);
  const caseTreatmentIDIXD = useSelector(
    state =>
      state.processDiagramSlice.lstTechnologyLists.find(
        item => item?.technologyID == getTechnologyId(ixStore.treatmentName) && !item?.isDeleted,
      )?.caseTreatmentID,
  );

  //CSS store Data -------------------------------------------------------------------------------------
  const scrollDirection = useSelector(state => state.scrollData.direction);

  //contore tab change and constants
  const userId = UserInfoStore ? UserInfoStore.UserId : 1;
  const loggedInUserID = userId;
  const projectid = ProjectInfoStore ? ProjectInfoStore.projectID : 0;
  const [selectedIndex, setSelectedIndex] = useState("System Design"); //set the selected tab
  const [panelIndex, setPanelIndex] = useState(0); //provide CSS

  // unit conversion
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);

  const tabAvailable = useSelector(state => state.ReportIXD.tabAvailable);
  const reportData = useSelector(state => state.ReportIXD.data);

  const [resinItem1, resinItem2] = ixStore.selectedResinList;
  const isIXD = tabAvailable.IXD;
  const isFeedSetup = tabAvailable.FeedSetup;

  //css variabels
  const [scrollCheck, setScrollCheck] = useState(false);
  const [isFeedError, setIsFeedError] = useState(false);
  const [isReportError, setIsReportError] = useState(false);
  const [isFeedsetupError, setisFeedsetupError] = useState(false);
  const [errorOperator, setErrorOperator] = useState({ show: false, message: "" });

  //Api Variabels
  const [updateData] = useUpdateDataMutation();
  const [updateUFData] = useCreateDataMutation();
  const [updateFeedsetupData] = useCreateDataMutation();
  const [POSTIXDJsonData, IXDReportResponse] = useCreateDataMutation();
  const [POSTUFJsonData, UFReportResponse] = useCreateDataMutation();
  const [postUFJsonReport] = useCreateDataMutation();
  const [postIXDJsonReport] = useCreateDataMutation();
  const [getDesignData, responseDesignData] = useLazyGetAllDataQuery();
  const [getConfigurationData, responseConfigData] = useLazyGetAllDataQuery();
  const [getBackWashData, responseBWData] = useLazyGetAllDataQuery();
  const [getUFDetails, responseUFDetails] = useLazyGetAllDataQuery();
  const treatmentName = useMemo(() => ixStore.treatmentName, [ixStore.treatmentName]);
  const isIXMB = treatmentName === TECH_IXMB;

  const [swellingValueData, setSwellingValueData] = useState([]);
  const [swellingValueData2, setSwellingValueData2] = useState([]);
  const [swellingValueData3, setSwellingValueData3] = useState([]);
  const [swellingValueData4, setSwellingValueData4] = useState([]);
  const { updateIXConversion } = useUnitConversionIXD();

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { unitTypeFlow, unitTypeGasFlow, unitTypeFlux, unitTypePressure, unitTypePower, unitTypeTemp } = useSelector(
    state => state.GUnitConversion,
  );

  const ufStoreUnitsCoversion = useCallback(() => {
    const ufStoreParams = getUFStoreParams(
      unit,
      unitTypeFlow,
      unitTypePressure,
      unitTypeFlux,
      unitTypePower,
      unitTypeGasFlow,
      unitTypeTemp,
    );
    const newUFStore = ufStoreParams.reduce(
      (acc, { key, selectedUnitIdx, unitType }) => ({
        ...acc,
        [key]: convertUnit(UFData[key], selectedUnitIdx, unitType),
      }),
      UFData,
    );
    return newUFStore;
  }, [unit, unitTypeFlow, unitTypePressure, unitTypeFlux, unitTypePower, unitTypeGasFlow]);

  useEffect(() => {
    if (waterSubTypeID && isUFDetailsFetched) {
      setUFChemicalsDefaultValues();
    }
  }, [waterSubTypeID]);

  useEffect(() => {
    if (StreamStoreData?.totalDissolvedSolutes > 0 && isUFDetailsFetched) {
      setUFChemicalValuesInPH();
    }
  }, [StreamStoreData?.totalDissolvedSolutes]);

  useEffect(() => {
    const allResinsArray = [ixResinID1, ixResinID2, ixResinID3, ixResinID4];
    const filteredList = allResinsArray.filter(item => item);
    const [swellingValue1, swellingValue2, swellingValue3, swellingValue4] = filteredList;
    if (swellingValue1) {
      getSwellingValues(
        `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue1}`,
      );
    }
    if (swellingValue2) {
      getSwellingValues2(
        `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue2}`,
      );
    }
    if (swellingValue3) {
      getSwellingValues3(
        `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue3}`,
      );
    }
    if (swellingValue4) {
      getSwellingValues4(
        `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue4}`,
      );
    }
  }, [ixResinID1, ixResinID2, ixResinID3, ixResinID4]);

  useEffect(() => {
    if (isSaveIXDReportJson) {
      const reportListFinal = listFinalParamAdj.map(item =>
        convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_CALC_ENGINE_DATA, convertUnitNumber),
      );
      SaveIXDJSONData(reportListFinal, false);
      dispatch(updateFlagIsSaveIXDReportJson(false));
    }
  }, [isSaveIXDReportJson]);

  useEffect(() => {
    if (responseSwellingValues.isSuccess) {
      setSwellingValueData(responseSwellingValues.data);
    }
  }, [responseSwellingValues]);
  useEffect(() => {
    if (responseSwellingValues2.isSuccess) {
      setSwellingValueData2(responseSwellingValues2.data);
    }
  }, [responseSwellingValues2]);
  useEffect(() => {
    if (responseSwellingValues3.isSuccess) {
      setSwellingValueData3(responseSwellingValues3.data);
    }
  }, [responseSwellingValues3]);
  useEffect(() => {
    if (responseSwellingValues4.isSuccess) {
      setSwellingValueData4(responseSwellingValues4.data);
    }
  }, [responseSwellingValues4]);

  useEffect(() => {
    if (!isIXD) {
      const newUFStore = ufStoreUnitsCoversion();
      dispatch(updateUFStoreData({ data: { ...newUFStore } }));
      const newUnits = {
        unitTypeFlux: unit.selectedUnits[4],
        unitTypeSVelocity: unit.selectedUnits[10],
        unitTypeRVolume: unit.selectedUnits[12],
        unitTypeLength: unit.selectedUnits[8],
        unitTypeConductivity: unit.selectedUnits[17],
        unitTypeTemp: unit.selectedUnits[2],
        unitTypeCVolume: unit.selectedUnits[13],
        unitTypeRegeneration: unit.selectedUnits[14],
        unitTypeContentration: unit.selectedUnits[6],
        unitTypeFlow: unit.selectedUnits[1],
        unitTypeOrganic: unit.selectedUnits[19],
        unitTypePressure: unit.selectedUnits[3],
        unitTypeGasFlow: unit.selectedUnits[18],
        unitTypePower: unit.selectedUnits[9],
      };
      dispatch(updateGlobalUnits(newUnits));
    } else {
      updateIXConversion();
    }
  }, [unit.selectedUnits]);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsFeedError(false);
      setIsReportError(false);
      setisFeedsetupError(false);
    }
  };
  //----------------------------Force fully change------------------------------------------------
  useEffect(() => {
    setSelectedIndex("System Design");
    setPanelIndex(0);
    setCurrentPanel("System Design");
  }, [projectTitle]);
  //----------------------------**Report Response**------------------------------------------------
  useEffect(() => {
    if (UFReportResponse.isLoading) {
      dispatch(setReportLoader());
    }
    if (UFReportResponse.isSuccess) {
      dispatch(setReportData(UFReportResponse.data));
    }
    if (UFReportResponse.isError) {
      dispatch(setErrorReport({ error: true, message: "woeukfhdkjhbcaskcnaskjcbdskj" }));
    }
  }, [UFReportResponse]);

  useEffect(() => {
    if (IXDReportResponse.isLoading) {
      dispatch(setReportLoader());
    }
    if (IXDReportResponse.isSuccess) {
      const calcChemDose = GlobalUnitConversion(
        GlobalUnitConversionStore,
        IXDReportResponse.data?.calcChemDose,
        "g/L",
        unit.selectedUnits[14],
      );
      dispatch(setReportData(IXDReportResponse.data?.byteArray));
      dispatch(updateAfterReportChemDoseData(calcChemDose));
      dispatch(updateAfterReportOverRunDoseData(IXDReportResponse.data?.calcOverrun));
      dispatch(updateShowFinalParameterTab(true));
      dispatch(updateViewReport("true"));
      fetchIXFPADetails();
    }
    if (IXDReportResponse.isError) {
      dispatch(setErrorReport({ error: true, message: "woeukfhdkjhbcaskcnaskjcbdskj" }));
    }
  }, [IXDReportResponse]);

  const getFiltrate = filtrate => {
    const selectedUnit = unitConfig.selectedUnits[1];
    let value = (calcEngineData && calcEngineData[filtrate]) || 0;
    if (value && selectedUnit !== FLOW_METRIC_UNIT) {
      value = unitConversionByName(value, selectedUnit, FLOW_METRIC_UNIT);
    }
    value = (value && value / 24) || 0;
    return value;
  };

  useEffect(() => {
    const data = {
      netFiltrate: getFiltrate(SYSTEM_DESIGN_WATER.PRODUCT_WATER),
      feedFlow: getFiltrate(SYSTEM_DESIGN_WATER.FEED_WATER),
    };
    const recovery = (data.netFiltrate / data.feedFlow) * 100;
    dispatch(updateRecovery({ name: "UF", value: recovery || defaultRecovery.UF }));
  }, [unitConfig.selectedUnits, calcEngineData]);

  useEffect(() => {
    setSelectedIndex("System Design");
    setPanelIndex(0);
    setCurrentPanel("System Design");
    callDesignDataAPI();
    callConfigurationDataAPI();
    callBackWashDataAPI();
    dispatch(updateEvaluateExistFlag(false));
  }, [evaluateExistFlag]);

  const sio2ConversionMeql = () => {
    const divisor = ixStoreAnion.speciesUnit === 1 ? SIO2_MASS_MICRO : SIO2_MASS_MILLI;
    const anionMeqlAvg = ixStoreAnion.averageSpeciesVal / divisor;
    const anionMeqlEnd = ixStoreAnion.endpoingSpeciesVal / divisor;
    return [anionMeqlAvg, anionMeqlEnd];
  };

  const createNewData = port => {
    const index = port - 1;
    const regenConds = listRegenConds[index];

    if (!regenConds) return {};

    const steps = [regenConds.step1_ind, regenConds.step2_ind, regenConds.step3_ind].filter(Boolean).length;
    const concs = [regenConds.step1Con, regenConds.step2Con, regenConds.step3Con].slice(0, steps).reverse();
    const fractions = [regenConds.step1DosFrac, regenConds.step2DosFrac, regenConds.step3DosFrac]
      .slice(0, steps)
      .map(frac => frac / 100)
      .reverse();

    return {
      [`RegenProt_${port}_Steps`]: steps,
      [`RegenProt_${port}_Conc_Percent_n0`]: concs[0] || 0,
      [`RegenProt_${port}_Conc_Percent_n1`]: concs[1] || 0,
      [`RegenProt_${port}_Conc_Percent_n2`]: concs[2] || 0,
      [`RegenProt_${port}_Conc_Percent_n3`]: 0,
      [`RegenProt_${port}_fraction_n1`]: fractions[0] || 0,
      [`RegenProt_${port}_fraction_n2`]: fractions[1] || 0,
      [`RegenProt_${port}_fraction_n3`]: fractions[2] || 0,
    };
  };

  const plantDesign = () => {
    if (evaluteExisting_ind) return 0;
    if (plant_to_Upcore_ind) return 1;
    return 2;
  };

  const userSelectedMetric = () => (unit?.selectedUnits[8] === "in" ? 1 : 0);

  const waterBlockFlag = () =>
    vessel1 === WATER_BLOCK_ID ||
    vessel2 === WATER_BLOCK_ID ||
    vessel3 === WATER_BLOCK_ID ||
    vessel4 === WATER_BLOCK_ID;

  const resin = listProductQualityandregeneration.slice(0, 2).map(item => ({
    safteyFactorVal: item?.saftyFactorVal,
    RegenreteDoseVal: item?.regeneratDoseVal1,
  }));
  const getProperData = data => {
    const lastData = [];
    data.map((item, index) => {
      const selectedObject = ixStoreObj?.resinArrangementData.find(i => i.ixResinArrangmentID == item);
      const selectedName = selectedObject?.resinArrangmentName;
      if (selectedName?.includes("W") && selectedName?.includes("S")) {
        lastData.push(resin[index].RegenreteDoseVal);
        lastData.push(resin[index].safteyFactorVal);
      } else {
        lastData.push(resin[index].safteyFactorVal);
      }
    });
    while (lastData.length < 4) {
      lastData.push(0);
    }
    return {
      Capacity_Safety_Factor_1: parseFloat(lastData[0]),
      Capacity_Safety_Factor_2: parseFloat(lastData[1]),
      Capacity_Safety_Factor_3: parseFloat(lastData[2]),
      Capacity_Safety_Factor_4: parseFloat(lastData[3]),
    };
  };

  const fastRinseIXDReport = () => {
    let FRCation = null;
    let FRAnion = null;
    let actionForCation1 = null;
    let actionForAnion1 = null;
    let actionForCation2 = null;
    let actionForAnion2 = null;
    if (vessel1 !== null && vessel2 === null && vessel3 === null && vessel4 === null) {
      actionForCation1 = vessel1;
      actionForAnion1 = vessel1;
    } else if (vessel1 !== null && vessel2 !== null && vessel3 === null && vessel4 === null) {
      actionForCation1 = vessel1;
      actionForAnion1 = vessel2;
    } else if (
      cationResin === 5 &&
      anionResin !== 11 &&
      vessel1 !== null &&
      vessel2 !== null &&
      vessel3 !== null &&
      vessel4 === null
    ) {
      actionForCation1 = vessel1;
      actionForCation2 = vessel2;
      actionForAnion1 = vessel3;
    } else if (
      anionResin === 11 &&
      cationResin !== 5 &&
      vessel1 !== null &&
      vessel2 !== null &&
      vessel3 !== null &&
      vessel4 === null
    ) {
      actionForCation1 = vessel1;
      actionForCation2 = null;
      actionForAnion1 = vessel2;
      actionForAnion2 = vessel3;
    } else if (vessel1 !== null && vessel2 !== null && vessel3 !== null && vessel4 !== null) {
      actionForCation1 = vessel1;
      actionForCation2 = vessel2;
      actionForAnion1 = vessel3;
      actionForAnion2 = vessel4;
    }

    if (actionForCation1 == 1 || actionForCation2 == 1 || actionForAnion1 == 1 || actionForAnion2 == 1) {
      FRCation = 2;
      FRAnion = 1;
    } else {
      FRCation = 0;
      FRAnion = 0;
    }
    return [FRCation, FRAnion];
  };
  const tLoadTargetConversion = () => (hrs_Day_ind ? operatingCycle_Lenght_txt : operatingCycle_Lenght_txt * 24);

  const getInMeterFromSelectedUnit = value =>
    unitConversionByName(value, UNITS.millimeter, unit.selectedUnits[8]) / 1000;
  const SaveIXDJSONData = async (reportListFinal, isGenerateReport = true) => {
    const { EVALUATE_DESIGN, RETROFIT_DESIGN } = IXD_DESIGN_FPA;
    const flagVesselInert = updateResinFlags(
      cationResin,
      anionResin,
      vessel1,
      vessel2,
      vessel3,
      vessel4,
      ixStoreResin1[0]?.inert,
      ixStoreResin2[0]?.inert,
    );
    const customHeightData = finalParameterDataCalcEngine("Custom_Height", reportListFinal);
    const customInertHeightData = finalParameterDataCalcEngine("Custom_Inert_Height", reportListFinal);
    const customWallThicknessData = finalParameterDataCalcEngine("Custom_Diameter", reportListFinal);
    const customVesseldiameterData = finalParameterDataCalcEngine("Custom_Wall", reportListFinal);
    const flagCustomInertHeight = isInertVessel(
      ixStoreResin1[0]?.inert,
      ixStoreResin2[0]?.inert,
      vessel1,
      vessel2,
      vessel3,
      vessel4,
    );
    const cation = createNewData(1);
    const anion = createNewData(2);
    const plantDesignInd = plantDesign();
    const [sio2MeqlAvg, sio2MeqlEnd] = sio2ConversionMeql();
    const selectedUnitbyUser = userSelectedMetric();
    const water = waterBlockFlag();
    const fastRinseWater = fastRinseIXDReport();
    const tvLoadTarget = tLoadTargetConversion();
    const ToUnit = 2;
    const FromUnit = ixStoreCation.speciesUnit;
    const cationMeqlAvg = UnitConversion("IXD", ixStoreCation.averageSpeciesVal, ToUnit, FromUnit);
    const cationMeqlEnd = UnitConversion("IXD", ixStoreCation.endpoingSpeciesVal, ToUnit, FromUnit);
    const getResinVolume = index =>
      reportListFinal.length > index ? parseFloat(reportListFinal[index]?.resinVolumeAsDelivered ?? 0) : 0;
    const [a, b] = resinVal;
    let cationTemp = resinVal[0]?.temperature;
    let anionTemp = resinVal[1]?.temperature;
    if (a) {
      cationTemp = Number(unitConversionByName(resinVal[0]?.temperature, "°C", unit.selectedUnits[2]));
    }
    if (b) {
      anionTemp = Number(unitConversionByName(resinVal[1]?.temperature, "°C", unit.selectedUnits[2]));
    }
    /*----Unit conversion for Advance Regeneration start-----*/
    const [c, d] = ixStoreAdvance;
    let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
    let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
    if (c) {
      cationregenVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
    }
    if (d) {
      anionregeneVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
    }
    /*----Unit conversion for Advance Regeneration end-----*/
    /*----Unit conversion for Product Quality Regeneration start-----*/
    const [Ra, Rd] = ixRegenreteDose;
    // averageConductivityVal
    let cationRegenreteDoseVel = ixRegenreteDose[0]?.regenerantDoseVal4;
    let anionRegenreteDoseVel = ixRegenreteDose[1]?.regenerantDoseVal4;
    let cationAverageConduc = ixRegenreteDose[0]?.averageConductivityVal;
    let anionAverageConduc = ixRegenreteDose[1]?.averageConductivityVal;
    let cationendpointConduc = ixRegenreteDose[0]?.endpointConductivityVal;
    const anionendpointConduc = ixRegenreteDose[1]?.endpointConductivityVal;
    if (Ra) {
      cationRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      cationAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
      cationendpointConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.endpointConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }
    if (Rd) {
      anionRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      anionAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }

    /*----Unit conversion for Product Quality Regeneration end-----*/
    const MAIN_INPUT = isIXD
      ? {
          Export_to_Excel: 0,
          Name_1: "", //It will be updated by middleware before sending to Calc-engine
          Name_2: "", //It will be updated by middleware before sending to Calc-engine
          Name_3: "", //It will be updated by middleware before sending to Calc-engine
          Name_4: "", //It will be updated by middleware before sending to Calc-engine
          Name_Inert1: "", //It will be updated by middleware before sending to Calc-engine
          Name_Inert2: "", //It will be updated by middleware before sending to Calc-engine
          Name_Inert3: "", //It will be updated by middleware before sending to Calc-engine
          Flag_Evaluate_Type: evaluateExisiting_ind_RuntimeOptimized,
          Flag_Degas: degasifation_ind !== null && degasifation_ind,
          Flag_Custom_Resin_Volume_1: listFinalParamAdj[0]?.ixfpaRadioButtonID === 1,
          Flag_Vessel_1_Custom: [1, 2].includes(listFinalParamAdj[0]?.ixfpaRadioButtonID),
          Flag_Custom_Inert_Height: [EVALUATE_DESIGN, RETROFIT_DESIGN].includes(
            listFinalParamAdj[0]?.ixfpaRadioButtonID,
          )
            ? flagCustomInertHeight
            : false,
          Flag_Overrun_Computation: listProductQualityandregeneration[0]?.overAllComputation == 1,
          Flag_neutral_Effluent: listProductQualityandregeneration[0]?.naturalEffect === 2 && true,
          Flag_Optimise_Dose: listProductQualityandregeneration[0]?.doseOptimization === 2 && true,
          Flag_Overall_Process: selectedProcessID,
          Flag_CP_Sub_Process: 0, //IXCPP
          Flag_Layout:
            parseFloat(trains_StandBy) > 0 && trains_StandBy !== null ? 2 : no_Buffer_Tank_ind === true ? 0 : 1,
          Bypass_Fraction: parseFloat(bypassed || 0) / 100,
          N_trains_online: parseFloat(trains_Online || 0),
          N_trains_Standby: parseFloat(trains_StandBy || 0),
          Flag_Evaluation_Mode: listFinalParamAdj[0]?.ixfpaRadioButtonID
            ? listFinalParamAdj[0]?.ixfpaRadioButtonID - 1
            : plantDesignInd,
          Flag_Design_Criteria: evaluateExisiting_ind_RuntimeOptimized === true ? 1 : 0,
          t_load_target: operatingCycle_Lenght_txt ? tvLoadTarget : 0,
          SV_Load_target: space_velocity_txt
            ? Number(
                GlobalUnitConversion(
                  GlobalUnitConversionStore,
                  space_velocity_txt,
                  "BV/h",
                  unit.selectedUnits[10],
                ).toFixed(2),
              )
            : 0,
          Flag_Design_Scavenger: 0, //IXCPP
          Flag_Design_Cation: isIXMB ? 0 : cationResin, //It will be updated by middleware before sending to Calc-engine
          Flag_Design_Anion: isIXMB ? 0 : anionResin, //It will be updated by middleware  before sending to Calc-engine
          Flag_Design_Polish: isIXMB ? anionResin : 0, //IXCPP
          Flag_Design_CPP: 0, //IXCPP
          Flag_Degas_Target_Type: selectedEffluent || 0,
          Degas_Target: effluentValue || 0,
          Flag_Degas_Location: location || 0,
          Flag_Ionic_Form_1: 5, //It will be updated by middleware before sending to Calc-engine
          Flag_Ionic_Form_2: 5, //It will be updated by middleware before sending to Calc-engine
          Flag_Ionic_Form_3: 5, //It will be updated by middleware before sending to Calc-engine
          Flag_Ionic_Form_4: 5, //It will be updated by middleware before sending to Calc-engine
          Resin_Packaging_Size_1: 1,
          Resin_Packaging_Size_2: 1,
          Resin_Packaging_Size_3: 1,
          Resin_Packaging_Size_4: 1,
          Custom_Resin_Volume_1: getResinVolume(0) / (swellingValueData?.srHOhCycleSold || 1),
          Custom_Resin_Volume_2: getResinVolume(1) / (swellingValueData2?.srHOhCycleSold || 1),
          Custom_Resin_Volume_3: getResinVolume(2) / (swellingValueData3?.srHOhCycleSold || 1),
          Custom_Resin_Volume_4: getResinVolume(3) / (swellingValueData4?.srHOhCycleSold || 1),
          ...getProperData([cationResin, anionResin]),
          Flag_Vessel_1_Regen_Sys: vessel1 || 0,
          Flag_Vessel_2_Regen_Sys: vessel2 || 0,
          Flag_Vessel_3_Regen_Sys: vessel3 || 0,
          Flag_Vessel_4_Regen_Sys: vessel4 || 0,
          Flag_Vessel_1_Inert: 0, //It will be updated by middleware before sending to Calc-engine
          Flag_Vessel_2_Inert: 0, //It will be updated by middleware before sending to Calc-engine
          Flag_Vessel_3_Inert: 0, //It will be updated by middleware before sending to Calc-engine
          Flag_Vessel_4_Inert: 0, //It will be updated by middleware before sending to Calc-engine
          ...customVesseldiameterData.vesselMeasurmentsData,
          ...customWallThicknessData.vesselMeasurmentsData,
          ...customHeightData.compartMentData,
          ...customHeightData.vesselMeasurmentsData,
          ...customInertHeightData.compartMentData,
          ...customInertHeightData.vesselMeasurmentsData,
          Flag_Regenerant_1: listRegenConds.length > 0 && listRegenConds[0]?.regenerantID,
          Flag_Regenerant_2: listRegenConds.length > 1 && listRegenConds[1]?.regenerantID,
          Regenerant_1_Stock_Conc_Percent: 0, //It will be updated by middleware before sending to Calc-engine
          Regenerant_2_Stock_Conc_Percent: 0, //It will be updated by middleware before sending to Calc-engine
          Regenerant_1_Price_Bulk: 0, //It will be updated by middleware before sending to Calc-engine
          Regenerant_2_Price_Bulk: 0, //It will be updated by middleware before sending to Calc-engine
          RegenProt_1_Temperature: listRegenConds.length > 0 && cationTemp,
          RegenProt_2_Temperature: listRegenConds.length > 1 && anionTemp,
          RegenProt_3_Temperature: 0,
          RegenProt_3_Steps: 0,
          ...cation,
          ...anion,
          RegenProt_3_Conc_Percent_n0: 0.0,
          RegenProt_3_Conc_Percent_n1: 0.0,
          RegenProt_3_Conc_Percent_n2: 0.0,
          RegenProt_3_Conc_Percent_n3: 0.0,
          RegenProt_3_fraction_n1: 0.0,
          RegenProt_3_fraction_n2: 0.0,
          RegenProt_3_fraction_n3: 0.0,
          RegenProt_1_Dose_Target: Number(cationRegenreteDoseVel?.toFixed(2)),
          RegenProt_2_Dose_Target: Number(anionRegenreteDoseVel?.toFixed(2)),
          RegenProt_3_Dose_Target: 0,
          RegenProt_1_Ratio_Target: listProductQualityandregeneration[0]?.regenerationRatio
            ? parseFloat(listProductQualityandregeneration[0].regenerationRatio)
            : 0,

          RegenProt_2_Ratio_Target: listProductQualityandregeneration[1]?.regenerationRatio
            ? parseFloat(listProductQualityandregeneration[1].regenerationRatio)
            : 0,
          RegenProt_3_Ratio_Target: 0,
          RegenProt_1_Manual_Overrun_Factor: listProductQualityandregeneration[0]?.regenerantDoseVal2,
          RegenProt_2_Manual_Overrun_Factor: listProductQualityandregeneration[1]?.regenerantDoseVal2,
          RegenProt_3_Manual_Overrun_Factor: 0,
          Flag_RegenProt_1_BW_Source: isIXMB
            ? BW_SOURCE_IXMB
            : listRegenConds.length > 0 && listRegenConds[0]?.backwash !== null
              ? listRegenConds[0]?.backwash
              : 0,
          Flag_RegenProt_2_BW_Source:
            listRegenConds.length > 1 && listRegenConds[1]?.backwash !== null ? listRegenConds[1]?.backwash : 0,
          Flag_RegenProt_3_BW_Source: 0,
          Flag_RegenProt_1_Service_Source: isIXMB
            ? BW_SOURCE_IXMB
            : listRegenConds.length > 0 && listRegenConds[0]?.serviceWater !== null
              ? listRegenConds[0]?.serviceWater
              : 0,
          Flag_RegenProt_2_Service_Source:
            listRegenConds.length > 1 && listRegenConds[1]?.serviceWater !== null ? listRegenConds[1]?.serviceWater : 0,
          Flag_RegenProt_3_Service_Source: 0,
          RegenProt_1_BW_interval: listAdvRegen.length > 0 && listAdvRegen[0]?.bwFrequency,
          RegenProt_2_BW_interval: listAdvRegen.length > 1 && listAdvRegen[1]?.bwFrequency,
          RegenProt_3_BW_interval: 0,
          RegenProt_1_BW_expansion: listAdvRegen.length > 0 && listAdvRegen[0]?.bwExpansion,
          RegenProt_2_BW_expansion: listAdvRegen.length > 1 && listAdvRegen[1]?.bwExpansion,
          RegenProt_3_BW_expansion: 0,
          RegenProt_1_BW_duration:
            listAdvRegen.length > 0 && listAdvRegen[0]?.bwDuration !== 0
              ? parseFloat(listAdvRegen[0]?.bwDuration / 60)
              : 0,
          RegenProt_2_BW_duration:
            listAdvRegen.length > 1 && listAdvRegen[1]?.bwDuration !== 0
              ? parseFloat(listAdvRegen[1]?.bwDuration / 60)
              : 0,
          RegenProt_3_BW_duration: 0,
          RegenProt_1_Compact_t:
            listAdvRegen.length > 0 && listAdvRegen[0]?.compactionDuration !== 0
              ? parseFloat(listAdvRegen[0]?.compactionDuration / 60)
              : 0,
          RegenProt_2_Compact_t:
            listAdvRegen.length > 1 && listAdvRegen[1]?.compactionDuration !== 0
              ? parseFloat(listAdvRegen[1]?.compactionDuration / 60)
              : 0,
          RegenProt_3_Compact_t: 0,
          RegenProt_1_Regeneration_SV: listAdvRegen.length > 0 && cationregenVel,
          RegenProt_2_Regeneration_SV: listAdvRegen.length > 1 && anionregeneVel,
          RegenProt_3_Regeneration_SV: 0,
          RegenProt_1_Water_Block_factor: listAdvRegen.length > 0 && listAdvRegen[0]?.regenerationFactor / 100,
          RegenProt_2_Water_Block_factor: listAdvRegen.length > 1 && listAdvRegen[1]?.regenerationFactor / 100,
          RegenProt_3_Water_Block_factor: 0,
          Flag_RegenProt_1_Displacement_Rinse: listAdvRegen.length > 0 && listAdvRegen[0]?.displacementFlow,
          Flag_RegenProt_2_Displacement_Rinse: listAdvRegen.length > 1 && listAdvRegen[1]?.displacementFlow,
          Flag_RegenProt_3_Displacement_Rinse: 0,
          RegenProt_1_Displ_Rinse_BV: listAdvRegen.length > 0 && listAdvRegen[0]?.displacementVolume,
          RegenProt_2_Displ_Rinse_BV: listAdvRegen.length > 1 && listAdvRegen[1]?.displacementVolume,
          RegenProt_3_Displ_Rinse_BV: 0,
          Flag_RegenProt_1_Rinse_Recycle: listAdvRegen.length > 0 && listAdvRegen[0]?.fatRinseRecycle - 1,
          Flag_RegenProt_2_Rinse_Recycle: listAdvRegen.length > 1 && listAdvRegen[1]?.fatRinseRecycle - 1,
          RegenProt_1_Fast_Rinse_BV: listAdvRegen.length > 0 && listAdvRegen[0]?.fatRinseVolume,
          RegenProt_2_Fast_Rinse_BV: listAdvRegen.length > 1 && listAdvRegen[1]?.fatRinseVolume,
          RegenProt_3_Fast_Rinse_BV: 0,
          RegenProt_1_Settling_t: listAdvRegen.length > 0 && listAdvRegen[0]?.settingDuration / 60,
          RegenProt_2_Settling_t: listAdvRegen.length > 1 && listAdvRegen[1]?.settingDuration / 60,
          RegenProt_3_Settling_t: 0,
          Typical_Org_Ads_Demin: parseFloat(listProductQualityandregeneration[1]?.endpointConductivityVal) / 100,
          Typical_Org_Ads_Scav: 0.75, //Not in case of IXD
          PD_Distributors: unitConversionByName(pdIntDistributor, UNITS.bar, unit.selectedUnits[3]),
          PD_Piping: unitConversionByName(pdExtPiping, UNITS.bar, unit.selectedUnits[3]),
          Product_Pressure: unitConversionByName(effluentPressure, UNITS.bar, unit.selectedUnits[3]),
          Temp_bulk_regenerant:
            unit.selectedUnits[2] !== UNITS.celsius
              ? unitConversionByName(tankTemperature, UNITS.celsius, unit.selectedUnits[2])
              : tankTemperature,
          MB_External_BWT_Diameter: getInMeterFromSelectedUnit(backwashTowerDiameter), //Converting mm to meter
          MB_External_SAC_Diameter: getInMeterFromSelectedUnit(sacRegenVesselDia), //Converting mm to meter
          MB_External_SBA_Diameter: getInMeterFromSelectedUnit(sbaRegenVesselDia), //Converting mm to meter
          Crud_Input: 0.01, //It applies only to IXCP. On Condensate Polishing additional parameters. Pop up => CRUD
          Price_Electricity: chemicalConfig.operatingCost?.electricity,
          Price_Raw_Water: chemicalConfig.operatingCost.rawWater,
          Price_Waste_Water: chemicalConfig.operatingCost.wasteWaterDisposal,
          Efficiency_Pump_Feed: pumpCofig.pupmList.find(a => a.pumpID == 62).pumpEfficiency,
          Efficiency_Motor_Feed: pumpCofig.pupmList.find(a => a.pumpID == 62).motorEfficiency,
          Efficiency_Pump_BW: pumpCofig.pupmList.find(a => a.pumpID == 63).pumpEfficiency,
          Efficiency_Motor_BW: pumpCofig.pupmList.find(a => a.pumpID == 63).motorEfficiency,
          Efficiency_Pump_Regen: pumpCofig.pupmList.find(a => a.pumpID == 64).pumpEfficiency,
          Efficiency_Motor_Regen: pumpCofig.pupmList.find(a => a.pumpID == 64).motorEfficiency,
          Efficiency_Compressor: pumpCofig.pupmList.find(a => a.pumpID == 65).pumpEfficiency,
          Efficiency_Compressor_Motor: pumpCofig.pupmList.find(a => a.pumpID == 65).motorEfficiency,
          Flow_System_Design: Number(
            GlobalUnitConversion(GlobalUnitConversionStore, feedFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(3),
          ),
          RegenProt_1_Ion_Conc_Leakage: cationMeqlAvg,
          RegenProt_2_Ion_Conc_Leakage: sio2MeqlAvg,
          RegenProt_3_Ion_Conc_Leakage: 0,
          RegenProt_1_Conductivity_Leakage: Number(cationAverageConduc?.toFixed(2)),
          RegenProt_2_Conductivity_Leakage: Number(anionAverageConduc?.toFixed(2)),
          RegenProt_3_Conductivity_Leakage: 0,
          Organic_Adsorption_Efficiency: 0,
          Organic_Adsorption_Efficiency_2: 0,
          Organic_Adsorption_Efficiency_3: 0,
          RegenProt_1_Ion_Conc_Endpoint: cationMeqlEnd,
          RegenProt_2_Ion_Conc_Endpoint: sio2MeqlEnd,
          RegenProt_3_Ion_Conc_Endpoint: 0,
          RegenProt_1_Conductivity_Endpoint: Number(cationendpointConduc?.toFixed(2)),
          RegenProt_2_Conductivity_Endpoint: 0,
          RegenProt_3_Conductivity_Endpoint: 0,
          Diaminoethane_dose: 0, //It applies only to IXCP
          Amino_2_methyl_1_propanol_dose: 0, //It applies only to IXCP
          Methoxypropylamine_dose: 0, //It applies only to IXCP
          Aminopentanol_dose: 0, //It applies only to IXCP
          Cyclohexylamine_dose: 0, //It applies only to IXCP
          Ethanolamine_dose: 0, //It applies only to IXCP
          Morpholine_dose: 0, //It applies only to IXCP
          Hydrazine_dose: 0, //It applies only to IXCP
          Flow_net_product:
            selectedEndNode == "startNode"
              ? 0
              : Number(
                  GlobalUnitConversion(
                    GlobalUnitConversionStore,
                    productFlowRate,
                    "m³/h",
                    unit.selectedUnits[1],
                  ).toFixed(3),
                ),
          //if product water than product water input needs to be send
          TSS_IX_Input: parseFloat(feedDataJson?.tss), //feed water TSS iif IX is first unit operation.  If IX follows UF, RO, or another IX process, IX feed TSS = 0
          flag_Vessel_Dimension_Units: selectedUnitbyUser,
          Flag_RegenProt_1_Fast_Rinse_Source: fastRinseWater[0],
          Flag_RegenProt_2_Fast_Rinse_Source: fastRinseWater[1],
          Flag_RegenProt_3_Fast_Rinse_Source: 0,
        }
      : reportData.ixReport.MAIN_INPUT;
    const FEED_WATER = isFeedSetup
      ? {
          designTemp: StreamStoreData?.tempDesign,
          methodname: "normal",
          TOC_System_Feed: parseFloat(feedDataJson?.toc),
          ph: parseFloat(feedDataJson?.pH),
          TotalDissolvedSolutes: parseFloat(feedDataJson?.totalDissolvedSolutes),
          ChargeBalance: parseFloat(feedDataJson?.chargeBalance),
          EstimatedConductivity: parseFloat(feedDataJson?.estimatedConductivity),
          Degas: 0.0,
          percentage_of_initial_total_CO2_remaining: parseFloat(feedDataJson?.percentContribution),
          Equilibrate_with: 0.0,
          Adjustment_Type: 0.0,
          Add_Reagent: 0.0,
          Total_CO2: 0.0,
          cations: feedDataJson?.cations,
          anions: feedDataJson?.anions,
          neutrals: feedDataJson?.neutral,
        }
      : reportData.ixReport.FEED_WATER;
    const Myobject = {
      userID: userId || reportData.userID,
      projectID: projectid || reportData.projectID,
      caseID: ProjectInfoStore?.caseId || reportData.caseID,
      treatmentObjID: isIXD ? treatmentObjID : reportData.treatmentObjID,
      validDesignID: isIXD ? validdesignID : reportData.validDesignID,
      resinID1: isIXD ? ixStoreResin1[0]?.ixResinID1 : reportData.resinID1,
      resinID2: isIXD ? ixStoreResin1[0]?.ixResinID2 : reportData.resinID2,
      resinID3: isIXD ? ixStoreResin2[0]?.ixResinID1 : reportData.resinID3,
      resinID4: isIXD ? ixStoreResin2[0]?.ixResinID2 : reportData.resinID4,
      inertID1: isIXD ? ixStoreResin1[0]?.inert || 0 : reportData.inertID1,
      inertID2: isIXD ? ixStoreResin2[0]?.inert || 0 : reportData.inertID2,
      inertID3: 0,
      chemID_Regenerant1: isIXD
        ? listRegenConds.length > 0 && listRegenConds[0]?.chemicalID
        : reportData.chemID_Regenerant1,
      chemID_Regenerant2: isIXD
        ? listRegenConds.length > 1 && listRegenConds[1]?.chemicalID
        : reportData.chemID_Regenerant2,
      flag_waterblock: isIXD ? water : reportData.flag_waterblock,
      flag_Vessel1_block: isIXD ? flagVesselInert.flag1 : reportData.flag_Vessel1_block,
      flag_Vessel2_block: isIXD ? flagVesselInert.flag2 : reportData.flag_Vessel2_block,
      flag_Vessel3_block: isIXD ? flagVesselInert.flag3 : reportData.flag_Vessel3_block,
      flag_Vessel4_block: isIXD ? flagVesselInert.flag4 : reportData.flag_Vessel4_block,
      ixReport: {
        MAIN_INPUT: MAIN_INPUT,
        FEED_WATER: FEED_WATER,
      },
    };

    //Calling this API to save Report JSON data
    const { caseID: caseId, projectID: projectId } = Myobject;
    const saveIXDReportPayload = {
      Method: "masterdata/api/v1/ProjectState",
      projectId,
      caseId,
      projectData: { ...Myobject, showFinalParameterTab: showFinalParameterTab },
    };
    postIXDJsonReport(saveIXDReportPayload);
    if (isGenerateReport) {
      const MethodName = { Method: "ix/api/v1/DetailedReport" };
      const IxdDetailReport = { ...MethodName, ...Myobject };
      await POSTIXDJsonData(IxdDetailReport);
    }
  };

  const modifiedData = array => {
    let tempData = [];
    array.map(item => {
      const data = [];
      item.unitKey.map(x => {
        data.push({
          uomId: x.uomId,
          uomTypeID: item.uomTypeID,
          uomTypeName: item.uomTypeName,
          uomName: x.uomName,
          uomsystemId: x.uomsystemId,
          uomsystemName: x.uomsystemName,
          isSelected: x.isSelected,
        });
      });
      tempData = [...tempData, ...data];
    });
    return tempData;
  };

  const validData = (data, label, flag) => {
    const den = flag ? "0" : 0;
    return data ? data[label] : den;
  };
  const calculateData = (mcipFlag, cebFlag) => {
    const t_BW_module_cycle = getCalculateData();

    const tempFlag_cycle_input = 0;
    const t_normal_module_cycle = Number(
      Number(ufData.backwash_design) + (tempFlag_cycle_input == 0 ? t_BW_module_cycle : 0),
    );
    const tempN_F_per_CIP_raw = ufData.cIP > 0 ? (Number(ufData.cIP) * 60 * 24) / t_normal_module_cycle : 1000000;
    const tempN_F_per_mCIP_raw = ufData.miniCIP > 0 ? (ufData.miniCIP * 60 * 24) / t_normal_module_cycle : 1000000;
    const tempN_F_per_CEB3_raw =
      ufData.disinfectionCEB > 0 ? Number(ufData.disinfectionCEB * 60) / t_normal_module_cycle : 1000000;
    const tempN_F_per_CEB2_raw =
      ufData.alkaliOxidantCEB > 0 ? Number(ufData.alkaliOxidantCEB * 60) / t_normal_module_cycle : 1000000;
    const tempN_F_per_CEB1_raw = ufData.acidCEB > 0 ? Number(ufData.acidCEB * 60) / t_normal_module_cycle : 1000000;
    const N_F_per_clean_min = Math.min(
      tempN_F_per_CEB1_raw,
      tempN_F_per_CEB2_raw,
      tempN_F_per_CEB3_raw,
      tempN_F_per_CIP_raw,
      tempN_F_per_mCIP_raw,
    );
    const N_F_per_CIP_ratio = ufData.cIP > 0 ? tempN_F_per_CIP_raw / N_F_per_clean_min : 0;
    const N_F_per_mCIP_ratio = ufData.miniCIP > 0 ? tempN_F_per_mCIP_raw / N_F_per_clean_min : 0;
    const N_F_per_CEB3_ratio = ufData.disinfectionCEB > 0 ? tempN_F_per_CEB3_raw / N_F_per_clean_min : 0;
    const N_F_per_CEB2_ratio = ufData.alkaliOxidantCEB > 0 ? tempN_F_per_CEB2_raw / N_F_per_clean_min : 0;
    const N_F_per_CEB1_ratio = ufData.acidCEB > 0 ? tempN_F_per_CEB1_raw / N_F_per_clean_min : 0;

    return {
      N_BW_per_AS: parseInt(ufData.backwash_design / ufData.backwash_design + 0.5).toString(),
      N_F_per_CEB1: cebFlag ? parseInt(N_F_per_CEB1_ratio * parseInt(N_F_per_clean_min + 0.5) + 0.5).toString() : "0",
      N_F_per_CEB2: cebFlag ? parseInt(N_F_per_CEB2_ratio * parseInt(N_F_per_clean_min + 0.5) + 0.5).toString() : "0",
      N_F_per_CEB3: cebFlag ? parseInt(N_F_per_CEB3_ratio * parseInt(N_F_per_clean_min + 0.5) + 0.5).toString() : "0",
      N_F_per_mCIP: mcipFlag ? parseInt(N_F_per_mCIP_ratio * parseInt(N_F_per_clean_min + 0.5) + 0.5).toString() : "0",
      N_F_per_CIP: parseInt(parseInt(N_F_per_CIP_ratio * parseInt(N_F_per_clean_min + 0.5) + 0.5)).toString(),
      t_normal_module_cycle: t_normal_module_cycle.toString(),
    };
  };
  const getChemicalCIP = () => {
    const checkValues = (value1, value2, enabled) => {
      let result = 0;
      if (value1 > 0 && enabled) {
        result = 2;
      } else if (value1 > 0 || value2 > 0) {
        result = 1;
      }
      return result;
    };

    const firstData = checkValues(UFData.mineralValue_CIP, UFData.organicValue_CIP, UFData.mineralEnabled_Ind_CIP);
    const secondData = checkValues(UFData.alkaliValue_CIP, UFData.oxidantValue_CIP, UFData.alkaliEnabled_Ind_CIP);

    return Math.max(1, firstData, secondData);
  };

  const getChemicalMiniCIP = () => {
    const checkValues = (value1, value2, enabled) => {
      let result = 0;
      if (value1 > 0 && enabled) {
        result = 2;
      } else if (value1 > 0 || value2 > 0) {
        result = 1;
      }
      return result;
    };

    const firstData = checkValues(
      UFData.mineralValue_MiniCIP,
      UFData.organicValue_MiniCIP,
      UFData.mineralEnabled_Ind_MiniCIP,
    );
    const secondData = checkValues(
      UFData.alkaliValue_MiniCIP,
      UFData.oxidantValue_MiniCIP,
      UFData.alkaliEnabled_Ind_MiniCIP,
    );

    return Math.max(1, firstData, secondData);
  };

  const getModuleDetails = moduleID => {
    const selectedModules = ufModules?.filter(m => m.ufmoduleId == moduleID);
    if (selectedModules.length > 0 && selectedModules[0]) {
      const moduleFlowData = ufModuleFlowVM.filter(vfvm => vfvm.ufmoduleId == moduleID);
      const modulePressureData = ufModulePressureRatingVM.filter(pvm => pvm.ufmoduleID == moduleID);
      const flowVM = moduleFlowData.length > 0 ? moduleFlowData[0] : {};
      const pressureRatingVM = modulePressureData.length > 0 ? modulePressureData[0] : {};
      const activeModuleDetails = {
        ...selectedModules[0],
        ...flowVM,
        ...pressureRatingVM,
      };
      return activeModuleDetails;
    }
  };
  const changeChemicalFormat = chemicalSymbol => {
    const data = [
      {
        from: "₁",
        to: "1",
      },
      {
        from: "₆",
        to: "6",
      },
      {
        from: "₈",
        to: "8",
      },
      {
        from: "₂",
        to: "2",
      },
      {
        from: "₄",
        to: "4",
      },
      {
        from: "₃",
        to: "3",
      },
      {
        from: "₀",
        to: "0",
      },
      {
        from: "₅",
        to: "5",
      },
      {
        from: "₇",
        to: "7",
      },
      {
        from: "₉",
        to: "9",
      },
    ];
    let camelCase = chemicalSymbol;
    data.map(item => {
      camelCase = camelCase.replaceAll(item.from, item.to);
    });
    return camelCase;
  };

  const { mcipFlag, cebFlag } = useMemo(() => {
    const ufSpecialFeatureID = ufData ? ufData.ufSpecialFeatureID : DefaultUFstore.ufspecialfeatureflag;
    return {
      mcipFlag: ufSpecialFeatureID != 1,
      cebFlag: ufSpecialFeatureID != 2,
    };
  }, [ufData, DefaultUFstore]);

  const getFeedChemicalsAfterChemAdjustment = (prefix, source) => {
    Logger.log("getFeedChemicalsAfterChemAdjustment", prefix, source, chemicalConfig.chemicalListById);
    const result = {};
    if (source) {
      const chemical = chemicalConfig.chemicalListById[source.chemicalId];
      result[`${prefix}_bulk_conc`] = chemical.bulkConcentration / 100;
      result[`${prefix}_conc`] = source.value;
      result[`${prefix}_name`] = changeChemicalFormat(chemical.symbol);
      result[`${prefix}_price`] = chemical.bulkPrice;
      result[`${prefix}_density`] = chemical.bulkDensity;
    }
    return result;
  };

  const getFeedWaterPayload = (ufReport, chemicalAdjustedPH) => {
    const feed_water = {
      ...ufReport.feed_water,
      ...(ufChemicalAdjustment?.chemicalAdjustment || {}),
    };

    if (feed_water.ChemicalAdjustment) {
      feed_water.ChemicalAdjustment = [
        {
          ...feed_water.ChemicalAdjustment[0],
          pH: chemicalAdjustedPH,
        },
      ];
    }

    return feed_water;
  };

  const getUpdatedUFReportPayload = (ufReport, chemicalAdjustedPH) => {
    const { oxidant, coagulant, phDown } = ufChemicalAdjustment?.data || {};
    const acidDetails = getFeedChemicalsAfterChemAdjustment("Feed_acid", phDown);

    if (Object.keys(acidDetails).length) {
      const chemical = chemicalConfig.chemicalListById[phDown?.chemicalId];
      acidDetails.Feed_acid_conc = chemical?.bulkConcentration;
    }

    const oxCoagAdjustmentData = {
      ...acidDetails,
      ...getFeedChemicalsAfterChemAdjustment("Feed_ox", oxidant),
      ...getFeedChemicalsAfterChemAdjustment("Feed_coag", coagulant),
    };

    return {
      ...ufReport,
      ...oxCoagAdjustmentData,
      feed_water: getFeedWaterPayload(ufReport, chemicalAdjustedPH),
    };
  };

  const getModuleBasedParams = (activeUFModule, ufData) => {
    const isIntegraPacTRack = activeUFModule.integraPacInd || activeUFModule.tRack;
    return {
      IP_Skids_train: isIntegraPacTRack ? ufData.skidsPerTrain : 1,
      IP_Mod_skid: isIntegraPacTRack ? ufData.modulesPerSkid : ufData.modulesPerTrain,
    };
  };

  const getAcidChemDetails = pH => ({
    Feed_acid_name: "0",
    Feed_acid_conc: "0",
    Feed_acid_pH: pH?.toString(),
    Feed_acid_bulk_conc: "0",
    Feed_acid_density: "0",
    Feed_acid_price: "0",
  });

  const getBWProtocol = (uFBWProtocolID, activeModule) =>
    uFBWProtocolID == 1 ? 2 : activeModule.moduleType === "Inge" ? 3 : 0;

  const getFeedChemicalAdjustment = () => [
    {
      CaSO4_per: 0,
      BaSO4_per: 0,
      SrSO4_per: 0,
      CaF2_per: 0,
      SiO2_per: 0,
      MgOH2_per: 0,
      LSI: 0,
      SDI: 0,
    },
  ];

  const SaveUFJSONData = async (isGenerateRepoprt = true) => {
    const activeUFModule = getModuleDetails(ufData.uFModuleID);
    if (!activeUFModule || !activeUFModule.ufmoduleId) return;

    const userData = {
      lstdefaultProjectUnits: modifiedData(unitConfig.defaultValues).filter(item => item.isSelected),
      reportProjectInfoVM: {
        projectNumber: projectInfoVM?.projectNumber,
        projectName: projectInfoVM?.projectName,
        createDate: projectInfoVM?.createdDate,
        lastModified: projectInfoVM?.updatedDate,
        caseName: feedWaterData.projectCaseName,
        preparedBy: projectInfoVM?.designer,
        designerCompany: projectInfoVM?.designerCompany,
        customer: projectInfoVM?.customer,
        countryName: countries?.find(item => item.countryID == projectInfoVM?.countryID)?.countryName,
        segmentName: projectInfoVM?.marketsegmentName,
        projectNotes: projectInfoVM?.projectNotes,
        exchangeRate: currencyConfig?.selectedCurrency.currencyValue,
        currencySymbol: currencyConfig?.selectedCurrency.currencyUnit,
        appVersion: appInfoVM?.appVersion,
      },
      lstAllUOMs: modifiedData(unitConfig.defaultValues),
      lstreportConversionFactors: uomData,
    };
    const ufReport1 = {
      ...DefaultUFstore.ufReport,
      WaterTypeID: waterTypeID,
      WaterSubTypeID: waterSubTypeID,
      TechnologyId: 1,
      Flow_Design:
        selectedEndNode == "startNode"
          ? convertUnitNumber(feedFlowRate, "m³/h", 1)
          : convertUnitNumber(productFlowRate, "m³/h", 1),
      Flag_Design_Flow: selectedEndNode == "startNode" ? 0 : 2,
      Guideline_number: waterSubTypeID?.toString(),
      Temp_min: StreamStoreData?.tempMin,
      Temp_design: StreamStoreData?.tempDesign,
      Temp_max: StreamStoreData?.tempMax,
      feed_water: {
        designTemp: feedDataJson?.tempDesign,
        methodname: "normal",
        ph: parseFloat(feedDataJson?.pH),
        Degas: 0.0,
        percentage_of_initial_total_CO2_remaining: parseFloat(feedDataJson?.percentContribution),
        Equilibrate_with: 0.0,
        Adjustment_Type: 0.0,
        Add_Reagent: 0.0,
        Total_CO2: 0.0,
        turbidity: feedDataJson?.turbidity,
        organicToc: feedDataJson?.toc,
        tss: feedDataJson?.tss,
        tds: feedDataJson?.totalDissolvedSolutes,
        cations: feedDataJson?.cations,
        anions: feedDataJson?.anions,
        neutrals: feedDataJson?.neutral,
        LSI_targ: 0,
        SDI_targ: 0,
        ChemicalAdjustment: getFeedChemicalAdjustment(),
      },
    };
    const MyObjectFeed = {
      userID: userId,
      projectID: projectid,
      caseID: DefaultUFstore.caseID,
      treatmentObjID: DefaultUFstore.treatmentObjID,
      adjustment: DefaultUFstore.adjustment,
      flux_Filter_actual: DefaultUFstore.flux_Filter_actual,
      ufReport: ufReport1,
      ufspecialfeatureflag: parseInt(DefaultUFstore.ufspecialfeatureflag),
      ...userData,
    };
    const { adjustment } = ufChemicalAdjustment || DefaultUFstore;
    const newActiveModueID = activeUFModule?.ufmoduleId || existingActiveModueID;
    const chemicalAdjustedPH = adjustment?.afterPh || parseFloat(feedDataJson?.pH);
    const Myobject =
      !tabAvailableForUF.UF && !tabAvailableForUF.FeedSetup
        ? {
            ...DefaultUFstore,
            ufReport: {
              ...DefaultUFstore.ufReport,
              Flow_Design:
                selectedEndNode == "startNode"
                  ? convertUnitNumber(feedFlowRate, "m³/h", 1)
                  : convertUnitNumber(productFlowRate, "m³/h", 1),
              Flag_Design_Flow: selectedEndNode == "startNode" ? 0 : 2,
            },
            ...userData,
          }
        : tabAvailableForUF.FeedSetup && !tabAvailableForUF.UF
          ? MyObjectFeed
          : !tabAvailableForUF.FeedSetup && tabAvailableForUF.UF
            ? {
                userID: userId,
                projectID: projectid,
                caseID: DefaultUFstore.caseID,
                treatmentObjID: DefaultUFstore.treatmentObjID,
                adjustment: DefaultUFstore.adjustment,
                flux_Filter_actual: calcEngineData?.flux_Filter_actual || "0",
                ufspecialfeatureflag: parseInt(DefaultUFstore.ufspecialfeatureflag),
                ufReport: {
                  ...{
                    method: "default",
                    exportReport: 0,
                    reportType: 3,
                    WaterTypeID: waterTypeID,
                    WaterSubTypeID: waterSubTypeID,
                    TechnologyId: 1,
                    Flow_Design:
                      selectedEndNode == "startNode"
                        ? convertUnitNumber(feedFlowRate, "m³/h", 1)
                        : convertUnitNumber(productFlowRate, "m³/h", 1),
                    Flag_Design_Flow: selectedEndNode == "startNode" ? 0 : 2,
                    Guideline_number: waterSubTypeID?.toString(),
                    Temp_min: StreamStoreData?.tempMin,
                    Temp_design: StreamStoreData?.tempDesign,
                    Temp_max: StreamStoreData?.tempMax,
                    Recovery_Pretreat: ufData.strainerRecovery / 100,
                    Strainer_Size: ufData.strainerSize,
                    Recovery_RO: "0",
                    Feed_coag_name: "FeCl3",
                    Feed_coag_conc: "0",
                    Feed_ox_name: "NaOCl",
                    Feed_ox_conc: "0",
                    N_Part_number: activeUFModule.moduleName,
                    N_Part_number_long: activeUFModule.newModuleLongName,
                    Company: activeUFModule.companyName,
                    Drinking_water_part_names: activeUFModule.drinkingWaterInd.toString().toUpperCase(),
                    IntegraPac: activeUFModule.integraPacInd.toString().toUpperCase(),
                    T_Rack: activeUFModule.tRack.toString().toUpperCase(),
                    Mem_Rack: activeUFModule.memRack.toString().toUpperCase(),
                    IntegraFlo: activeUFModule.integraFlowInd.toString().toUpperCase(),
                    Flux_Filter_target: convertUnitNumber(ufData.filtrateFlux, "LMH", 4),
                    Flux_BW: convertUnitNumber(ufData.backwashFlux, "LMH", 4),
                    Flux_CEB: cebFlag ? convertUnitNumber(ufData.cEBFlux, "LMH", 4) : 0,
                    Flow_FF: convertUnitNumber(ufData.forwardFlushFlow, "m³/h", 1),
                    Flow_FF2: ufData.flow_FF2,
                    Flow_FF3: ufData.flow_FF3,
                    Flow_FF4: ufData.flow_FF4,
                    Flow_AS: convertUnitNumber(ufData.airFlow, "Nm³/h", 18),
                    Flow_AS2: convertUnitNumber(ufData.aerationAirFlow, "Nm³/h", 18),
                    Flow_mCIP_recycle: mcipFlag ? convertUnitNumber(ufData.recycleFlowRate_MiniCIP, "m³/h", 1) : 0,
                    Flow_CIP_recycle: convertUnitNumber(ufData.recycleFlowRate, "m³/h", 1),
                    Flag_cycle_input: ufData.uFBWCEBStandbyOptionID == 1 ? "0" : "1",
                    t_filtration_cycle: ufData.backwash_design,
                    t_interval_AS: ufData.backwash_design,
                    t_interval_CEB_acid: cebFlag ? ufData.acidCEB : 0,
                    t_interval_CEB_caustic: cebFlag ? ufData.alkaliOxidantCEB : 0,
                    t_interval_CEB_Oxidant: "0",
                    t_interval_mCIP: mcipFlag ? ufData.miniCIP : 0,
                    t_interval_CIP: ufData.cIP,
                    t_MIT_module_day: ufData.offlinetimepertrain,
                    TMP_slope_BW: ufData.backwash_Filtration / 1000, //as per excel provided
                    TMP_slope_CEB1: cebFlag ? ufData.acidCEB_Filtration / 1000 : 0, //as per excel provided
                    TMP_slope_CEB2: cebFlag ? ufData.alkaliCEB_Filtration / 1000 : 0, //as per excel provided
                    TMP_slope_mCIP: mcipFlag ? ufData.miniCIP_Filtration / 1000 : 0, //as per excel provided
                    TMP_slope_CIP: ufData.cIP_Filtration / 1000, //as per excel provided
                    Standby_Option:
                      ufData.uFBWCEBStandbyOptionID == 1
                        ? "Constant operating flux, variable system output"
                        : "Constant system output, variable operating flux",
                    Flag_CIP_standby: ufData.uFBWCEBStandbyOptionID == 1 ? 0 : 2,
                    Flag_Storage_Tank: ufData.uFBWCEBStandbyOptionID == 1 ? 1 : 0,
                    N_Trains_online: ufData.onlineTrains,
                    N_Trains_standby: ufData.redundantStandbyTrains,
                    N_Trains_Redundant: ufData.redundantTrains,
                    N_Modules_per_Train: ufData.modulesPerTrain,
                    // N_Modules_per_Train: 34,
                    ...getModuleBasedParams(activeUFModule, ufData),
                    Flag_BW: ufData.uFBWWaterTypeID - 1,
                    Flag_FF: ufData.uFBWFlushWaterTypeID - 1,
                    //Flag_BW_Protocol: ufData.uFBWProtocolID == 1 ? 2 : 0,
                    Flag_BW_Protocol: getBWProtocol(ufData.uFBWProtocolID, activeUFModule),
                    Temp_BW: StreamStoreData?.tempDesign,
                    t_AS: ufData.backwash_AirScour / 60,
                    t_Drain: ufData.drain_backWash / 60,
                    t_BW1: ufData.backWash1_backWash / 60,
                    t_BW2: ufData.backWash2_backWash / 60,
                    t_FF: ufData.forwardFlush_backWash / 60,
                    t_LF: ufData.lF / 60,
                    t_FTL: ufData.t_FTL / 60,
                    BW_ox_name: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                      "symbol",
                      true,
                    ),
                    BW_ox_conc: ufData.oxidantDosage,
                    Temp_CEB: cebFlag ? StreamStoreData?.tempDesign : 0,
                    Flag_CEB: cebFlag ? ufData.uFCEBWaterTypeID : 0,
                    t_AS_CEB: cebFlag ? ufData.ceb_AirScour / 60 : 0,
                    t_Drain_CEB: cebFlag ? ufData.drain / 60 : 0,
                    t_BW1_CEB: cebFlag ? ufData.backWash1_CEB / 60 : 0,
                    t_BW2_CEB: cebFlag ? ufData.backWash2_CEB / 60 : 0,
                    t_FF_CEB: cebFlag ? ufData.forwardFlush / 60 : 0,
                    t_CEB_soak: cebFlag ? ufData.chemicalSoakingDuration_CEB : 0,
                    t_BW1_CEBrinse: cebFlag ? ufData.bwRinseDrainTop / 60 : 0,
                    t_BW2_CEBrinse: cebFlag ? ufData.bwRinseDrainBottom / 60 : 0,
                    N_CEB_RScycles: "1",
                    CEB1_acid_name: cebFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                            "symbol",
                            true,
                          ),
                        )
                      : "0",
                    CEB1_acid_conc: cebFlag ? (ufData.mineralValueInPh_Ind ? 0 : ufData.mineralValue) : 0,
                    CEB1_acid_pH: cebFlag ? (ufData.mineralValueInPh_Ind ? ufData.mineralValue : 0) : 0,
                    CEB1_org_acid_name: cebFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                            "chemicalName",
                            true,
                          ),
                        )
                      : "0",
                    CEB1_org_acid_conc: cebFlag ? ufData.organicValue : 0,
                    Flag_CEB1_Chem: "false",
                    CEB2_base_name: cebFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                            "symbol",
                            true,
                          ),
                        )
                      : "0",
                    CEB2_base_conc: cebFlag ? (ufData.alkaliValueInPh_Ind ? 0 : ufData.alkaliValue) : 0,
                    CEB2_base_pH: cebFlag ? (ufData.alkaliValueInPh_Ind ? ufData.alkaliValue : 0) : 0,
                    CEB2_ox_name: cebFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                            "symbol",
                            true,
                          ),
                        )
                      : "0",
                    CEB2_ox_conc: cebFlag ? ufData.oxidantValue : 0,
                    Flag_CEB2_Chem: "false",
                    CEB3_ox_name: "0",
                    CEB3_ox_conc: "0",
                    Temp_mCIP: mcipFlag ? StreamStoreData?.tempDesign : 0,
                    N_BW_mCIP: mcipFlag ? ufData.bWStepInMiniCIP : 0,
                    N_BW_Rinse_mCIP: mcipFlag ? ufData.rinseBWCycle_MiniCIP : 0,
                    Flag_mCIP: mcipFlag ? ufData.uFMiniCIPWaterTypeID : 0,
                    t_mCIP_heat: mcipFlag ? ufData.heatingStepDuration_MiniCIP : 0,
                    t_mCIP_recycle: mcipFlag ? ufData.recycleDuration_MiniCIP : 0,
                    t_mCIP_soak: mcipFlag ? ufData.chemicalSoakingDuration_MiniCIP : 0,
                    N_mCIP_RScycles: mcipFlag ? ufData.cIPRinseSoakCycle_MiniCIP : 0,
                    mCIP_acid_name: mcipFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                            "symbol",
                            true,
                          ),
                        )
                      : "0",
                    mCIP_acid_conc: mcipFlag
                      ? ufData.mineralValueInPh_Ind_MiniCIP
                        ? 0
                        : ufData.mineralValue_MiniCIP
                      : 0,
                    mCIP_acid_pH: mcipFlag
                      ? ufData.mineralValueInPh_Ind_MiniCIP
                        ? ufData.mineralValue_MiniCIP
                        : 0
                      : 0,
                    mCIP_org_acid_name: mcipFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                            "chemicalName",
                            true,
                          ),
                        )
                      : "0",
                    mCIP_org_acid_conc: mcipFlag ? ufData.organicValue_MiniCIP : 0,
                    Flag_mCIP1_Chem: "false",
                    mCIP_base_name: mcipFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                            "symbol",
                            true,
                          ),
                        )
                      : "0",
                    mCIP_base_conc: mcipFlag
                      ? ufData.alkaliValueInPh_Ind_MiniCIP
                        ? 0
                        : ufData.alkaliValue_MiniCIP
                      : 0,
                    mCIP_base_pH: mcipFlag ? (ufData.alkaliValueInPh_Ind_MiniCIP ? ufData.alkaliValue_MiniCIP : 0) : 0,
                    mCIP_ox_name: mcipFlag
                      ? changeChemicalFormat(
                          validData(
                            chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                            "symbol",
                            true,
                          ),
                        )
                      : "0",
                    mCIP_ox_conc: mcipFlag ? ufData.oxidantValue_MiniCIP : 0,
                    mCIP_SLS_name: "0",
                    mCIP_SLS_conc: "0",
                    Flag_mCIP2_Chem: "false",
                    Temp_CIP: convertUnitNumber(ufData.recycleTemperature, UNITS.celsius, 2),
                    N_BW_CIP: ufData.bWStepInCIP,
                    N_BW_Rinse_CIP: ufData.rinseBWCycle,
                    Flag_CIP: ufData.uFCIPWaterTypeID,
                    t_CIP_heat: ufData.heatingStepDuration,
                    t_CIP_recycle: ufData.recycleDuration,
                    t_CIP_soak: ufData.chemicalSoakingDuration_CIP,
                    N_CIP_RScycles: ufData.cIPRinseSoakCycle,
                    CIP_acid_name: changeChemicalFormat(
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                        "symbol",
                        true,
                      ),
                    ),
                    CIP_acid_conc: ufData.mineralEnabled_Ind_CIP ? 0 : ufData.mineralValue_CIP,
                    CIP_acid_pH: ufData.mineralValueInPh_Ind_CIP ? ufData.mineralValue_CIP : 0,
                    CIP_org_acid_name: changeChemicalFormat(
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                        "chemicalName",
                        true,
                      ),
                    ),
                    CIP_org_acid_conc: ufData.organicValue_CIP,
                    CIP_N_Chem1_Flag: "false",
                    CIP_base_name: changeChemicalFormat(
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                        "symbol",
                        true,
                      ),
                    ),
                    CIP_base_conc: ufData.alkaliValueInPh_Ind_CIP ? 0 : ufData.alkaliValue_CIP,
                    CIP_base_pH: ufData.alkaliValueInPh_Ind_CIP ? ufData.alkaliValue_CIP : 0,
                    CIP_ox_name: changeChemicalFormat(
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                        "symbol",
                        true,
                      ),
                    ),
                    CIP_ox_conc: ufData.oxidantValue_CIP,
                    CIP_SLS_name: changeChemicalFormat(
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                        "symbol",
                        true,
                      ),
                    ),
                    CIP_SLS_conc: ufData.oxidant2Enabled_Ind_CIP ? ufData.oxidant2Value_CIP?.toString() : "0",
                    CIP_SLS_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    CIP_SLS_density: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                      "bulkDensity",
                      false,
                    ),
                    CIP_SLS_price: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                      "bulkPrice",
                      false,
                    ),
                    CIP_N_Chem2_Flag: "false",
                    AdditionalSettingsScreen: "false",
                    P_air_max: convertUnitNumber(ufData.maxAirScourPressure, "bar", 3),
                    P_ADBW_max: convertUnitNumber(ufData.maxAirProcPressure, "bar", 3),
                    P_Filtrate: convertUnitNumber(ufData.filteratePressure, "bar", 3),
                    Delta_P_piping_filtration: convertUnitNumber(ufData.nonIntegraPacTrainPresDrop, "bar", 3),
                    Delta_P_strainer_filtration: convertUnitNumber(ufData.integraPacFiltrationPreDrop, "bar", 3),
                    Delta_P_piping_BW: convertUnitNumber(ufData.backwashPipingPreDrop, "bar", 3),
                    Delta_P_piping_mCIP: mcipFlag ? convertUnitNumber(ufData.cIPPipingPreDrop, "bar", 3) : 0,
                    Delta_P_piping_CIP: convertUnitNumber(ufData.cIPPipingPreDrop, "bar", 3),
                    StorageTankParameters: "0",
                    f_Chem_storage_days: ufData.chemicalStorageTime,
                    f_BW_tank_feed: ufData.bWTankRefillRate / 100,
                    f_Filtrate_tank_safety_margin: ufData.filterateTank / 100,
                    f_BW_tank_safety_margin: ufData.bWTank / 100,
                    f_mCIP_tank: mcipFlag ? ufData.cIPTank / 100 : 0,
                    f_CIP_tank: ufData.cIPTank / 100,
                    f_ADBW: ufData?.aDBWDisplacement / 100,
                    f_FTL: ufData?.fTLDisplacement,
                    N_valves_per_skid: ufData.valvesPerTrain,
                    t_wait: ufData[TYPICAL_WAIT_DURATION[ufData.pUFTechnologyID]] / 60,
                    t_valve: ufData.valveOpenCloseDuration / 60,
                    t_ramp: ufData[TYPICAL_PUMP_RAMP[ufData.pUFTechnologyID]] / 60,
                    Power_PLC: convertUnitNumber(ufData.pLCPowerReqPertrain, "kW", 9),
                    Power_valve: convertUnitNumber(ufData.volvePowerReqPerTrain, "kW", 9),
                    ProjectLevelUserEntries: "0",
                    Eff_motor_feed: pumpCofig.pupmList.find(a => a.pumpID == 53).motorEfficiency,
                    Eff_motor_BW: pumpCofig.pupmList.find(a => a.pumpID == 54).motorEfficiency,
                    Eff_motor_mCIP: mcipFlag ? pumpCofig.pupmList.find(a => a.pumpID == 55).motorEfficiency : 0,
                    Eff_motor_CIP: pumpCofig.pupmList.find(a => a.pumpID == 55).motorEfficiency,
                    Eff_motor_metering: pumpCofig.pupmList.find(a => a.pumpID == 57).motorEfficiency,
                    Eff_motor_compressor: pumpCofig.pupmList.find(a => a.pumpID == 56).motorEfficiency,
                    Eff_pump_feed: pumpCofig.pupmList.find(a => a.pumpID == 53).pumpEfficiency,
                    Eff_pump_BW: pumpCofig.pupmList.find(a => a.pumpID == 54).pumpEfficiency,
                    Eff_pump_mCIP: mcipFlag ? pumpCofig.pupmList.find(a => a.pumpID == 55).pumpEfficiency : 0,
                    Eff_pump_CIP: pumpCofig.pupmList.find(a => a.pumpID == 55).pumpEfficiency,
                    Eff_pump_metering: pumpCofig.pupmList.find(a => a.pumpID == 57).pumpEfficiency,
                    Eff_compressor: pumpCofig.pupmList.find(a => a.pumpID == 56).pumpEfficiency,
                    OperatingCostPrices: "0",
                    Price_Elec: chemicalConfig.operatingCost.electricity,
                    Price_Water: chemicalConfig.operatingCost.rawWater,
                    Price_Waste: chemicalConfig.operatingCost.wasteWaterDisposal,
                    ...calculateData(mcipFlag, cebFlag),
                    N_Chem_CEB1: "1",
                    N_Chem_CEB2: "1",
                    N_Chem_CEB3: "1",
                    N_Chem_CIP: getChemicalCIP(),
                    N_Chem_mCIP: mcipFlag ? getChemicalMiniCIP() : 0,
                    ModuleProperties: "0",
                    Area_Module: activeUFModule.moduleArea,
                    Vol_module: activeUFModule.v,
                    Length_module: activeUFModule.l,
                    Length_fibers: activeUFModule.fiberLength,
                    N_capillary: activeUFModule.bores,
                    N_Capillary_Ends: activeUFModule.ends,
                    D_ID: activeUFModule.dId,
                    D_OD: activeUFModule.dOd,
                    Av: activeUFModule.av,
                    P0: activeUFModule.p0,
                    S0: activeUFModule.s0,
                    S10: activeUFModule.s10,
                    S20: activeUFModule.s20,
                    S30: activeUFModule.s30,
                    S40: activeUFModule.s40,
                    ...getAcidChemDetails(feedDataJson?.pH),
                    Feed_coag_bulk_conc: "0",
                    Feed_coag_density: "0",
                    Feed_coag_price: "0",
                    Feed_ox_bulk_conc: "0",
                    Feed_ox_density: "0",
                    Feed_ox_price: "0",
                    BW_ox_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    BW_ox_density: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                      "bulkDensity",
                      false,
                    ),
                    BW_ox_price: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                      "bulkPrice",
                      false,
                    ),
                    CEB1_acid_bulk_conc: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    CEB1_acid_density: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    CEB1_acid_price: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    CEB1_org_acid_bulk_conc: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                          "bulkConcentration",
                        ) / 100
                      : 0,
                    CEB1_org_acid_density: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    CEB1_org_acid_price: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    CEB2_base_bulk_conc: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    CEB2_base_density: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    CEB2_base_price: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    CEB2_ox_bulk_conc: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    CEB2_ox_density: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    CEB2_ox_price: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    CEB3_ox_bulk_conc: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.disOxidantChemId),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    CEB3_ox_density: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.disOxidantChemId),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    CEB3_ox_price: cebFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.disOxidantChemId),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    mCIP_acid_bulk_conc: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    mCIP_acid_density: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                          "bulkDensity",
                          false,
                        )
                      : "0",
                    mCIP_acid_price: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    mCIP_org_acid_bulk_conc: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    mCIP_org_acid_density: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    mCIP_org_acid_price: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    mCIP_base_bulk_conc: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    mCIP_base_density: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    mCIP_base_price: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    mCIP_ox_bulk_conc: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    mCIP_ox_density: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    mCIP_ox_price: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    mCIP_SLS_bulk_conc: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_MiniCIP),
                          "bulkConcentration",
                          false,
                        ) / 100
                      : 0,
                    mCIP_SLS_density: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_MiniCIP),
                          "bulkDensity",
                          false,
                        )
                      : 0,
                    mCIP_SLS_price: mcipFlag
                      ? validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_MiniCIP),
                          "bulkPrice",
                          false,
                        )
                      : 0,
                    CIP_acid_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    CIP_acid_density: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                      "bulkDensity",
                      false,
                    ),
                    CIP_acid_price: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                      "bulkPrice",
                      false,
                    ),
                    CIP_org_acid_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    CIP_org_acid_density: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                      "bulkDensity",
                      false,
                    ),
                    CIP_org_acid_price: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                      "bulkPrice",
                      false,
                    ),
                    CIP_base_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    CIP_base_density: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                      "bulkDensity",
                      false,
                    ),
                    CIP_base_price: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                      "bulkPrice",
                      false,
                    ),
                    CIP_ox_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    CIP_ox_density: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                      "bulkDensity",
                      false,
                    ),
                    CIP_ox_price: validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                      "bulkPrice",
                      false,
                    ),
                    Citric_Acid_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "Citric Acid(100)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    FeCl3_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "FeCl₃(100)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    H2SO4_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "H₂SO₄(98)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    HCl_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "HCl (32)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    NaOCl_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "NaOCl(12)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    NaOH_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "NaOH (50)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    Oxalic_Acid_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "Oxalic Acid(100)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    PACl_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == "PACl(5)"),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    SLS_bulk_conc:
                      validData(
                        chemicalConfig.chemicalList.find(item => item.displayName == CHEMICAL_DISPLAY_NAMES.SLS),
                        "bulkConcentration",
                        false,
                      ) / 100,
                    Citric_Acid_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "Citric Acid(100)"),
                      "bulkDensity",
                      false,
                    ),
                    FeCl3_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "FeCl₃(100)"),
                      "bulkDensity",
                      false,
                    ),
                    H2SO4_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "H₂SO₄(98)"),
                      "bulkDensity",
                      false,
                    ),
                    HCl_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "HCl (32)"),
                      "bulkDensity",
                      false,
                    ),
                    NaOCl_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "NaOCl(12)"),
                      "bulkDensity",
                      false,
                    ),
                    NaOH_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "NaOH (50)"),
                      "bulkDensity",
                      false,
                    ),
                    Oxalic_Acid_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "Oxalic Acid(100)"),
                      "bulkDensity",
                      false,
                    ),
                    PACl_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "PACl(5)"),
                      "bulkDensity",
                      false,
                    ),
                    SLS_density: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName.includes(CHEMICAL_DISPLAY_NAMES.SLS)),
                      "bulkDensity",
                      false,
                    ),
                    Citric_Acid_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "Citric Acid(100)"),
                      "bulkPrice",
                      false,
                    ),
                    FeCl3_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "FeCl₃(100)"),
                      "bulkPrice",
                      false,
                    ),
                    H2SO4_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "H₂SO₄(98)"),
                      "bulkPrice",
                      false,
                    ),
                    HCl_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "HCl (32)"),
                      "bulkPrice",
                      false,
                    ),
                    NaOCl_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "NaOCl(12)"),
                      "bulkPrice",
                      false,
                    ),
                    NaOH_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "NaOH (50)"),
                      "bulkPrice",
                      false,
                    ),
                    Oxalic_Acid_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "Oxalic Acid(100)"),
                      "bulkPrice",
                      false,
                    ),
                    PACl_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "PACl(5)"),
                      "bulkPrice",
                      false,
                    ),
                    SLS_price: validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == CHEMICAL_DISPLAY_NAMES.SLS),
                      "bulkPrice",
                      false,
                    ),
                    feed_water: {
                      designTemp: feedDataJson?.tempDesign,
                      methodname: "normal",
                      // TOC_System_Feed: parseFloat(feedDataJson?.toc),
                      ph: parseFloat(feedDataJson?.pH),
                      Degas: 0.0,
                      percentage_of_initial_total_CO2_remaining: parseFloat(feedDataJson?.percentContribution),
                      Equilibrate_with: 0.0,
                      Adjustment_Type: 0.0,
                      Add_Reagent: 0.0,
                      Total_CO2: 0.0,
                      turbidity: feedDataJson?.turbidity,
                      organicToc: feedDataJson?.toc,
                      tss: feedDataJson?.tss,
                      tds: feedDataJson?.totalDissolvedSolutes,
                      cations: feedDataJson?.cations,
                      anions: feedDataJson?.anions,
                      neutrals: feedDataJson?.neutral,
                      LSI_targ: 0,
                      SDI_targ: 0,
                      ChemicalAdjustment: getFeedChemicalAdjustment(),
                    },
                  },
                  WaterTypeID: waterTypeID,
                  WaterSubTypeID: waterSubTypeID,
                  TechnologyId: 1,
                  Flow_Design:
                    selectedEndNode == "startNode"
                      ? convertUnitNumber(feedFlowRate, "m³/h", 1)
                      : convertUnitNumber(productFlowRate, "m³/h", 1),
                  Flag_Design_Flow: selectedEndNode == "startNode" ? 0 : 2,
                  Guideline_number: DefaultUFstore.ufReport?.Guideline_number,
                  Temp_min: DefaultUFstore.ufReport?.Temp_min,
                  Temp_design: DefaultUFstore.ufReport?.Temp_design,
                  Temp_max: DefaultUFstore.ufReport?.Temp_max,
                  feed_water: DefaultUFstore.ufReport?.feed_water,
                },
                ...userData,
              }
            : {
                userID: userId,
                projectID: projectid,
                caseID: ProjectInfoStore?.caseId,
                treatmentObjID: 1,
                flux_Filter_actual: calcEngineData?.flux_Filter_actual || "0",
                ufspecialfeatureflag: parseInt(ufData.ufSpecialFeatureID),
                adjustment: adjustment || undefined,
                ufReport: {
                  method: "default",
                  exportReport: 0,
                  reportType: 3,
                  WaterTypeID: waterTypeID,
                  WaterSubTypeID: waterSubTypeID,
                  TechnologyId: 1,
                  Flow_Design:
                    selectedEndNode == "startNode"
                      ? convertUnitNumber(feedFlowRate, "m³/h", 1)
                      : convertUnitNumber(productFlowRate, "m³/h", 1),
                  Flag_Design_Flow: selectedEndNode == "startNode" ? 0 : 2,
                  Guideline_number: waterSubTypeID?.toString(),
                  Temp_min: StreamStoreData?.tempMin,
                  Temp_design: StreamStoreData?.tempDesign,
                  Temp_max: StreamStoreData?.tempMax,
                  Recovery_Pretreat: ufData.strainerRecovery / 100,
                  Strainer_Size: ufData.strainerSize,
                  Recovery_RO: "0",
                  Feed_coag_name: "FeCl3",
                  Feed_coag_conc: "0",
                  Feed_ox_name: "NaOCl",
                  Feed_ox_conc: "0",
                  N_Part_number: activeUFModule.moduleName,
                  N_Part_number_long: activeUFModule.newModuleLongName,
                  Company: activeUFModule.companyName,
                  Drinking_water_part_names: activeUFModule.drinkingWaterInd.toString().toUpperCase(),
                  IntegraPac: activeUFModule.integraPacInd.toString().toUpperCase(),
                  T_Rack: activeUFModule.tRack.toString().toUpperCase(),
                  Mem_Rack: activeUFModule.memRack.toString().toUpperCase(),
                  IntegraFlo: activeUFModule.integraFlowInd.toString().toUpperCase(),
                  Flux_Filter_target: convertUnitNumber(ufData.filtrateFlux, "LMH", 4),
                  Flux_BW: convertUnitNumber(ufData.backwashFlux, "LMH", 4),
                  Flux_CEB: cebFlag ? convertUnitNumber(ufData.cEBFlux, "LMH", 4) : 0,
                  Flow_FF: convertUnitNumber(ufData.forwardFlushFlow, "m³/h", 1),
                  Flow_FF2: ufData.flow_FF2,
                  Flow_FF3: ufData.flow_FF3,
                  Flow_FF4: ufData.flow_FF4,
                  Flow_AS: convertUnitNumber(ufData.airFlow, "Nm³/h", 18),
                  Flow_AS2: convertUnitNumber(ufData.aerationAirFlow, "Nm³/h", 18),
                  Flow_mCIP_recycle: mcipFlag ? convertUnitNumber(ufData.recycleFlowRate_MiniCIP, "m³/h", 1) : 0,
                  Flow_CIP_recycle: convertUnitNumber(ufData.recycleFlowRate, "m³/h", 1),
                  Flag_cycle_input: ufData.uFBWCEBStandbyOptionID == 1 ? "0" : "1",
                  t_filtration_cycle: ufData.backwash_design,
                  t_interval_AS: ufData.backwash_design,
                  t_interval_CEB_acid: cebFlag ? ufData.acidCEB : 0,
                  t_interval_CEB_caustic: cebFlag ? ufData.alkaliOxidantCEB : 0,
                  t_interval_CEB_Oxidant: "0",
                  t_interval_mCIP: mcipFlag ? ufData.miniCIP : 0,
                  t_interval_CIP: ufData.cIP,
                  t_MIT_module_day: ufData.offlinetimepertrain,
                  TMP_slope_BW: ufData.backwash_Filtration / 1000, //as per excel provided
                  TMP_slope_CEB1: cebFlag ? ufData.acidCEB_Filtration / 1000 : 0, //as per excel provided
                  TMP_slope_CEB2: cebFlag ? ufData.alkaliCEB_Filtration / 1000 : 0, //as per excel provided
                  TMP_slope_mCIP: mcipFlag ? ufData.miniCIP_Filtration / 1000 : 0, //as per excel provided
                  TMP_slope_CIP: ufData.cIP_Filtration / 1000, //as per excel provided
                  Standby_Option:
                    ufData.uFBWCEBStandbyOptionID == 1
                      ? "Constant operating flux, variable system output"
                      : "Constant system output, variable operating flux",
                  Flag_CIP_standby: ufData.uFBWCEBStandbyOptionID == 1 ? 0 : 2,
                  Flag_Storage_Tank: ufData.uFBWCEBStandbyOptionID == 1 ? 1 : 0,
                  N_Trains_online: ufData.onlineTrains,
                  N_Trains_standby: ufData.redundantStandbyTrains,
                  N_Trains_Redundant: ufData.redundantTrains,
                  N_Modules_per_Train: ufData.modulesPerTrain,
                  ...getModuleBasedParams(activeUFModule, ufData),
                  Flag_BW: ufData.uFBWWaterTypeID - 1,
                  Flag_FF: ufData.uFBWFlushWaterTypeID - 1,
                  Flag_BW_Protocol: getBWProtocol(ufData.uFBWProtocolID, activeUFModule),
                  Temp_BW: StreamStoreData?.tempDesign,
                  t_AS: ufData.backwash_AirScour / 60,
                  t_Drain: ufData.drain_backWash / 60,
                  t_BW1: ufData.backWash1_backWash / 60,
                  t_BW2: ufData.backWash2_backWash / 60,
                  t_FF: ufData.forwardFlush_backWash / 60,
                  t_LF: ufData.lF / 60,
                  t_FTL: ufData.t_FTL / 60,
                  BW_ox_name: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                    "symbol",
                    true,
                  ),
                  BW_ox_conc: ufData.oxidantDosage,
                  Temp_CEB: cebFlag ? StreamStoreData?.tempDesign : 0,
                  Flag_CEB: cebFlag ? ufData.uFCEBWaterTypeID : 0,
                  t_AS_CEB: cebFlag ? ufData.ceb_AirScour / 60 : 0,
                  t_Drain_CEB: cebFlag ? ufData.drain / 60 : 0,
                  t_BW1_CEB: cebFlag ? ufData.backWash1_CEB / 60 : 0,
                  t_BW2_CEB: cebFlag ? ufData.backWash2_CEB / 60 : 0,
                  t_FF_CEB: cebFlag ? ufData.forwardFlush / 60 : 0,
                  t_CEB_soak: cebFlag ? ufData.chemicalSoakingDuration_CEB : 0,
                  t_BW1_CEBrinse: cebFlag ? ufData.bwRinseDrainTop / 60 : 0,
                  t_BW2_CEBrinse: cebFlag ? ufData.bwRinseDrainBottom / 60 : 0,
                  N_CEB_RScycles: "1",
                  CEB1_acid_name: cebFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                          "symbol",
                          true,
                        ),
                      )
                    : "0",
                  CEB1_acid_conc: cebFlag ? (ufData.mineralValueInPh_Ind ? 0 : ufData.mineralValue) : 0,
                  CEB1_acid_pH: cebFlag ? (ufData.mineralValueInPh_Ind ? ufData.mineralValue : 0) : 0,
                  CEB1_org_acid_name: cebFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                          "chemicalName",
                          true,
                        ),
                      )
                    : "0",
                  CEB1_org_acid_conc: cebFlag ? ufData.organicValue : 0,
                  Flag_CEB1_Chem: "false",
                  CEB2_base_name: cebFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                          "symbol",
                          true,
                        ),
                      )
                    : "0",
                  CEB2_base_conc: cebFlag ? (ufData.alkaliValueInPh_Ind ? 0 : ufData.alkaliValue) : 0,
                  CEB2_base_pH: cebFlag ? (ufData.alkaliValueInPh_Ind ? ufData.alkaliValue : 0) : 0,
                  CEB2_ox_name: cebFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                          "symbol",
                          true,
                        ),
                      )
                    : "0",
                  CEB2_ox_conc: cebFlag ? ufData.oxidantValue : 0,
                  Flag_CEB2_Chem: "false",
                  CEB3_ox_name: "0",
                  CEB3_ox_conc: "0",
                  Temp_mCIP: mcipFlag ? StreamStoreData?.tempDesign : 0,
                  N_BW_mCIP: mcipFlag ? ufData.bWStepInMiniCIP : 0,
                  N_BW_Rinse_mCIP: mcipFlag ? ufData.rinseBWCycle_MiniCIP : 0,
                  Flag_mCIP: mcipFlag ? ufData.uFMiniCIPWaterTypeID : 0,
                  t_mCIP_heat: mcipFlag ? ufData.heatingStepDuration_MiniCIP : 0,
                  t_mCIP_recycle: mcipFlag ? ufData.recycleDuration_MiniCIP : 0,
                  t_mCIP_soak: mcipFlag ? ufData.chemicalSoakingDuration_MiniCIP : 0,
                  N_mCIP_RScycles: mcipFlag ? ufData.cIPRinseSoakCycle_MiniCIP : 0,
                  mCIP_acid_name: mcipFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                          "symbol",
                          true,
                        ),
                      )
                    : "0",
                  mCIP_acid_conc: mcipFlag
                    ? ufData.mineralValueInPh_Ind_MiniCIP
                      ? 0
                      : ufData.mineralValue_MiniCIP
                    : 0,
                  mCIP_acid_pH: mcipFlag ? (ufData.mineralValueInPh_Ind_MiniCIP ? ufData.mineralValue_MiniCIP : 0) : 0,
                  mCIP_org_acid_name: mcipFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                          "chemicalName",
                          true,
                        ),
                      )
                    : "0",
                  mCIP_org_acid_conc: mcipFlag ? ufData.organicValue_MiniCIP : 0,
                  Flag_mCIP1_Chem: "false",
                  mCIP_base_name: mcipFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                          "symbol",
                          true,
                        ),
                      )
                    : "0",
                  mCIP_base_conc: mcipFlag ? (ufData.alkaliValueInPh_Ind_MiniCIP ? 0 : ufData.alkaliValue_MiniCIP) : 0,
                  mCIP_base_pH: mcipFlag ? (ufData.alkaliValueInPh_Ind_MiniCIP ? ufData.alkaliValue_MiniCIP : 0) : 0,
                  mCIP_ox_name: mcipFlag
                    ? changeChemicalFormat(
                        validData(
                          chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                          "symbol",
                          true,
                        ),
                      )
                    : "0",
                  mCIP_ox_conc: mcipFlag ? ufData.oxidantValue_MiniCIP : 0,
                  mCIP_SLS_name: "0",
                  mCIP_SLS_conc: "0",
                  Flag_mCIP2_Chem: "false",
                  Temp_CIP: convertUnitNumber(ufData.recycleTemperature, UNITS.celsius, 2),
                  N_BW_CIP: ufData.bWStepInCIP,
                  N_BW_Rinse_CIP: ufData.rinseBWCycle,
                  Flag_CIP: ufData.uFCIPWaterTypeID,
                  t_CIP_heat: ufData.heatingStepDuration,
                  t_CIP_recycle: ufData.recycleDuration,
                  t_CIP_soak: ufData.chemicalSoakingDuration_CIP,
                  N_CIP_RScycles: ufData.cIPRinseSoakCycle,
                  CIP_acid_name: changeChemicalFormat(
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                      "symbol",
                      true,
                    ),
                  ),
                  CIP_acid_conc: ufData.mineralValueInPh_Ind_CIP ? 0 : ufData.mineralValue_CIP,
                  CIP_acid_pH:
                    ufData.mineralValueInPh_Ind_CIP && ufData.mineralEnabled_Ind_CIP ? ufData.mineralValue_CIP : 0,
                  CIP_org_acid_name: changeChemicalFormat(
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                      "chemicalName",
                      true,
                    ),
                  ),
                  CIP_org_acid_conc: ufData.organicValue_CIP,
                  CIP_N_Chem1_Flag: "false",
                  CIP_base_name: changeChemicalFormat(
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                      "symbol",
                      true,
                    ),
                  ),
                  CIP_base_conc: ufData.alkaliValueInPh_Ind_CIP ? 0 : ufData.alkaliValue_CIP,
                  CIP_base_pH: ufData.alkaliValueInPh_Ind_CIP ? ufData.alkaliValue_CIP : 0,
                  CIP_ox_name: changeChemicalFormat(
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                      "symbol",
                      true,
                    ),
                  ),
                  CIP_ox_conc: ufData.oxidantValue_CIP,
                  CIP_SLS_name: changeChemicalFormat(
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                      "symbol",
                      true,
                    ),
                  ),
                  CIP_SLS_conc: ufData.oxidant2Enabled_Ind_CIP ? ufData.oxidant2Value_CIP?.toString() : "0",
                  CIP_N_Chem2_Flag: "false",
                  AdditionalSettingsScreen: "false",
                  P_air_max: convertUnitNumber(ufData.maxAirScourPressure, "bar", 3),
                  P_ADBW_max: convertUnitNumber(ufData.maxAirProcPressure, "bar", 3),
                  P_Filtrate: convertUnitNumber(ufData.filteratePressure, "bar", 3),
                  Delta_P_piping_filtration: convertUnitNumber(ufData.nonIntegraPacTrainPresDrop, "bar", 3),
                  Delta_P_strainer_filtration: convertUnitNumber(ufData.integraPacFiltrationPreDrop, "bar", 3),
                  Delta_P_piping_BW: convertUnitNumber(ufData.backwashPipingPreDrop, "bar", 3),
                  Delta_P_piping_mCIP: mcipFlag ? convertUnitNumber(ufData.cIPPipingPreDrop, "bar", 3) : 0,
                  Delta_P_piping_CIP: convertUnitNumber(ufData.cIPPipingPreDrop, "bar", 3),
                  StorageTankParameters: "0",
                  f_Chem_storage_days: ufData.chemicalStorageTime,
                  f_BW_tank_feed: ufData.bWTankRefillRate / 100,
                  f_Filtrate_tank_safety_margin: ufData.filterateTank / 100,
                  f_BW_tank_safety_margin: ufData.bWTank / 100,
                  f_mCIP_tank: mcipFlag ? ufData.cIPTank / 100 : 0,
                  f_CIP_tank: ufData.cIPTank / 100,
                  f_ADBW: ufData?.aDBWDisplacement / 100,
                  f_FTL: ufData?.fTLDisplacement,
                  N_valves_per_skid: ufData.valvesPerTrain,
                  t_wait: ufData[TYPICAL_WAIT_DURATION[ufData.pUFTechnologyID]] / 60,
                  t_valve: ufData.valveOpenCloseDuration / 60,
                  t_ramp: ufData[TYPICAL_PUMP_RAMP[ufData.pUFTechnologyID]] / 60,
                  Power_PLC: convertUnitNumber(ufData.pLCPowerReqPertrain, "kW", 9),
                  Power_valve: convertUnitNumber(ufData.volvePowerReqPerTrain, "kW", 9),
                  ProjectLevelUserEntries: "0",
                  Eff_motor_feed: pumpCofig.pupmList.find(a => a.pumpID == 53).motorEfficiency,
                  Eff_motor_BW: pumpCofig.pupmList.find(a => a.pumpID == 54).motorEfficiency,
                  Eff_motor_mCIP: mcipFlag ? pumpCofig.pupmList.find(a => a.pumpID == 55).motorEfficiency : 0,
                  Eff_motor_CIP: pumpCofig.pupmList.find(a => a.pumpID == 55).motorEfficiency,
                  Eff_motor_metering: pumpCofig.pupmList.find(a => a.pumpID == 57).motorEfficiency,
                  Eff_motor_compressor: pumpCofig.pupmList.find(a => a.pumpID == 56).motorEfficiency,
                  Eff_pump_feed: pumpCofig.pupmList.find(a => a.pumpID == 53).pumpEfficiency,
                  Eff_pump_BW: pumpCofig.pupmList.find(a => a.pumpID == 54).pumpEfficiency,
                  Eff_pump_mCIP: mcipFlag ? pumpCofig.pupmList.find(a => a.pumpID == 55).pumpEfficiency : 0,
                  Eff_pump_CIP: pumpCofig.pupmList.find(a => a.pumpID == 55).pumpEfficiency,
                  Eff_pump_metering: pumpCofig.pupmList.find(a => a.pumpID == 57).pumpEfficiency,
                  Eff_compressor: pumpCofig.pupmList.find(a => a.pumpID == 56).pumpEfficiency,
                  OperatingCostPrices: "0",
                  Price_Elec: chemicalConfig.operatingCost.electricity,
                  Price_Water: chemicalConfig.operatingCost.rawWater,
                  Price_Waste: chemicalConfig.operatingCost.wasteWaterDisposal,
                  ...calculateData(mcipFlag, cebFlag),
                  N_Chem_CEB1: "1",
                  N_Chem_CEB2: "1",
                  N_Chem_CEB3: "1",
                  N_Chem_CIP: getChemicalCIP(),
                  N_Chem_mCIP: mcipFlag ? getChemicalMiniCIP() : 0,
                  ModuleProperties: "0",
                  Area_Module: activeUFModule.moduleArea,
                  Vol_module: activeUFModule.v,
                  Length_module: activeUFModule.l,
                  Length_fibers: activeUFModule.fiberLength,
                  N_capillary: activeUFModule.bores,
                  N_Capillary_Ends: activeUFModule.ends,
                  D_ID: activeUFModule.dId,
                  D_OD: activeUFModule.dOd,
                  Av: activeUFModule.av,
                  P0: activeUFModule.p0,
                  S0: activeUFModule.s0,
                  S10: activeUFModule.s10,
                  S20: activeUFModule.s20,
                  S30: activeUFModule.s30,
                  S40: activeUFModule.s40,
                  ...getAcidChemDetails(feedDataJson?.pH),
                  Feed_coag_bulk_conc: "0",
                  Feed_coag_density: "0",
                  Feed_coag_price: "0",
                  Feed_ox_bulk_conc: "0",
                  Feed_ox_density: "0",
                  Feed_ox_price: "0",
                  BW_ox_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  BW_ox_density: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                    "bulkDensity",
                    false,
                  ),
                  BW_ox_price: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantID),
                    "bulkPrice",
                    false,
                  ),
                  CEB1_acid_bulk_conc: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  CEB1_acid_density: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  CEB1_acid_price: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  CEB1_org_acid_bulk_conc: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                        "bulkConcentration",
                      ) / 100
                    : 0,
                  CEB1_org_acid_density: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  CEB1_org_acid_price: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  CEB2_base_bulk_conc: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  CEB2_base_density: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  CEB2_base_price: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  CEB2_ox_bulk_conc: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  CEB2_ox_density: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  CEB2_ox_price: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  CEB3_ox_bulk_conc: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.disOxidantChemId),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  CEB3_ox_density: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.disOxidantChemId),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  CEB3_ox_price: cebFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.disOxidantChemId),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  mCIP_acid_bulk_conc: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  mCIP_acid_density: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  mCIP_acid_price: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_MiniCIP),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  mCIP_org_acid_bulk_conc: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  mCIP_org_acid_density: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  mCIP_org_acid_price: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_MiniCIP),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  mCIP_base_bulk_conc: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  mCIP_base_density: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  mCIP_base_price: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_MiniCIP),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  mCIP_ox_bulk_conc: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  mCIP_ox_density: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  mCIP_ox_price: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_MiniCIP),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  mCIP_SLS_bulk_conc: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_MiniCIP),
                        "bulkConcentration",
                        false,
                      ) / 100
                    : 0,
                  mCIP_SLS_density: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_MiniCIP),
                        "bulkDensity",
                        false,
                      )
                    : 0,
                  mCIP_SLS_price: mcipFlag
                    ? validData(
                        chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_MiniCIP),
                        "bulkPrice",
                        false,
                      )
                    : 0,
                  CIP_acid_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  CIP_acid_density: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                    "bulkDensity",
                    false,
                  ),
                  CIP_acid_price: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.mineralChemId_CIP),
                    "bulkPrice",
                    false,
                  ),
                  CIP_org_acid_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  CIP_org_acid_density: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                    "bulkDensity",
                    false,
                  ),
                  CIP_org_acid_price: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.organicChemId_CIP),
                    "bulkPrice",
                    false,
                  ),
                  CIP_base_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  CIP_base_density: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                    "bulkDensity",
                    false,
                  ),
                  CIP_base_price: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.alkaliChemId_CIP),
                    "bulkPrice",
                    false,
                  ),
                  CIP_ox_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  CIP_ox_density: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                    "bulkDensity",
                    false,
                  ),
                  CIP_ox_price: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidantChemId_CIP),
                    "bulkPrice",
                    false,
                  ),
                  CIP_SLS_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  CIP_SLS_density: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                    "bulkDensity",
                    false,
                  ),
                  CIP_SLS_price: validData(
                    chemicalConfig.chemicalList.find(item => item.iD == ufData.oxidant2ChemId_CIP),
                    "bulkPrice",
                    false,
                  ),
                  Citric_Acid_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "Citric Acid(100)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  FeCl3_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "FeCl₃(100)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  H2SO4_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "H₂SO₄(98)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  HCl_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "HCl (32)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  NaOCl_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "NaOCl(12)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  NaOH_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "NaOH (50)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  Oxalic_Acid_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "Oxalic Acid(100)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  PACl_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == "PACl(5)"),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  SLS_bulk_conc:
                    validData(
                      chemicalConfig.chemicalList.find(item => item.displayName == CHEMICAL_DISPLAY_NAMES.SLS),
                      "bulkConcentration",
                      false,
                    ) / 100,
                  Citric_Acid_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "Citric Acid(100)"),
                    "bulkDensity",
                    false,
                  ),
                  FeCl3_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "FeCl₃(100)"),
                    "bulkDensity",
                    false,
                  ),
                  H2SO4_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "H₂SO₄(98)"),
                    "bulkDensity",
                    false,
                  ),
                  HCl_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "HCl (32)"),
                    "bulkDensity",
                    false,
                  ),
                  NaOCl_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "NaOCl(12)"),
                    "bulkDensity",
                    false,
                  ),
                  NaOH_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "NaOH (50)"),
                    "bulkDensity",
                    false,
                  ),
                  Oxalic_Acid_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "Oxalic Acid(100)"),
                    "bulkDensity",
                    false,
                  ),
                  PACl_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "PACl(5)"),
                    "bulkDensity",
                    false,
                  ),
                  SLS_density: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == CHEMICAL_DISPLAY_NAMES.SLS),
                    "bulkDensity",
                    false,
                  ),
                  Citric_Acid_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "Citric Acid(100)"),
                    "bulkPrice",
                    false,
                  ),
                  FeCl3_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "FeCl₃(100)"),
                    "bulkPrice",
                    false,
                  ),
                  H2SO4_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "H₂SO₄(98)"),
                    "bulkPrice",
                    false,
                  ),
                  HCl_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "HCl (32)"),
                    "bulkPrice",
                    false,
                  ),
                  NaOCl_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "NaOCl(12)"),
                    "bulkPrice",
                    false,
                  ),
                  NaOH_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "NaOH (50)"),
                    "bulkPrice",
                    false,
                  ),
                  Oxalic_Acid_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "Oxalic Acid(100)"),
                    "bulkPrice",
                    false,
                  ),
                  PACl_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == "PACl(5)"),
                    "bulkPrice",
                    false,
                  ),
                  SLS_price: validData(
                    chemicalConfig.chemicalList.find(item => item.displayName == CHEMICAL_DISPLAY_NAMES.SLS),
                    "bulkPrice",
                    false,
                  ),
                  feed_water: {
                    designTemp: feedDataJson?.tempDesign,
                    methodname: "normal",
                    // TOC_System_Feed: parseFloat(feedDataJson?.toc),
                    ph: parseFloat(feedDataJson?.pH),
                    Degas: 0.0,
                    percentage_of_initial_total_CO2_remaining: parseFloat(feedDataJson?.percentContribution),
                    Equilibrate_with: 0.0,
                    Adjustment_Type: 0.0,
                    Add_Reagent: 0.0,
                    Total_CO2: 0.0,
                    turbidity: feedDataJson?.turbidity,
                    organicToc: feedDataJson?.toc,
                    tss: feedDataJson?.tss,
                    tds: feedDataJson?.totalDissolvedSolutes,
                    cations: feedDataJson?.cations,
                    anions: feedDataJson?.anions,
                    neutrals: feedDataJson?.neutral,
                    LSI_targ: 0,
                    SDI_targ: 0,
                    ChemicalAdjustment: getFeedChemicalAdjustment(),
                  },
                },
                ...userData,
              };
    const payload = {
      ...Myobject,
      ufReport: getUpdatedUFReportPayload(Myobject.ufReport, chemicalAdjustedPH),
    };

    //Calling this API to save Report JSON data
    const { caseID: caseId } = payload;
    const saveUFReportPayload = {
      Method: "uf/api/v1/UFReportJSON",
      userId: userId,
      projectId: projectid,
      caseId,
      ufData: { ...payload, activeModueID: newActiveModueID, ufRecovery: defaultRecovery.UF },
    };
    postUFJsonReport(saveUFReportPayload);

    if (isGenerateRepoprt) {
      const MethodName = { Method: "uf/api/v1/UFDetailedReport" };
      const UFDetailReport = { ...MethodName, ...payload };
      const postResponse = await POSTUFJsonData(UFDetailReport);
      if (postResponse.data) {
        dispatch(setReportData(postResponse.data));
      }
    }
  };
  //----------------------------**UF Report Section END**----------------------------------------

  const cationValues =
    FeedStoreData && FeedStoreData[0]?.cations?.filter(item => item.mgL > 0 || item.mgL > 0 || item.mgL > 0);
  const anionValues =
    FeedStoreData && FeedStoreData[0]?.anions?.filter(item => item.mgL > 0 || item.mgL > 0 || item.mgL > 0);
  const neutralsValues =
    FeedStoreData && FeedStoreData[0]?.anions?.filter(item => item.mgL > 0 || item.mgL > 0 || item.mgL > 0);

  useEffect(() => {
    setSelectedIndex("System Design");
    setPanelIndex(0);
    setCurrentPanel("System Design");
    //fetching metadata's for UF Screen.
    callDesignDataAPI();
    callConfigurationDataAPI();
    callBackWashDataAPI();
  }, [projectid]);

  const callDesignDataAPI = () => {
    if (!isUFDesignDataFetched) {
      const UFDesignDataURL = `${"uf/api/v1/DesignData"}?userID=${loggedInUserID}&projectID=${projectid}`;
      getDesignData(UFDesignDataURL);
    }
  };
  const callConfigurationDataAPI = () => {
    getConfigurationData(`uf/api/v${1}/Configuration?userID=${loggedInUserID}&projectID=${projectid}&languageID=1`);
  };
  const callBackWashDataAPI = () => {
    if (!isUFBWDataFetched) {
      getBackWashData(`uf/api/v${1}/UFBackwash?userID=${loggedInUserID}&projectID=${projectid}`);
    }
  };

  useEffect(() => {
    dispatch(setTabData(selectedIndex));
  }, [selectedIndex]);

  useEffect(() => {
    if (responseDesignData?.isSuccess) {
      const {
        listModules,
        listUfField,
        lstUFGuidelineVM,
        lstUfmoduleFlowVM: ufModuleFlowVM,
        lstUFFluxGuideline: ufFluxGuideline,
        lstUFTargetPHGuideline: ufPHGuidline,
        lstUFTargetDoseGuideline: ufDoseGuidline,
        lstUFModulePressureRatingVM: ufModulePressureRatingVM,
      } = responseDesignData?.data || {};

      setUFModules(listModules);

      const ufInputRangeConfig = formatUFFields(listUfField);
      const ufInputRangeConfigByWaterType = formatUFFields(lstUFGuidelineVM);
      const isUFDesignDataFetched = true;

      const ufData = {
        ufPHGuidline,
        ufModuleFlowVM,
        ufDoseGuidline,
        ufFluxGuideline,
        ufInputRangeConfig,
        ufModulePressureRatingVM,
        ufInputRangeConfigByWaterType,
        isUFDesignDataFetched,
      };

      dispatch(updateUFStoreData(ufData));
    } else if (responseDesignData?.isError) {
      throw new MyError("DesignData Api Error", responseDesignData.error.status, "ApiError");
    }
  }, [responseDesignData]);

  useEffect(() => {
    if (responseConfigData.isSuccess) {
      const { standbyOptions, storageTankOptions } = responseConfigData.data;
      dispatch(updateUFStandByOptions(standbyOptions));
      dispatch(updateUFStorageTankOptions(storageTankOptions));
    }
    if (responseConfigData.isError) {
      throw new MyError(responseConfigData.error.message, responseConfigData.error.status, "ApiError");
    }
  }, [responseConfigData]);

  useEffect(() => {
    if (responseBWData.isSuccess) {
      const { data } = responseBWData;
      const bwData = {
        bwProtocol: data.lstUFBWProtocolVMs,
        oxidantChemicals: data.lstUFChemicalVM,
        bwWaterSource: data.lstUFBWWaterTypeVMs,
        forwardFlushWaterSource: data.lstUFBWFlushWaterTypeVMs,
        isUFBWDataFetched: true,
      };
      dispatch(updateUFStoreData(bwData));
    }
  }, [responseBWData]);

  useEffect(() => {
    if (responseUFDetails.isLoading) {
      console.log("Loading UF Details...");
    } else {
      if (responseUFDetails.isSuccess === true) {
        const obj = { ...UFStore.data };
        const UFInputKeys = Object.keys(responseUFDetails.data);
        UFInputKeys?.map(x => {
          obj[x] = responseUFDetails.data[x];
          if (obj[x] === null) {
            obj[x] = "0";
          }
        });
        obj.userID = userId;
        obj.projectID = projectID;
        obj.caseID = caseID;
        if (responseUFDetails.data.uFBWCEBStandbyOptionID == 0) {
          obj.uFBWCEBStandbyOptionID = 1;
        }
        dispatch(updateUFStore(obj));
      }
    }
    if (responseUFDetails.isError) {
      throw new MyError("UFDetails Api Error", responseUFDetails.error.status, "ApiError");
    }
  }, [responseUFDetails]);

  const formatUFFields = ufFieldsArray => {
    const fieldToValueMap = [];
    ufFieldsArray?.map(f => {
      if (
        f.guideLineAbbr === "Backwash TMP Increase" ||
        f.guideLineAbbr === "Acid CEB TMP Increase" ||
        f.guideLineAbbr === "Alkali CEB TMP increase" ||
        f.guideLineAbbr === "CIP TMP increase" ||
        f.guideLineAbbr === "Mini-CIP"
      ) {
        if (f.uom == "bar/h" || f.uom == "bar/hr") {
          fieldToValueMap.push({
            label: f.guideLineAbbr,
            defaultValue: f.typicalValue * 1000,
            minValue: f.minValue * 1000,
            maxValue: f.maxValue * 1000,
            softLowerLimit: f.softLowerLimit ? f.softLowerLimit : f.minValue,
            softUpperLimit: f.softUpperLimit ? f.softUpperLimit : f.maxValue,
            uom: "mbar/h",
            waterSubType: f.waterSubTypeId != undefined ? f.waterSubTypeId : "",
          });
        } else {
          fieldToValueMap.push({
            label: f.guideLineAbbr,
            defaultValue: f.typicalValue,
            minValue: f.minValue,
            maxValue: f.maxValue,
            softLowerLimit: f.softLowerLimit ? f.softLowerLimit : f.minValue,
            softUpperLimit: f.softUpperLimit ? f.softUpperLimit : f.maxValue,
            uom: f.uom,
            waterSubType: f.waterSubTypeId != undefined ? f.waterSubTypeId : "",
          });
        }
      } else {
        fieldToValueMap.push({
          label: f.guideLineAbbr,
          defaultValue: f.typicalValue,
          minValue: f.minValue,
          maxValue: f.maxValue,
          softLowerLimit: f.softLowerLimit ? f.softLowerLimit : f.minValue,
          softUpperLimit: f.softUpperLimit ? f.softUpperLimit : f.maxValue,
          uom: f.uom,
          waterSubType: f.waterSubTypeId != undefined ? f.waterSubTypeId : "",
        });
      }
    });
    return fieldToValueMap;
  };

  // Auto save functionality------------------------------------------------------------------------------
  const updateDataFunction = async () => {
    await updateData({
      Method: "masterdata/api/v1/SystemDesign",
      feedFlow: selectedEndNode != "startNode",
      flowValue:
        selectedEndNode == "startNode"
          ? Number(
              GlobalUnitConversion(GlobalUnitConversionStore, feedFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(2),
            )
          : Number(
              GlobalUnitConversion(GlobalUnitConversionStore, productFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(
                2,
              ),
            ),
      caseID: feedWaterData.caseID,
      projectID: projectid,
      waterTypeID: feedWaterData.waterTypeID,
      userID: userId,
      processMap: { nodes: nodes, edges: edges },
      lstTechnologyLists,
    });
  };

  const updateFeedsetDataFunction = (tab, index) => {
    const num = FeedStreamData.lstrequestsavefeedwater[0]?.streams[0]?.chargeBalance;
    const decimalStr = num?.toString().split(".");
    if (!decimalStr) return;
    const decimalValue = Number(decimalStr[1]?.toString().substring(0, 4));
    const numberValue = Number(decimalStr[0]);
    if (decimalValue == 0 && numberValue == 0) {
      if (FeedStreamData?.Method) {
        updateFeedsetupData(FeedStreamData);
      }
      if (
        (tab.heading === "IXD" || tab.heading == "IXMB") &&
        cationValues.length === 0 &&
        anionValues.length === 0 &&
        neutralsValues.length === 0
      ) {
        setIsFeedError(true);
        setSelectedIndex("Feed Setup");
        setPanelIndex(1);
        setCurrentPanel("Feed Setup");
        setScrollCheck(!scrollCheck);
        return false;
      } else {
        changeTabsControler(tab, index);
        return true;
      }
    } else {
      setisFeedsetupError(true);
      setSelectedIndex("Feed Setup");
      setPanelIndex(1);
      setCurrentPanel("Feed Setup");
      setScrollCheck(!scrollCheck);
      return false;
    }
  };

  const closeErrorMessag = () => {
    resetVesselCylindricalHeightData();
    setErrorOperator({ show: false, message: "" });
  };

  const showError = message => {
    setErrorOperator({ show: true, message });
  };

  const checkChemicalError = () => {
    const isUF = systemDesignCaseTreatmentVM?.find(item => item.treatmentName === TECH_UF) || false;
    if (!isUF || !tabAvailableForUF.UF) {
      return true;
    }
    const chemicalErrMsg = getChemicalErrorMsg({ ufData, ufCompany });
    if (chemicalErrMsg) {
      showError(chemicalErrMsg);
      return false;
    }
    return true;
  };

  const saveUFData = () => {
    const data = {
      userID: userId,
      projectID: projectid,
      caseID: ProjectInfoStore?.caseId,
      treatmentName: "UF",
      treatmentObjID: caseTreatmentID,
      fromTreatmentObjID: ufData.fromTreatmentObjID,
      treatmentSeqNo: ufData.treatmentSeqNo,
      recoveryTypeID: parseInt(ufData.recoveryTypeID),
      recovery: Number(ufData.recovery),
      feed: Number(ufData.feed),
      automatic: ufData.automatic,
      recoveryRo: Number(ufData.recoveryRo),
      compactionTemperature: Number(ufData.compactionTemperature),
      isCompactionFlux: ufData.isCompactionFlux,
      uFDesignFluxID: parseInt(ufData.uFDesignFluxID),
      caseTreatmentID: parseInt(caseTreatmentID),
      filtrateFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.filtrateFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      backwashFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.backwashFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      cEBFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.cEBFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      forwardFlushFlow: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.forwardFlushFlow, "m³/h", unit.selectedUnits[1]).toFixed(
          2,
        ),
      ),
      airFlow: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.airFlow, "Nm³/h", unit.selectedUnits[18]).toFixed(2),
      ),
      aerationAirFlow: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.aerationAirFlow,
          "Nm³/h",
          unit.selectedUnits[18],
        ).toFixed(2),
      ),
      recycleFlowRate: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.recycleFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(
          2,
        ),
      ),
      recycleFlowRate_MiniCIP: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.recycleFlowRate_MiniCIP,
          "m³/h",
          unit.selectedUnits[1],
        ).toFixed(2),
      ),
      uFModuleID: parseInt(ufData.uFModuleID),
      flow_FF1: Number(ufData.flow_FF1),
      flow_FF2: Number(ufData.flow_FF2),
      flow_FF3: Number(ufData.flow_FF3),
      flow_FF4: Number(ufData.flow_FF4),
      aDBWDisplacement: Number(ufData.aDBWDisplacement),
      fTLDisplacement: Number(ufData.fTLDisplacement),
      typicalWaitDuration_Dupont: Number(ufData.typicalWaitDuration_Dupont),
      typicalPumpRamp_Dupont: Number(ufData.typicalPumpRamp_Dupont),
      typicalWaitDuration_Inge: Number(ufData.typicalWaitDuration_Inge),
      typicalPumpRamp_Inge: Number(ufData.typicalPumpRamp_Inge),
      typicalWaitDuration_Memcor: Number(ufData.typicalWaitDuration_Memcor),
      typicalPumpRamp_Memcor: Number(ufData.typicalPumpRamp_Memcor),
      uFDesignCycleIntervalsID: parseInt(ufData.uFDesignCycleIntervalsID),
      backwash_design: Number(ufData.backwash_design),
      airScour_design: Number(ufData.airScour_design),
      acidCEB: Number(ufData.acidCEB),
      alkaliOxidantCEB: Number(ufData.alkaliOxidantCEB),
      cIP: Number(ufData.cIP),
      miniCIP: Number(ufData.miniCIP),
      disinfectionCEB: Number(ufData.disinfectionCEB),
      t_CEB_Rinse12: Number(ufData.t_CEB_Rinse12),
      t_CEB_Rinse2: Number(ufData.t_CEB_Rinse2),
      bwRinseDrainTop: Number(ufData.bwRinseDrainTop),
      bwRinseDrainBottom: Number(ufData.bwRinseDrainBottom),
      uFBWCEBStandbyOptionID: parseInt(ufData.uFBWCEBStandbyOptionID),
      bWPerCEBstandbyTrains: parseInt(ufData.bWPerCEBstandbyTrains),
      uFConfigurationID: parseInt(ufData.uFConfigurationID),
      uFCIPStandbyTrainOptionID: parseInt(ufData.uFCIPStandbyTrainOptionID),
      cIPstandbyTrains: parseInt(ufData.cIPstandbyTrains),
      integraPackDesign_Ind: ufData.integraPackDesign_Ind,
      drinkingWaterInd: ufData.drinkingWaterInd,
      membraneintergrityoption_Ind: ufData.membraneintergrityoption_Ind,
      modulesPerSkid: parseInt(ufData.modulesPerSkid),
      modulesPerTrain: parseInt(ufData.modulesPerTrain),
      offlinetimepertrain: parseInt(ufData.offlinetimepertrain),
      onlineTrains: parseInt(ufData.onlineTrains),
      redundantStandbyTrains: parseInt(ufData.redundantStandbyTrains),
      skids: parseInt(ufData.skids),
      skidsPerTrain: parseInt(ufData.skidsPerTrain),
      uFStorageTankOptionID: parseInt(ufData.uFStorageTankOptionID),
      totalModules: parseInt(ufData.totalModules),
      totalTrains: parseInt(ufData.totalTrains),
      redundantTrains: parseInt(ufData.redundantTrains),
      isBWCEBStandbyTrainsEnabled: ufData.isBWCEBStandbyTrainsEnabled,
      radTR1: ufData.radTR1,
      radTR2: ufData.radTR2,
      radTR3: ufData.radTR3,
      radMR1: ufData.radMR1,
      radMR2: ufData.radMR2,
      radMR3: ufData.radMR3,
      uFFiltrationID: Number(ufData.uFFiltrationID),
      backwash_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.backwash_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      acidCEB_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.acidCEB_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      alkaliCEB_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.alkaliCEB_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      cIP_Filtration: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.cIP_Filtration, "bar", unit.selectedUnits[3]).toFixed(2),
      ),
      miniCIP_Filtration: Number(ufData.miniCIP_Filtration),
      strainerRecovery: Number(ufData.strainerRecovery),
      strainerSize: Number(ufData.strainerSize),
      uFTanksID: Number(ufData.uFTanksID),
      chemicalStorageTime: Number(ufData.chemicalStorageTime),
      bWTankRefillRate: Number(ufData.bWTankRefillRate),
      filterateTank: Number(ufData.filterateTank),
      bWTank: Number(ufData.bWTank),
      cIPTank: Number(ufData.cIPTank),
      uFEquipmentPressureID: Number(ufData.uFEquipmentPressureID),
      maxAirScourPressure: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.maxAirScourPressure,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      maxAirProcPressure: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.maxAirProcPressure,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      filteratePressure: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.filteratePressure, "bar", unit.selectedUnits[3]).toFixed(
          2,
        ),
      ),
      nonIntegraPacTrainPresDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.nonIntegraPacTrainPresDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      integraPacFiltrationPreDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.integraPacFiltrationPreDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      backwashPipingPreDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.backwashPipingPreDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      cIPPipingPreDrop: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.cIPPipingPreDrop, "bar", unit.selectedUnits[3]).toFixed(
          2,
        ),
      ),
      uFPowerID: Number(ufData.uFPowerID),
      pLCPowerReqPertrain: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.pLCPowerReqPertrain,
          "kW",
          unit.selectedUnits[9],
        ).toFixed(2),
      ),
      volvePowerReqPerTrain: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ufData.volvePowerReqPerTrain,
          "kW",
          unit.selectedUnits[9],
        ).toFixed(2),
      ),
      uFValvesID: Number(ufData.uFValvesID),
      valvesPerTrain: Number(ufData.valvesPerTrain),
      valveOpenCloseDuration: Number(ufData.valveOpenCloseDuration),
      uFCEBID: Number(ufData.uFCEBID),
      uFCEBWaterTypeID: parseInt(ufData.uFCEBWaterTypeID),
      ceb_AirScour: Number(ufData.ceb_AirScour),
      backWash1_CEB: Number(ufData.backWash1_CEB),
      backWash2_CEB: Number(ufData.backWash2_CEB),
      cEBTemperature: parseInt(ufData.cEBTemperature),
      chemicalSoakingDuration_CEB: parseInt(ufData.chemicalSoakingDuration_CEB),
      drain: Number(ufData.drain),
      forwardFlush: Number(ufData.forwardFlush),
      designTemperature_Ind: ufData.designTemperature_Ind,
      ceb_LSI: Number(ufData.ceb_LSI),
      sameAsBackwash_Ind: ufData.sameAsBackwash_Ind,
      alkaliEnabled_Ind_CEB: ufData.alkaliEnabled_Ind_CEB,
      organicEnabled_Ind_CEB: ufData.organicEnabled_Ind_CEB,
      oxidantEnabled_Ind_CEB: ufData.oxidantEnabled_Ind_CEB,
      mineralEnabled_Ind_CEB: ufData.mineralEnabled_Ind_CEB,
      disOxidantEnabled_Ind_CEB: ufData.disOxidantEnabled_Ind_CEB,
      mineralValue: Number(ufData.mineralValue),
      organicValue: Number(ufData.organicValue),
      oxidantValue: Number(ufData.oxidantValue),
      alkaliValue: Number(ufData.alkaliValue),
      disOxidantValue: Number(ufData.disOxidantValue),
      alkaliChemId: ufData.alkaliChemId.toString(),
      mineralChemId: ufData.mineralChemId.toString(),
      organicChemId: ufData.organicChemId.toString(),
      oxidantChemId: ufData.oxidantChemId.toString(),
      disOxidantChemId: ufData.disOxidantChemId.toString(),
      alkaliValueInPh_Ind: ufData.alkaliValueInPh_Ind,
      mineralValueInPh_Ind: ufData.mineralValueInPh_Ind,
      uFCIPID: Number(ufData.uFCIPID),
      bWStepInCIP: parseInt(ufData.bWStepInCIP),
      chemicalSoakingDuration_CIP: Number(ufData.chemicalSoakingDuration_CIP),
      uFCIPWaterTypeID: parseInt(ufData.uFCIPWaterTypeID),
      heatingStepDuration: Number(ufData.heatingStepDuration),
      cip_LSI: Number(ufData.cip_LSI),
      recycleDuration: Number(ufData.recycleDuration),
      recycleTemperature: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, ufData.recycleTemperature, "°C", unit.selectedUnits[2]).toFixed(
          2,
        ),
      ),
      rinseBWCycle: parseInt(ufData.rinseBWCycle),
      cIPRinseSoakCycle: Number(ufData.cIPRinseSoakCycle),
      alkaliEnabled_Ind_CIP: ufData.alkaliEnabled_Ind_CIP,
      organicEnabled_Ind_CIP: ufData.organicEnabled_Ind_CIP,
      oxidantEnabled_Ind_CIP: ufData.oxidantEnabled_Ind_CIP,
      mineralEnabled_Ind_CIP: ufData.mineralEnabled_Ind_CIP,
      oxidant2Enabled_Ind_CIP: ufData.oxidant2Enabled_Ind_CIP,
      mineralValue_CIP: Number(ufData.mineralValue_CIP),
      organicValue_CIP: Number(ufData.organicValue_CIP),
      oxidantValue_CIP: Number(ufData.oxidantValue_CIP),
      alkaliValue_CIP: Number(ufData.alkaliValue_CIP),
      oxidant2Value_CIP: Number(ufData.oxidant2Value_CIP),
      alkaliChemId_CIP: ufData.alkaliChemId_CIP.toString(),
      mineralChemId_CIP: ufData.mineralChemId_CIP.toString(),
      organicChemId_CIP: ufData.organicChemId_CIP.toString(),
      oxidantChemId_CIP: ufData.oxidantChemId_CIP.toString(),
      oxidant2ChemId_CIP: ufData.oxidant2ChemId_CIP.toString(),
      alkaliValueInPh_Ind_CIP: ufData.alkaliValueInPh_Ind_CIP,
      mineralValueInPh_Ind_CIP: ufData.mineralValueInPh_Ind_CIP,
      uFBackWashID: parseInt(ufData.uFBackWashID),
      bWTemperature: Number(ufData.bWTemperature),
      bWDesignTemperature_Ind: ufData.bWDesignTemperature_Ind,
      uFBWWaterTypeID: parseInt(ufData.uFBWWaterTypeID),
      uFBWFlushWaterTypeID: parseInt(ufData.uFBWFlushWaterTypeID),
      uFBWProtocolID: parseInt(ufData.uFBWProtocolID),
      oxidantID: ufData.oxidantID.toString(),
      oxidantDosage: Number(ufData.oxidantDosage),
      backwash_AirScour: Number(ufData.backwash_AirScour),
      backWash1_backWash: Number(ufData.backWash1_backWash),
      backWash2_backWash: Number(ufData.backWash2_backWash),
      drain_backWash: Number(ufData.drain_backWash),
      forwardFlush_backWash: Number(ufData.forwardFlush_backWash),
      lF: Number(ufData.lF),
      t_FTL: Number(ufData.t_FTL),
      t_BWBtnAirScour: parseInt(ufData.t_BWBtnAirScour),
      uFMiniCIPID: Number(ufData.uFMiniCIPID),
      bWStepInMiniCIP: Number(ufData.bWStepInMiniCIP),
      rinseBWCycle_MiniCIP: parseInt(ufData.rinseBWCycle_MiniCIP),
      chemicalSoakingDuration_MiniCIP: Number(ufData.chemicalSoakingDuration_MiniCIP),
      uFMiniCIPWaterTypeID: parseInt(ufData.uFMiniCIPWaterTypeID),
      heatingStepDuration_MiniCIP: Number(ufData.heatingStepDuration_MiniCIP),
      lSI_MiniCIP: Number(ufData.lSI_MiniCIP),
      recycleDuration_MiniCIP: Number(ufData.recycleDuration_MiniCIP),
      recycleTemperature_MiniCIP: Number(ufData.recycleTemperature_MiniCIP),
      cIPRinseSoakCycle_MiniCIP: Number(ufData.cIPRinseSoakCycle_MiniCIP),
      alkaliEnabled_Ind_MiniCIP: ufData.alkaliEnabled_Ind_MiniCIP,
      organicEnabled_Ind_MiniCIP: ufData.organicEnabled_Ind_MiniCIP,
      oxidantEnabled_Ind_MiniCIP: ufData.oxidantEnabled_Ind_MiniCIP,
      mineralEnabled_Ind_MiniCIP: ufData.mineralEnabled_Ind_MiniCIP,
      oxidant2Enabled_Ind_MiniCIP: ufData.oxidant2Enabled_Ind_MiniCIP,
      mineralValue_MiniCIP: Number(ufData.mineralValue_MiniCIP),
      organicValue_MiniCIP: Number(ufData.organicValue_MiniCIP),
      oxidantValue_MiniCIP: Number(ufData.oxidantValue_MiniCIP),
      alkaliValue_MiniCIP: Number(ufData.alkaliValue_MiniCIP),
      oxidant2Value_MiniCIP: Number(ufData.oxidant2Value_MiniCIP),
      alkaliChemId_MiniCIP: ufData.alkaliChemId_MiniCIP.toString(),
      mineralChemId_MiniCIP: ufData.mineralChemId_MiniCIP.toString(),
      organicChemId_MiniCIP: ufData.organicChemId_MiniCIP.toString(),
      oxidantChemId_MiniCIP: ufData.oxidantChemId_MiniCIP.toString(),
      oxidant2ChemId_MiniCIP: ufData.oxidant2ChemId_MiniCIP.toString(),
      alkaliValueInPh_Ind_MiniCIP: ufData.alkaliValueInPh_Ind_MiniCIP,
      mineralValueInPh_Ind_MiniCIP: ufData.mineralValueInPh_Ind_MiniCIP,
      ufSpecialFeatureID: parseInt(UFData.ufSpecialFeatureID),
      pUFTechnologyID: parseInt(UFData.pUFTechnologyID),
    };
    if (caseTreatmentID != 0) {
      updateUFData({
        Method: caseTreatmentID != 0 ? "uf/api/v1/SaveUFData" : "uf/api/v1/AutoSaveUFData",
        ...data,
        ["drinkingWater_Ind"]: UFStore.isForDrinkingWater,
      });
    } else {
      updateUFData({
        Method: "uf/api/v1/AutoSaveUFData",
        ...data,
        ["drinkingWater_Ind"]: UFStore.isForDrinkingWater,
      });
    }
    dispatch(setUfDataUpdate(false));
  };

  const saveIXDData = async () => {
    let finalParameterList = [];
    if (ixStoreObj.viewReport == "true" || ixStoreObj.showFinalParameterTab) {
      finalParameterList = ixStoreObj?.listFinalParamAdj;
    } else {
      finalParameterList = ixStoreObj?.existingPlantDescription;
    }
    if (finalParameterList.length <= 1) {
      let vesselCount = 0;
      if (ixStoreObj.resinName4 !== null) {
        vesselCount = 4;
      } else if (ixStoreObj.resinName3 !== null) {
        vesselCount = 3;
      } else if (ixStoreObj.resinName2 !== null) {
        vesselCount = 2;
      } else {
        vesselCount = 1;
      }
      const dummyArray = Array.from({ length: vesselCount }, (_, index) => ({
        resinType: ixStoreObj.resinData[ixStoreObj[`resinName${index + 1}`]],
        resinName: ixStoreObj[`resinName${index + 1}`],
        resinId:
          ixStoreObj[`resinName${index + 1}`] == "WAC"
            ? ixResinID1
            : ixStoreObj[`resinName${index + 1}`] == "SAC"
              ? ixResinID2
              : ixStoreObj[`resinName${index + 1}`] == "WBA"
                ? ixResinID3
                : ixResinID4,
        vesselNo: index + 1,
        resinVolumeAsDelivered: 0,
        vesselDiameter: 0,
        resinBedHeightAsDelivered: 0,
        resinBedStandardHeight: 0,
        resinBedHeightAsExhausted: 0,
        resinBedHeightAsRegenerated: 0,
        inertResinVolume: 0,
        inertBedHeight: 0,
        freeBoard: 0,
        vesselCylindricalHeight: 0,
        vesselWallThickness: 0,
        pressureDropwithRecomQty: 0,
        resinPackagingSize: 0,
        ixfpaRadioButtonID: 0,
      }));
      if (vesselCount < 1) {
        finalParameterList = dummyArray;
      }
    }
    const list = [...finalParameterList];
    finalParameterList = list.map(item =>
      convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_SAVING_DATA, convertUnitNumber),
    );
    /*----Unit conversion for regenenConditionPage start-----*/
    const [a, b] = resinVal;
    let cationTemp = resinVal[0]?.temperature;
    let anionTemp = resinVal[1]?.temperature;
    if (unit.selectedUnits[2] !== UNITS.celsius) {
      if (a) {
        cationTemp = Number(
          GlobalUnitConversion(
            GlobalUnitConversionStore,
            resinVal[0]?.temperature,
            "°C",
            unit.selectedUnits[2],
          ).toFixed(2),
        );
      }
      if (b) {
        anionTemp = Number(
          GlobalUnitConversion(
            GlobalUnitConversionStore,
            resinVal[1]?.temperature,
            "°C",
            unit.selectedUnits[2],
          ).toFixed(2),
        );
      }
    }
    const [Ra, Rd] = ixRegenreteDose;
    // averageConductivityVal
    let cationRegenreteDoseVel = ixRegenreteDose[0]?.regenerantDoseVal4;
    let anionRegenreteDoseVel = ixRegenreteDose[1]?.regenerantDoseVal4;
    let cationAverageConduc = ixRegenreteDose[0]?.averageConductivityVal;
    let anionAverageConduc = ixRegenreteDose[1]?.averageConductivityVal;
    let cationendpointConduc = ixRegenreteDose[0]?.endpointConductivityVal;
    let anionendpointConduc = ixRegenreteDose[1]?.endpointConductivityVal;
    if (Ra) {
      cationRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      cationAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
      cationendpointConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.endpointConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }
    if (Rd) {
      anionRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      anionAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
      anionendpointConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.endpointConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }
    /*----Unit conversion for regenenConditionPage end-----*/
    /*----Unit conversion for Advance Regeneration start-----*/
    const [c, d] = ixStoreAdvance;
    let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
    let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
    let cationDisVol = ixStoreAdvance[0]?.displacementVolume;
    let anionDisVol = ixStoreAdvance[1]?.displacementVolume;
    let cationFasVol = ixStoreAdvance[0]?.fatRinseVolume;
    let anionFasVol = ixStoreAdvance[1]?.fatRinseVolume;
    if (c) {
      cationregenVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
      cationDisVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.displacementVolume,
        "BV",
        unit.selectedUnits[13],
      );
      cationFasVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.fatRinseVolume,
        "BV",
        unit.selectedUnits[13],
      );
    }
    if (d) {
      anionregeneVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
      anionDisVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.displacementVolume,
        "BV",
        unit.selectedUnits[13],
      );
      anionFasVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.fatRinseVolume,
        "BV",
        unit.selectedUnits[13],
      );
    }
    /*----Unit conversion for Vessel Regeneration end-----*/
    const MethodName = { Method: "ix/api/v1/IXData" };
    const IXData_Method_Body = {
      ...MethodName,
      ...ixStore,
      ["treatmentObjID"]: caseTreatmentIDIXD,
      ["caseTreatmentID"]: caseTreatmentIDIXD,
      ["space_velocity_txt"]: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ixStore.space_velocity_txt,
          "BV/h",
          unit.selectedUnits[10],
        ).toFixed(2),
      ),
      selectedResinList: [
        { ...resinItem1, ["inert"]: resinItem1?.inert || 0 },
        { ...resinItem2, ["inert"]: resinItem2?.inert || 0 },
      ],
      listRegenConds: [
        { ...ixStore.listRegenConds[0], ["temperature"]: cationTemp },
        { ...ixStore.listRegenConds[1], ["temperature"]: anionTemp },
      ],
      listAdvRegen: [
        {
          ...ixStore.listAdvRegen[0],
          ["regenerationVelocity"]: Number(cationregenVel?.toFixed(2)),
          ["displacementVolume"]: Number(cationDisVol?.toFixed(2)),
          ["fatRinseVolume"]: Number(cationFasVol?.toFixed(2)),
        },
        {
          ...ixStore.listAdvRegen[1],
          ["regenerationVelocity"]: Number(anionregeneVel?.toFixed(2)),
          ["displacementVolume"]: Number(anionDisVol?.toFixed(2)),
          ["fatRinseVolume"]: Number(anionFasVol?.toFixed(2)),
        },
      ],
      listProductQualityandregeneration: [
        {
          ...ixStore.listProductQualityandregeneration[0],
          ["regenerantDoseVal4"]: Number(cationRegenreteDoseVel?.toFixed(2)),
          ["averageConductivityVal"]: Number(cationAverageConduc?.toFixed(2)),
          ["endpointConductivityVal"]: Number(cationendpointConduc?.toFixed(2)),
        },
        {
          ...ixStore.listProductQualityandregeneration[1],
          ["regenerantDoseVal4"]: Number(anionRegenreteDoseVel?.toFixed(2)),
          ["averageConductivityVal"]: Number(anionAverageConduc?.toFixed(2)),
          ["endpointConductivityVal"]: Number(anionendpointConduc?.toFixed(2)),
        },
      ],
      listFinalParamAdj: ixStoreObj.showFinalParameterTab ? finalParameterList : [],
      selectedProcessID: ixStore.selectedProcessID,
      treatmentName: ixStore.treatmentName,

      //Equipment details in IX Technology
      pdExtPiping: unitConversionByName(ixStore.pdExtPiping, "bar", unit.selectedUnits[3]),
      pdIntDistributor: unitConversionByName(ixStore.pdIntDistributor, "bar", unit.selectedUnits[3]),
      effluentPressure: unitConversionByName(ixStore.effluentPressure, "bar", unit.selectedUnits[3]),
      tankTemperature: Number(unitConversionByName(ixStore.tankTemperature, "°C", unit.selectedUnits[2])),
      backwashTowerDiameter: Number(unitConversionByName(ixStore.backwashTowerDiameter, "mm", unit.selectedUnits[8])),
      sacRegenVesselDia: Number(unitConversionByName(ixStore.sacRegenVesselDia, "mm", unit.selectedUnits[8])),
      sbaRegenVesselDia: Number(unitConversionByName(ixStore.sbaRegenVesselDia, "mm", unit.selectedUnits[8])),
    };
    await IXData_PostData(IXData_Method_Body);
    dispatch(setIXDUpdate(false));
  };

  //tab changing functions
  const changePanel = (tab, index) => {
    let canMoveToReport = false;
    if (tab.heading !== "Report") {
      SaveUFJSONData(false);
    }
    if (!isLoading) {
      if (validateTabChange(tab, index).canChange) {
        if (selectedIndex === "System Design") {
          if (!needToRetriveData) {
            changeTabsControler(tab, index);
            updateDataFunction();
            canMoveToReport = true;
          }
        } else if (selectedIndex === "Feed Setup") {
          if (FeedStreamData.lstrequestsavefeedwater[0].streams.length > 0) {
            canMoveToReport = updateFeedsetDataFunction(tab, index);
          }
        } else if (selectedIndex === "UF") {
          if (tab.heading !== "UF") {
            saveUFData();
          }
          canMoveToReport = true;
          changeTabsControler(tab, index);
        } else if (selectedIndex === "IXD" || selectedIndex === "IXMB") {
          dispatch(
            updateUFStore({
              ...UFData,
              ["isWaterSubTypeChanged"]: false,
              ["isDesignTempChanged"]: false,
            }),
          );
          if (tab.heading !== "IXD") {
            saveIXDData();
          }
          const validationErrorMessage = validateFPA();
          if (validationErrorMessage) {
            setErrorOperator({
              show: true,
              message: validationErrorMessage,
            });
          } else {
            changeTabsControler(tab, index);
            canMoveToReport = true;
          }
        } else if (selectedIndex === "Report") {
          changeTabsControler(tab, index);
          canMoveToReport = true;
        }
      }

      if (tab.heading === "Report" && canMoveToReport) {
        if (techNolist.includes("UF") && ((tabAvailableForUF.UF && tabAvailableForUF.FeedSetup) || !ufReportLoading)) {
          SaveUFJSONData();
          changeTabsControler(tab, index);
        } else if (
          (techNolist.includes("IXD") || techNolist.includes(TECH_IXMB)) &&
          ((isIXD && isFeedSetup) || !IXDloadder)
        ) {
          const list = [...listFinalParamAdj];
          const reportListFinal = list.map(item =>
            convertAndFormatProperties(item, PROPERTIES_TO_CONVERT_CALC_ENGINE_DATA, convertUnitNumber),
          );
          SaveIXDJSONData(reportListFinal);
          changeTabsControler(tab, index);
        }
        if (technologyAdded !== true) {
          setIsReportError(true);
          setSelectedIndex("System Design");
          setPanelIndex(0);
          setCurrentPanel("System Design");
          setScrollCheck(!scrollCheck);
        }
      }
    }
  };
  const validateTabChange = (tab, index) => {
    const isUFIncluded = techNolist.includes("UF");
    const isUFAndFeedSetupAvailable = tabAvailableForUF.UF && tabAvailableForUF.FeedSetup;
    const isIXDAndFeedSetupAvailable = isIXD && isFeedSetup;
    switch (tab.heading) {
      case "System Design":
        return { canChange: true, tab: tab, index: index };
      case "Feed Setup":
        if (projectid == 0 || ProjectInfoStore?.caseId == 0) {
          return { canChange: false, tab: tab, index: index };
        } else {
          return { canChange: true, tab: tab, index: index };
        }

      case "UF":
        if (StreamStoreData?.waterTypeID == 0) {
          return { canChange: false, tab: tab, index: index };
        } else {
          return { canChange: true, tab: tab, index: index };
        }
      case "IXMB":
      case "IXD":
        if (cationValues.length === 0 && anionValues.length === 0 && neutralsValues.length === 0) {
          setIsFeedError(true);
          setSelectedIndex("Feed Setup");
          setPanelIndex(1);
          setCurrentPanel("Feed Setup");
          setScrollCheck(!scrollCheck);

          return { canChange: false, tab: tab, index: index };
        } else {
          return { canChange: true, tab: tab, index: index };
        }

      case "Report":
        return {
          canChange: isUFIncluded
            ? isUFAndFeedSetupAvailable || !ufReportLoading
            : isIXDAndFeedSetupAvailable || !IXDloadder,
          tab: tab,
          index: index,
        };
    }
  };
  const changeTabsControler = (tab, index) => {
    if (tab.heading === "Feed Setup") {
      dispatch(updateTabAvailable({ ...tabAvailable, FeedSetup: true }));
      dispatch(updateTabAvailableForUF({ ...tabAvailableForUF, FeedSetup: true }));
    } else if (tab.heading === "IXD" || tab.heading === "IXMB") {
      dispatch(updateTabAvailable({ ...tabAvailable, IXD: true }));
    } else if (tab.heading === "UF") {
      dispatch(updateTabAvailableForUF({ ...tabAvailableForUF, UF: true }));
    } else if (tab.heading === "Report") {
      if (checkChemicalError()) {
        setSelectedIndex(tab.heading);
        setPanelIndex(index);
        setCurrentPanel(tab.heading);
        setScrollCheck(!scrollCheck);
      }
    }
    if (tab.heading !== "Report") {
      setSelectedIndex(tab.heading);
      setPanelIndex(index);
      setCurrentPanel(tab.heading);
      setScrollCheck(!scrollCheck);
    }
  };

  const givePanel = id => {
    switch (id) {
      case "System Design":
        return (
          <div className='panel'>
            <SystemDesign />
          </div>
        );
      case "Feed Setup":
        return (
          <div className='panel'>
            <FeedSetup />
          </div>
        );
      case "UF":
        return (
          <div className='panel'>
            <UF />
          </div>
        );
      case "IXD":
        return (
          <div className='panel'>
            <IXD techObjID={5} ixTreatment='IXD' />
          </div>
        );
      case "IXMB":
        return (
          <div className='panel'>
            <IXD techObjID={9} ixTreatment='IXMB' />
          </div>
        );
      case "Report":
        return (
          <div className='panel'>
            <Report />
          </div>
        );
    }
  };

  const isTabDisabled = tab => {
    if (tab.heading === selectedIndex) {
      return false;
    }
    return isLoading || !isFeedDataFetched || !FeedStoreData;
  };

  return (
    <>
      <ActivityMonitorStyled className='feed-progress-row g-0' scrollDirection={scrollDirection}>
        <Tabs
          // selectedIndex={selectedTab}
          // onSelect={handleTabClick}
          defaultIndex={panelIndex}
        >
          <TabList className='feed-progress-top-column'>
            {addedTechnology.map((tab, index) => (
              <Tab
                disabled={isTabDisabled(tab)}
                disabledClassName='tab-disabled'
                className={`feed-progress-column ${
                  panelIndex === index ? "selected_activity_monitor_tab" : "react-tabs"
                }`}
                onClick={() => changePanel(tab, index)}
                key={tab.id}
              >
                <div className='activity_monitor'>
                  <div className={`activity_monitor_count ${panelIndex === index ? "selected_circle" : ""}`}>
                    {index + 1}
                  </div>
                  <div className='activity_monitor_name'>
                    <div className='activity_monitor_name_heading'>
                      <div>
                        <CustomHeading
                          label={tab.heading}
                          lineHeight={"20px"}
                          fontFamily='NotoSansSemiBold'
                          color={panelIndex === index ? colors.PrimaryDarkAquaMarine : colors.Grey96}
                        />
                      </div>
                      <div className='activity_monitor_progress_bar'>
                        {tab.heading === "System Design" ? (
                          <ProgressBrokenIcon />
                        ) : (
                          <div className='dynamic_range'></div>
                        )}
                      </div>
                    </div>
                    <div className='activity_monitor_name_progress'>
                      <CustomHeading label={tab.subHeading} lineHeight={"12px"} fontSize={"10px"} />
                    </div>
                  </div>
                </div>
                <div className={`activity_monitor_triangle ${panelIndex === index ? "selected" : ""}`}>
                  <ActivityMonitorTriangle />
                </div>
              </Tab>
            ))}
            <Tab
              disabled={isTabDisabled({ heading: "Report" })}
              disabledClassName='tab-disabled'
              className={`feed-progress-column ${
                panelIndex === addedTechnology.length ? "selected_activity_monitor_tab" : "react-tabs"
              }`}
              onClick={() => changePanel({ heading: "Report" }, addedTechnology.length)}
            >
              <div className='activity_monitor'>
                <div
                  className={`activity_monitor_count ${panelIndex === addedTechnology.length ? "selected_circle" : ""}`}
                >
                  {addedTechnology.length + 1}
                </div>
                <div className='activity_monitor_name'>
                  <div className='activity_monitor_name_heading'>
                    <div>
                      <CustomHeading
                        label={"Report"}
                        lineHeight={"20px"}
                        fontFamily='NotoSansSemiBold'
                        color={panelIndex === addedTechnology.length ? colors.PrimaryDarkAquaMarine : colors.Grey96}
                      />
                    </div>
                    <div className='activity_monitor_progress_bar'>
                      <div className='dynamic_range'></div>
                    </div>
                  </div>
                  <div className='activity_monitor_name_progress'>
                    <CustomHeading
                      label={"Finish all steps to generate report."}
                      lineHeight={"12px"}
                      fontSize={"10px"}
                    />
                  </div>
                </div>
              </div>
              <div className={`activity_monitor_triangle ${panelIndex === addedTechnology.length ? "selected" : ""}`}>
                <ActivityMonitorTriangle />
              </div>
            </Tab>
          </TabList>
          <DynamicLoadder isLoading={!isFeedDataFetched}>{givePanel(selectedIndex)}</DynamicLoadder>
        </Tabs>
        {isFeedError && (
          <>
            <ProjectErrorPopup
              show={isFeedError}
              close={() => {
                setIsFeedError(false);
              }}
              message={`For ${ixStore.treatmentName} System Specifications, at least one ion should have non-zero value.`}
            />
          </>
        )}
        {isReportError && (
          <>
            <ProjectErrorPopup
              show={isReportError}
              close={() => {
                setIsReportError(false);
              }}
              message='Please select at least one treatment option.'
            />
          </>
        )}
        {isFeedsetupError && (
          <>
            <ProjectErrorPopup
              show={isFeedsetupError}
              close={() => {
                setisFeedsetupError(false);
              }}
              message='Please charge-balance the Feedwater using the Add Solutes or Adjust Solutes buttons at the top of the Feed Water Tab.'
            />
          </>
        )}
        {errorOperator.show && (
          <ProjectErrorPopup show={errorOperator.show} close={closeErrorMessag} message={errorOperator.message} />
        )}
      </ActivityMonitorStyled>
    </>
  );
};

export default ActivityMonitor;
